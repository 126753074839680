import { LoadScript } from '@react-google-maps/api';
import { GOOGLE_MAP_API_KEY } from 'const';

const libraries = ['places'];

function LoadGoogleMapScript({ children }) {
    return (
        <LoadScript googleMapsApiKey={GOOGLE_MAP_API_KEY} libraries={libraries}>
            {children}
        </LoadScript>
    );
}

export default LoadGoogleMapScript;
