import { Fragment } from 'react';
import Navigation from 'components/Navigation';
import { useStepController } from 'hooks';
import { history } from 'routes';
import { isArrayEmpty } from 'lib';
import { InlineLoading } from 'carbon-components-react';

function AdditionalIncome({ spouse, checking, income }) {
    const { nextStep, previousStep } = useStepController();

    if (checking) return <Loader />;

    return (
        <Fragment>
            {isArrayEmpty(income) ? (
                <NotFoundAdditionalIncome spouse={spouse} />
            ) : (
                <FoundAdditionalIncome spouse={spouse} />
            )}

            <Navigation
                onNext={() => history.push(nextStep.path)}
                onPrevious={() => history.push(previousStep.path)}
            />
        </Fragment>
    );
}

const Loader = () => (
    <InlineLoading
        status="active"
        description={<strong>Checking Additional Income</strong>}
    />
);

const FoundAdditionalIncome = ({ spouse }) => (
    <Fragment>
        <h1>
            We’ve Found{' '}
            <span className="color-blue">
                Additional Income {spouse ? 'For Your Spouse' : 'For You'}
            </span>{' '}
            That Needs Verification!
        </h1>
        <p>
            Some of your deposits require further clarification to better
            represent your actual monthly income.
        </p>
    </Fragment>
);

const NotFoundAdditionalIncome = ({ spouse }) => (
    <h1>
        We did not find any{' '}
        <span className="color-blue">
            additional income {spouse ? 'for your spouse' : 'for you'}
        </span>
        . Please <span className="color-blue">continue</span> by clicking the{' '}
        <span className="color-blue">next button</span> below.
    </h1>
);

export default AdditionalIncome;
