import { useReducer, useEffect, useContext } from 'react';
import AdditionalInfoContext from './AdditionalInfoContext';
import reducerAdditionalInfo, {
    initAdditionalInfo,
} from './reducerAdditionalInfo';
import { contextActionTypes, PersonalInfoContext } from 'contexts';
import { useAdditionalInfo } from 'hooks';
import { STATUS_MARRIED, STATUS_MARRIED_SEPARATELY } from 'const';
import InvestmentContext from 'contexts/InvestmentContext/InvestmentContext';

const {
    UPDATE_ADDITIONAL_INFO,
    UPDATE_SPOUSE_ADDITIONAL_INFO,
    HAS_ADDITIONAL_PROPERTY,
    HAS_ADDITIONAL_PROPERTY_SPOUSE,
    HAS_ADDITIONAL_VEHICLE,
    HAS_ADDITIONAL_VEHICLE_SPOUSE,
} = contextActionTypes;

function checkNullValue(value, defaultReturnValue = null) {
    if (typeof value === 'boolean') {
        return value;
    } else {
        return defaultReturnValue === false ? false : true;
    }
}

function AdditionalInfoProvider({ children }) {
    const [additionalInfo, dispatchAdditionalInfo] = useReducer(
        reducerAdditionalInfo,
        initAdditionalInfo
    );
    const { personalUid, spouseUid, filingStatus } = useContext(
        PersonalInfoContext
    );
    const { dispatchInvestments } = useContext(InvestmentContext);

    const clientAdditionalInfo = useAdditionalInfo(personalUid);
    const spouseAdditionalInfo = useAdditionalInfo(spouseUid);

    useEffect(() => {
        const {
            dependents,
            earning_any_income,
            has_separate_business_account,
            spouses_contribution_to_household_income,
            income_type,
            spouses_online_bank_info,
            spouses_employment_info,
            spouses_business_structure_info,
            has_spouses_asset_investment,
            provide_spouses_asset_investment_info,
            has_additional_properties,
            has_additional_vehicles,
            spouses_income_in_your_bank,
            has_manual_business_bank,
            has_manual_personal_bank,
            has_manual_additional_income,
            has_investments,
        } = clientAdditionalInfo;

        dispatchAdditionalInfo({
            type: UPDATE_ADDITIONAL_INFO,
            payload: {
                dependents: checkNullValue(dependents),
                earning_any_income: checkNullValue(earning_any_income),
                has_business_banks: checkNullValue(
                    has_separate_business_account
                ),
                spouse_contribute_household: checkNullValue(
                    spouses_contribution_to_household_income
                ),
                income_type,
                spouses_online_bank_info: checkNullValue(
                    spouses_online_bank_info
                ),
                spouses_employment_info: checkNullValue(
                    spouses_employment_info
                ),
                spouses_business_structure_info: checkNullValue(
                    spouses_business_structure_info
                ),
                has_spouses_asset_investment: checkNullValue(
                    has_spouses_asset_investment
                ),
                provide_spouses_asset_investment_info: checkNullValue(
                    provide_spouses_asset_investment_info
                ),
                spouses_income_in_your_bank: checkNullValue(
                    spouses_income_in_your_bank
                ),
                has_manual_business_bank: checkNullValue(
                    has_manual_business_bank,
                    false
                ),
                has_manual_personal_bank: checkNullValue(
                    has_manual_personal_bank,
                    false
                ),
                has_manual_additional_income: checkNullValue(
                    has_manual_additional_income,
                    false
                ),
                has_investments: checkNullValue(has_investments),
            },
        });

        // Update client has additional property
        dispatchInvestments({
            type: HAS_ADDITIONAL_PROPERTY,
            payload: checkNullValue(has_additional_properties),
        });

        // Update client has additional vehicles
        dispatchInvestments({
            type: HAS_ADDITIONAL_VEHICLE,
            payload: checkNullValue(has_additional_vehicles),
        });
    }, [clientAdditionalInfo, dispatchInvestments]);

    useEffect(() => {
        if (
            (filingStatus === STATUS_MARRIED ||
                filingStatus === STATUS_MARRIED_SEPARATELY) &&
            spouseAdditionalInfo
        ) {
            const {
                has_separate_business_account,
                income_type,
                has_additional_properties,
                has_additional_vehicles,
                has_manual_business_bank,
                has_manual_personal_bank,
                has_manual_additional_income,
                has_investments,
            } = spouseAdditionalInfo;

            dispatchAdditionalInfo({
                type: UPDATE_SPOUSE_ADDITIONAL_INFO,
                payload: {
                    has_business_banks: checkNullValue(
                        has_separate_business_account
                    ),
                    income_type,
                    has_manual_business_bank: checkNullValue(
                        has_manual_business_bank,
                        false
                    ),
                    has_manual_personal_bank: checkNullValue(
                        has_manual_personal_bank,
                        false
                    ),
                    has_manual_additional_income: checkNullValue(
                        has_manual_additional_income,
                        false
                    ),
                    has_investments: checkNullValue(has_investments),
                },
            });

            // Update spouse has additional property
            dispatchInvestments({
                type: HAS_ADDITIONAL_PROPERTY_SPOUSE,
                payload: checkNullValue(has_additional_properties),
            });

            // Update spouse has additional vehicles
            dispatchInvestments({
                type: HAS_ADDITIONAL_VEHICLE_SPOUSE,
                payload: checkNullValue(has_additional_vehicles),
            });
        }
    }, [spouseAdditionalInfo, filingStatus, dispatchInvestments]);

    return (
        <AdditionalInfoContext.Provider
            value={{
                additionalInfo,
                dispatchAdditionalInfo,
            }}
        >
            {children}
        </AdditionalInfoContext.Provider>
    );
}

export default AdditionalInfoProvider;
