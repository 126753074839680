import { ButtonBlock } from '../Buttons';
import PropTypes from 'prop-types';
import Styled from 'styled-components';

// Page Navigation Component
function Navigation(props) {
    const {
        onPrevious,
        onNext,
        disableNext,
        disablePrev,
        className,
        typeNext,
        nextText,
        previousText,
    } = props;

    return (
        <NavWrapper className={`bx--grid bx--no-gutter ${className}`}>
            <div className="bx--row">
                <div className="bx--col">
                    <ButtonBlock
                        color="gray"
                        onClick={onPrevious}
                        disabled={disablePrev}
                    >
                        {previousText}
                    </ButtonBlock>
                </div>
                <div className="bx--col">
                    <ButtonBlock
                        onClick={onNext}
                        disabled={disableNext}
                        type={typeNext}
                    >
                        {nextText}
                    </ButtonBlock>
                </div>
            </div>
        </NavWrapper>
    );
}

const NavWrapper = Styled.div`
    margin-top: 40px;

    @media (max-width: 767px){
        margin-top: 0;
        position: fixed;
        left: 0;
        right: 0;
        z-index: 999;
        bottom: 0;
        padding: 17.5px 32px;
        background: #fff;
        border-top: 1px solid #E5E5E5;
    }
`;

Navigation.propTypes = {
    onPrevious: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    disableNext: PropTypes.bool,
    className: PropTypes.string,
    typeNext: PropTypes.string,
    nextText: PropTypes.string,
    previousText: PropTypes.string,
};

Navigation.defaultProps = {
    onPrevious: () => {},
    onNext: () => {},
    disableNext: false,
    className: '',
    typeNext: 'button',
    nextText: 'Next',
    previousText: 'Previous',
};

export default Navigation;
