import { toCapitalize, toUsCurrency } from 'lib';
import { Fragment } from 'react';
import styled from 'styled-components';

const Investments = ({ accounts }) => {
    if (Array.isArray(accounts) && accounts.length) {
        return (
            <Fragment>
                <TitlePart>Investment info</TitlePart>
                {accounts.map((acc, id) => {
                    return (
                        <Wrapper key={`vehicle_${id}`}>
                            <h1>
                                Investment Account
                                <span className="color-blue">({id + 1})</span>
                            </h1>
                            <p>
                                <span>Account Type </span>
                                <span>
                                    :&nbsp;{toCapitalize(acc.account_sub_type)}
                                </span>
                            </p>
                            <p>
                                <span>Name of Institution </span>
                                <span>:&nbsp;{acc.institution_name}</span>
                            </p>
                            <p>
                                <span>Market Value </span>
                                <span>
                                    :&nbsp;
                                    {toUsCurrency(acc.current_market_value)}
                                </span>
                            </p>
                            <p>
                                <span>Loan Value </span>
                                <span>
                                    :&nbsp;{toUsCurrency(acc.loan_balance)}
                                </span>
                            </p>
                            <p>
                                <span>Account Number </span>
                                <span>:&nbsp;{acc.account_number}</span>
                            </p>
                            <p>
                                <span>Withdrawable </span>
                                <span>:&nbsp;{acc.withdrawable ? 'YES' : 'NO'}</span>
                            </p>
                        </Wrapper>
                    );
                })}
            </Fragment>
        );
    }

    return (
        <TitlePart>
            Investment info <span className="color-blue">(0)</span>
        </TitlePart>
    );
};

const TitlePart = styled.h1`
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.02px;
    text-align: left;
    color: #0f173a;
    position: relative;
    padding-left: 15px;
    &:before {
        position: absolute;
        content: '';
        top: 5px;
        left: 0;
        width: 0;
        height: 0;
        border-top: 7px solid #000000;
        border-right: 9px solid transparent;
    }
`;

const Wrapper = styled.div`
    &:not(:last-child) {
        border-bottom: 1px solid rgb(112 112 112 / 0.16);
        margin-bottom: 7px;
    }
    h1 {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #0f173a;
    }
    p {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #4f5260;
        display: flex;
        align-items: center;
        span {
            &:first-child {
                width: 65%;
                display: block;
            }
        }
    }
`;

export default Investments;
