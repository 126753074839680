import { Fragment, useEffect, useState, useMemo, useContext } from 'react';
import Step from './ProgressStep';
import { PersonalInfoContext } from 'contexts';
import Navigation from 'components/Navigation';
import { PROGRESS_KEY } from 'const';
import { useStepController } from 'hooks';
import { history } from 'routes';

const { personal, dependent, bank, employment, investment } = PROGRESS_KEY;

const STEPS = {
    [personal]: 'Personal Info verification',
    [dependent]: 'Dependent Info',
    [bank]: 'Link Bank Accounts',
    [employment]: 'Employment, Income & Expense',
    [investment]: 'Retirement & Investments',
};

function Progress({ completedProgress }) {
    const { personalInfo } = useContext(PersonalInfoContext);
    const [currentIndex, updateIndex] = useState(0);
    const { nextStep, previousStep } = useStepController();

    const stepKeys = useMemo(() => Object.keys(STEPS), []);

    useEffect(() => {
        updateIndex(stepKeys.indexOf(completedProgress));
    }, [completedProgress, stepKeys]);

    return (
        <Fragment>
            <h1 className="mb-25">Thank you, {' '}<span className="color-blue">{personalInfo.first_name}!</span>{' '} Let’s continue to the next step.</h1>

            {stepKeys.map((step, count) => (
                <Step
                    key={count}
                    selected={count <= currentIndex}
                    currentStep={count === currentIndex}
                    count={count + 1}
                    finalStep={count === stepKeys.length - 1}
                    name={STEPS[step]}
                />
            ))}

            <Navigation
                onNext={() => history.push(nextStep.path)}
                onPrevious={() => history.push(previousStep.path)}
                nextText="Let’s Continue"
            />
        </Fragment>
    );
}

Progress.defaultProps = {
    completedProgress: personal,
};

export default Progress;
