import { Fragment, useContext, useEffect, useState } from 'react';
import Navigation from 'components/Navigation';
import { FormGroup } from 'carbon-components-react';
import { PersonalInfoContext } from 'contexts';
import { Formik } from 'formik';
import { useStepController } from '../../hooks';
import { history } from 'routes';
import * as Yup from 'yup';
import api, { endpoints } from 'api';
import { AmountInput } from 'components/Utilities';
import {
    enableDotAmountCheck,
    enableMaxAmountCheck,
    enableMinAmountCheck,
} from 'lib/yupValidMethods';
import { parseAmount } from 'lib';
import { MAX_AMOUNT, MAX_AMOUNT_US, MIN_AMOUNT } from 'const';

const initialValues = { income: '' };

function ManualEntryVerificartion({ spouse }) {
    const { personalUid, spouseUid } = useContext(PersonalInfoContext);
    const [manualEntry, updateManualEntry] = useState(initialValues);
    const { nextStep, previousStep } = useStepController();

    useEffect(() => {
        const manualEntryIncome = async () => {
            try {
                const req = await api.get(
                    `${endpoints.CLIENT_INCOME}?profile=${
                        spouse ? spouseUid : personalUid
                    }`,
                    {
                        params: {
                            income_type: 31,
                        },
                    }
                );
                const res = await req.data;
                if (res) {
                    updateManualEntry({ income: res[0].amount });
                }
            } catch (e) {
                console.log(e.response);
            }
        };
        if ((spouse && spouseUid) || (!spouse && personalUid)) {
            manualEntryIncome();
        }
    }, [personalUid, spouseUid, spouse]);

    const incomeVerification = async (values) => {
        const data = {
            income_type: 31,
            amount: parseAmount(values.income),
            profile: spouse ? spouseUid : personalUid,
        };
        try {
            const req = await api.post(`${endpoints.CLIENT_INCOME}`, data);
            const res = await req.data;
            if (res) {
                history.push(nextStep.path);
            }
        } catch (e) {
            console.log(e.response);
        }
    };

    return (
        <Formik
            initialValues={manualEntry}
            onSubmit={incomeVerification}
            validationSchema={inputValidate}
            enableReinitialize={true}
        >
            {({ isValid, handleSubmit }) => (
                <form>
                    <h1>
                        Help Us Calculate A More Accurate Income
                        {spouse ? (
                            <Fragment>
                                {' '}
                                for your{' '}
                                <span className="color-blue">Spouse</span>
                            </Fragment>
                        ) : (
                            ''
                        )}
                    </h1>
                    <Fragment>
                        <p>
                            Since none of our estimations were accurate, please
                            enter your{' '}
                            {spouse ? (
                                <Fragment>
                                    <span className="color-blue">Spouse's</span>
                                </Fragment>
                            ) : (
                                ''
                            )}{' '}
                            monthly income estimation below, then click
                            continue.
                        </p>
                        <h2>
                            Estimate Your{' '}
                            {spouse ? (
                                <Fragment>
                                    <span className="color-blue">Spouse's</span>
                                </Fragment>
                            ) : (
                                ''
                            )}{' '}
                            Additional Net Income:
                        </h2>
                        <FormGroup legendText={''}>
                            <AmountInput
                                placeholder="$"
                                name="income"
                                labelText="Estimated Monthly Income"
                            />
                        </FormGroup>
                        <Navigation
                            disableNext={!isValid}
                            onNext={handleSubmit}
                            onPrevious={() => history.push(previousStep.path)}
                        />
                    </Fragment>
                </form>
            )}
        </Formik>
    );
}

enableDotAmountCheck();
enableMinAmountCheck();
enableMaxAmountCheck();

const inputValidate = Yup.object().shape({
    income: Yup.string()
        .required('Please enter monthly income')
        .onlyDot('Monthly income is not valid')
        .minAmount(MIN_AMOUNT, 'Monthly income is not valid')
        .maxAmount(MAX_AMOUNT, `Maximum income can be ${MAX_AMOUNT_US}`),
});

export default ManualEntryVerificartion;
