import actionTypes from '../context-action-types';

const {
    STORE_PERSONAL_INFO,
    STORE_FILING_STATUS,
    STORE_SPOUSE_INFO,
    NO_OF_DEPENDENTS,
    CHANGE_SPOUSE_DETAIL,
    CHANGE_BANK_TYPES,
    CHANGE_SPOUSE_BANK_TYPES,
} = actionTypes;

const initialPersonalInfo = {
    personalInfo: {
        addresses: [],
    },
    spouseInfo: {
        addresses: [],
    },
    fullName: '',
    spouseName: '',
    personalUid: '',
    spouseUid: '',
    filingStatus: 'single',
    noOfDependents: null,
    spouseDetail: undefined,
    personalBankTypes: {
        personal_bank_type: true,
        business_bank_type: false,
        joint_bank_type: false,
    },
    spouseBankTypes: {
        personal_bank_type: true,
        business_bank_type: false,
        joint_bank_type: false,
    },
};

const reducerPersonalInfo = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case STORE_PERSONAL_INFO:
            return {
                ...state,
                personalInfo: payload,
                fullName: `${payload.first_name} ${payload.last_name}`, // Person Full Name
                personalUid: payload.uid,
            };

        case STORE_SPOUSE_INFO:
            return {
                ...state,
                spouseInfo: payload,
                spouseName: `${payload.first_name} ${payload.last_name}`, // Spouse Full Name
                spouseUid: payload.uid,
            };

        case STORE_FILING_STATUS:
            return {
                ...state,
                filingStatus: payload,
            };

        case NO_OF_DEPENDENTS:
            return {
                ...state,
                noOfDependents: payload,
            };

        case CHANGE_SPOUSE_DETAIL:
            return {
                ...state,
                spouseDetail: payload,
            };

        case CHANGE_BANK_TYPES:
            return {
                ...state,
                personalBankTypes: {
                    ...state.personalBankTypes,
                    ...payload, // Payload is object
                },
            };

        case CHANGE_SPOUSE_BANK_TYPES:
            return {
                ...state,
                spouseBankTypes: {
                    ...state.spouseBankTypes,
                    ...payload, // Payload is object
                },
            };

        default:
            throw new Error();
    }
};

export default reducerPersonalInfo;
export { initialPersonalInfo };
