import { useContext, useEffect, useState } from 'react';
import Navigation from 'components/Navigation';
import { Formik } from 'formik';
import {
    FormGroup,
    RadioButtonGroup,
    RadioButton,
} from 'carbon-components-react';
import { DepositAverage } from 'components/ListItems';
import { PersonalInfoContext, contextActionTypes } from '../../../contexts';
import { history } from 'routes';
import { useStepController } from '../../../hooks';
import styled from 'styled-components';
import api, { endpoints } from 'api';
const {
    SELF_AVERAGE_ACCURATE,
    SELF_AVERAGE_ACCURATE_SPOUSE,
} = contextActionTypes;

const initialValues = { average_income: true };

function MonthlyAverage({ spouse }) {
    const { personalUid, spouseUid, dispatchEmployment } = useContext(
        PersonalInfoContext
    );
    const [threeMonthsIncome, setThreeMonthsIncome] = useState({});
    const [sixMonthsIncome, setSixMonthsIncome] = useState({});
    const [disabled, setDisabled] = useState(true);
    const [choice, setChoice] = useState({});
    const [twelveMonthsIncome, setTwelveMonthsIncome] = useState({});
    const [averageIncome, setAverageIncome] = useState([]);
    const [notAccurateIncome, setNotAccurateIncome] = useState({});
    const { nextStep, previousStep } = useStepController();
    useEffect(() => {
        const averageIncome = async () => {
            try {
                const req = await api({
                    url: endpoints.SELF_EMPLOYED_AVERAGE_INCOME_ESTIMATION,
                    params: {
                        profile: spouse ? spouseUid : personalUid,
                    },
                });
                const res = await req.data;
                setAverageIncome(res);
                const filterItem = res.find(
                    (data) => data.verified === true && data.period !== null
                );
                if (filterItem) {
                    setDisabled(false);
                    setChoice({
                        uid: filterItem.uid,
                    });
                }
                if (res) {
                    res.forEach((items) => {
                        switch (items.period) {
                            case 3:
                                setThreeMonthsIncome({
                                    key: items.period,
                                    total_deposit: items.estimated_total_income,
                                    average_deposit:
                                        items.estimated_average_income,
                                    average_expense:
                                        items.estimated_average_expense,
                                    verified: items.verified,
                                    uid: items.uid,
                                });
                                break;

                            case 6:
                                setSixMonthsIncome({
                                    key: items.period,
                                    total_deposit: items.estimated_total_income,
                                    average_deposit:
                                        items.estimated_average_income,
                                    average_expense:
                                        items.estimated_average_expense,
                                    verified: items.verified,
                                    uid: items.uid,
                                });
                                break;

                            case 12:
                                setTwelveMonthsIncome({
                                    key: items.period,
                                    total_deposit: items.estimated_total_income,
                                    average_deposit:
                                        items.estimated_average_income,
                                    average_expense:
                                        items.estimated_average_expense,
                                    verified: items.verified,
                                    uid: items.uid,
                                });
                                break;

                            case 13:
                                setNotAccurateIncome({
                                    key: items.period,
                                    total_deposit: items.estimated_total_income,
                                    average_deposit:
                                        items.estimated_average_income,
                                    average_expense:
                                        items.estimated_average_expense,
                                    verified: items.verified,
                                    uid: items.uid,
                                });
                                break;

                            default:
                                break;
                        }
                    });
                }
            } catch (e) {
                console.log(e.response);
            }
        };

        if ((spouse && spouseUid) || (!spouse && personalUid)) {
            averageIncome();
        }
    }, [personalUid, spouseUid, spouse]);

    const handleEstimateChange = (uid) => {
        const result = averageIncome.find((data) => data.uid === uid);
        dispatchEmployment({
            type: spouse ? SELF_AVERAGE_ACCURATE_SPOUSE : SELF_AVERAGE_ACCURATE,
            payload: result.period !== 13,
        });
        setChoice({
            uid: uid,
        });
        setDisabled(false);
    };

    const averageIncomeVerification = async () => {
        try {
            const req = await api({
                method: 'put',
                url: `${endpoints.SELF_EMPLOYED_AVERAGE_INCOME_ESTIMATION}${choice.uid}/`,
                data: {
                    verified: true,
                },
            });
            const res = await req.data;
            if (res) {
                if (res) {
                    history.push(nextStep.path);
                }
            }
        } catch (e) {
            console.log(e.response);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={averageIncomeVerification}
            enableReinitialize={true}
        >
            {({ handleSubmit }) => (
                <div>
                    <FormGroup legendText=""></FormGroup>
                    <h1>
                        Please select the most accurate{' '}
                        <span className="color-blue">monthly average</span>
                        {' '}based on your{' '}
                        {spouse ? (
                            <span className="color-blue"> spouse's </span>
                        ) : (
                            ''
                        )}{' '}income.
                    </h1>
                    <MonthlyAverageList
                        orientation="vertical"
                        id={'average_income'}
                        name={'average_income'}
                        onChange={handleEstimateChange}
                        valueSelected={choice.uid}
                    >
                        <RadioButton
                            value={threeMonthsIncome.uid}
                            name="threeMonths"
                            labelText={
                                <DepositAverage
                                    month={threeMonthsIncome.key}
                                    totalDeposit={
                                        threeMonthsIncome.total_deposit
                                    }
                                    averageDeposit={
                                        threeMonthsIncome.average_deposit
                                    }
                                />
                            }
                        />
                        <RadioButton
                            value={sixMonthsIncome.uid}
                            name="sixMonths"
                            labelText={
                                <DepositAverage
                                    month={sixMonthsIncome.key}
                                    totalDeposit={sixMonthsIncome.total_deposit}
                                    averageDeposit={
                                        sixMonthsIncome.average_deposit
                                    }
                                />
                            }
                        />
                        <RadioButton
                            value={twelveMonthsIncome.uid}
                            name="twelveMonths"
                            labelText={
                                <DepositAverage
                                    month={twelveMonthsIncome.key}
                                    totalDeposit={
                                        twelveMonthsIncome.total_deposit
                                    }
                                    averageDeposit={
                                        twelveMonthsIncome.average_deposit
                                    }
                                />
                            }
                        />
                        <RadioButton
                            name="notAccurate"
                            value={notAccurateIncome.uid}
                            labelText="These averages are not accurate"
                        />
                    </MonthlyAverageList>
                    <Navigation
                        onPrevious={() => history.push(previousStep.path)}
                        onNext={handleSubmit}
                        className="mt-24"
                        disableNext={disabled}
                    />
                </div>
            )}
        </Formik>
    );
}

const MonthlyAverageList = styled(RadioButtonGroup)`
    margin-top: 25px;
    width: 100%;

    .bx--radio-button-wrapper {
        width: inherit;
        padding-bottom: 16px;
        border-bottom: 1px solid #c2c2c2;

        &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
        }

        .bx--radio-button__label {
            justify-content: flex-start;

            span:nth-child(2) {
                flex: 1;
            }
        }
    }
`;

MonthlyAverageList.displayName = 'MonthlyAverageList';
export default MonthlyAverage;
