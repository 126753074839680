import { Fragment } from 'react';
import styled from 'styled-components';
import { RadioButton32, CheckmarkFilled32 } from '@carbon/icons-react';
import PropTypes from 'prop-types';

function ProgressStep(props) {
    const { selected, currentStep, count, finalStep, name } = props;

    return (
        <Fragment>
            <Step selected={selected}>
                {selected ? (
                    <CheckmarkFilled32 width={23} height={23} color="#008AC8" />
                ) : (
                    <RadioButton32 width={23} height={23} />
                )}
                <h3>{`${count}. ${name}`}</h3>
            </Step>
            {currentStep && !finalStep && (
                <Remaining>Remaining steps:</Remaining>
            )}
            {!selected && !finalStep && <Line />}
        </Fragment>
    );
}

ProgressStep.propTypes = {
    selected: PropTypes.bool,
    currentStep: PropTypes.bool,
    count: PropTypes.number,
    finalStep: PropTypes.bool,
    name: PropTypes.string.isRequired,
};

const Step = styled('div')`
    min-height: 50px;
    background-color: #ebf2f7;
    padding: 11px 8px;
    margin-bottom: ${(props) => (props.selected ? '18px' : '5px')};
    display: flex;
    align-items: center;

    h3 {
        font-size: 16px;
        font-weight: 600;
        color: #0f212e;
        margin-top: 2px;
        margin-left: 14px;
        line-height: 1.5rem;
    }
`;

const Remaining = styled('h1')`
    margin-top: 18px;
    margin-bottom: 18px;
`;

const Line = styled('div')`
    height: 30px;
    border-left: 2px dotted #0f212e;
    margin-left: 15px;
    margin-bottom: 5px;
`;

export default ProgressStep;
