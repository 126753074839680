import {
    HOUSEHOLD_INFORMATION,
    ADD_DEPENDENTS,
    CLAIM_DEPENDENTS,
    DEPENDENTS_PROGRESS,
} from '../../routes/paths';
import { STATUS_HEAD_OF_HOUSEHOLD, STATUS_WIDOW_WITH_DEPENDENT } from 'const';

/**
 * @function householdSteps
 *
 * Generates Household and Dependents Steps following clients Filing Status and Dependents info.
 *
 * @param {object} additionalInfo
 * @param {string} filingStatus
 * @return {Array} Household and Dependents Steps
 */

const householdSteps = (additionalInfo, filingStatus) => {
    const { dependents } = additionalInfo;

    const steps = [
        {
            path: HOUSEHOLD_INFORMATION,
            title: 'Household Information',
        },
    ];

    // Ask claim dependent when filing status is not head of household or widow with dependent
    if (
        filingStatus !== STATUS_HEAD_OF_HOUSEHOLD &&
        filingStatus !== STATUS_WIDOW_WITH_DEPENDENT
    ) {
        steps.push({
            path: CLAIM_DEPENDENTS,
            title: 'Household Information',
        });
    }

    if (dependents) {
        steps.push({
            path: ADD_DEPENDENTS,
            title: 'Household Information',
        });
    }

    steps.push({
        path: DEPENDENTS_PROGRESS,
        title: 'Household Information',
    });

    return steps;
};

export default householdSteps;
