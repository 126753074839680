import { FormattedInput } from 'components/Form';

function AmountInput(props) {
    return (
        <FormattedInput
            formatOptions={{
                numeral: true,
                numeralThousandsGroupStyle: 'thousand',
                numeralPositiveOnly: true,
            }}
            {...props}
        />
    );
}

export default AmountInput;
