import { useState, useContext, useEffect, useMemo } from 'react';
import VerifyAdditionalIncome from './VerifyAdditionalIncome';
import AdditionalIncome from './AdditionalIncome';
import CalculateIncome from './CalculateIncome';
import ManualEntryVerificartion from './ManualEntryVerificartion';
import ManualAdditionalIncomeChoice from './ManualAdditionalIncomeChoice';
import ManualAdditionalIncome from './ManualAdditionalIncome';
import {
    ADDITIONAL_INCOME,
    ADDITIONAL_INCOME_VERIFICATION,
    ESTIMATE_ADDITIONAL_INCOME,
    MANUAL_ENTRY_ADDITIONAL_INCOME,
    SPOUSE_ADDITIONAL_INCOME,
    SPOUSE_ADDITIONAL_INCOME_VERIFICATION,
    SPOUSE_ESTIMATE_ADDITIONAL_INCOME,
    SPOUSE_MANUAL_ENTRY_ADDITIONAL_INCOME,
    MANUAL_ADDITIONAL_INCOME,
    MANUAL_ADDITIONAL_INCOME_ADD,
    SPOUSE_MANUAL_ADDITIONAL_INCOME,
    SPOUSE_MANUAL_ADDITIONAL_INCOME_ADD,
} from 'routes/paths';
import { InnerRoutes } from 'routes';
import api, { endpoints } from 'api';
import { PersonalInfoContext, contextActionTypes } from 'contexts';
import { useCallback } from 'react';
import { isArrayEmpty } from 'lib';
import { STATUS_MARRIED, STATUS_MARRIED_SEPARATELY } from 'const';

const {
    HAS_ADDITIONAL_INCOME,
    SPOUSE_HAS_ADDITIONAL_INCOME,
} = contextActionTypes;

function AdditionalIncomeVerification() {
    const [checkingIncome, toggleCheckingIncome] = useState(true);
    const [personalIncome, updatePersonalIncome] = useState([]);
    const [spouseIncome, updateSpouseIncome] = useState([]);

    const {
        personalUid,
        spouseUid,
        filingStatus,
        spouseDetail,
        dispatchEmployment,
    } = useContext(PersonalInfoContext);

    const checkAdditionalIncome = useCallback(
        async (profileUid, contextKey, updateState) => {
            if (!profileUid) return;

            try {
                toggleCheckingIncome(true);
                const req = await api({
                    url: `${endpoints.AVAILABLE_DEPOSIT}${profileUid}/`,
                    params: {
                        inc_type: 2,
                    },
                });

                const res = await req.data;

                toggleCheckingIncome(false);

                updateState(res);

                dispatchEmployment({
                    type: contextKey,
                    payload: !isArrayEmpty(res),
                });
            } catch (e) {
                toggleCheckingIncome(false);

                console.log(e);
            }
        },
        [dispatchEmployment]
    );

    useEffect(() => {
        checkAdditionalIncome(
            personalUid,
            HAS_ADDITIONAL_INCOME,
            updatePersonalIncome
        );

        if (filingStatus && filingStatus === STATUS_MARRIED) {
            checkAdditionalIncome(
                spouseUid,
                SPOUSE_HAS_ADDITIONAL_INCOME,
                updateSpouseIncome
            );
        }

        if (
            filingStatus &&
            filingStatus === STATUS_MARRIED_SEPARATELY &&
            spouseDetail
        ) {
            checkAdditionalIncome(
                spouseUid,
                SPOUSE_HAS_ADDITIONAL_INCOME,
                updateSpouseIncome
            );
        }
    }, [
        checkAdditionalIncome,
        personalUid,
        spouseUid,
        filingStatus,
        spouseDetail,
    ]);

    const additionalIncomeRoutes = useMemo(() => {
        return [
            {
                path: MANUAL_ADDITIONAL_INCOME,
                component: <ManualAdditionalIncomeChoice />,
                options: { exact: true },
            },
            {
                path: MANUAL_ADDITIONAL_INCOME_ADD,
                component: <ManualAdditionalIncome />,
                options: { exact: true },
            },
            {
                path: SPOUSE_MANUAL_ADDITIONAL_INCOME,
                component: <ManualAdditionalIncomeChoice spouse />,
                options: { exact: true },
            },
            {
                path: SPOUSE_MANUAL_ADDITIONAL_INCOME_ADD,
                component: <ManualAdditionalIncome spouse />,
                options: { exact: true },
            },
            {
                path: ADDITIONAL_INCOME,
                component: (
                    <AdditionalIncome
                        checking={checkingIncome}
                        income={personalIncome}
                    />
                ),
                options: { exact: true },
            },
            {
                path: ADDITIONAL_INCOME_VERIFICATION,
                component: <VerifyAdditionalIncome />,
                options: { exact: true },
            },
            {
                path: ESTIMATE_ADDITIONAL_INCOME,
                component: <CalculateIncome />,
                options: { exact: true },
            },
            {
                path: MANUAL_ENTRY_ADDITIONAL_INCOME,
                component: <ManualEntryVerificartion />,
                options: { exact: true },
            },
            {
                path: SPOUSE_ADDITIONAL_INCOME,
                component: (
                    <AdditionalIncome
                        spouse
                        checking={checkingIncome}
                        income={spouseIncome}
                    />
                ),
                options: { exact: true },
            },
            {
                path: SPOUSE_ADDITIONAL_INCOME_VERIFICATION,
                component: <VerifyAdditionalIncome spouse />,
                options: { exact: true },
            },
            {
                path: SPOUSE_ESTIMATE_ADDITIONAL_INCOME,
                component: <CalculateIncome spouse />,
                options: { exact: true },
            },
            {
                path: SPOUSE_MANUAL_ENTRY_ADDITIONAL_INCOME,
                component: <ManualEntryVerificartion spouse />,
                options: { exact: true },
            },
        ];
    }, [checkingIncome, personalIncome, spouseIncome]);

    return <InnerRoutes routes={additionalIncomeRoutes} />;
}

export default AdditionalIncomeVerification;
