import { Modal as CarbonModal } from 'carbon-components-react';
import styled from 'styled-components';
import PropType from 'prop-types';
import { ButtonBlock } from '../Buttons';

function Modal(props) {
    const {
        open,
        onClose,
        children,
        onSave,
        modalHeading,
        primaryButtonText,
        secondaryButtonText,
        size,
        singleButton,
        singleButtonText,
        danger
    } = props;

    // Callback save and hide modal
    const onSubmit = () => {
        onSave();
        onClose();
    };

    // Single button handler
    const onSingle = () => {
        onClose();
    };

    return (
        <StyledModal
            modalHeading={modalHeading}
            primaryButtonText={primaryButtonText}
            secondaryButtonText={secondaryButtonText}
            open={open}
            size={size}
            onRequestClose={onClose}
            onRequestSubmit={onSubmit}
            passiveModal={singleButton}
            danger={danger}
        >
            {children}
            {singleButton && <SingleButton onClick={onSingle}>{singleButtonText}</SingleButton>}
        </StyledModal>
    );
}

Modal.propTypes = {
    open: PropType.bool.isRequired,
    onClose: PropType.func.isRequired,
    children: PropType.node,
    onSave: PropType.func,
    modalHeading: PropType.string,
    primaryButtonText: PropType.string,
    secondaryButtonText: PropType.string,
    size: PropType.string,
    singleButton: PropType.bool,
};

Modal.defaultProps = {
    primaryButtonText: 'Save',
    secondaryButtonText: 'Cancel',
    singleButtonText: 'Ok',
    size: 'sm',
    onSave: function onSave() { },
    singleButton: false,
};

const StyledModal = styled(CarbonModal)`
    && {
        &.is-visible {
            background-color: rgba(29, 60, 74, 0.9);
        }

        .bx--modal-container {
            .bx--modal-header {
                padding: 2rem 2rem 0.5rem;
            }

            .bx--modal-content {
                padding: 0 2rem;
                margin-bottom: ${(props) =>
        props.passiveModal ? '6rem' : '3rem'};
            }

            .bx--modal-header__heading {
                font-size: 1rem;
                font-weight: 600;
            }

            .bx--modal-close {
                width: 0;
                height: 0;
                position: relative;
                overflow: hidden;
                display: none;
            }
        }

        @media (max-width: 767px) {
            .bx--modal-container {
                height: auto;
                top: auto;
                width: calc(100% - 2rem);
            }
        }
    }
`;

const SingleButton = styled(ButtonBlock)`
    /* position: absolute */
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 4rem;
    padding-top: 0;
    width: 200px;
`;

export default Modal;
