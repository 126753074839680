import { useState, Fragment, useEffect } from 'react';
import { STATUS_MARRIED, STATUS_MARRIED_SEPARATELY } from '../../../const';
import EmploymentIncome from './EmploymentIncome';
import SelfEmployedIncome from './SelfEmployedIncome';
import BothIncome from './BothIncome';
import UnemployedIncome from './UnemployedIncome';
import RetiredIncome from './RetiredIncome';

function EmployedIndex({
    personal,
    spouse,
    filing,
    spouseDetail,
    selfEmployed,
    personalInfo,
    spouseInfo,
    additionalInfo,
    otherIncome,
}) {
    const [show, setShow] = useState();
    useEffect(() => {
        if (additionalInfo.self) {
            setShow(
                (STATUS_MARRIED === filing ||
                    STATUS_MARRIED_SEPARATELY === filing) &&
                    additionalInfo.self.spouses_contribution_to_household_income
            );
        }
    }, [filing, spouseDetail, additionalInfo]);
    return (
        <Fragment>
            <div>
                {Object.keys(selfEmployed).length !== 0 &&
                    selfEmployed.constructor === Object &&
                    additionalInfo.self.income_type === '1099' && (
                        <SelfEmployedIncome
                            data={selfEmployed.self}
                            info={personalInfo}
                        />
                    )}
                {Object.keys(personal).length !== 0 &&
                    personal.constructor === Object &&
                    additionalInfo.self.income_type === 'W-2' && (
                        <EmploymentIncome data={personal} info={personalInfo} />
                    )}
                {Object.keys(selfEmployed).length !== 0 &&
                    selfEmployed.constructor === Object &&
                    additionalInfo.self.income_type === 'both' && (
                        <BothIncome
                            business={selfEmployed.self}
                            fulltime={personal}
                            info={personalInfo}
                        />
                    )}
                {Object.keys(otherIncome).length !== 0 &&
                    otherIncome.constructor === Object &&
                    additionalInfo.self.income_type === 'Unemployed' && (
                        <UnemployedIncome
                            data={otherIncome.self}
                            info={personalInfo}
                        />
                    )}
                {Object.keys(otherIncome).length !== 0 &&
                    otherIncome.constructor === Object &&
                    additionalInfo.self.income_type === 'Retired' && (
                        <RetiredIncome
                            data={otherIncome.self}
                            info={personalInfo}
                        />
                    )}
            </div>
            {show && (
                <div>
                    <hr className="border-line" />
                    {Object.keys(selfEmployed).length !== 0 &&
                        selfEmployed.constructor === Object &&
                        additionalInfo.spouse.income_type === '1099' && (
                            <SelfEmployedIncome
                                data={selfEmployed.spouse}
                                info={spouseInfo}
                            />
                        )}
                    {Object.keys(spouse).length !== 0 &&
                        spouse.constructor === Object &&
                        additionalInfo.spouse.income_type === 'W-2' && (
                            <EmploymentIncome data={spouse} info={spouseInfo} />
                        )}
                    {Object.keys(selfEmployed).length !== 0 &&
                        selfEmployed.constructor === Object &&
                        additionalInfo.spouse.income_type === 'both' && (
                            <BothIncome
                                business={selfEmployed.spouse}
                                fulltime={spouse}
                                info={spouseInfo}
                            />
                        )}
                    {Object.keys(otherIncome).length !== 0 &&
                        otherIncome.constructor === Object &&
                        additionalInfo.spouse.income_type === 'Unemployed' && (
                            <UnemployedIncome
                                data={otherIncome.spouse}
                                info={spouseInfo}
                            />
                        )}
                    {Object.keys(otherIncome).length !== 0 &&
                        otherIncome.constructor === Object &&
                        additionalInfo.spouse.income_type === 'Retired' && (
                            <RetiredIncome
                                data={otherIncome.spouse}
                                info={spouseInfo}
                            />
                        )}
                </div>
            )}
        </Fragment>
    );
}

export default EmployedIndex;
