import LinkPersonalAccounts from './LinkPersonalAccounts';
import LinkBusinessAccounts from './LinkBusinessAccounts';
import { LinkBankWelcome } from 'screens/Welcome';
import EmploymentType from './EmploymentType';
import {
    CONNECT_PERSONAL_BANKS,
    CONNECT_BANKS,
    CONNECT_BUSINESS_BANKS,
    EMPLOYMENT,
    BANK_PROGRESS,
    SEPARATE_BUSINESS,
    SPOUSE_CONTRIBUTION,
    SPOUSE_EMPLOYMENT_TYPE,
    SPOUSE_JOINT_BANK,
    SPOUSE_ONLINE_INFO,
    CONNECT_SPOUSE_PERSONAL_BANKS,
    CONNECT_SPOUSE_BUSINESS_BANKS,
    SEPARATE_BUSINESS_SPOUSE,
    CONNECT_PERSONAL_MANUAL_BANKS,
    CONNECT_SPOUSE_PERSONAL_MANUAL_BANKS,
    CONNECT_BUSINESS_MANUAL_BANKS,
    CONNECT_SPOUSE_BUSINESS_MANUAL_BANKS,
} from 'routes/paths';
import Progress from 'screens/Progress';
import { PROGRESS_KEY } from 'const';
import SeparateBusinessBank from './SeparateBusinessBank';
import { SpouseContribute, SpouseJointBank, SpouseOnlineInfo } from './Spouse';
import { InnerRoutes } from 'routes';
import LinkManualBanks from './LinkManualBanks';

const connectBankRoutes = [
    {
        path: CONNECT_PERSONAL_MANUAL_BANKS,
        component: <LinkManualBanks bank_account_type={'personal'} />,
        options: { exact: true },
    },
    {
        path: CONNECT_SPOUSE_PERSONAL_MANUAL_BANKS,
        component: <LinkManualBanks spouse bank_account_type={'personal'} />,
        options: { exact: true },
    },
    {
        path: CONNECT_BUSINESS_MANUAL_BANKS,
        component: <LinkManualBanks business bank_account_type={'business'} />,
        options: { exact: true },
    },
    {
        path: CONNECT_SPOUSE_BUSINESS_MANUAL_BANKS,
        component: <LinkManualBanks business spouse bank_account_type={'business'} />,
        options: { exact: true },
    },
    {
        path: SPOUSE_CONTRIBUTION,
        component: <SpouseContribute />,
        options: { exact: true },
    },
    {
        path: SPOUSE_EMPLOYMENT_TYPE,
        component: <EmploymentType spouse={true} />,
        options: { exact: true },
    },
    {
        path: SPOUSE_JOINT_BANK,
        component: <SpouseJointBank />,
        options: { exact: true },
    },
    {
        path: SPOUSE_ONLINE_INFO,
        component: <SpouseOnlineInfo />,
        options: { exact: true },
    },
    {
        path: BANK_PROGRESS,
        component: <Progress completedProgress={PROGRESS_KEY.bank} />,
        options: { exact: true },
    },
    {
        path: CONNECT_SPOUSE_BUSINESS_BANKS,
        component: (
            <LinkBusinessAccounts spouse bank_account_type={'business'} />
        ),
        options: { exact: true },
    },
    {
        path: CONNECT_BUSINESS_BANKS,
        component: <LinkBusinessAccounts bank_account_type={'business'} />,
        options: { exact: true },
    },
    {
        path: CONNECT_SPOUSE_PERSONAL_BANKS,
        component: (
            <LinkPersonalAccounts spouse bank_account_type={'personal'} />
        ),
        options: { exact: true },
    },
    {
        path: CONNECT_PERSONAL_BANKS,
        component: <LinkPersonalAccounts bank_account_type={'personal'} />,
        options: { exact: true },
    },
    {
        path: SEPARATE_BUSINESS_SPOUSE,
        component: <SeparateBusinessBank spouse />,
        options: { exact: true },
    },
    {
        path: SEPARATE_BUSINESS,
        component: <SeparateBusinessBank />,
        options: { exact: true },
    },
    {
        path: EMPLOYMENT,
        component: <EmploymentType />,
        options: { exact: true },
    },
    {
        path: CONNECT_BANKS,
        component: <LinkBankWelcome />,
        options: { exact: true },
    },
];

function ConnectBanks() {
    return <InnerRoutes routes={connectBankRoutes} />;
}

export default ConnectBanks;
