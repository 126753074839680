import Modal from 'components/Modal';

function AlertTerms(props) {
    const { open, hide } = props;

    return (
        <Modal
            open={open}
            onClose={hide}
            singleButton
            modalHeading={'Review and agree to our Terms and Conditions.'}
        >
            <p>
                You must review and agree with our terms and conditions before
                you can continue using Verifilink.
            </p>
        </Modal>
    );
}

export default AlertTerms;
