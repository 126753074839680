import { useState, Fragment, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { STATUS_MARRIED, STATUS_MARRIED_SEPARATELY } from '../../const';
import { PersonalInfoContext } from 'contexts';
const baseURL = `${process.env['REACT_APP_API_PROTOCOL']}${process.env['REACT_APP_API_ENDPOINT']}`;

function BankAccounts({ personal, spouse, filing, spouseDetail }) {
    const { fullName, spouseName } = useContext(PersonalInfoContext);
    const [show, setShow] = useState();

    useEffect(() => {
        setShow(
            STATUS_MARRIED === filing ||
                (STATUS_MARRIED_SEPARATELY === filing && spouseDetail === true)
        );
    }, [filing, spouse, spouseDetail]);

    return (
        <Fragment>
            <BankAccountsDetails>
                {personal.length === 0 && (
                    <BankWrapper>
                        <h1 className="bank-name">No Bank Account Found.</h1>
                    </BankWrapper>
                )}
                {personal.length > 0 && (
                    <Fragment>
                        <h1 className="bank-name"> {fullName} </h1>
                        {personal.map((dataItem, id) => {
                            const imageUrl = baseURL + dataItem.bank_icon;
                            return (
                                <BankWrapper key={`personal_${id}`}>
                                    <div>
                                        <span>
                                            <p>{dataItem.bank}</p>
                                            <p>
                                                Account Number:&nbsp;
                                                {dataItem.account_number}
                                            </p>
                                            <p>{dataItem.type}</p>
                                        </span>
                                        <span>
                                            <img src={imageUrl} alt="TaxRise" />
                                        </span>
                                    </div>
                                </BankWrapper>
                            );
                        })}
                    </Fragment>
                )}
                {spouse.length > 0 && show && (
                    <div>
                        <hr className="border-line" />

                        <h1 className="bank-name">{spouseName}</h1>

                        {spouse.map((dataItem, id) => {
                            const imageUrl = baseURL + dataItem.bank_icon;
                            return (
                                <BankWrapper key={`spouse_${id}`}>
                                    <div>
                                        <span>
                                            <p>{dataItem.bank}</p>
                                            <p>
                                                Account Number:&nbsp;
                                                {dataItem.account_number}
                                            </p>
                                            <p>{dataItem.type}</p>
                                        </span>
                                        <span>
                                            <img src={imageUrl} alt="TaxRise" />
                                        </span>
                                    </div>
                                </BankWrapper>
                            );
                        })}
                    </div>
                )}
            </BankAccountsDetails>
        </Fragment>
    );
}

const BankAccountsDetails = styled.div`
    .bank-name {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #0f173a;
        position: relative;
        margin: 8px 0;
        padding-left: 15px;

        &:before {
            position: absolute;
            content: '';
            top: 5px;
            left: 0;
            width: 0;
            height: 0;
            border-top: 7px solid #000000;
            border-right: 9px solid transparent;
        }
    }
    .border-line {
        border-width: 0px;
        border-top: 1px solid #e0e0e0;
    }
`;

const BankWrapper = styled.div`
    h1 {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #0f173a;
    }
    div {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #4f5260;
        display: flex;
        align-items: center;
        span {
            &:first-child {
                width: 90%;
                display: block;
            }
        }
    }
    img {
        height: 25px;
        width: 25px;
    }
`;

export default BankAccounts;
