import Styled from 'styled-components';

function ExpenseList(props) {
    const { header, subHeader } = props;

    return (
        <Wrapper>
            <h1>{header}</h1>
            <p>
                <span>{subHeader}</span>
            </p>
        </Wrapper>
    );
}

const Wrapper = Styled.div`
    h1 {
        font-size: 12px;
        line-height: 18px;
        font-weight: 600;
        color: #000000;
        margin: 3px;
        padding: 0;
    }
    p {
        font-size: 12px;
        line-height: 19px;
        color: #0F212E;
        display: flex;
        justify-content: space-between;
        margin: 0;
        padding: 4px 0 0 0;
    }
`;
export default ExpenseList;
