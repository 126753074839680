import { useState, useEffect } from 'react';
import { SELF_PRIMARY_BUSINESS_KEY } from 'const';
import api, { endpoints } from 'api';

function useBusinessInfo(profile, business_type = SELF_PRIMARY_BUSINESS_KEY) {
    const [businessInfo, updateBusinessInfo] = useState({
        address: {},
        sole_proprietorship: true,
    });
    const [loading, toggleLoading] = useState(false);

    useEffect(() => {
        const fetchBusinessInfo = async () => {
            toggleLoading(true);
            try {
                const req = await api.get(
                    endpoints.SELF_EMPLOYED_BUSINESS_INFO,
                    {
                        params: {
                            profile,
                            self_employed_business_type: business_type,
                        },
                    }
                );

                const res = await req.data;
                if (Array.isArray(res) && res.length > 0) {
                    const index = res.findIndex(
                        (b) => b.self_employed_business_type === business_type
                    );

                    if (index >= 0) {
                        updateBusinessInfo(res[index]);
                    }
                }

                toggleLoading(false);
            } catch (e) {
                console.log(e.response);
                toggleLoading(false);
            }
        };

        if (profile) fetchBusinessInfo();
    }, [profile, business_type]);

    return {
        businessInfo,
        loading,
    };
}

export default useBusinessInfo;
