/* eslint-disable camelcase */
import { Fragment, useState, useEffect } from 'react';
import Navigation from 'components/Navigation';
import { FormGroup } from 'carbon-components-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import api, { endpoints } from 'api';
import { useParams } from 'react-router-dom';
import { Input, SwitchInput } from 'components/Form';
import { useStepController } from 'hooks';
import { history } from 'routes';
import { AmountInput } from 'components/Utilities';
import { MAX_AMOUNT_VEHICLES } from 'const';
import { toUsCurrency } from 'lib';
import {
    enableDotAmountCheck,
    enableMinAmountCheck,
    enableMaxAmountCheck
} from 'lib/yupValidMethods';
import { parseAmount } from 'lib';

const initialData = {
    odometer: '',
    year: '',
    make: '',
    model: '',
    market_value: '',
    outstanding_balance: '',
    mortgage_type: 'loan',
    verified: false,
};

function Vehicle({ spouse }) {
    const [formInitial, updateFormInitial] = useState(
        Object.assign({}, initialData)
    );
    const [choice, setChoice] = useState(null);
    const [vehicleData, updateVehicleData] = useState({});

    const { vehicle } = useParams();
    const { previousStep, nextStep } = useStepController();

    const { source_details, monthly_payment, uid } = vehicleData;

    // Fetch vehicle data
    useEffect(() => {
        const fetchVehiclesData = async () => {
            try {
                const req = await api.get(
                    `${endpoints.DYNAMIC_VEHICLE_UPDATE}${vehicle}/`
                );
                const res = await req.data;

                updateVehicleData(res);

                // User owns the vehicle
                if (res.verified) {
                    const {
                        odometer,
                        year,
                        make,
                        model,
                        market_value,
                        outstanding_balance,
                        mortgage_type,
                        verified,
                    } = res;
                    setChoice(typeof verified === 'boolean' && verified);
                    updateFormInitial({
                        odometer,
                        year,
                        make,
                        model,
                        market_value,
                        outstanding_balance,
                        mortgage_type,
                        verified,
                    });
                } else {
                    /* 
                        Manually reset component when vehicle is changed. 
                        It's needed because of using same component for same route with different params
                    */
                    const new_obj = { ...initialData, outstanding_balance: res.outstanding_balance, mortgage_type: res.mortgage_type}
                    updateFormInitial(new_obj);
                }
            } catch (e) {
                console.log(e);
            }
        };

        if (vehicle) {
            fetchVehiclesData(vehicle);
        }
    }, [vehicle]);

    const saveVehicle = async (values, { resetForm }) => {
        let data = {};

        if (values.verified) {
            data = {
                year: values.year,
                make: values.make,
                model: values.model,
                odometer: parseAmount(values.odometer),
                ownership_status: 'owned',
                market_value: values.mortgage_type === "loan" ? parseAmount(values.market_value) : 0,
                outstanding_balance: (values.outstanding_balance && values.mortgage_type === "loan") ? parseAmount(values.outstanding_balance) : 0,
                verified: true,
            };
        } else {
            data = {
                verified: false,
                ownership_status: 'not_owned',
            };
        }

        try {
            const req = await api.patch(
                endpoints.DYNAMIC_VEHICLE_UPDATE + uid + '/',
                data
            );
            const res = req.data;
            if (res) {
                resetForm();
                history.push(nextStep.path);
            }
        } catch (e) {
            console.log(e.response);
        }
    };

    const vehicleInfoForm= (values) => (
        <Fragment>
            <h2 className="mb-13">
                Please enter your vehicle&apos;s information below.
            </h2>

            <div>
                <div className="bx--grid bx--no-gutter">
                    <div className="bx--row">
                        <div className="bx--col">
                            <FormGroup legendText={''}>
                                <Input id="year" labelText="Year" name="year" />
                            </FormGroup>
                        </div>
                        <div className="bx--col">
                            <FormGroup legendText={''}>
                                <Input id="make" labelText="Make" name="make" />
                            </FormGroup>
                        </div>
                    </div>
                </div>
                <FormGroup legendText={''}>
                    <Input id="model" labelText="Model" name="model" />
                </FormGroup>

                <FormGroup legendText={''}>
                    <AmountInput
                        id="odometer"
                        name="odometer"
                        labelText="Odometer(Miles)"
                    />
                </FormGroup>
                {values.mortgage_type === "loan" &&
                    <Fragment>
                        <FormGroup legendText={''}>
                            <AmountInput
                                placeholder="$"
                                id="market_value"
                                name="market_value"
                                labelText="Estimated Market Value"
                            />
                        </FormGroup>
                        <FormGroup legendText={''}>
                            <AmountInput
                                placeholder="$"
                                id="outstanding_balance"
                                name="outstanding_balance"
                                labelText="Estimated Loan Balance"
                            />
                        </FormGroup>
                    </Fragment>
                }
            </div>
        </Fragment>
    );

    return (
        <Fragment>
            {spouse ? (
                <h1 className="mb-32">
                    Does your <span className="color-blue">spouse</span> still
                    own the vehicle they financed with&nbsp;
                    <span className="text-color">{source_details}</span>
                    &nbsp;
                    {monthly_payment <= 0 ? 'which is closed' : ''}
                    &nbsp;
                    {monthly_payment > 0 ? 'With A Monthly Payment Of' : ''}
                    &nbsp;
                    {monthly_payment > 0 ? (
                        <span className="text-color">${monthly_payment}</span>
                    ) : (
                        ''
                    )}
                    ?
                </h1>
            ) : (
                <h1 className="mb-32">
                    Do you still own the vehicle you financed with&nbsp;
                    <span className="text-color">{source_details}</span>
                    &nbsp;
                    {monthly_payment <= 0 ? 'which is closed' : ''}
                    &nbsp;
                    {monthly_payment > 0 ? 'With A Monthly Payment Of' : ''}
                    &nbsp;
                    {monthly_payment > 0 ? (
                        <span className="text-color">${monthly_payment}</span>
                    ) : (
                        ''
                    )}
                    ?
                </h1>
            )}

            <Formik
                initialValues={formInitial}
                onSubmit={saveVehicle}
                validationSchema={formValidation}
                validateOnMount
                enableReinitialize
            >
                {({ handleSubmit, values, isValid }) => (
                    <Fragment>
                        <SwitchInput
                            name="verified"
                            className="mb-32"
                            choice={choice}
                        />

                        {values.verified && vehicleInfoForm(values)}

                        <Navigation
                            onNext={handleSubmit}
                            onPrevious={() => history.push(previousStep.path)}
                            disableNext={!isValid}
                        />
                    </Fragment>
                )}
            </Formik>
        </Fragment>
    );
}

const currentYear = new Date().getFullYear();
enableDotAmountCheck();
enableMinAmountCheck();
enableMaxAmountCheck();

const formValidation = Yup.object().shape({
    verified: Yup.boolean()
        .nullable()
        .required('Please choice either yes or no'),
    mortgage_type: Yup.string(),
    odometer: Yup.string().when('verified', (verified, schema) => {
        return verified
            ? schema
                    .required('Required field')
                    .onlyDot('Odometer value is not valid')
                    .maxAmount(MAX_AMOUNT_VEHICLES, `Maximum Odometer is ${MAX_AMOUNT_VEHICLES}`)
            : schema.nullable();
    }),
    year: Yup.number().when('verified', (verified, schema) => {
        return verified
            ? schema
                  .min(1920, 'Year must be older than 1920')
                  .max(currentYear, "Year can't be older than now")
                  .typeError('Invalid year')
                  .required('Please Enter Year')
            : schema.nullable();
    }),
    make: Yup.string().when('verified', (verified, schema) => {
        return verified
            ? schema.required('Please Enter Valid Maker')
            : schema.nullable();
    }),
    model: Yup.string().when('verified', (verified, schema) => {
        return verified
            ? schema.required('Please Enter Valid Model')
            : schema.nullable();
    }),
    market_value: Yup.string()
        .nullable()
        .when(['verified', 'mortgage_type'], {
            is: (verified, mortgage_type) =>
                (verified && mortgage_type === "loan"),
            then: Yup.string()
                .required('Required field')
                .onlyDot('Your market value is not valid')
                .minAmount(500, 'Minimum amount is 500')
                .maxAmount(MAX_AMOUNT_VEHICLES, `Maximum amount is ${toUsCurrency(MAX_AMOUNT_VEHICLES)}`)
        }),
});

export default Vehicle;
