function updateManualValuesForType(income, typeObj) {
    if (!income) return {};

    const {
        deposit_reason: { uid },
        rental_income,
        others_income,
    } = income;

    switch (typeObj[uid]) {
        case 'Rental Income': {
            const { rental_expense, rental_expense_amount } =
                rental_income || {};
            return {
                rental_expense,
                total_rental_expense_amount: rental_expense_amount,
            };
        }

        case 'No Longer Working Here': {
            const { business_name, worked_from, worked_to } =
                others_income || {};

            return {
                business_name,
                worked_from,
                worked_to,
            };
        }

        case 'Part Time Job': {
            const { business_name, income_type } = others_income || {};

            return {
                business_name,
                income_type,
            };
        }

        case 'Other': {
            const { deposit_description } = others_income || {};

            return {
                deposit_description,
            };
        }
        default:
            return {};
    }
}

export default updateManualValuesForType;
