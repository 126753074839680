import { Fragment } from 'react';
import styled from 'styled-components';
import { Close24} from '@carbon/icons-react';
// import apiConfig from 'config/api.config';

// const env = apiConfig[apiConfig.environment];
// eslint-disable-next-line no-undef
const baseURL = `${process.env['REACT_APP_API_PROTOCOL']}${process.env['REACT_APP_API_ENDPOINT']}`;

function BankInfo(props) {
    const { bank, bankItem } = props;

    const removeBank = (item) => {
        bankItem(item);
    };

    return (
        <Fragment>
            {bank.map((item, idx) => {
                const imageUrl = baseURL + item.bank_icon;
                return (
                    <Wrapper key={idx}>
                        <img src={imageUrl} alt="TaxRise" />
                        <div className="info">
                            <h2>{item.bank_name}</h2>
                            {item.acc_numbers.map((acc, id) => {
                                return (
                                    <p key={id}>{acc}</p>
                                );
                            })}
                            <p>{item.bank_source === "business" ? 'Business': 'Personal'}</p>
                        </div>
                        <Close onClick={() => removeBank(item)}/>
                    </Wrapper>
                );
            })}
        </Fragment>
    );
}

const Wrapper = styled.section`
    margin: 10px auto 2px;
    width: 302px;
    height: auto;
    border-radius: 4px;
    border: solid 1px #616161;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    img {
        height: 42px;
        width: 42px;
        margin-top: 16px;
        margin-left: 16px;
        margin-bottom: 14px;
    }

    .info {
        display: flex;
        flex-direction: column;
        width: 150px;

        h2 {
            -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: rgba(0, 0, 0, 0.87);
            margin-left: 16px;
            margin-bottom: 0;
        }

        p {
            width: 64px;
            height: 20px;
            -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            text-align: left;
            color: rgba(0, 0, 0, 0.54);
            margin-left: 16px;
            margin-bottom: 0;
        }
    }

    .delete {
        width: 12px;
        height: 14px;
        -webkit-text-stroke: 1px rgba(0, 0, 0, 0);
        font-family: FontAwesome5Pro;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: 0.56px;
        text-align: center;
        color: #e20000;
        margin-left: 20%;
    }
`;

const Close = styled(Close24)`
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 8px;
    color: #161616;
`;
Close.displayName = 'Close';

export default BankInfo;
