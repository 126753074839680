import { contextActionTypes } from 'contexts';

const {
    CLAIM_DEPENDENT,
    EARN_INCOME,
    HAS_SEPARATE_BUSINESS_BANK,
    SPOUSE_CONTRIBUTE_HOUSEHOLD,
    INCOME_TYPE,
    SPOUSE_INCOME_TYPE,
    SPOUSE_HAS_BUSINESS_BANK,
    UPDATE_ADDITIONAL_INFO,
    UPDATE_SPOUSE_ADDITIONAL_INFO,
    CLIENT_SPOUSE_ONLINE_INFO,
    CLIENT_KNOWS_SPOUSE_WORK,
    CLIENT_KNOWS_SPOUSE_BUSINESS,
    SPOUSE_HAS_ASSET_INVESTMENT,
    CLIENT_KNOWS_SPOUSE_ASSET_INVEST,
    SHARE_JOINT_BANK,
    MANUAL_PERSONAL_BANK,
    MANUAL_BUSINESS_BANK,
    SPOUSE_MANUAL_PERSONAL_BANK,
    SPOUSE_MANUAL_BUSINESS_BANK,
    HAS_MANUAL_ADD_INC,
    SPOUSE_HAS_MANUAL_ADD_INC,
    HAS_INVESTMENTS,
    SPOUSE_HAS_INVESTMENTS,
} = contextActionTypes;

const initAdditionalInfo = {
    dependents: true,
    earning_any_income: true,
    has_business_banks: true,
    spouse_contribute_household: true,
    income_type: 'W-2',
    spouses_online_bank_info: true,
    spouses_employment_info: true,
    spouses_business_structure_info: true,
    has_spouses_asset_investment: true,
    provide_spouses_asset_investment_info: true,
    spouses_income_in_your_bank: true,
    has_manual_personal_bank: false,
    has_manual_business_bank: false,
    has_manual_additional_income: false,
    has_investments: true,
    spouse: {
        has_business_banks: true,
        income_type: 'W-2',
        has_manual_personal_bank: false,
        has_manual_business_bank: false,
        has_manual_additional_income: false,
        has_investments: true,
    },
};

const reducerAdditionalInfo = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_ADDITIONAL_INFO:
            return {
                ...state,
                ...payload, // payload must be object
            };

        case UPDATE_SPOUSE_ADDITIONAL_INFO:
            return {
                ...state,
                spouse: {
                    ...state.spouse,
                    ...payload, // payload must be object
                },
            };

        case CLAIM_DEPENDENT:
            return {
                ...state,
                dependents: payload, // payload should be either true/false
            };
        case EARN_INCOME:
            return {
                ...state,
                earning_any_income: payload, // payload should be either true/false
            };

        case HAS_SEPARATE_BUSINESS_BANK:
            return {
                ...state,
                has_business_banks: payload, // payload should be either true/false
            };

        case SPOUSE_CONTRIBUTE_HOUSEHOLD:
            return {
                ...state,
                spouse_contribute_household: payload,
            };

        case INCOME_TYPE:
            return {
                ...state,
                income_type: payload,
            };

        case SPOUSE_INCOME_TYPE:
            return {
                ...state,
                spouse: {
                    ...state.spouse,
                    income_type: payload,
                },
            };

        case SPOUSE_HAS_BUSINESS_BANK:
            return {
                ...state,
                spouse: {
                    ...state.spouse,
                    has_business_banks: payload,
                },
            };

        case CLIENT_SPOUSE_ONLINE_INFO:
            return {
                ...state,
                spouses_online_bank_info: payload,
            };

        case CLIENT_KNOWS_SPOUSE_WORK:
            return {
                ...state,
                spouses_employment_info: payload,
            };

        case CLIENT_KNOWS_SPOUSE_BUSINESS:
            return {
                ...state,
                spouses_business_structure_info: payload,
            };

        case SPOUSE_HAS_ASSET_INVESTMENT:
            return {
                ...state,
                has_spouses_asset_investment: payload,
            };

        case CLIENT_KNOWS_SPOUSE_ASSET_INVEST:
            return {
                ...state,
                provide_spouses_asset_investment_info: payload,
            };

        case SHARE_JOINT_BANK:
            return {
                ...state,
                spouses_income_in_your_bank: payload,
            };

        case MANUAL_PERSONAL_BANK:
            return {
                ...state,
                has_manual_personal_bank: payload,
            };

        case MANUAL_BUSINESS_BANK:
            return {
                ...state,
                has_manual_business_bank: payload,
            };

        case SPOUSE_MANUAL_PERSONAL_BANK:
            return {
                ...state,
                spouse: {
                    ...state.spouse,
                    has_manual_personal_bank: payload,
                },
            };

        case SPOUSE_MANUAL_BUSINESS_BANK:
            return {
                ...state,
                spouse: {
                    ...state.spouse,
                    has_manual_business_bank: payload,
                },
            };

        case HAS_MANUAL_ADD_INC:
            return {
                ...state,
                has_manual_additional_income: payload,
            };

        case SPOUSE_HAS_MANUAL_ADD_INC:
            return {
                ...state,
                spouse: {
                    ...state.spouse,
                    has_manual_additional_income: payload,
                },
            };

        case HAS_INVESTMENTS:
            return {
                ...state,
                has_investments: payload,
            };

        case SPOUSE_HAS_INVESTMENTS:
            return {
                ...state,
                spouse: {
                    ...state.spouse,
                    has_investments: payload,
                },
            };

        default:
            return new Error('Action type not found!');
    }
};

export default reducerAdditionalInfo;

export { initAdditionalInfo };
