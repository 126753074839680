import { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import SignInPhase from 'screens/SignIn';
import PersonalInfoVerification from 'screens/PersonalInformation';
import HouseholdInformation from 'screens/HouseholdInfomation';
import IncomeSummary from 'screens/IncomeSummary';
import ConnectBanks from 'screens/ConnectBanks';
import AssetInvestmentVerification from 'screens/AssetInvestmentVerification';
import EmploymentIncomeVerification from 'screens/EmploymentIncomeVerification';
import AdditionalIncomeVerification from 'screens/AdditionalIncomeVerification';
import { TermsOfUse, PrivacyPolicy } from 'screens/TermsAndPrivacy';
import store from 'store';
import { DesktopWrapper } from '../components/Wrappers';
// import Temp from 'screens/Temp/Temp';
import {
    SIGN_IN,
    TERMS_OF_USE,
    PRIVACY_POLICY,
    PERSONAL_INFORMATION,
    HOUSEHOLD_INFORMATION,
    CONNECT_BANKS,
    ASSET_INVESTMENT_VERIFICATION,
    EMPLOYMENT_INCOME_VERIFICATION,
    ADDITIONAL_INCOME,
    INCOME_SUMMARY,
    EXTERNAL_LOGIN,
} from './paths';
import {
    InvestmentProvider,
    PersonalInfoProvider,
    AdditionalInfoProvider,
} from 'contexts';
import { PageNotFound } from 'screens/Errors';
import ExternalLogin from 'screens/ExternalLogin';

const Routes = () => {
    return (
        <DesktopWrapper>
            <PersonalInfoProvider>
                <InvestmentProvider>
                    <AdditionalInfoProvider>
                        <Switch>
                            <PrivateRoute path={INCOME_SUMMARY}>
                                <IncomeSummary />
                            </PrivateRoute>
                            <PrivateRoute path={ASSET_INVESTMENT_VERIFICATION}>
                                <AssetInvestmentVerification />
                            </PrivateRoute>
                            <PrivateRoute path={ADDITIONAL_INCOME}>
                                <AdditionalIncomeVerification />
                            </PrivateRoute>
                            <PrivateRoute path={EMPLOYMENT_INCOME_VERIFICATION}>
                                <EmploymentIncomeVerification />
                            </PrivateRoute>
                            <PrivateRoute path={CONNECT_BANKS}>
                                <ConnectBanks />
                            </PrivateRoute>
                            <PrivateRoute path={HOUSEHOLD_INFORMATION}>
                                <HouseholdInformation />
                            </PrivateRoute>
                            <PrivateRoute path={PERSONAL_INFORMATION}>
                                <PersonalInfoVerification />
                            </PrivateRoute>
                            <PrivateRoute path={TERMS_OF_USE}>
                                <TermsOfUse />
                            </PrivateRoute>
                            <PrivateRoute path={PRIVACY_POLICY}>
                                <PrivacyPolicy />
                            </PrivateRoute>
                            <PublicRoute exact path={SIGN_IN}>
                                <SignInPhase />
                            </PublicRoute>
                            <PublicRoute exact path='/:case_id'>
                                <SignInPhase />
                            </PublicRoute>
                            <PublicRoute exact path={EXTERNAL_LOGIN}>
                                <ExternalLogin />
                            </PublicRoute>
                            <Route path="*">
                                <PageNotFound />
                            </Route>
                        </Switch>
                    </AdditionalInfoProvider>
                </InvestmentProvider>
            </PersonalInfoProvider>
        </DesktopWrapper>
    );
};

function PrivateRoute({ children, ...rest }) {
    const access_token = store.get('access_token');

    return (
        <Route
            {...rest}
            render={({ location }) =>
                access_token ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: SIGN_IN,
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

function PublicRoute({ children, ...rest }) {
    useEffect(() => {
        // Clear token from localStorage when redirects to any Public Route
        store.remove('access_token');
        store.remove('refresh_token');
    }, []);

    return <Route {...rest}>{children}</Route>;
}

export default Routes;
