import { useState, useEffect } from 'react';
import api, { endpoints } from 'api';
import genReasonTypeObj from './genReasonTypeObj';

function useDepositReason() {
    const [reasons, updateReasons] = useState([]);
    const [reasonObj, updateReasonObj] = useState({});

    useEffect(() => {
        const fetchReasons = async () => {
            try {
                const req = await api({
                    url: endpoints.DEPOSIT_REASON,
                });

                const res = await req.data;

                if (Array.isArray(res) && res.length) {
                    updateReasons(res);
                    updateReasonObj(genReasonTypeObj(res));
                }
            } catch (e) {
                console.log(e.response);
            }
        };

        fetchReasons();
    }, []);

    return [reasons, reasonObj];
}

export default useDepositReason;
