import { useEffect, useState, useContext, useMemo, Fragment } from 'react';
import * as Yup from 'yup';
import { FieldArray, Formik } from 'formik';
import api, { endpoints } from 'api';
import { LinkButton } from 'components/Buttons';
import { useStepController, useModal } from 'hooks';
import { history } from 'routes';
import Modal from 'components/Modal';
import Navigation from 'components/Navigation';
import { PersonalInfoContext } from 'contexts';
import ManualAdditionalForm from './ManualAdditionalForm';
import useDepositReason from './useDepositReason';
import mapManualAdditionalIncome from './mapManualAdditionalIncome';
import updateManualValuesForType from './updateManualValuesForType';
import {
    MAX_AMOUNT,
    MAX_AMOUNT_US,
    MIN_ADDRESS_CHARS,
    MAX_ADDRESS_CHARS,
} from 'const';
import moment from 'moment';
import {
    enableDateFormatMethod,
    enableZipCodeValidateMethod,
    enableFutuerDateMethod,
    enableMinDateCheck,
    enableDotAmountCheck,
    enableMinAmountCheck,
    enableMaxAmountCheck,
} from 'lib/yupValidMethods';

function ManualAdditionalIncome({spouse}) {
    const [reasons, reasonTypeObj] = useDepositReason();
    const [depositReason, setDepositReason] = useState([]);
    const initManualInfo = useMemo(() => ({
        profile: '',
        deposit_reason: '',
        source_of_income: '',
        gross_income: '',
        reasons: reasonTypeObj,
        uid: '',
        rental_expense: '',
        total_rental_expense_amount: '',
        business_name: '',
        worked_from: '',
        worked_to: '',
        income_type: '',
        deposit_description: '',
    }), [reasonTypeObj]);
    const [initialValues, updateInitial] = useState({
        data: [Object.assign({}, initManualInfo)],
    });
    const [delManualInfo, setDeleteManualInformation] = useState([]);
    const { personalUid, spouseUid } = useContext(PersonalInfoContext);

    const { previousStep, nextStep } = useStepController();
    const [open, showModal, hideModal] = useModal();
    useEffect(() => {
        const fetchExistManualInformation = async (profile) => {
            if (!profile) return;

            try {
                const req = await api({
                    url: endpoints.ADDITIONAL_INCOME_INSERT,
                    params: {
                        profile,
                        source: "manual"
                    },
                });
                const res = await req.data;
                if (Array.isArray(res) && res.length) {
                    const existingInfo = res.map((data) => ({
                        profile: spouse ? spouseUid : personalUid,
                        uid: data ? data.uid : '',
                        deposit_reason: data
                            ? data.deposit_reason.uid
                            : '',
                        reasons: reasonTypeObj,
                        gross_income: data.gross_income,
                        source_of_income: data.source_of_income,
                        ...updateManualValuesForType(
                            data,
                            reasonTypeObj
                        ),
                    }));

                    updateInitial({ data: existingInfo });

                } else {
                    updateInitial({
                        data: [Object.assign({}, initManualInfo)],
                    })
                }

            } catch (e) {
                console.log(e.response);
            }
        };

        fetchExistManualInformation(spouse ? spouseUid : personalUid);
    }, [personalUid, spouse, spouseUid, reasonTypeObj, initManualInfo]);

    useEffect(() => {
        setDepositReason(
            reasons.map((r) => {
                const { name, uid } = r;
                return { label: name, id: uid };
            })
        );
    }, [reasons]);

    const saveManualInformation = async (values) => {
        const formDataArray = [];
        const { data } = values;
        data.forEach((income) => {
            formDataArray.push(mapManualAdditionalIncome(income, reasonTypeObj, spouse ? spouseUid : personalUid));
        });
        try {
            const req = await api.post(
                endpoints.ADDITIONAL_INCOME_INSERT,
                formDataArray
            );
            const res = await req.data;

            if (res) {
                history.push(nextStep.path);
            }
        } catch (err) {
            console.log(err);
            console.log(err.response);
        }
    };

    const handleClear = (index, method, uid) => {
        setDeleteManualInformation([index, method, uid]);
        showModal();
    };

    const deleteManualInformation = async () => {
        const [index, remove, uid] = delManualInfo;

        // Delete Manual from backend when uid available otherwise remove from the design
        if (!uid) {
            remove(index);
        } else {
            try {
                const deleteReq = await api({
                    method: 'delete',
                    url: `${endpoints.ADDITIONAL_INCOME_INSERT}${uid}/`,
                });

                if (deleteReq) remove(index);
            } catch (e) {
                console.log(e.response);
            }
        }
    };

    const goBack = () => {
        history.push(previousStep.path);
    };
    return (
        <Formik
            displayName="Manual Form"
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationRules}
            validateOnMount
            onSubmit={saveManualInformation}
        >
            {({ handleSubmit, values, isValid}) => (
                <Fragment>
                    <h1>
                        Please add all your{' '}
                        <span className="color-blue">
                            {spouse && " spouse's"}{' '}
                        </span>
                        additional income.
                    </h1>

                    {spouse ? (
                        <p>
                            Use the add button below to add as many
                            sources of additional income as your's spouse have.
                        </p>
                    ) : (
                        <p>
                            Use the add button below to add as many
                            sources of additional income as you have.
                        </p>
                    )}

                    <FieldArray
                        name="data"
                        render={({ push, remove }) => (
                            <div className="mt-32">
                                <h2>Additional income:</h2>
                                <div>
                                    {values.data.map(
                                        ({ uid }, i) => (
                                            <ManualAdditionalForm
                                                values={values}
                                                key={i}
                                                index={i}
                                                reasons={depositReason}
                                                typeObj={reasonTypeObj}
                                                onClear={handleClear.bind(
                                                    null,
                                                    i,
                                                    remove,
                                                    uid
                                                )}
                                            />
                                        )
                                    )}
                                </div>

                                <LinkButton
                                    type="button"
                                    onClick={() => push(initManualInfo)}
                                    className="mb-32"
                                >
                                    <span>Add Additional Income</span>
                                </LinkButton>
                            </div>
                        )}
                    />

                    <Navigation
                        onPrevious={goBack}
                        onNext={handleSubmit}
                        disableNext={!isValid}
                        typeNext="submit"
                    />

                    <Modal
                        open={open}
                        onClose={hideModal}
                        onSave={deleteManualInformation}
                        modalHeading="Are you sure you want to remove this Information?"
                        primaryButtonText="Yes"
                        danger
                    >
                        <p>The information you entered will not be saved.</p>
                    </Modal>
                </Fragment>
            )}
        </Formik>
    );
}

enableDateFormatMethod();
enableFutuerDateMethod();
enableZipCodeValidateMethod();
enableMinDateCheck();
enableDotAmountCheck();
enableMinAmountCheck();
enableMaxAmountCheck();

const validationRules = Yup.object().shape({
    data: Yup.array().of(
        Yup.object().shape({
            uid: Yup.string(),
            deposit_reason: Yup.string().required('Please select a reason'),
            gross_income: Yup.string()
                .required('Please Enter Gross Income')
                .maxAmount(MAX_AMOUNT, `Maximum Balance can be ${MAX_AMOUNT_US}`),
            source_of_income: Yup.string()
                .required('Please Enter Source Of Additional Income')
                .min(MIN_ADDRESS_CHARS, 'Minimum 1 characters')
                .max(MAX_ADDRESS_CHARS, 'Maximum 35 characters'),
            income_transaction: Yup.string(),
            profile: Yup.string(),
            rental_expense: Yup.boolean()
                .nullable()
                .when(['deposit_reason', 'reasons'], {
                    is: (deposit_reason, reasons) =>
                        reasons[deposit_reason] === 'Rental Income',
                    then: Yup.boolean()
                        .required('This field is required')
                }),
            total_rental_expense_amount: Yup.string()
                .nullable()
                .typeError('Invalid Amount')
                .when(
                    ['deposit_reason', 'reasons', 'rental_expense', 'gross_income'],
                    (
                        deposit_reason,
                        reasons,
                        rental_expense,
                        gross_income,
                        schema
                    ) => {
                        if (
                            reasons[deposit_reason] === 'Rental Income' &&
                            rental_expense === true
                        ) {
                            return schema
                                .required('Provide correct expense')
                                .onlyDot('Your monthly income is not valid')
                                .minAmount(
                                    0,
                                    'Amount should be more than or equal to 0'
                                )
                                .maxAmount(
                                    gross_income,
                                    'Amount should be equal or less than ' +
                                    gross_income
                                );
                        }
                        return schema;
                    }
                ),
            business_name: Yup.string()
                .nullable()
                .when(['deposit_reason', 'reasons'], {
                    is: (deposit_reason, reasons) =>
                        reasons[deposit_reason] === 'No Longer Working Here' ||
                        reasons[deposit_reason] === 'Part Time Job',
                    then: Yup.string()
                        .required('Employer is required')
                        .test(
                            'len',
                            'Please provide valid occupation name',
                            (val) => (val + '').length >= 2
                        ),
                }),
            worked_from: Yup.string()
                .nullable()
                .when(['deposit_reason', 'reasons'], {
                    is: (deposit_reason, reasons) =>
                        reasons[deposit_reason] === 'No Longer Working Here',
                    then: Yup.string()
                        .required('Required field')
                        .dateFormat()
                        .futureDate()
                        .minDate(),
                }),
            worked_to: Yup.string()
                .nullable()
                .when(
                    ['deposit_reason', 'reasons', 'worked_from'],
                    (deposit_reason, reasons, worked_from, schema) => {
                        if (
                            reasons[deposit_reason] === 'No Longer Working Here'
                        ) {
                            return schema
                                .required('Required field')
                                .dateFormat()
                                .futureDate()
                                .minDate()
                                .test(
                                    'is-valid',
                                    'Date is earlier than working from',
                                    (value) => {
                                        if (worked_from && value) {
                                            return moment(value).isAfter(
                                                worked_from
                                            );
                                        } else {
                                            return true;
                                        }
                                    }
                                );
                        }
                    }
                ),
            income_type: Yup.string()
                .nullable()
                .when(['deposit_reason', 'reasons'], {
                    is: (deposit_reason, reasons) =>
                        reasons[deposit_reason] === 'Part Time Job',
                    then: Yup.string().required('Income type is required'),
                }),
            deposit_description: Yup.string()
                .nullable()
                .when(['deposit_reason', 'reasons'], {
                    is: (deposit_reason, reasons) =>
                        reasons[deposit_reason] === 'Other',
                    then: Yup.string().required('Description is required'),
                }),
            reasons: Yup.object().shape({}),
        })
    ),
});

export default ManualAdditionalIncome;

