import { Fragment, useState, useContext, useEffect } from 'react';
import Switch from 'components/Switch';
import Navigation from 'components/Navigation';
import { useStepController, useEmployersList } from 'hooks';
import api, { endpoints } from 'api';
import { history } from 'routes';
import { PersonalInfoContext } from 'contexts';

function PartOwnership({ spouse }) {
    const [selectedChoice, setChoice] = useState(null);
    const [employer, setEmployer] = useState({});

    const { personalUid, spouseUid } = useContext(PersonalInfoContext);

    const { nextStep, previousStep } = useStepController();
    const clientEmployers = useEmployersList(spouse ? spouseUid : personalUid);
    useEffect(() => {
        const employer = clientEmployers.find((emp) => emp.verified === true);

        if (employer) {
            setEmployer(employer);

            const { employer_information } = employer;

            // Update selection
            // Boolean check necessary for the Switch component. When null no option is selected.
            if (
                employer_information &&
                typeof employer_information['part_of_ownership_in_business'] ===
                    'boolean'
            ) {
                setChoice(
                    employer_information['part_of_ownership_in_business'] ===
                        true
                        ? 0
                        : 1
                );
            }
        }
    }, [clientEmployers]);

    const handleChoice = ({ index, name }) => {
        setChoice(index);
    };

    const saveChoice = async () => {
        try {
            const req = await api({
                method: 'patch',
                url: `${endpoints.EMPLOYER_INFO}${employer.uid}/`,
                data: {
                    employer_information: {
                        part_of_ownership_in_business:
                            selectedChoice === 0 ? true : false,
                    },
                },
            });

            const res = await req.data;

            if (res) history.push(nextStep.path);
        } catch (e) {
            console.log(e.response);
        }
    };

    const goBack = () => {
        history.push(previousStep.path);
    };

    return (
        <Fragment>
            <h1>
                Do {spouse ? "your spouse's" : 'you'} have{' '}
                <span className="color-blue">part ownership</span> in this
                company?
            </h1>
            <Switch
                className="mt-32"
                onChange={handleChoice}
                choice={selectedChoice}
            />

            <Navigation
                disableNext={!(typeof selectedChoice === 'number')}
                onNext={saveChoice}
                onPrevious={goBack}
            />
        </Fragment>
    );
}

export default PartOwnership;
