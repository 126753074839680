import { useReducer, useEffect, useContext, useMemo, useCallback } from 'react';
import PersonalInfoContext from './PersonalInfoContext';
import actionTypes from '../context-action-types';
import api, { endpoints } from 'api';
import { AuthContext } from '../index';
import reducerPersonalInfo, {
    initialPersonalInfo,
} from './reducerPersonalInfo';
import reducerEmploymentInfo, {
    initialEmploymentInfo,
} from './reducerEmploymentInfo';
import store from 'store';
import { STATUS_MARRIED, STATUS_MARRIED_SEPARATELY } from 'const';
import { isArrayEmpty } from 'lib';
import * as Sentry from '@sentry/react';

const {
    STORE_PERSONAL_INFO,
    STORE_FILING_STATUS,
    STORE_SPOUSE_INFO,
    CHANGE_SPOUSE_DETAIL,
    HAS_ADDITIONAL_INCOME,
    SPOUSE_HAS_ADDITIONAL_INCOME,
} = actionTypes;

// Fetch Personal Info
const getPersonalInfo = async () => {
    try {
        const req = await api.get(endpoints.PERSONAL_PROFILE);
        return await req.data;
    } catch (e) {
        console.log(e);
    }
};

// Fetch Filing Status
const getFilingStatus = async () => {
    try {
        const req = await api.get(endpoints.FILING_STATUS);
        return await req.data;
    } catch (e) {
        console.log(e);
    }
};

// Fetch Additional Income
const getAdditionalIncome = async (uid) => {
    if (!uid) return [];

    try {
        const req = await api({
            url: `${endpoints.AVAILABLE_DEPOSIT}${uid}/`,
            params: {
                inc_type: 2,
            },
        });

        const res = await req.data;

        return res;
    } catch (e) {
        console.log(e);
    }
};

function PersonalInfoProvider({ children }) {
    const [state, dispatch] = useReducer(
        reducerPersonalInfo,
        initialPersonalInfo
    );
    const [employmentState, dispatchEmployment] = useReducer(
        reducerEmploymentInfo,
        initialEmploymentInfo
    );
    const {
        // eslint-disable-next-line camelcase
        authData: { access_token },
    } = useContext(AuthContext);

    useEffect(() => {
        // Dispatch actions
        const fetchProfile = async () => {
            // eslint-disable-next-line camelcase
            const personal_info = await getPersonalInfo();

            if (Array.isArray(personal_info)) {
                // Client Profile
                const personalProfile = personal_info.find(
                    (profile) => profile.relation === 'self'
                );

                // Set user on Sentry
                Sentry.setUser({
                    id: personalProfile.uid,
                    email: personalProfile.email,
                    username: `${personalProfile.first_name} ${personalProfile.last_name}`,
                });

                // Update personal info
                dispatch({
                    type: STORE_PERSONAL_INFO,
                    payload: personalProfile,
                });

                // Check spouse and update spouse info
                if (personal_info.length > 1) {
                    const spouseProfile = personal_info.find(
                        (profile) => profile.relation === 'spouse'
                    );

                    dispatch({
                        type: STORE_SPOUSE_INFO,
                        payload: spouseProfile,
                    });
                }
            }

            const filingStatus = await getFilingStatus();

            if (
                filingStatus &&
                typeof filingStatus === 'object' &&
                filingStatus['filing_status']
            ) {
                dispatch({
                    type: STORE_FILING_STATUS,
                    payload: filingStatus['filing_status'],
                });

                dispatch({
                    type: CHANGE_SPOUSE_DETAIL,
                    payload: filingStatus['spouse_detail'],
                });
            } else {
                dispatch({
                    type: STORE_FILING_STATUS,
                    payload: 'single',
                });
            }
        };

        // eslint-disable-next-line camelcase
        if (access_token) {
            fetchProfile();
        }
        // eslint-disable-next-line camelcase
    }, [access_token]);

    // Check Additional Income to remove Additional income step if not found
    const checkAdditionalIncome = useCallback(async () => {
        const { personalUid, spouseUid, filingStatus, spouseDetail } = state;

        const additionalIncome = await getAdditionalIncome(personalUid);

        dispatchEmployment({
            type: HAS_ADDITIONAL_INCOME,
            payload: !isArrayEmpty(additionalIncome),
        });

        const checkForSpouse = async () => {
            const spouseAdditionalIncome = await getAdditionalIncome(spouseUid);

            dispatchEmployment({
                type: SPOUSE_HAS_ADDITIONAL_INCOME,
                payload: !isArrayEmpty(spouseAdditionalIncome),
            });
        };

        if (filingStatus && filingStatus === STATUS_MARRIED) {
            checkForSpouse();
        }

        if (
            filingStatus &&
            filingStatus === STATUS_MARRIED_SEPARATELY &&
            spouseDetail
        ) {
            checkForSpouse();
        }
    }, [state]);

    // Persist employment to localStorage
    useEffect(() => {
        store.set('employmentContext', employmentState);
    });

    const profileContext = useMemo(() => {
        const {
            personalInfo,
            spouseInfo,
            fullName,
            spouseName,
            personalUid,
            spouseUid,
            filingStatus,
            noOfDependents,
            spouseDetail,
            personalBankTypes,
            spouseBankTypes,
        } = state;

        return {
            personalInfo,
            spouseInfo,
            fullName,
            spouseName,
            filingStatus,
            spouseDetail,
            personalUid,
            spouseUid,
            noOfDependents,
            employment: employmentState,
            personalBankTypes,
            spouseBankTypes,
            dispatchPersonal: dispatch,
            dispatchEmployment,
            checkAdditionalIncome,
        };
    }, [
        state,
        dispatch,
        employmentState,
        dispatchEmployment,
        checkAdditionalIncome,
    ]);

    return (
        <PersonalInfoContext.Provider value={profileContext}>
            {children}
        </PersonalInfoContext.Provider>
    );
}

export default PersonalInfoProvider;
