import { contextActionTypes } from 'contexts';
import { Decision } from 'screens/Helper';

function ClientKnowsSpouseBusiness() {
    return (
        <Decision
            apiKey="spouses_business_structure_info"
            contextKey={contextActionTypes.CLIENT_KNOWS_SPOUSE_BUSINESS}
        >
            <h1>
                Are you able to provide your{' '}
                <span className="color-blue">
                    spouse’s business structure information?
                </span>
            </h1>

            <p>
                We need their employee and business information.{' '}
                <strong>
                    If you don’t know these details, we will ask at a later
                    time.
                </strong>
            </p>
        </Decision>
    );
}

export default ClientKnowsSpouseBusiness;
