import { Fragment, useContext, useEffect, useState } from 'react';
import Navigation from 'components/Navigation';
import { FormGroup } from 'carbon-components-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import api, { endpoints } from 'api';
import { history } from 'routes';
import { PersonalInfoContext } from '../../../contexts';
import { useStepController } from '../../../hooks';
import {
    enableDotAmountCheck,
    enableMaxAmountCheck,
    enableMinAmountCheck,
} from 'lib/yupValidMethods';
import { AmountInput } from 'components/Utilities';
import { MAX_AMOUNT, MAX_AMOUNT_US, MIN_AMOUNT } from 'const';
import { parseAmount } from 'lib';

const initialValues = { income: '', net_income: '' };

function ManualIncome({ spouse }) {
    const { personalUid, spouseUid, employment } = useContext(
        PersonalInfoContext
    );
    const [incomeUid, setIncomeUid] = useState(null);
    const { nextStep, previousStep } = useStepController();
    const [manualEntry, updateManualEntry] = useState(initialValues);
    const { clientEmployerName, spouseEmployerName } = employment;

    useEffect(() => {
        const manualEntryIncome = async () => {
            try {
                const req = await api.get(
                    `${endpoints.SELF_EMPLOYED_MANUAL_INCOME}?profile=${spouse ? spouseUid : personalUid
                    }`
                );
                const res = await req.data;
                if (res) {
                    res.forEach((data) => {
                        updateManualEntry({
                            income: data.income,
                            net_income: data.net_income,
                        });
                        setIncomeUid(data.uid);
                    });
                }
            } catch (e) {
                console.log(e.response);
            }
        };

        if ((spouse && spouseUid) || (!spouse && personalUid)) {
            manualEntryIncome();
        }
    }, [personalUid, spouseUid, spouse]);

    const incomeVerification = async (values) => {
        const data = {
            income: parseAmount(values.income),
            net_income: parseAmount(values.net_income),
            expense: 0,
            status: 'in_progress',
            verified: true,
            profile: spouse ? spouseUid : personalUid,
        };
        try {
            let url = endpoints.SELF_EMPLOYED_MANUAL_INCOME;
            if (incomeUid) url += `${incomeUid}/`;
            const req = await api({
                method: incomeUid ? 'patch' : 'post',
                url,
                data,
            });
            const res = await req.data;
            if (res) {
                if (res) {
                    history.push(nextStep.path);
                }
            }
        } catch (e) {
            console.log(e.response);
        }
    };

    return (
        <Formik
            initialValues={manualEntry}
            onSubmit={incomeVerification}
            validationSchema={inputValidate}
            enableReinitialize={true}
        >
            {({ handleSubmit, values, isValid }) => (
                <Fragment>
                    <h1>
                        Please enter your
                        {spouse ? (
                            <span className="color-blue"> spouse's </span>
                        ) : (
                            ''
                        )}
                        {' '}monthly income (after taxes) from{' '}
                        {spouse ? (
                            <span className="color-blue">
                                {spouseEmployerName}
                            </span>
                        ) : (
                            <span className="color-blue">
                                {clientEmployerName}
                            </span>
                        )}{' '}
                    </h1>
                    <p>It’s okay to estimate this number.</p>
                    <section className="mt-24">
                        <FormGroup legendText={''}>
                            <AmountInput
                                id="f-income"
                                name="income"
                                labelText="Monthly Gross Income"
                                placeholder="$"
                            />
                        </FormGroup>

                        <FormGroup legendText={''}>
                            <AmountInput
                                id="n-income"
                                name="net_income"
                                labelText="Net Income"
                                placeholder="$"
                            />
                        </FormGroup>


                        <Navigation
                            disableNext={!isValid}
                            onNext={handleSubmit}
                            onPrevious={() => history.push(previousStep.path)}
                        />
                    </section>
                </Fragment>
            )}
        </Formik>
    );
}

enableDotAmountCheck();
enableMinAmountCheck();
enableMaxAmountCheck();

const inputValidate = Yup.object().shape({
    income: Yup.string()
        .required('Please enter monthly gross income')
        .onlyDot('Monthly gross income is not valid')
        .minAmount(MIN_AMOUNT, 'Monthly gross income is not valid')
        .maxAmount(MAX_AMOUNT, `Maximum gross income can be ${MAX_AMOUNT_US}`),
    net_income: Yup.string()
        .required('Please enter net income')
        .onlyDot('net income is not valid')
        .minAmount(MIN_AMOUNT, 'Net income is not valid')
        .maxAmount(MAX_AMOUNT, `Maximum net income can be ${MAX_AMOUNT_US}`),
});

export default ManualIncome;
