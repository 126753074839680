import { contextActionTypes } from 'contexts';
import { Decision } from 'screens/Helper';

function ClientKnowsSpouseWork() {
    return (
        <Decision
            apiKey="spouses_employment_info"
            contextKey={contextActionTypes.CLIENT_KNOWS_SPOUSE_WORK}
        >
            <h1>
                Are you able to provide your{' '}
                <span className="color-blue">spouse’s work information?</span>
            </h1>

            <p>
                We need their employer’s name, address, and other information.
                <strong>
                    If you don’t know these details, we will ask at a later
                    time.
                </strong>
            </p>
        </Decision>
    );
}

export default ClientKnowsSpouseWork;
