import { Decision } from 'screens/Helper';
import { contextActionTypes } from 'contexts';

function SpouseJointBank() {
    return (
        <Decision
            apiKey="spouses_income_in_your_bank"
            contextKey={contextActionTypes.SHARE_JOINT_BANK}
        >
            <h1>
                Is your <span className="color-blue">spouse’s income</span>{' '}
                deposited in{' '}
                <span className="color-blue">your bank account?</span>
            </h1>

            <p>Do you share a joint bank account?</p>
        </Decision>
    );
}

export default SpouseJointBank;
