import { toast } from 'react-toastify';
import Notification from 'components/Notification';

/**
 * This function generates droprown options from an array of object.
 * The object pattern is below
 * [{'opt1': 'Option 1'}, {'opt2': 'Option 2'}]
 *
 * @param {array} arr - Array of objects
 * @param {string} valueLabel - Provide key text, default is "id"
 * @return {array} Array for select options
 *
 * @example
 *
 *     genOptsFromArrOfObjs([{'opt1': 'Option 1'}, {'opt2': 'Option 2'}])
 */
export const genOptsFromArrOfObjs = (arr = [], valueLabel = 'id') =>
    arr.map((opt) => {
        const key = Object.keys(opt);
        const id = key[0];
        return {
            [valueLabel]: id,
            label: opt[id],
        };
    });

export const removeCountryCodeFromPhone = (phoneNumber, countryCode = '+1') => {
    if (phoneNumber.substring(0, 2) === countryCode) {
        return phoneNumber.substring(2);
    }

    return phoneNumber;
};

export const addCountryCodeWithPhone = (phoneNumber, countryCode = '+1') => {
    // Check first two character is +1, then remove and return
    if (phoneNumber.substring(0, 2) === countryCode) {
        return phoneNumber;
    } else {
        return `${countryCode}${phoneNumber}`;
    }
};

/**
 * This function render a notification and show title and message which are provided.
 * This function calls a function "toast" which is using from react-toastify package.
 *
 * @param {string} title - Notification title
 * @param {string} message - Notification message
 * @param {string} type - Notification type. "success", "error", "warning", "info". Default "success"
 *
 * @example
 *     notification("500 Server Error", "Sorry for this inconvenience! Please contact with us!", "error");
 */
export const notification = (title, message, type) => {
    if (typeof title === 'string') {
        toast(<Notification title={title} message={message} type={type} />, {
            autoClose: 5000,
        });
    }
};

export const showServerErrorAlert = () => {
    notification(
        '500 - Server Error',
        'Something went wrong! Sorry for this inconvenience. Please contact with us.',
        'error'
    );
};

const appLoader = document.getElementById('app-loader');

export const showAppLoader = () => appLoader.classList.add('spinner');
export const hideAppLoader = () => appLoader.classList.remove('spinner');

/**
 * This function generates percentage of progress from total steps in a case.
 *
 * @param {array} steps - Total Steps
 *
 * @example
 *     calculateStepProgress([
 *      {
            path: "/personal-info/",
            title: "Personal Info Verification"
        },
        {
            path: "/personal-info/filing-select/",
            title: "Personal Info Verification"
        },
        {
            path: "/personal-info/verify-contact",
            title: "Personal Info Verification"
        }
 *     ]);
 */

export const calculateStepProgress = (steps) => {
    const stepPercentage = (100 / steps.length).toFixed(2);

    return steps.map((step, index) => ({
        ...step,
        progress: (index * stepPercentage).toFixed(2),
    }));
};

/**
 * This function returns a formatted object of address, city, zip_code and state from 
 * google map's place api response.
 *
 * @param {array} results - place details response from google map
 *
 * @example
 *     formateAddressComponent([
 *      {
            "address_components" : [
                {
                    "long_name" : "280",
                    "short_name" : "280",
                    "types" : [ "subpremise" ]
                },
                {
                    "long_name" : "19900",
                    "short_name" : "19900",
                    "types" : [ "street_number" ]
                },
                {
                    "long_name" : "Macarthur Boulevard",
                    "short_name" : "MacArthur Blvd",
                    "types" : [ "route" ]
                },
                {
                    "long_name" : "Irvine",
                    "short_name" : "Irvine",
                    "types" : [ "locality", "political" ]
                },
                {
                    "long_name" : "Orange County",
                    "short_name" : "Orange County",
                    "types" : [ "administrative_area_level_2", "political" ]
                },
                {
                    "long_name" : "California",
                    "short_name" : "CA",
                    "types" : [ "administrative_area_level_1", "political" ]
                },
                {
                    "long_name" : "United States",
                    "short_name" : "US",
                    "types" : [ "country", "political" ]
                },
                {
                    "long_name" : "92612",
                    "short_name" : "92612",
                    "types" : [ "postal_code" ]
                }
            ]
        },
 *     ]);
 */

export const formateAddressComponent = (results) => {
    const location = {};

    for (let i = 0; i < results[0].address_components.length; ++i) {
        const component = results[0].address_components[i];

        if (
            !location.postal_code &&
            component.types.indexOf('postal_code') > -1
        ) {
            location.postal_code = component.long_name;
        } else if (
            !location.locality &&
            component.types.indexOf('locality') > -1
        ) {
            location.locality = component.long_name;
        } else if (
            !location.administrative_area_level_1 &&
            component.types.indexOf('administrative_area_level_1') > -1
        ) {
            location.administrative_area_level_1 = component.short_name;
        } else if (
            !location.street_number &&
            component.types.indexOf('street_number') > -1
        ) {
            location.street_number = component.long_name;
        } else if (!location.route && component.types.indexOf('route') > -1) {
            location.route = component.long_name;
        } else if (
            !location.subpremise &&
            component.types.indexOf('subpremise') > -1
        ) {
            location.subpremise = component.long_name;
        }
    }

    const {
        // eslint-disable-next-line camelcase
        street_number,
        route,
        subpremise,
        // eslint-disable-next-line camelcase
        postal_code,
        locality,
        // eslint-disable-next-line camelcase
        administrative_area_level_1,
    } = location;

    const formattedAddress = () => {
        let address = '';

        // eslint-disable-next-line camelcase
        if (street_number) address += `${street_number} `;
        if (route) address += `${route}`;
        if (subpremise) address += ` #${subpremise}`;

        return address;
    };

    return {
        address: formattedAddress(),
        zipCode: postal_code,
        state: administrative_area_level_1,
        city: locality,
    };
};

/**
 * This function converts a number to US Currency Format
 *
 * @param {number} amount - Number to convert to US Currency
 *
 * @example
 *     toUsCurrency(1230000);
 */

export const toUsCurrency = (amount) => {
    const format = {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
    };

    const NumberFormat = new Intl.NumberFormat('en-US', format);
    const formattedAmount = parseFloat(amount);

    if (!isNaN(formattedAmount)) {
        return NumberFormat.format(formattedAmount);
    }
};

export const convertDateToString = (date) => {
    const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];
    const d = new Date(date);
    return monthNames[d.getMonth()] + ' ' + d.getDate();
};

/**
 * This function check the paramter is an array and it's empty
 *
 * @param {array} arr - Parameter
 *
 * @example
 *     isArrayEmpty(["One", "Two"])
 */

export const isArrayEmpty = (arr) => {
    return Array.isArray(arr) && !arr.length;
};

export const parseAmount = (amount) =>
    parseFloat(amount.toString().replace(/,/g, ''));

export const toCapitalize = (text) => {
    return text.replace(/\b\w/g, (l) => l.toUpperCase());
};
