import { Fragment, useState, useContext, useEffect } from 'react';
import Navigation from 'components/Navigation';
import {
    InvestmentContext,
    contextActionTypes,
    PersonalInfoContext,
} from 'contexts';
import Switch from 'components/Switch';
import { useAdditionalInfo, useStepController } from 'hooks';
import { history } from 'routes';
import api, { endpoints } from 'api';

const {
    HAS_ADDITIONAL_VEHICLE,
    HAS_ADDITIONAL_VEHICLE_SPOUSE,
} = contextActionTypes;

function OwnedVehicles({ spouse }) {
    const [choice, setChoice] = useState(null);

    const {
        investments: { vehicles_financed, vehicles_financed_spouse },
        dispatchInvestments,
    } = useContext(InvestmentContext);
    const { personalUid, spouseUid } = useContext(PersonalInfoContext);

    const { has_additional_vehicles, uid } = useAdditionalInfo(
        spouse ? spouseUid : personalUid
    );
    const { nextStep, previousStep } = useStepController();

    useEffect(() => {
        if (typeof has_additional_vehicles === 'boolean') {
            setChoice(has_additional_vehicles ? 0 : 1);
        }
    }, [has_additional_vehicles]);

    const statusChange = ({ name }) => {
        const hasVehicle = name === 'yes';

        // Switch component for True, 0 and for False 1 need to provide
        setChoice(hasVehicle ? 0 : 1);

        if (spouse) {
            // Update context
            dispatchInvestments({
                type: HAS_ADDITIONAL_VEHICLE_SPOUSE,
                payload: hasVehicle,
            });
        } else {
            // Update context
            dispatchInvestments({
                type: HAS_ADDITIONAL_VEHICLE,
                payload: hasVehicle,
            });
        }
    };

    const saveChoice = async () => {
        try {
            const req = await api({
                method: 'patch',
                url: `${endpoints.CLIENT_ADDITIONAL_INFO}${uid}/`,
                data: {
                    profile: spouse ? spouseUid : personalUid,
                    has_additional_vehicles: choice === 0,
                },
            });

            const res = await req.data;

            if (res) {
                history.push(nextStep.path);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const is_additional = spouse
        ? vehicles_financed_spouse.length
        : vehicles_financed.length;

    return (
        <Fragment>
            {spouse ? (
                <h1>
                    Does your <span className="color-blue">spouse</span> own any
                    <span className="color-blue">
                        {is_additional ? ' other' : ''} vehicles?
                    </span>
                </h1>
            ) : (
                <h1>
                    Do you own any
                    <span className="color-blue">
                        {is_additional ? ' other' : ''} vehicles?
                    </span>
                </h1>
            )}

            <Switch choice={choice} onChange={statusChange} className="mt-32" />

            <Navigation
                onNext={saveChoice}
                onPrevious={() => history.push(previousStep.path)}
                disableNext={!(typeof choice === 'number')}
            />
        </Fragment>
    );
}

export default OwnedVehicles;
