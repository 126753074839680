/* eslint-disable no-invalid-this */
import * as yup from 'yup';
import moment from 'moment';
import { DATE_FORMAT } from 'const';
import { parseAmount } from 'lib';

// Yup validation method for checking date format
export const enableDateFormatMethod = () => {
    yup.addMethod(yup.string, 'dateFormat', function (message, format) {
        return this.test(
            'date-format',
            message || 'Not a valid date',
            function (value) {
                // Return true if field is empty, then it will handle by required() api
                if (!value) return true;
                const date = moment(value, format || DATE_FORMAT, true);

                return date.isValid();
            }
        );
    });
};

// Yup validation method for checking date is older than today
export const enableFutuerDateMethod = () => {
    yup.addMethod(yup.string, 'futureDate', function (
        message = 'Date should be earlier than current date',
        maxDate = moment(),
        format = DATE_FORMAT
    ) {
        return this.test('future-date', message, function (value) {
            // Return true if field is empty, then it will handle by required() api
            if (!value) return true;

            const date = moment(value, format, true);

            return date.isValid() && !date.isAfter(moment(maxDate, format));
        });
    });
};

// Yup validation method for checking minimum date
export const enableMinDateCheck = () => {
    yup.addMethod(yup.string, 'minDate', function (
        message = `Minimum date supported is 01-01-1920`,
        minDate = '01-01-1920',
        format = DATE_FORMAT
    ) {
        return this.test('min-date', message, function (value) {
            // Return true if field is empty, then it will handle by required() api
            if (!value) return true;

            const date = moment(value, format, true);

            return date.isValid() && !date.isBefore(moment(minDate, format));
        });
    });
};

export const enableZipCodeValidateMethod = () => {
    yup.addMethod(yup.string, 'zipCodeValidate', function () {
        return this.trim().matches(/^(\d{5})?$/, 'Zip Code Is 5 Digit Number');
    });
};

export const enablePhoneValidateMethod = () => {
    yup.addMethod(yup.string, 'phone', function () {
        return this.matches(/^[0-9]{10}$/g, 'Invalid phone number');
    });
};

// This test checks if an amount has only a dot at the end
export const enableDotAmountCheck = () => {
    yup.addMethod(yup.string, 'onlyDot', function (message = 'Invalid amount') {
        return this.test('only-dot', message, (value) => {
            // If no amount found, pass test is success. Required is checked separately.
            if (!value) return true;

            return !!value.split(',').join('').match(/^[0-9]+(\.[0-9]{1,2})?$/);
        });
    });
};

// This test checks if an amount is less than the minimum amount
export const enableMinAmountCheck = () => {
    yup.addMethod(yup.string, 'minAmount', function (
        amount,
        message = 'Amount is less than minimum amount'
    ) {
        return this.test('min-amount', message, (value) => {
            // If no amount found, pass test is success. Required is checked separately.
            if (!value) return true;

            return !(parseAmount(value) < amount);
        });
    });
};

// This test checks if an amount is exceeds the maximum amount
export const enableMaxAmountCheck = () => {
    yup.addMethod(yup.string, 'maxAmount', function (
        amount,
        message = 'Amount exceeds maximum amount'
    ) {
        return this.test('min-amount', message, (value) => {
            // If no amount found, pass test is success. Required is checked separately.
            if (!value) return true;

            return !(parseAmount(value) > amount);
        });
    });
};
