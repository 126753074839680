import { Fragment } from 'react';
import { FormGroup } from 'carbon-components-react';
import { Close24 } from '@carbon/icons-react';
import { Input, SwitchInput } from 'components/Form';
import styled from 'styled-components';
import { AddressBlock } from 'components/Utilities';
import { AmountInput } from 'components/Utilities';

const Title = ({ index, is_additional }) => (
    <p className="color-blue text-bold mb-13">
        {is_additional ? 'Additional' : ''} Property ({parseInt(index, 10) + 1})
    </p>
);

function PropertyAdditional(props) {
    const {
        index,
        onClear,
        additional,
        spouse,
        onResidentChange,
        hideResident,
    } = props;

    return (
        <Wrapper>
            <Title index={index} is_additional={additional} />
            <Close onClick={onClear} />

            <HiddenInput
                name={`properties[${index}].uid`}
                disabled
                labelText=""
            />

            <AddressBlock
                name={{
                    address: `properties[${index}].address.line_1`,
                    address2: `properties[${index}].address.line_2`,
                    city: `properties[${index}].address.city`,
                    state: `properties[${index}].address.state`,
                    zipCode: `properties[${index}].address.zip_code`,
                }}
                dontLoadScript
            />
            <FormGroup legendText={''}>
                <AmountInput
                    placeholder="$"
                    name={`properties[${index}].market_value`}
                    labelText="Estimated Market Value"
                />
            </FormGroup>
            <FormGroup legendText={''}>
                <AmountInput
                    placeholder="$"
                    name={`properties[${index}].outstanding_balance`}
                    labelText="Estimated Loan Balance"
                />
            </FormGroup>

            {!hideResident && (
                <Fragment>
                    <label className="bx--label">
                        Is this your {spouse && "spouse's "} primary residence?
                    </label>
                    <SwitchInput
                        name={`properties[${index}].primary_residence`}
                        onChange={onResidentChange}
                    />
                </Fragment>
            )}
        </Wrapper>
    );
}

const Wrapper = styled.section`
    border: 1px solid #d8e5f0;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 12px;
    position: relative;
`;

const HiddenInput = styled(Input)`
    height: 1px;
    overflow: hidden;
    width: 1px;
    display: none;
`;

const Close = styled(Close24)`
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 8px;
    color: #161616;
    z-index: 9;
`;

Wrapper.displayName = 'Wrapper';
Close.displayName = 'Close';

export default PropertyAdditional;
