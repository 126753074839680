import { Fragment, useCallback } from 'react';
import {
    RadioButtonGroup,
    RadioButton,
    TextInput,
} from 'carbon-components-react';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ButtonBlock } from 'components/Buttons';
import { endpoints } from 'api';
import styled from 'styled-components';
import { SignInList } from 'components/ListItems';
import {
    hideAppLoader,
    removeCountryCodeFromPhone,
    showAppLoader,
    showServerErrorAlert,
} from '../../lib';
import { EMAIL_REGEX } from 'const';
import { useRouter } from 'hooks';

function SignInPhase({ onSubmit }) {
    const router = useRouter();
    const checkClient = async (values) => {
        const { type } = values;
        // Copy value of selected type from three types.
        const clients_input = values[type];
        /* const serverErrors = {
            email: 'No record found',
            phone: 'Invalid Phone Number',
            case_number: 'Invalid case number',
        }; */

        // Concat +1 for US phone numbers
        const data = {
            clients_input:
                type === 'phone'
                    ? removeCountryCodeFromPhone(clients_input)
                    : clients_input,
            type,
        };

        try {
            showAppLoader();
            if(type === 'case_number'){
                const req_is_vlink_done = await axios.get(`${endpoints.HAS_ALREADY_COMPLETED}${clients_input}`)
                const res_is_vlink_done = await req_is_vlink_done.data;
                if(!res_is_vlink_done?.completed){
                    const req = await axios.post(endpoints.CLIENT_CHECK, data);
                    const res = await req.data;
                    hideAppLoader();

                    onSubmit(res.result);
                }
                else{
                    hideAppLoader();
                    form.setFieldError(type, 'You have already completed Verifilink once. If otherwise, please contact your Case Manager.');
                }
            }
            else{
                const req = await axios.post(endpoints.CLIENT_CHECK, data);
                const res = await req.data;
                hideAppLoader();

                onSubmit(res.result);
            }

        } catch (err) {
            hideAppLoader();
            const { status } = err.response;

            // Invalid/Wrong email, phone or case number
            if (status === 400) {
                form.setFieldError(type, 'Not available or invalid');
            }

            if (status === 404) {
                form.setFieldError(type, 'No account found');
            }

            if (status === 500) {
                showServerErrorAlert();
            }
        }
    };

    const extractCaseNumber = useCallback(() => {
        let caseIdFromURL = '';
        if (router.query.case_id != null) {
            caseIdFromURL = router.query.case_id;
        }
        else {
            caseIdFromURL = router.location.search.split('=')[1];
        }
        return caseIdFromURL;
    }, [router.location.search, router.query.case_id]);

    const form = useFormik({
        initialValues: {
            phone: '',
            email: '',
            case_number: extractCaseNumber(),
            type: 'case_number',
        },
        onSubmit: checkClient,
        validationSchema: clientCheckSchema,
        isInitialValid: false,
        validateOnMount: true,
    });



    // Display specific type field based on selected type
    const typeField = () => {
        const {
            errors,
            touched,
            values: { type },
        } = form;

        switch (type) {
            case 'phone':
                return (
                    <PhoneWrapper>
                        <PhoneInput
                            invalid={errors.phone && touched.phone}
                            id="input-phone"
                            name="phone"
                            invalidText={errors.phone}
                            labelText="Phone Number"
                            placeholder="Enter Phone Number"
                            onChange={form.handleChange}
                            value={form.values.phone}
                            data-hj-allow
                        />
                        <CountryCode>+1</CountryCode>
                    </PhoneWrapper>
                );
            case 'email':
                return (
                    <TextInput
                        invalid={errors.email && touched.email}
                        id="input-email"
                        name="email"
                        type="email"
                        invalidText={errors.email}
                        labelText="Email Address"
                        placeholder="Enter Email Address"
                        onChange={form.handleChange}
                        value={form.values.email}
                        data-hj-allow
                    />
                );

            default:
                return (
                    <TextInput
                        invalid={errors.case_number && touched.case_number}
                        id="input-case"
                        name="case_number"
                        invalidText={errors.case_number}
                        labelText="TaxRise Case Number"
                        placeholder="Enter TaxRise Case Number"
                        onChange={form.handleChange}
                        value={form.values.case_number}
                        data-hj-allow
                    />
                );
        }
    };
    return (
        <Fragment>
            <h1>
                Welcome To <span className="color-blue">Verifilink</span>.
            </h1>
            <p className="welcome-text">
                The secure way to verify your eligibility for tax relief.
                Powered by TaxRise.
            </p>
            <h2>Sign in with TaxRise</h2>
            <p>
                Please verify your identity by selecting one of the following
                sign in options associated with your TaxRise account.
            </p>
            <form>
                <RadioButtonGroupWrapper
                    orientation="vertical"
                    labelPosition="right"
                    defaultSelected="case_number"
                    legend="Group Legend"
                    name="type"
                    valueSelected={form.values.type}
                    className="sign-phase-radio-button"
                    onChange={(type) => form.setFieldValue('type', type)}
                >
                    <RadioButton
                        id="case-number"
                        className="test"
                        // labelText="TaxRise Case Number"
                        labelText={
                            <SignInList
                                header={'TaxRise Case Number'}
                                subHeader={'(Provided by your case manager)'}
                            />
                        }
                        value="case_number"
                    />
                    <RadioButton
                        id="phone"
                        labelText="Phone Number"
                        value="phone"
                    />
                    <RadioButton
                        id="email"
                        labelText="Email Address"
                        value="email"
                    />
                </RadioButtonGroupWrapper>

                {typeField()}

                <ButtonBlock
                    type={'submit'}
                    size="field"
                    className="mt-24"
                    onClick={form.handleSubmit}
                    disabled={form.values.case_number ? false : true}
                >
                    Next
                </ButtonBlock>
            </form>
        </Fragment>
    );
}

const clientCheckSchema = Yup.object().shape({
    type: Yup.string().required('Required field'),
    email: Yup.string()
        .matches(EMAIL_REGEX, {
            excludeEmptyString: true,
            message: 'Invalid Email',
        })
        .when(['type'], {
            is: (type) => type === 'email',
            then: Yup.string().required('Required field'),
        }),
    case_number: Yup.string().when(['type'], {
        is: (type) => type === 'case_number',
        then: Yup.string().required('Required field'),
    }),
    phone: Yup.string().when(['type'], {
        is: (type) => type === 'phone',
        then: Yup.string().required('Required field'),
    }),
});

const PhoneWrapper = styled('div')`
    position: relative;
`;

const PhoneInput = styled(TextInput)`
    padding-left: 50px;
`;

const CountryCode = styled('p')`
    margin-bottom: 0;
    position: absolute;
    top: 26px;
    left: 2px;
    font-size: 0.875rem;
    background: #edefed;
    height: 36px;
    line-height: 34px;
    width: 40px;
    color: #161616;
    text-align: center;
`;

const RadioButtonGroupWrapper = styled(RadioButtonGroup)`
    .bx--radio-button-wrapper .bx--radio-button__label {
        align-items: flex-start;
    }
    .bx--radio-button__appearance {
        margin-top: 3px;
    }
    .bx--radio-button:checked
        + .bx--radio-button__label
        .bx--radio-button__appearance {
        margin-top: 3px;
    }
`;

export default SignInPhase;
