import { useField } from 'formik';
import { TextInput } from 'carbon-components-react';
import styled from 'styled-components';

function Input(props) {
    const {
        labelText,
        name,
        placeholder,
        className,
        type,
        addon,
        disabled,
    } = props;
    const [field, meta, helper] = useField(name);

    const { value, onBlur } = field;
    const { touched, error } = meta;
    const { setValue } = helper;

    const onChange = (e) => {
        setValue(e.target.value);
    };

    return (
        <Wrapper addon={addon}>
            <TextInput
                id={name}
                type={type || 'text'}
                labelText={labelText}
                placeholder={placeholder}
                className={className}
                value={value || ''}
                onChange={onChange}
                onBlur={onBlur}
                invalid={error && touched}
                invalidText={error && touched ? error : ''}
                disabled={disabled}
                data-hj-allow
            />

            {addon && <Addon>{addon}</Addon>}
        </Wrapper>
    );
}

const Wrapper = styled('div')`
    position: relative;

    .bx--text-input {
        padding-left: ${(props) => (props.addon ? '50px' : '16px')};
    }
`;

const Addon = styled('p')`
    margin-bottom: 0;
    position: absolute;
    top: 26px;
    left: 2px;
    font-size: 0.875rem;
    background: #edefed;
    height: 36px;
    line-height: 34px;
    width: 40px;
    color: #161616;
    text-align: center;
`;

export default Input;
