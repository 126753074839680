import styled from 'styled-components';

export const AmountWrapper = styled('div')`
    position: relative;
    span {
        margin-bottom: 0;
        position: absolute;
        top: 26px;
        bottom: 2px;
        left: 2px;
        font-size: 0.875rem;
        background: #ebf2f7;
        height: 36px;
        line-height: 34px;
        width: 40px;
        color: #b3bac2;
        text-align: center;
    }

    .bx--text-input {
        padding-left: 50px;
    }
`;
