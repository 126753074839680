import { Fragment, useEffect, useContext, useState } from 'react';
import { InvestmentContext } from 'contexts';
import { Dropdown } from 'components/Form';
import { useFormikContext } from 'formik';
import { AddressBlock } from 'components/Utilities';

const OTHER_TYPE = 'other';

function PropertyAddress({ spouse }) {
    const [addresses, updateAddresses] = useState([]);
    const {
        investments: { addresses_mortgage, addresses_mortgage_spouse },
    } = useContext(InvestmentContext);
    const { values } = useFormikContext();

    useEffect(() => {
        const address = spouse ? addresses_mortgage_spouse : addresses_mortgage;

        if (Array.isArray(address) && address.length) {
            const addr = address.map((address) => ({
                id: address.uid,
                label: address.data,
            }));

            updateAddresses([...addr, { id: OTHER_TYPE, label: 'Other' }]);
        } else {
            // If no address found then enable manual option
            updateAddresses([{ id: OTHER_TYPE, label: 'Other' }]);
        }
    }, [addresses_mortgage, spouse, addresses_mortgage_spouse]);

    return (
        <section className="mt-32">
            <h2>Which address does this Mortgage belong to?</h2>
            <Dropdown
                name="address"
                id="address"
                ariaLabel="Dropdown"
                items={addresses}
                label="Select Mortgage Address"
                titleText="Mortgage Address"
            />

            {values.address === OTHER_TYPE && (
                <Fragment>
                    <h2 className="mt-32">
                        Enter this property’s information.
                    </h2>
                    <AddressBlock
                        dontLoadScript
                        name={{
                            address: 'other_address.line_1',
                            address2: 'other_address.line_2',
                            city: 'other_address.city',
                            state: 'other_address.state',
                            zipCode: 'other_address.zip_code',
                        }}
                    />
                </Fragment>
            )}
        </section>
    );
}

export default PropertyAddress;
