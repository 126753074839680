import { useState, useEffect, useContext, Fragment } from 'react';
import {
    enableDateFormatMethod,
    enableZipCodeValidateMethod,
    enableFutuerDateMethod,
    enableMinDateCheck,
    enableDotAmountCheck,
    enableMinAmountCheck,
    enableMaxAmountCheck,
} from 'lib/yupValidMethods';
import { InlineLoading } from 'carbon-components-react';
import { contextActionTypes, PersonalInfoContext } from 'contexts';
import { useStepController } from '../../hooks';
import { withFormik } from 'formik';
import api, { endpoints } from 'api';
import { history } from 'routes';
import DepositReasonDecision from './DepositReasonDecision';
import { withRouter } from 'react-router-dom';
import useDepositReason from './useDepositReason';
import mapAdditionalIncome from './mapAdditionalIncome';
import updateValuesForType from './updateValuesForType';
import Navigation from 'components/Navigation';
import moment from 'moment';
import * as Yup from 'yup';
import { useMemo } from 'react';

const { IGNORES_ADD_INCOME, SPOUSE_IGNORES_ADD_INCOME } = contextActionTypes;

function VerifyAdditionalIncome(props) {
    const { values, setFieldValue, handleSubmit, isValid, spouse } = props;
    const { data: addIncFormData } = values;

    const [loading, setLoading] = useState(true);
    const [transactions, setTransactions] = useState([]);
    const [depositReason, setDepositReason] = useState([]);
    const [defaultReason, setDefaultReason] = useState(''); // Default reason uid

    const { personalUid, spouseUid, dispatchEmployment } = useContext(
        PersonalInfoContext
    );
    const { previousStep, nextStep } = useStepController();
    const [reasons, reasonTypeObj] = useDepositReason();

    useEffect(() => {
        const transactionList = async () => {
            try {
                const req = await api({
                    url: `${endpoints.AVAILABLE_DEPOSIT}${
                        spouse ? spouseUid : personalUid
                    }/`,
                    params: {
                        inc_type: 2,
                    },
                });
                const res = await req.data;
                if (res) {
                    setTransactions(res);
                    setLoading(false);
                }
            } catch (e) {
                console.log(e.response);
            }
        };

        if ((spouse && spouseUid) || (!spouse && personalUid)) {
            transactionList();
        }
    }, [personalUid, spouseUid, spouse]);

    useEffect(() => {
        setDepositReason(
            reasons.map((r) => {
                const { name, uid } = r;

                if (name === 'Ignore') {
                    setDefaultReason(uid);
                }

                return { label: name, id: uid };
            })
        );
    }, [reasons]);

    useEffect(() => {
        if (Array.isArray(transactions) && transactions.length) {
            setFieldValue(
                'data',
                transactions.map((r) => {
                    const { uid, additional_income, amount } = r;
                    return {
                        income_transaction: uid,
                        profile: spouse ? spouseUid : personalUid,
                        uid: additional_income ? additional_income.uid : '',
                        deposit_reason: additional_income
                            ? additional_income.deposit_reason.uid
                            : defaultReason,
                        reasons: reasonTypeObj,
                        amount,
                        ...updateValuesForType(
                            additional_income,
                            reasonTypeObj
                        ),
                    };
                })
            );
        }
    }, [
        transactions,
        personalUid,
        spouse,
        setFieldValue,
        defaultReason,
        reasonTypeObj,
        spouseUid,
    ]);

    useEffect(() => {
        setFieldValue('nextStep', nextStep);
    }, [nextStep, setFieldValue]);

    // To detect if user ignores all additional income
    const ignoresAllIncome = useMemo(() => {
        const ignoreInc = addIncFormData.find(
            (d) => d.deposit_reason !== defaultReason
        );

        return !ignoreInc;
    }, [addIncFormData, defaultReason]);

    // Update step
    useEffect(() => {
        dispatchEmployment({
            type: spouse ? SPOUSE_IGNORES_ADD_INCOME : IGNORES_ADD_INCOME,
            payload: ignoresAllIncome,
        });
    }, [ignoresAllIncome, spouse, dispatchEmployment]);

    return (
        <form>
            <h1>
                Verify <span className="color-blue">Additional Income</span>
                {spouse ? (
                    <Fragment>
                        {' '}
                        for your <span className="color-blue">Spouse</span>.
                    </Fragment>
                ) : (
                    '.'
                )}
            </h1>
            <p className="mb-32">
                Please select the most accurate reason for each additional deposit detect
                via the drop down below. If you do not believe a deposit should be considered
                toward your total monthly income, please select “Other”
            </p>
            {loading && (
                <Fragment>
                    <InlineLoading
                        status="active"
                        description="Please Wait..."
                        className="mb-40"
                    />
                </Fragment>
            )}
            {!loading && (
                <Fragment>
                    {values.data.length > 0 && (
                        <Fragment>
                            <h2>Additional Income:</h2>

                            <div>
                                {values.data.map((dataItem, index) => (
                                    <DepositReasonDecision
                                        key={index}
                                        index={index}
                                        reason={transactions[index]}
                                        reasonTypes={depositReason}
                                        typeObj={reasonTypeObj}
                                    />
                                ))}
                            </div>
                        </Fragment>
                    )}
                    {values.data.length === 0 && (
                        <Fragment>
                            <h2>No Additional Income Found</h2>
                        </Fragment>
                    )}
                </Fragment>
            )}

            <Navigation
                disableNext={!isValid}
                onNext={handleSubmit}
                onPrevious={() => history.push(previousStep.path)}
            />
        </form>
    );
}

const mapFormData = (values) => {
    const formDataArray = [];
    const { data } = values;
    data.forEach((income) => {
        formDataArray.push(mapAdditionalIncome(income));
    });
    return formDataArray;
};

enableDateFormatMethod();
enableFutuerDateMethod();
enableZipCodeValidateMethod();
enableMinDateCheck();
enableDotAmountCheck();
enableMinAmountCheck();
enableMaxAmountCheck();

const validationRules = Yup.object().shape({
    data: Yup.array().of(
        Yup.object().shape({
            uid: Yup.string(),
            deposit_reason: Yup.string(),
            amount: Yup.string(),
            income_transaction: Yup.string(),
            profile: Yup.string(),
            rental_expense: Yup.boolean().nullable(),
            total_rental_expense_amount: Yup.string()
                .nullable()
                .typeError('Invalid Amount')
                .when(
                    ['deposit_reason', 'reasons', 'rental_expense', 'amount'],
                    (
                        deposit_reason,
                        reasons,
                        rental_expense,
                        amount,
                        schema
                    ) => {
                        if (
                            reasons[deposit_reason] === 'Rental Income' &&
                            rental_expense === true
                        ) {
                            return schema
                                .required('Provide correct expense')
                                .onlyDot('Your monthly income is not valid')
                                .minAmount(
                                    0,
                                    'Amount should be more than or equal to 0'
                                )
                                .maxAmount(
                                    amount,
                                    'Amount should be equal or less than ' +
                                        amount
                                );
                        }
                        return schema;
                    }
                ),
            business_name: Yup.string()
                .nullable()
                .when(['deposit_reason', 'reasons'], {
                    is: (deposit_reason, reasons) =>
                        reasons[deposit_reason] === 'No Longer Working Here' ||
                        reasons[deposit_reason] === 'Part Time Job',
                    then: Yup.string()
                        .required('Employer is required')
                        .test(
                            'len',
                            'Please provide valid occupation name',
                            (val) => (val + '').length >= 2
                        ),
                }),
            worked_from: Yup.string()
                .nullable()
                .when(['deposit_reason', 'reasons'], {
                    is: (deposit_reason, reasons) =>
                        reasons[deposit_reason] === 'No Longer Working Here',
                    then: Yup.string()
                        .required('Required field')
                        .dateFormat()
                        .futureDate()
                        .minDate(),
                }),
            worked_to: Yup.string()
                .nullable()
                .when(
                    ['deposit_reason', 'reasons', 'worked_from'],
                    (deposit_reason, reasons, worked_from, schema) => {
                        if (
                            reasons[deposit_reason] === 'No Longer Working Here'
                        ) {
                            return schema
                                .required('Required field')
                                .dateFormat()
                                .futureDate()
                                .minDate()
                                .test(
                                    'is-valid',
                                    'Date is earlier than working from',
                                    (value) => {
                                        if (worked_from && value) {
                                            return moment(value).isAfter(
                                                worked_from
                                            );
                                        } else {
                                            return true;
                                        }
                                    }
                                );
                        }
                    }
                ),
            income_type: Yup.string()
                .nullable()
                .when(['deposit_reason', 'reasons'], {
                    is: (deposit_reason, reasons) =>
                        reasons[deposit_reason] === 'Part Time Job',
                    then: Yup.string().required('Income type is required'),
                }),
            deposit_description: Yup.string()
                .nullable()
                .when(['deposit_reason', 'reasons'], {
                    is: (deposit_reason, reasons) =>
                        reasons[deposit_reason] === 'Other',
                    then: Yup.string().required('Description is required'),
                }),
            reasons: Yup.object().shape({}),
        })
    ),
});

export default withRouter(
    withFormik({
        mapPropsToValues: () => ({ data: [] }),
        initialValues: { data: [] },
        displayName: 'additional-income',
        validationSchema: validationRules,
        validateOnMount: true,
        handleSubmit: async (
            values,
            {
                props: {
                    history,
                    match: { params },
                },
            }
        ) => {
            const dataMapping = mapFormData(values);
            try {
                const req = await api.post(
                    endpoints.ADDITIONAL_INCOME_INSERT,
                    dataMapping
                );
                const res = await req.data;
                const { nextStep } = values;
                if (res) {
                    history.push(nextStep.path);
                }
            } catch (e) {
                console.log(e);
            }
        },
    })(VerifyAdditionalIncome)
);
