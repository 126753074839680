import { endpoints } from 'api';
import { InlineLoading, InlineNotification } from 'carbon-components-react';
import { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import api from 'axios';
import store from 'store';
import { PERSONAL_INFORMATION } from 'routes/paths';
import { AuthContext } from 'contexts';

function ExternalLogin(props) {
    const [error, setError] = useState({ hasError: false, status: null });
    const { authDispatch } = useContext(AuthContext);

    const params = useParams();
    const history = useHistory();

    useEffect(() => {
        const { short_token } = params;

        const generateAccess = async () => {
            try {
                const data = new FormData();
                data.append('grant_type', 'password');
                data.append('username', 'cp-connect');
                data.append('password', short_token);

                const req = await api({
                    method: 'POST',
                    url: endpoints.EXTERNAL_CONNECT,
                    data,
                    auth: {
                        // eslint-disable-next-line no-undef
                        username: process.env['REACT_APP_CLIENT_ID'],
                        // eslint-disable-next-line no-undef
                        password: process.env['REACT_APP_CLIENT_SECRET'],
                    },
                });

                const res = await req.data;

                if (res['access_token'] && res['refresh_token']) {
                    store.set('access_token', res['access_token']);
                    store.set('refresh_token', res['refresh_token']);

                    authDispatch({ type: 'REHYDRATE' });

                    history.replace(PERSONAL_INFORMATION);
                } else {
                    setError({
                        hasError: true,
                        status: null,
                    });
                }
            } catch (error) {
                const { response } = error;
                if (response && response['status']) {
                    setError({
                        hasError: true,
                        status: response['status'],
                    });
                } else {
                    setError({
                        hasError: false,
                        status: null,
                    });
                }
            }
        };

        if (short_token) generateAccess();
    }, [params, history, authDispatch]);

    const errorMessage = (
        <div>
            <span>Something went wrong!</span>
            <p className="mt-24">
                {error.status === 500 ? (
                    <span>
                        It&apos;s not you, it&apos;s us! We are working to fix
                        the issue.
                    </span>
                ) : (
                    <span>
                        The URL you are trying is not valid or expired! <br />
                        Please try again.
                    </span>
                )}
            </p>
        </div>
    );

    if (error.hasError) {
        return (
            <InlineNotification
                kind="warning"
                iconDescription="describes the close button"
                subtitle={errorMessage}
                hideCloseButton
                title={''}
                lowContrast
            />
        );
    }

    return <InlineLoading description="Please wait!" />;
}

export default ExternalLogin;
