import { contextActionTypes } from 'contexts';
import { Decision } from 'screens/Helper';

function ClientKnowsSpouseAssetInvest() {
    return (
        <Decision
            apiKey="provide_spouses_asset_investment_info"
            contextKey={contextActionTypes.CLIENT_KNOWS_SPOUSE_ASSET_INVEST}
        >
            <h1>
                Are you able to provide your{' '}
                <span className="color-blue">
                    spouse’s asset and investment information?
                </span>
            </h1>
            <p>
                We need information about their cars and homes (if they have
                any).{' '}
                <strong>
                    If you don’t know these details, we will ask at a later
                    time.
                </strong>
            </p>
        </Decision>
    );
}

export default ClientKnowsSpouseAssetInvest;
