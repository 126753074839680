import { contextActionTypes } from 'contexts';
import { Decision } from 'screens/Helper';

function SpouseHasAssetInvest() {
    return (
        <Decision
            apiKey="has_spouses_asset_investment"
            contextKey={contextActionTypes.SPOUSE_HAS_ASSET_INVESTMENT}
        >
            <h1>
                Does your <span className="color-blue">spouse</span> have{' '}
                <span className="color-blue">assets or investments</span> in
                their name?
            </h1>

            <p>
                We need information about their cars and homes (if they have
                any).
            </p>
        </Decision>
    );
}

export default SpouseHasAssetInvest;
