function genReasonTypeObj(arr) {
    const resultObj = {};

    if (Array.isArray(arr)) {
        for (let i = 0; i < arr.length; i++) {
            resultObj[arr[i].uid] = arr[i].name;
        }
    }

    return resultObj;
}

export default genReasonTypeObj;
