import { useContext, useEffect, useState } from 'react';
import Navigation from 'components/Navigation';
import { useStepController } from 'hooks';
import { history } from 'routes';
import { Dropdown } from 'carbon-components-react';
import { BUSINESS_STRUCTURES, SELF_PRIMARY_BUSINESS_KEY } from 'const';
import useBusinessInfo from './useBusinessInfo';
import { PersonalInfoContext } from 'contexts';
import api, { endpoints } from 'api';

function BusinessStructure({spouse}) {
    // Business Structure
    const [structure, setStructure] = useState({});

    const { nextStep, previousStep } = useStepController();
    const { personalUid, spouseUid} = useContext(PersonalInfoContext);
    const { businessInfo } = useBusinessInfo(
        spouse ? spouseUid : personalUid,
        SELF_PRIMARY_BUSINESS_KEY
    );

    useEffect(() => {
        if (businessInfo['business_structure_type']) {
            const structureItem = BUSINESS_STRUCTURES.find(
                (s) => s.id === businessInfo['business_structure_type']
            );

            setStructure(structureItem || {});
        }
    }, [businessInfo]);

    const onStructureChange = ({ selectedItem }) => {
        setStructure(selectedItem);
    };

    const submit = async () => {
        const uid = businessInfo['uid'];

        const data = {
            profile: spouse ? spouseUid : personalUid,
            verified: true,
            self_employed_business_type: SELF_PRIMARY_BUSINESS_KEY,
            business_structure_type: structure.id,
        };

        try {
            let url = endpoints.SELF_EMPLOYED_BUSINESS_INFO;
            if (uid) url += `${uid}/`;

            const req = await api({
                method: uid ? 'patch' : 'post',
                url,
                data,
            });

            const res = await req.data;

            if (res) {
                history.push(nextStep.path);
            }
        } catch (e) {
            console.log(e.response);
        }
    };

    return (
        <div>
            <h1 className="mb-25">
                What <span className="color-blue">type of business</span>{' '}do{' '}
                {spouse ? (
                    <span className="color-blue">your spouse's</span>
                ) : (
                    'you'
                )}{' '}have?
            </h1>

            <Dropdown
                id="businessStructure"
                label=""
                name="business-structure"
                titleText="Business Structure"
                items={BUSINESS_STRUCTURES}
                selectedItem={structure}
                onChange={onStructureChange}
            />

            <Navigation
                disableNext={!structure.id}
                onNext={submit}
                onPrevious={() => history.push(previousStep.path)}
            />
        </div>
    );
}

export default BusinessStructure;
