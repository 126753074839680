import { useReducer, useEffect } from 'react';
import AuthContext from './AuthContext';
import store from 'store';

const initialAuth = {
    access_token: null,
    refresh_token: null,
};

const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            store.set('access_token', action.payload.access_token);
            store.set('refresh_token', action.payload.refresh_token);
            return {
                ...state,
                access_token: action.payload.access_token,
                refresh_token: action.payload.refresh_token,
            };
        case 'LOGOUT':
            store.clearAll();
            return {
                ...state,
                access_token: null,
                refresh_token: null,
            };
        case 'REHYDRATE':
            return {
                ...state,
                access_token: store.get('access_token'),
                refresh_token: store.get('refresh_token'),
            };
        default:
            return new Error('Action type not found!');
    }
};

function AuthProvider({ children }) {
    const [state, dispatch] = useReducer(authReducer, initialAuth);

    useEffect(() => {
        // Update state on page refresh / reopen app
        dispatch({ type: 'REHYDRATE' });
    }, []);

    return (
        <AuthContext.Provider
            value={{
                authData: state,
                authDispatch: dispatch,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;
