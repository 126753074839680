import { Switch, Route } from 'react-router-dom';
import PropType from 'prop-types';
import { PageNotFound } from 'screens/Errors';

function InnerRoutes({ routes }) {
    return (
        <Switch>
            {routes.map((route, key) => {
                const { path, component, options } = route;
                return (
                    <Route {...options} path={path} key={key}>
                        {component}
                    </Route>
                );
            })}
            <Route path="*">
                <PageNotFound />
            </Route>
        </Switch>
    );
}

InnerRoutes.propTypes = {
    routes: PropType.array.isRequired,
};

InnerRoutes.defaultProps = {
    routes: [],
};

export default InnerRoutes;
