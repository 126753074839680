import { Button } from 'carbon-components-react';
import { Add16 } from '@carbon/icons-react';
import styled from 'styled-components';

function LinkButton(props) {
    return (
        <LinkBtn type={'button'} {...props}>
            <Add16 className="plus-icon" />
            {props.children}
        </LinkBtn>
    );
}
const LinkBtn = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: start;
    background-color: transparent;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0px;
    color: #008ac8;
    min-height: auto;
    padding: 0 16px 0 0;
    position: relative;
    max-width: inherit;
    &:hover {
        background-color: transparent !important;
        color: #008ac8;
    }
    &:focus {
        background-color: transparent !important;
        color: #008ac8;
        border: 1px solid transparent;
    }

    svg.plus-icon {
        fill: white;
        width: 40px;
        height: 40px;
        background-color: #008ac8;
        margin-right: 1rem;
        padding: 4px;
    }
`;

export default LinkButton;
