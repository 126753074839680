import { useMemo } from 'react';
import { FormGroup } from 'carbon-components-react';
import { Close24 } from '@carbon/icons-react';
import { Input } from 'components/Form';
import styled from 'styled-components';
import { Dropdown } from 'components/Form';
import { useFormikContext } from 'formik';
import { AmountInput } from 'components/Utilities';
import RentalIncome from './RentalIncome/RentalIncome';
import PartTimeJob from './PartTimeJob/PartTimeJob';
import NoLongerWorkingHere from './NoLongerWorkingHere/NoLongerWorkingHere';
import Other from './Other/Other';

const renderType = (type, index) => {
    switch (type) {
        case 'Rental Income':
            return <RentalIncome index={index} />;
        case 'No Longer Working Here':
            return <NoLongerWorkingHere index={index} />;
        case 'Part Time Job':
            return <PartTimeJob index={index} />;
        case 'Other':
            return <Other index={index} />;
        default:
            return null;
    }
};

function ManualAdditionalForm(props) {
    const { index, onClear, values, reasons, typeObj} = props;
    const {
        values: { data },
    } = useFormikContext();

    const value = useMemo(() => {
        if (data[index]) {
            return data[index].deposit_reason;
        } else {
            return '';
        }
    }, [data, index]);
    return (
        <Wrapper>
            {values.data.length > 1 &&
                <Close onClick={onClear}/>
            }
            <HiddenInput
                name={`data[${index}].uid`}
                disabled
                labelText=""
            />
            <FormGroup legendText="">
                <Input
                    name={`data[${index}].source_of_income`}
                    labelText="Source of Additional Income"
                />
            </FormGroup>
            <FormGroup legendText="">
                <AmountInput
                    name={`data[${index}].gross_income`}
                    labelText="Additional Income Amount"
                />
            </FormGroup>

            <FormGroup legendText="">
                <Dropdown
                    id={`data[${index}].deposit_reason`}
                    name={`data[${index}].deposit_reason`}
                    titleText="Income Reason"
                    items={reasons}
                />
            </FormGroup>
            {renderType(typeObj[value], index)}
        </Wrapper>
    );
}

const Wrapper = styled.section`
    border: 1px solid #d8e5f0;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 32px;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -17px;
        height: 1px;
        background: #cdd3d8;
    }

    &:last-child::after {
        content: none;
    }
`;

const HiddenInput = styled(Input)`
    height: 1px;
    overflow: hidden;
    width: 1px;
    display: none;
`;

const Close = styled(Close24)`
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 8px;
    color: #161616;
    z-index: 9;
`;

Wrapper.displayName = 'Wrapper';
Close.displayName = 'Close';

export default ManualAdditionalForm;
