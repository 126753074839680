import styled from 'styled-components';

const WelcomeWrapper = styled.div`
    img {
        max-width: 311px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        max-height: 312px;
    }
    p {
        font-size: 12px;
        line-height: 18px;
    }
`;
export default WelcomeWrapper;
