import CheckboxField from './Checkbox';
import styled from 'styled-components';
import PropType from 'prop-types';

function TermsCheckbox(props) {
    return (
        <Wrapper>
            {/* labelText won't be used here thus overwriting it with empty string */}
            <Checkbox {...props} labelText="" />
            <TextSection>{props.terms}</TextSection>
        </Wrapper>
    );
}

TermsCheckbox.propTypes = {
    name: PropType.string.isRequired,
    id: PropType.string,
    terms: PropType.oneOfType([PropType.string, PropType.node]),
};

const Wrapper = styled('section')`
    display: flex;

    div.bx--form-item.bx--checkbox-wrapper {
        margin-top: 0;
    }
`;

const Checkbox = styled(CheckboxField)`
    &::before {
        border-color: #008ac8;
    }

    .bx--checkbox:checked + &::before {
        background-color: #008ac8;
        border-color: #008ac8;
    }
`;

const TextSection = styled('div')`
    font-size: 11px;
    line-height: 1.5;
    font-weight: 400;
    color: #707070;
    margin-bottom: 8px;
    letter-spacing: 0.16px;
`;

export default TermsCheckbox;
