import { useEffect, useState, useContext, useMemo } from 'react';
import { InvestmentContext } from 'contexts';
import * as Yup from 'yup';
import { FieldArray, Formik } from 'formik';
import api, { endpoints } from 'api';
import { LinkButton } from 'components/Buttons';
import { useStepController, useModal } from 'hooks';
import { history } from 'routes';
import Modal from 'components/Modal';
import Navigation from 'components/Navigation';
import { PersonalInfoContext } from 'contexts';
import PropertyAdditional from './PropertyAdditional';
import { LoadGoogleMapScript } from 'components/GoogleMap';
import {
    MAX_AMOUNT_PROPERTIES,
    MIN_ADDRESS_CHARS,
    MAX_ADDRESS_CHARS,
} from 'const';
import { toUsCurrency } from 'lib';
import {
    enableDotAmountCheck,
    enableMinAmountCheck,
    enableMaxAmountCheck,
} from 'lib/yupValidMethods';
import { parseAmount } from 'lib';

const initProperty = {
    address: { line_1: '', line_2: '', city: '', state: 'AL', zip_code: '' },
    market_value: '',
    outstanding_balance: '',
    verified: true,
    uid: '',
    primary_residence: false,
};

function AdditionalProperties({ spouse }) {
    const [initialValues, updateInitial] = useState({
        properties: [Object.assign({}, initProperty)],
    });
    const {
        investments: { properties_financed, properties_financed_spouse },
    } = useContext(InvestmentContext);
    /* 
        Index of dependent to clear and remove method.
        Remove method is necessary to save in state because FieldArray methods can't be accessed outside render. 
    */
    const [delPropertyInfo, setDeleteProperty] = useState([]);

    const { personalUid, spouseUid } = useContext(PersonalInfoContext);

    const { previousStep, nextStep } = useStepController();
    const [open, showModal, hideModal] = useModal();

    useEffect(() => {
        // Fetch existing dependents
        const fetchExistProperties = async (profile) => {
            if (!profile) return;

            try {
                const req = await api({
                    url: endpoints.ADDITIONAL_PROPERTY,
                    params: {
                        profile,
                    },
                });
                const res = await req.data;

                if (Array.isArray(res) && res.length) {
                    const existingProperties = res.map((p) => ({
                        address: {
                            line_1: p.address && p.address.line_1,
                            line_2: p.address && p.address.line_2,
                            city: p.address && p.address.city,
                            state: p.address && p.address.state,
                            zip_code: p.address && p.address.zip_code,
                        },
                        market_value: p.market_value,
                        outstanding_balance: p.outstanding_balance,
                        verified: true,
                        uid: p.uid,
                        primary_residence: p.primary_residence,
                    }));

                    updateInitial({ properties: existingProperties });
                }
            } catch (e) {
                console.log(e.response);
            }
        };

        if (personalUid) fetchExistProperties(spouse ? spouseUid : personalUid);
    }, [personalUid, spouse, spouseUid]);

    // Checking user has selected primary residence on dynamic property
    const hasExistingPrimaryResidence = useMemo(() => {
        const dynamicProperties = spouse
            ? properties_financed_spouse
            : properties_financed;

        for (let i = 0; i < dynamicProperties.length; i++) {
            if (dynamicProperties[i].primary_residence === true) {
                return true;
            }
        }

        return false;
    }, [spouse, properties_financed, properties_financed_spouse]);

    const saveProperties = async (values) => {
        try {
            const properties = values.properties.map((property) => ({
                profile: spouse ? spouseUid : personalUid,
                address: property.address,
                verified: property.verified,
                uid: property.uid ? property.uid : '',
                market_value: parseAmount(property.market_value),
                outstanding_balance: property.outstanding_balance
                    ? parseAmount(property.outstanding_balance)
                    : 0,
                primary_residence: hasExistingPrimaryResidence
                    ? false
                    : property.primary_residence,
            }));

            const req = await api.post(
                endpoints.ADDITIONAL_PROPERTY,
                properties
            );
            const res = await req.data;

            if (res) {
                history.push(nextStep.path);
            }
        } catch (err) {
            console.log(err.response);
        }
    };

    const handleClear = (index, method, uid) => {
        setDeleteProperty([index, method, uid]);
        showModal();
    };

    const deleteProperty = async () => {
        const [index, remove, uid] = delPropertyInfo;

        // Delete Dependent from backend when uid available otherwise remove from the design
        if (!uid) {
            remove(index);
        } else {
            try {
                const deleteReq = await api({
                    method: 'delete',
                    url: `${endpoints.ADDITIONAL_PROPERTY}${uid}/`,
                });

                if (deleteReq) remove(index);
            } catch (e) {
                console.log(e.response);
            }
        }
    };

    const goBack = () => {
        history.push(previousStep.path);
    };

    /* 
        Client can set only one property as primary residence! Update other properties
        when one property is primary residence.

        choice = Yes or No selection data, values are true/false
        values = form data
        index = index of property which is primary residence
    */
    const handlePrimaryResSelection = (choice, values, index) => {
        // Skipping when choice is "No"
        if (!choice) return;

        const { properties } = values;

        const modifiedProperties = properties.map((property, i) => {
            return {
                ...property,
                primary_residence: i === index,
            };
        });

        updateInitial({
            properties: modifiedProperties,
        });
    };

    const is_additional = spouse
        ? properties_financed_spouse.length
        : properties_financed.length;

    return (
        <Formik
            displayName="Household Form"
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationRules}
            validateOnMount
            onSubmit={saveProperties}
        >
            {({ handleSubmit, values, isValid, errors }) => (
                <>
                    <h1>
                        Please enter your
                        <span className="color-blue">
                            {spouse && ' spouse’s'}
                            {is_additional ? ' other' : ''} property
                        </span>{' '}
                        information.
                    </h1>
                    <p>
                        Add additional properties by using the{' '}
                        <strong>“Add a Property”</strong> button.
                    </p>

                    <LoadGoogleMapScript>
                        <FieldArray
                            name="properties"
                            render={({ push, remove }) => (
                                <div className="mt-32">
                                    {values.properties.map(({ uid }, i) => (
                                        <PropertyAdditional
                                            key={i}
                                            index={i}
                                            additional={is_additional}
                                            onClear={handleClear.bind(
                                                null,
                                                i,
                                                remove,
                                                uid
                                            )}
                                            spouse={spouse}
                                            onResidentChange={(choice) =>
                                                handlePrimaryResSelection(
                                                    choice,
                                                    values,
                                                    i
                                                )
                                            }
                                            hideResident={
                                                hasExistingPrimaryResidence
                                            }
                                        />
                                    ))}

                                    <LinkButton
                                        type="button"
                                        onClick={() => push(initProperty)}
                                        className="mb-32"
                                    >
                                        <span>Add a Property</span>
                                    </LinkButton>
                                </div>
                            )}
                        />
                    </LoadGoogleMapScript>

                    <Navigation
                        onPrevious={goBack}
                        onNext={handleSubmit}
                        disableNext={!isValid}
                        typeNext="submit"
                    />

                    <Modal
                        open={open}
                        onClose={hideModal}
                        onSave={deleteProperty}
                        modalHeading="Are you sure you want to remove this property?"
                        primaryButtonText="Yes"
                        danger
                    >
                        <p>The information you entered will not be saved.</p>
                    </Modal>
                </>
            )}
        </Formik>
    );
}

enableDotAmountCheck();
enableMinAmountCheck();
enableMaxAmountCheck();

const validationRules = Yup.object().shape({
    properties: Yup.array().of(
        Yup.object().shape({
            address: Yup.object().shape({
                line_1: Yup.string()
                    .required('Please provide address information')
                    .min(MIN_ADDRESS_CHARS, 'Minimum 1 characters')
                    .max(MAX_ADDRESS_CHARS, 'Maximum 35 characters'),
                line_2: Yup.string()
                    .nullable()
                    .min(MIN_ADDRESS_CHARS, 'Minimum 1 characters')
                    .max(MAX_ADDRESS_CHARS, 'Maximum 35 characters'),
                city: Yup.string().required('Please provide city information'),
                state: Yup.string().required(
                    'Please provide state information'
                ),
                zip_code: Yup.string()
                    .required('Zip Code Is Required field')
                    .zipCodeValidate(),
            }),
            market_value: Yup.string()
                .required('Required field')
                .onlyDot('Your market value is not valid')
                .minAmount(10000, 'Minimum amount is 10,000')
                .maxAmount(
                    MAX_AMOUNT_PROPERTIES,
                    `Maximum amount is ${toUsCurrency(MAX_AMOUNT_PROPERTIES)}`
                ),
            primary_residence: Yup.bool().nullable(),
        })
    ),
});

export default AdditionalProperties;
