import { Fragment, useState, useEffect, useContext } from 'react';
import Navigation from 'components/Navigation';
import { Formik } from 'formik';
import { FormGroup } from 'carbon-components-react';
import { Dropdown } from 'components/Form';
import * as Yup from 'yup';
import api, { endpoints } from 'api';
import { PersonalInfoContext } from 'contexts';
import { useStepController } from 'hooks';
import { history } from 'routes';
import useBusinessInfo from './useBusinessInfo';
import useFinanceInfo from './useFinanceInfo';
import { EMPLOYMENT_TYPES } from 'const';

const items = [
    {
        id: EMPLOYMENT_TYPES['W-2'].id,
        label: 'W-2 (Wage Earner)',
    },
    {
        id: EMPLOYMENT_TYPES['1099'].id,
        label: '1099 (Self Employed)',
    },
    {
        id: EMPLOYMENT_TYPES.both.id,
        label: 'Both (W-2 & 1099)',
    },
    {
        id: 'not_sure',
        label: 'I’m Not Sure',
    },
];

function EmployeesPayment({ spouse }) {
    const [initialValues, updateInitialValues] = useState({
        employee_income_type: null,
    });

    const { personalUid, spouseUid } = useContext(PersonalInfoContext);
    const { previousStep, nextStep } = useStepController();
    const { businessInfo } = useBusinessInfo(spouse ? spouseUid : personalUid);
    const { financeInfo } = useFinanceInfo(spouse ? spouseUid : personalUid);

    useEffect(() => {
        const { employee_income_type, uid } = financeInfo;

        if (uid) {
            updateInitialValues({ employee_income_type });
        }
    }, [financeInfo]);

    const submit = async (values) => {
        const financeUid = financeInfo.uid;
        const data = {
            profile: spouse ? spouseUid : personalUid,
            verified: true,
            business_info: businessInfo.uid,
            ...values,
        };

        try {
            let url = endpoints.SELF_EMPLOYED_FINANCIAL_INFO;
            if (financeUid) url += `${financeUid}/`;

            const req = await api({
                method: financeUid ? 'patch' : 'post',
                data,
                url,
            });

            const res = req.data;

            if (res) history.push(nextStep.path);
        } catch (e) {
            console.log(e.response);
        }
    };

    const goPreviousStep = () => history.push(previousStep.path);

    return (
        <Formik
            initialValues={initialValues}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={submit}
            validationSchema={validation}
        >
            {({ handleSubmit, isValid }) => (
                <Fragment>
                    <h1 className="mb-25">
                        How{' '}
                        {spouse ? (
                            <Fragment>
                                does your{' '}
                                <span className="color-blue">spouse</span>
                            </Fragment>
                        ) : (
                            'did you'
                        )}{' '}
                        <span className="color-blue">
                            {' '}
                            pay {spouse ? 'their' : 'your'} employees?
                        </span>
                    </h1>

                    <FormGroup legendText="">
                        <Dropdown
                            id="employeesType"
                            label=""
                            name="employee_income_type"
                            titleText="Employee Income Type"
                            items={items}
                        />
                    </FormGroup>

                    <Navigation
                        disableNext={!isValid}
                        onNext={handleSubmit}
                        onPrevious={goPreviousStep}
                        typeNext={'submit'}
                    />
                </Fragment>
            )}
        </Formik>
    );
}

const validation = Yup.object().shape({
    employee_income_type: Yup.string().required('Required field'),
});

export default EmployeesPayment;
