import { Fragment, useState, useEffect, useContext } from 'react';
import { InlineLoading, Checkbox } from 'carbon-components-react';
import styled from 'styled-components';
import api, { endpoints } from 'api';
import { history } from 'routes';
import Navigation from 'components/Navigation';
import { PersonalInfoContext, contextActionTypes } from '../../../contexts';
import { TransactionLists } from 'components/ListItems';
import { useStepController } from '../../../hooks';
import { isArrayEmpty } from 'lib';

const {
    SELECTED_BUSINESS_DEPOSITS,
    SPOUSE_SELECTED_BUSINESS_DEPOSITS,
} = contextActionTypes;

function SelectDeposits({ spouse }) {
    const {
        personalUid,
        spouseUid,
        employment,
        dispatchEmployment,
    } = useContext(PersonalInfoContext);

    const [disabled, setDisabled] = useState();
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const { nextStep, previousStep } = useStepController();
    const { clientEmployerName, spouseEmployerName } = employment;

    useEffect(() => {
        const transactionList = async () => {
            const req = await api.get(
                `${endpoints.AVAILABLE_DEPOSIT}${
                    spouse ? spouseUid : personalUid
                }/`,
                {
                    params: {
                        bank_source: 'business',
                    },
                }
            );
            const res = await req.data;
            if (res) {
                setTransactions(res);
                setLoading(false);
            }
        };

        if ((spouse && spouseUid) || (!spouse && personalUid)) {
            transactionList();
            setDisabled(false);
        }
    }, [personalUid, spouseUid, spouse]);

    // Update context for updating step
    useEffect(() => {
        const selectedTransactions = [];

        transactions.forEach(function (item) {
            if (item.status === 'in_progress') {
                selectedTransactions.push(item.uid);
            }
        });

        dispatchEmployment({
            type: spouse
                ? SPOUSE_SELECTED_BUSINESS_DEPOSITS
                : SELECTED_BUSINESS_DEPOSITS,
            payload: !isArrayEmpty(selectedTransactions),
        });
    }, [transactions, dispatchEmployment, spouse]);

    const transactionHandle = (value, id, ev) => {
        const currentTransactions = [...transactions];

        if (ev.target.checked) {
            currentTransactions.forEach(function (item) {
                if (item.id === id) {
                    item.status = 'in_progress';
                }
            });
        } else {
            currentTransactions.forEach(function (item) {
                if (item.id === id) {
                    item.status = 'draft';
                    item.verified = false;
                }
            });
        }

        setTransactions(currentTransactions);
    };

    const updateDeposit = async () => {
        const params = {
            income_type: 101,
            uid_list: [],
        };
        transactions.forEach(function (item) {
            if (item.status === 'in_progress') {
                params.uid_list.push(item.uid);
            }
        });
        const req = await api.post(
            `${endpoints.AVAILABLE_DEPOSIT}${
                spouse ? spouseUid : personalUid
            }/`,
            params
        );
        const res = await req.data;
        if (res) {
            history.push(nextStep.path);
        } else {
            console.log('error');
        }
    };

    return (
        <Fragment>
            <h1 className="mb-40">
                Please select any direct deposits from the list below for{' '}
                {spouse ? (
                    <span className="color-blue">{spouseEmployerName}.</span>
                ) : (
                    <span className="color-blue">{clientEmployerName}.</span>
                )}{' '}
                If there are no matches select{' '}
                <span className="color-blue">next.</span>
            </h1>
            {loading && (
                <Fragment>
                    <InlineLoading
                        status="active"
                        description="Please Wait..."
                        className="mb-40"
                    />
                </Fragment>
            )}
            {!loading && (
                <div>
                    {transactions.map((dataItem, key) => {
                        const {
                            normalized_group_name,
                            id,
                            amount,
                            status,
                            from_date,
                            to_date,
                            bank_icon,
                            bank_name,
                            acc_numbers,
                        } = dataItem;
                        return (
                            <fieldset className="bx--fieldset" key={key}>
                                <CheckboxWrapper
                                    labelText={
                                        <TransactionLists
                                            description={normalized_group_name}
                                            amount={amount}
                                            bank_icon={bank_icon}
                                            bank_name={bank_name}
                                            from_date={from_date}
                                            to_date={to_date}
                                            acc_numbers={acc_numbers}
                                        />
                                    }
                                    id={id}
                                    value={status}
                                    defaultChecked={
                                        status === 'verified' ||
                                        status === 'in_progress'
                                    }
                                    onChange={transactionHandle}
                                />
                            </fieldset>
                        );
                    })}
                </div>
            )}
            <Navigation
                disableNext={disabled}
                onPrevious={() => history.push(previousStep.path)}
                onNext={updateDeposit}
                className="mt-24"
            />
        </Fragment>
    );
}

const CheckboxWrapper = styled(Checkbox)`
    && {
        &.bx--checkbox-label {
            width: 100% !important;
            .bx--checkbox-label-text {
                width: 100% !important;
            }
        }
    }
`;

export default SelectDeposits;
