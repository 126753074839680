import { Button } from 'carbon-components-react';
import styled from 'styled-components';

const handleColorType = (color) => {
    switch (color) {
        case 'gray':
            return '#6a7581';
        default:
            return '#008AC8';
    }
};

const hoverColorType = (color) => {
    switch (color) {
        case 'gray':
            return '#6a7581';
        default:
            return '#0075AA';
    }
};

function ButtonBlock(props) {
    return (
        <BtnBlock type={'button'} {...props}>
            {props.children}
        </BtnBlock>
    );
}

const BtnBlock = styled(Button)`
    && {
        max-width: none;
        width: 100%;
        text-align: center;
        display: block;
        padding-right: 12px;
        background: ${({ color }) => handleColorType(color)};
        &[disabled] {
            background-color: #c6c6c6;
        }
        a {
            color: #fff;
            display: block;
        }
        :hover {
            background-color: ${({ color }) => hoverColorType(color)};
        }

        &[disabled]:hover {
            background-color: #c6c6c6;
        }
    }
`;

export default ButtonBlock;
