import { useState } from 'react';

function useModal() {
    const [ modalVisible, toggleModal ] = useState(false);

    const showModal = () => toggleModal(true);
    const hideModal = () => toggleModal(false);

    return [ modalVisible, showModal, hideModal ];
}

export default useModal;