import { useState, useEffect } from 'react';
import api, { endpoints } from 'api';

// Hook to fetch client additional info

function useAdditionalInfo(profile) {
    const [clientInfo, updateClientInfo] = useState({});

    useEffect(() => {
        const getClientInfo = async () => {
            try {
                const req = await api({
                    url: endpoints.CLIENT_ADDITIONAL_INFO,
                    params: {
                        profile: profile,
                    },
                });
                const res = await req.data;

                if (Array.isArray(res) && res[0]) {
                    updateClientInfo(res[0]);
                }
            } catch (e) {
                console.log(e.response);
            }
        };

        if (profile) getClientInfo();
    }, [profile]);

    return clientInfo;
}

export default useAdditionalInfo;
