import { Fragment, useEffect, useContext, useState } from 'react';
import { PersonalInfoContext, contextActionTypes } from 'contexts';
import { FormGroup } from 'carbon-components-react';
import { Checkbox } from 'components/Form';
import { ExpenseList } from 'components/ListItems';
import { useStepController } from 'hooks';
import Navigation from 'components/Navigation';
import api, { endpoints } from 'api';
import { Formik } from 'formik';
import { history } from 'routes';
import * as yup from 'yup';
const { HAS_EXPENSES, HAS_EXPENSES_SPOUSE } = contextActionTypes;

const EXPENSE_TYPES = [
    {
        name: 'court_ordered_payments',
        label: (
            <ExpenseList
                header={'Court Ordered Payments'}
                subHeader={
                    'This includes monthly alimony, child support, etc.'
                }
            />
        ),
    },
    {
        name: 'child_dependent_care_payments',
        label: (
            <ExpenseList
                header={'Child/Dependent Care Payments'}
                subHeader={'This includes the cost of dependents such as child care, day care, etc.'}
            />
        ),
    },
    {
        name: 'term_life_insurance_premiums',
        label: (
            <ExpenseList
                header={'Term Life Insurance Premiums'}
                subHeader={
                    'This includes payments at a fixed rate for a limited amount of time, etc.'
                }
            />
        ),
    },
];

const init = {
    court_ordered_payments: false,
    child_dependent_care_payments: false,
    term_life_insurance_premiums: false,
    none_of_these: false,
};

function Expenses({ spouse }) {
    const [initialValues, updateInitialValues] = useState(
        Object.assign({}, init)
    );
    const { personalUid, spouseUid, dispatchEmployment } = useContext(
        PersonalInfoContext
    );
    const [expenseUid, setExpenseUid] = useState(null);
    const { nextStep, previousStep } = useStepController();

    useEffect(() => {
        const getExpenses = async () => {
            try {
                const req = await api.get(
                    `${endpoints.CLIENT_EMPLOYMENT_EXPENSES}?profile=${
                        spouse ? spouseUid : personalUid
                    }`
                );
                const res = await req.data;
                if (Array.isArray(res) && res.length > 0) {
                    const expenseInfo = res[0];

                    const {
                        court_ordered_payments,
                        child_dependent_care_payments,
                        term_life_insurance_premiums,
                        uid,
                    } = expenseInfo;

                    if (
                        court_ordered_payments ||
                        child_dependent_care_payments ||
                        term_life_insurance_premiums
                    ) {
                        dispatchEmployment({
                            type: spouse ? HAS_EXPENSES_SPOUSE : HAS_EXPENSES,
                            payload: true,
                        });
                    } else {
                        dispatchEmployment({
                            type: spouse ? HAS_EXPENSES_SPOUSE : HAS_EXPENSES,
                            payload: false,
                        });
                    }

                    setExpenseUid(uid);

                    updateInitialValues((initialValues) => ({
                        ...initialValues,
                        court_ordered_payments,
                        child_dependent_care_payments,
                        term_life_insurance_premiums,
                    }));
                }
            } catch (e) {
                console.log(e.response);
            }
        };

        if ((spouse && spouseUid) || (!spouse && personalUid)) {
            getExpenses();
        }
    }, [personalUid, spouse, dispatchEmployment, spouseUid]);

    const saveExpenses = async (values) => {
        values['profile'] = spouse ? spouseUid : personalUid;

        if (values.none_of_these) {
            if (expenseUid) {
                api.delete(
                    `${endpoints.CLIENT_EMPLOYMENT_EXPENSES}${expenseUid}/`
                );
            }

            history.push(nextStep.path);
        } else {
            try {
                let url = endpoints.CLIENT_EMPLOYMENT_EXPENSES;
                if (expenseUid) url += `${expenseUid}/`;

                const req = await api({
                    method: expenseUid ? 'patch' : 'post',
                    data: values,
                    url,
                });
                const res = req.data;
                if (res) {
                    history.push(nextStep.path);
                }
            } catch (e) {
                console.log(e.response);
            }
        }
    };

    const deselectExpenses = () => {
        updateInitialValues({
            court_ordered_payments: false,
            child_dependent_care_payments: false,
            term_life_insurance_premiums: false,
            none_of_these: true,
        });

        dispatchEmployment({
            type: spouse ? HAS_EXPENSES_SPOUSE : HAS_EXPENSES,
            payload: false,
        });
    };

    const deselectNoneOfThese = (currentValues, selectedCheckbox) => {
        dispatchEmployment({
            type: spouse ? HAS_EXPENSES_SPOUSE : HAS_EXPENSES,
            payload: true,
        });

        currentValues[selectedCheckbox] = !currentValues[selectedCheckbox];

        updateInitialValues({
            ...currentValues,
            none_of_these: false,
        });
    };

    return (
        <Fragment>
            <h1>
                Do any of these
                {' '}
                <span className="color-blue">expenses</span>
                {' '}
                apply to you?
            </h1>
            <p>
                Select all that apply
            </p>

            <Formik
                initialValues={initialValues}
                onSubmit={saveExpenses}
                enableReinitialize={true}
                validateOnMount={true}
                validationSchema={schema}
            >
                {({ handleSubmit, isValid, values }) => (
                    <Fragment>
                        <section className="py-24">
                            {EXPENSE_TYPES.map(({ name, label }, key) => (
                                <FormGroup legendText="" key={key}>
                                    <Checkbox
                                        onChange={deselectNoneOfThese.bind(
                                            null,
                                            values,
                                            name
                                        )}
                                        name={name}
                                        id={name}
                                        labelText={label}
                                    />
                                </FormGroup>
                            ))}
                            <FormGroup legendText="">
                                <Checkbox
                                    name={'none_of_these'}
                                    id={'none_of_these'}
                                    onChange={deselectExpenses}
                                    labelText={
                                        <ExpenseList
                                            header={'These expenses don’t apply to me'}
                                        />
                                    }
                                />
                            </FormGroup>
                        </section>
                        <Navigation
                            disableNext={!isValid}
                            onPrevious={() => history.push(previousStep.path)}
                            onNext={handleSubmit}
                            className="mt-24"
                        />
                    </Fragment>
                )}
            </Formik>
        </Fragment>
    );
}

const schema = yup
    .object({
        court_ordered_payments: yup.boolean(),
        child_dependent_care_payments: yup.boolean(),
        term_life_insurance_premiums: yup.boolean(),
        none_of_these: yup.boolean(),
    })
    .test('myCustomTest', null, (obj) => {
        const {
            court_ordered_payments,
            child_dependent_care_payments,
            term_life_insurance_premiums,
            none_of_these,
        } = obj;

        if (
            court_ordered_payments ||
            child_dependent_care_payments ||
            term_life_insurance_premiums ||
            none_of_these
        ) {
            return true; // everything is fine
        }

        return new yup.ValidationError(
            'Please check one checkbox',
            null,
            'myCustomFieldName'
        );
    });

export default Expenses;
