function ErrorBoundary() {
    return (
        <div className="my-32">
            <h1>Something went wrong.</h1>
            <h2>Please refresh the page and see the problem resolves!</h2>
            <p>
                Please contact with us regarding the issue. We are really sorry
                for this inconvenience!
            </p>
        </div>
    );
}

export default ErrorBoundary;
