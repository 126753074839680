export default {
    TYPE_ADD_PROPERTIES: 'PROPERTIES',
    TYPE_ADD_VEHICLES: 'VEHICLES',
    TYPE_ADD_ADDRESSES: 'ADDRESSES',
    TYPE_ADD_PROPERTIES_SPOUSE: 'PROPERTIES_SPOUSE',
    TYPE_ADD_VEHICLES_SPOUSE: 'VEHICLES_SPOUSE',
    TYPE_ADD_ADDRESSES_SPOUSE: 'ADDRESSES_SPOUSE',
    STORE_PERSONAL_INFO: 'STORE_PERSONAL_INFO',
    STORE_FILING_STATUS: 'STORE_FILING_STATUS',
    STORE_SPOUSE_INFO: 'STORE_SPOUSE_INFO',
    EMPLOYMENT_TYPE: 'EMPLOYMENT_TYPE',
    EMPLOYMENT_TYPE_SPOUSE: 'EMPLOYMENT_TYPE_SPOUSE',
    FULL_TIME_ESTIMATE_CORRECT: 'FULL_TIME_ESTIMATE_CORRECT',
    FULL_TIME_RE_ESTIMATE_CORRECT: 'FULL_TIME_RE_ESTIMATE_CORRECT',
    FULL_TIME_ESTIMATE_CORRECT_SPOUSE: 'FULL_TIME_ESTIMATE_CORRECT_SPOUSE',
    FULL_TIME_RE_ESTIMATE_CORRECT_SPOUSE:
        'FULL_TIME_RE_ESTIMATE_CORRECT_SPOUSE',
    NOT_FOUND_ESTIMATE_CORRECT: 'NOT_FOUND_ESTIMATE_CORRECT',
    NOT_FOUND_RE_ESTIMATE_CORRECT: 'NOT_FOUND_RE_ESTIMATE_CORRECT',
    NOT_FOUND_ESTIMATE_CORRECT_SPOUSE: 'NOT_FOUND_ESTIMATE_CORRECT_SPOUSE',
    NOT_FOUND_RE_ESTIMATE_CORRECT_SPOUSE:
        'NOT_FOUND_RE_ESTIMATE_CORRECT_SPOUSE',
    HAS_ADDITIONAL_INCOME: 'HAS_ADDITIONAL_INCOME',
    SPOUSE_HAS_ADDITIONAL_INCOME: 'SPOUSE_HAS_ADDITIONAL_INCOME',
    ADDITIONAL_INCOME_CORRECT: 'ADDITIONAL_INCOME_CORRECT',
    ADDITIONAL_INCOME_CORRECT_SPOUSE: 'ADDITIONAL_INCOME_CORRECT_SPOUSE',
    HAS_ADDITIONAL_PROPERTY: 'HAS_ADDITIONAL_PROPERTY',
    HAS_ADDITIONAL_VEHICLE: 'HAS_ADDITIONAL_VEHICLE',
    HAS_ADDITIONAL_PROPERTY_SPOUSE: 'HAS_ADDITIONAL_PROPERTY_SPOUSE',
    HAS_ADDITIONAL_VEHICLE_SPOUSE: 'HAS_ADDITIONAL_VEHICLE_SPOUSE',
    NO_OF_DEPENDENTS: 'NO_OF_DEPENDENTS',
    CHANGE_SPOUSE_DETAIL: 'CHANGE_SPOUSE_DETAIL',
    SELF_ESTIMATE_CORRECT: 'SELF_ESTIMATE_CORRECT',
    SELF_RE_ESTIMATE_CORRECT: 'SELF_RE_ESTIMATE_CORRECT',
    SELF_ESTIMATE_CORRECT_SPOUSE: 'SELF_ESTIMATE_CORRECT_SPOUSE',
    SELF_RE_ESTIMATE_CORRECT_SPOUSE: 'SELF_RE_ESTIMATE_CORRECT_SPOUSE',
    CHANGE_BANK_TYPES: 'CHANGE_BANK_TYPES',
    CHANGE_SPOUSE_BANK_TYPES: 'CHANGE_SPOUSE_BANK_TYPES',
    SELF_AVERAGE_ACCURATE: 'SELF_AVERAGE_ACCURATE',
    SELF_AVERAGE_ACCURATE_SPOUSE: 'SELF_AVERAGE_ACCURATE_SPOUSE',
    HAVE_SIDE_BUSINESS: 'HAVE_SIDE_BUSINESS',
    HAVE_SIDE_BUSINESS_SPOUSE: 'HAVE_SIDE_BUSINESS_SPOUSE',
    CLAIM_DEPENDENT: 'CLAIM_DEPENDENT',
    EARN_INCOME: 'EARN_INCOME',
    HAS_EXPENSES: 'HAS_EXPENSES',
    HAS_EXPENSES_SPOUSE: 'HAS_EXPENSES_SPOUSE',
    SELF_HAS_EMPLOYEES: 'SELF_HAS_EMPLOYEES',
    SELF_HAS_EMPLOYEES_SPOUSE: 'SELF_HAS_EMPLOYEES_SPOUSE',
    HAS_SEPARATE_BUSINESS_BANK: 'HAS_SEPARATE_BUSINESS_BANK',
    SPOUSE_CONTRIBUTE_HOUSEHOLD: 'SPOUSE_CONTRIBUTE_HOUSEHOLD',
    INCOME_TYPE: 'INCOME_TYPE',
    SPOUSE_INCOME_TYPE: 'SPOUSE_INCOME_TYPE',
    SPOUSE_HAS_BUSINESS_BANK: 'SPOUSE_HAS_BUSINESS_BANK',
    UPDATE_ADDITIONAL_INFO: 'UPDATE_ADDITIONAL_INFO',
    UPDATE_SPOUSE_ADDITIONAL_INFO: 'UPDATE_SPOUSE_ADDITIONAL_INFO',
    CLIENT_SPOUSE_ONLINE_INFO: 'CLIENT_SPOUSE_ONLINE_INFO',
    CLIENT_KNOWS_SPOUSE_WORK: 'CLIENT_KNOWS_SPOUSE_WORK',
    CLIENT_KNOWS_SPOUSE_BUSINESS: 'CLIENT_KNOWS_SPOUSE_BUSINESS',
    SPOUSE_HAS_ASSET_INVESTMENT: 'SPOUSE_HAS_ASSET_INVESTMENT',
    CLIENT_KNOWS_SPOUSE_ASSET_INVEST: 'CLIENT_KNOWS_SPOUSE_ASSET_INVEST',
    SHARE_JOINT_BANK: 'SHARE_JOINT_BANK',
    CLIENT_EMPLOYER_NAME: 'CLIENT_EMPLOYER_NAME',
    SPOUSE_EMPLOYER_NAME: 'SPOUSE_EMPLOYER_NAME',
    SELECTED_DEPOSITS: 'SELECTED_DEPOSITS',
    SPOUSE_SELECTED_DEPOSITS: 'SPOUSE_SELECTED_DEPOSITS',
    SELECTED_BUSINESS_DEPOSITS: 'SELECTED_BUSINESS_DEPOSITS',
    SPOUSE_SELECTED_BUSINESS_DEPOSITS: 'SPOUSE_SELECTED_BUSINESS_DEPOSITS',
    IGNORES_ADD_INCOME: 'IGNORES_ADD_INCOME',
    SPOUSE_IGNORES_ADD_INCOME: 'SPOUSE_IGNORES_ADD_INCOME',
    MANUAL_PERSONAL_BANK: 'MANUAL_PERSONAL_BANK',
    MANUAL_BUSINESS_BANK: 'MANUAL_BUSINESS_BANK',
    SPOUSE_MANUAL_PERSONAL_BANK: 'SPOUSE_MANUAL_PERSONAL_BANK',
    SPOUSE_MANUAL_BUSINESS_BANK: 'SPOUSE_MANUAL_BUSINESS_BANK',
    HAS_MANUAL_ADD_INC: 'HAS_MANUAL_ADD_INC',
    SPOUSE_HAS_MANUAL_ADD_INC: 'SPOUSE_HAS_MANUAL_ADD_INC',
    HAS_INVESTMENTS: 'HAS_INVESTMENTS',
    SPOUSE_HAS_INVESTMENTS: 'SPOUSE_HAS_INVESTMENTS',
};
