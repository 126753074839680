import { useState, useEffect } from 'react';
import api, { endpoints } from 'api';

function useFinanceInfo(profile) {
    const [financeInfo, updateFinanceInfo] = useState({});

    useEffect(() => {
        const fetchBusinessInfo = async () => {
            try {
                const req = await api({
                    url: endpoints.SELF_EMPLOYED_FINANCIAL_INFO,
                    params: {
                        profile,
                    },
                });

                const res = await req.data;

                if (Array.isArray(res) && res.length > 0) {
                    updateFinanceInfo(res[0]);
                }
            } catch (e) {
                console.log(e.response);
            }
        };

        if (profile) fetchBusinessInfo();
    }, [profile]);

    return {
        financeInfo,
    };
}

export default useFinanceInfo;
