import { useState, Fragment, useEffect } from 'react';
import { STATUS_MARRIED, STATUS_MARRIED_SEPARATELY } from '../../../const';
import Vehicles from './Vehicles';
import Properties from './Properties';
import Investments from './Investments';

function AssetInvestmentInfo(props) {
    const {
        personalProperties,
        personalVehicles,
        spouseProperties,
        spouseVehicles,
        filing,
        personalInfo,
        spouseInfo,
        spouseDetail,
        additionalInfo,
        investments,
    } = props;

    const [show, setShow] = useState();

    useEffect(() => {
        if (additionalInfo.self) {
            setShow(
                (STATUS_MARRIED === filing ||
                    (STATUS_MARRIED_SEPARATELY === filing &&
                        spouseDetail === true)) &&
                additionalInfo.self.spouses_contribution_to_household_income
            );
        }
    }, [filing, spouseDetail, additionalInfo]);

    return (
        <Fragment>
            <div>
                <h2>{personalInfo.name}</h2>
            </div>
            <Properties properties={personalProperties} />
            <Vehicles vehicles={personalVehicles} />
            <Investments accounts={investments.self} />
            {show && (
                <div>
                    <h2>{spouseInfo.name}</h2>
                    <Properties properties={spouseProperties} />
                    <Vehicles vehicles={spouseVehicles} />
                    <Investments accounts={investments.spouse} />
                </div>
            )}
        </Fragment>
    );
}

export default AssetInvestmentInfo;
