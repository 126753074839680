import { useContext, useEffect } from 'react';
import FullTime from './FullTime';
import SelfEmployed from './SelfEmployed';
import { PROGRESS_KEY } from 'const';
import { EmploymentWelcome } from 'screens/Welcome';
import {
    EMPLOYMENT_FULLTIME,
    EMPLOYMENT_INCOME_VERIFICATION,
    SELF_EMPLOYMENT,
    EXPENSES,
    EXPENSES_AMOUNTS,
    EMPLOYMENT_PROGRESS,
    SPOUSE_WORK,
    SPOUSE_BUSINESS,
    EXPENSES_SPOUSE,
    EXPENSES_AMOUNTS_SPOUSE,
} from '../../routes/paths';
import Expenses, { AmountExpenses } from './Expenses';
import Progress from 'screens/Progress';
import { InnerRoutes } from 'routes';
import { PersonalInfoContext } from 'contexts';
import { STATUS_MARRIED, STATUS_MARRIED_SEPARATELY } from 'const';
import api, { endpoints } from 'api';
import axios from 'axios';

const employmentRoutes = [
    {
        path: EMPLOYMENT_PROGRESS,
        component: <Progress completedProgress={PROGRESS_KEY.employment} />,
        options: { exact: true },
    },
    {
        path: EXPENSES_AMOUNTS,
        component: <AmountExpenses />,
        options: { exact: true },
    },
    {
        path: EXPENSES,
        component: <Expenses />,
        options: { exact: true },
    },
    {
        path: EXPENSES_AMOUNTS_SPOUSE,
        component: <AmountExpenses spouse />,
        options: { exact: true },
    },
    {
        path: EXPENSES_SPOUSE,
        component: <Expenses spouse />,
        options: { exact: true },
    },
    {
        path: [SELF_EMPLOYMENT, SPOUSE_BUSINESS],
        component: <SelfEmployed />,
        options: { exact: false },
    },
    {
        path: [EMPLOYMENT_FULLTIME, SPOUSE_WORK],
        component: <FullTime />,
        options: { exact: false },
    },
    {
        path: EMPLOYMENT_INCOME_VERIFICATION,
        component: <EmploymentWelcome />,
        options: { exact: true },
    },
];

function EmploymentIncomeVerification() {
    const { personalUid, filingStatus, spouseUid } = useContext(
        PersonalInfoContext
    );

    // API Call to generate employer name
    useEffect(() => {
        const generateEmployerName = async () => {
            const requests = [
                api({
                    method: 'post',
                    url: endpoints.GENERATE_EMPLOYER_NAME,
                    data: {
                        profile_uid: personalUid,
                    },
                }),
            ];

            // Spouse API Call to generate employer name
            if (
                (filingStatus === STATUS_MARRIED ||
                    filingStatus === STATUS_MARRIED_SEPARATELY) &&
                spouseUid
            ) {
                requests.push(
                    api({
                        method: 'post',
                        url: endpoints.GENERATE_EMPLOYER_NAME,
                        data: {
                            profile_uid: spouseUid,
                        },
                    })
                );
            }

            try {
                await axios.all(requests);
            } catch (e) {
                console.log(e);
            }
        };

        if (personalUid) generateEmployerName();
    }, [personalUid, filingStatus, spouseUid]);

    return <InnerRoutes routes={employmentRoutes} />;
}

export default EmploymentIncomeVerification;
