import { contextActionTypes } from 'contexts';
import { Decision } from 'screens/Helper';

const { CLAIM_DEPENDENT } = contextActionTypes;

function ClaimDependent() {
    return (
        <Decision apiKey="dependents" contextKey={CLAIM_DEPENDENT}>
            <h1>
                Do you claim any <span className="color-blue">dependents?</span>
            </h1>
            <p>
                A dependent is any child, parent, or individual that you claim on your
                tax returns.
            </p>
        </Decision>
    );
}

export default ClaimDependent;
