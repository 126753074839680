import { useReducer, useState, useContext, useCallback } from 'react';
import InvestmentContext from './InvestmentContext';
import actionTypes from '../context-action-types';
import api, { endpoints } from 'api';
import { PersonalInfoContext } from '../index';
import store from 'store';
import { STATUS_MARRIED, STATUS_MARRIED_SEPARATELY } from 'const';

const {
    TYPE_ADD_PROPERTIES,
    TYPE_ADD_VEHICLES,
    TYPE_ADD_ADDRESSES,
    TYPE_ADD_ADDRESSES_SPOUSE,
    TYPE_ADD_PROPERTIES_SPOUSE,
    TYPE_ADD_VEHICLES_SPOUSE,
    HAS_ADDITIONAL_PROPERTY,
    HAS_ADDITIONAL_VEHICLE,
    HAS_ADDITIONAL_PROPERTY_SPOUSE,
    HAS_ADDITIONAL_VEHICLE_SPOUSE,
} = actionTypes;

const reducer = (state, action) => {
    switch (action.type) {
        case TYPE_ADD_PROPERTIES:
            return {
                ...state,
                properties_financed: action.properties_financed,
            };
        case TYPE_ADD_VEHICLES:
            return { ...state, vehicles_financed: action.vehicles_financed };
        case TYPE_ADD_ADDRESSES:
            return { ...state, addresses_mortgage: action.addresses_mortgage };
        case TYPE_ADD_PROPERTIES_SPOUSE:
            return {
                ...state,
                properties_financed_spouse: action.properties_financed_spouse,
            };
        case TYPE_ADD_VEHICLES_SPOUSE:
            return {
                ...state,
                vehicles_financed_spouse: action.vehicles_financed_spouse,
            };
        case TYPE_ADD_ADDRESSES_SPOUSE:
            return {
                ...state,
                addresses_mortgage_spouse: action.addresses_mortgage_spouse,
            };
        case HAS_ADDITIONAL_PROPERTY:
            return { ...state, has_additional_property: action.payload };
        case HAS_ADDITIONAL_VEHICLE:
            return { ...state, has_additional_vehicle: action.payload };
        case HAS_ADDITIONAL_PROPERTY_SPOUSE:
            return { ...state, has_additional_property_spouse: action.payload };
        case HAS_ADDITIONAL_VEHICLE_SPOUSE:
            return { ...state, has_additional_vehicle_spouse: action.payload };
        default:
            throw new Error();
    }
};

/* const DUMMY_PROPERTIES = [
    {
        uid: '47f1e7be-3d3a-46b4-a1dd-991e1dd1feb7',
        profile: 11,
        source_details: 'WELLS FARGO HM MORTGAG',
        outstanding_balance: 656000,
        open_or_closed: 'C',
        address: null,
        market_value: null,
        ownership_status: 'owned',
        verified: null,
        status: 'draft',
        primary_residence: null,
    },
    {
        uid: '48f1e7be-3d3a-46b5-a1dd-991e1dd1feb7',
        profile: 11,
        source_details: 'CHASE BANK',
        outstanding_balance: 456000,
        open_or_closed: 'C',
        address: null,
        market_value: null,
        ownership_status: 'owned',
        verified: false,
        status: 'draft',
        primary_residence: null,
    },
]; */

// Selected choice from localStorage
const ex_add_property = store.get(HAS_ADDITIONAL_PROPERTY);
const ex_add_property_spouse = store.get(HAS_ADDITIONAL_PROPERTY_SPOUSE);
const ex_add_vehicle = store.get(HAS_ADDITIONAL_PROPERTY);
const ex_add_vehicle_spouse = store.get(HAS_ADDITIONAL_VEHICLE_SPOUSE);

const initialState = {
    properties_financed: [],
    vehicles_financed: [],
    addresses_mortgage: [],
    properties_financed_spouse: [],
    vehicles_financed_spouse: [],
    addresses_mortgage_spouse: [],
    has_additional_property:
        typeof ex_add_property === 'boolean' ? ex_add_property : true,
    has_additional_vehicle:
        typeof ex_add_vehicle === 'boolean' ? ex_add_vehicle : true,
    has_additional_property_spouse:
        typeof ex_add_property_spouse === 'boolean'
            ? ex_add_property_spouse
            : true,
    has_additional_vehicle_spouse:
        typeof ex_add_vehicle_spouse === 'boolean'
            ? ex_add_vehicle_spouse
            : true,
};

const fetchProperties = async (profile) => {
    try {
        const req = await api({
            url: endpoints.PROPERTIES_FINANCED,
            params: {
                profile, is_second_mortgage: false,
            },
        });
        return await req.data;
    } catch (error) {
        return [];
    }
};
const fetchVehicles = async (profile) => {
    try {
        const req = await api({
            url: endpoints.VEHICLES_FINANCED,
            params: {
                profile,
            },
        });
        return await req.data;
    } catch (error) {
        return [];
    }
};
const fetchAddresses = async (profile) => {
    try {
        const req = await api({
            url: endpoints.CLIENT_ADDRESSES,
            params: {
                profile,
            },
        });
        const res = await req.data;

        if (Array.isArray(res) && res.length) {
            // Remove the option "Other", requested by backend!
            return res.filter((a) => a.data !== 'Other');
        } else {
            return [];
        }
    } catch (error) {
        return [];
    }
};

function InvestmentProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [investLoading, toggleInvestLoading] = useState(true);
    const { personalUid, spouseUid, filingStatus } = useContext(
        PersonalInfoContext
    );

    const fetchInvestments = useCallback(async () => {
        if (!personalUid) return null;

        const properties_financed = await fetchProperties(personalUid);
        const vehicles_financed = await fetchVehicles(personalUid);
        const addresses_mortgage = await fetchAddresses(personalUid);

        dispatch({ type: TYPE_ADD_PROPERTIES, properties_financed });
        dispatch({ type: TYPE_ADD_VEHICLES, vehicles_financed });
        dispatch({ type: TYPE_ADD_ADDRESSES, addresses_mortgage });

        if (
            filingStatus === STATUS_MARRIED ||
            filingStatus === STATUS_MARRIED_SEPARATELY
        ) {
            const properties_financed_spouse = await fetchProperties(spouseUid);
            const vehicles_financed_spouse = await fetchVehicles(spouseUid);
            const addresses_mortgage_spouse = await fetchAddresses(spouseUid);

            dispatch({
                type: TYPE_ADD_PROPERTIES_SPOUSE,
                properties_financed_spouse,
            });
            dispatch({
                type: TYPE_ADD_VEHICLES_SPOUSE,
                vehicles_financed_spouse,
            });
            dispatch({
                type: TYPE_ADD_ADDRESSES_SPOUSE,
                addresses_mortgage_spouse,
            });
        }

        toggleInvestLoading(false);
    }, [spouseUid, personalUid, filingStatus]);

    return (
        <InvestmentContext.Provider
            value={{
                investments: state,
                dispatchInvestments: dispatch,
                reFetchInvestments: fetchInvestments,
                investLoading,
            }}
        >
            {children}
        </InvestmentContext.Provider>
    );
}

export default InvestmentProvider;
