import { ToastNotification } from "carbon-components-react";
import styled from "styled-components";

function Notification(props) {
    const { title, type, message } = props;

    return (
        <div>
            <NotificationStyled
                kind={type}
                subtitle={message}
                timeout={0}
                title={title}
                lowContrast
                hideCloseButton
                caption={false}
            />
        </div>
    );
}

const NotificationStyled = styled(ToastNotification)`
  &&{
    margin: 0;
    width: 100%;
  }
`

Notification.defaultProps = {
    title: "Notification",
    type: "success"
}

export default Notification;