import { Fragment } from 'react';
import styled from 'styled-components';
import { toUsCurrency } from 'lib';

function SelfEmployedIncome({ data, info }) {
    return (
        <Fragment>
            <div>
                {info && <h2>{info.name}</h2>}
                <EmploymentWrapper>
                    <h1>Employer: Self Employed</h1>
                    {data.business_info && (
                        <div>
                            {data.business_info.business_name && (
                                <p>{data.business_info.business_name}</p>
                            )}
                            {data.business_info.business_structure_type && (
                                <p>
                                    Business Type:&nbsp;
                                    {data.business_info.business_structure_type}
                                </p>
                            )}
                            {data.business_info.address.data && (
                                <p>{data.business_info.address.data}</p>
                            )}
                            {data.business_info
                                .employer_identification_number && (
                                    <p>
                                        <span>EIN</span>
                                        <span>
                                            :&nbsp;
                                            {data.business_info.employer_identification_number.replace(
                                                /(\d{2})/,
                                                '$1-'
                                            )}
                                        </span>
                                    </p>
                                )}
                            {data.business_info.has_other_business && (
                                <p>
                                    <span>Other Business Interests</span>
                                    <span>
                                        :&nbsp;
                                        {data.business_info.has_other_business
                                            ? 'Yes'
                                            : 'No'}
                                    </span>
                                </p>
                            )}
                        </div>
                    )}
                    {data.financial_info && (
                        <div>
                            {data.financial_info.no_of_employees > 0 &&
                                data.financial_info.tax_deposit_frequency && (
                                    <p>
                                        <span>Frequency of Tax Deposits</span>
                                        <span>
                                            :&nbsp;
                                            {
                                                data.financial_info
                                                    .tax_deposit_frequency
                                            }
                                        </span>
                                    </p>
                                )}
                            {data.financial_info.no_of_employees > 0 && (
                                <p>
                                    <span>Total Number of Employees</span>
                                    <span>
                                        :&nbsp;
                                        {data.financial_info.no_of_employees}
                                    </span>
                                </p>
                            )}
                            {data.financial_info.no_of_employees > 0 &&
                                data.financial_info.employee_income_type && (
                                    <p>
                                        <span>Employee Income Type</span>
                                        <span>
                                            :&nbsp;
                                            {
                                                data.financial_info
                                                    .employee_income_type
                                            }
                                        </span>
                                    </p>
                                )}
                        </div>
                    )}
                    {data && (
                        <div>
                            {data.gross_monthly_self_employed_income > 0 && (
                                <p>
                                    <span>Estimated Monthly Income</span>
                                    <span>
                                        :&nbsp;
                                        {toUsCurrency(
                                            data.gross_monthly_self_employed_income
                                        )}
                                    </span>
                                </p>
                            )}
                            {data.additional_income.amount > 0 && (
                                <p>
                                    <span>Additional Income</span>
                                    <span>
                                        :&nbsp;
                                        {toUsCurrency(
                                            data.additional_income.amount
                                        )}
                                    </span>
                                </p>
                            )}
                        </div>
                    )}
                </EmploymentWrapper>
            </div>
        </Fragment>
    );
}

const EmploymentWrapper = styled.div`
    h1 {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #0f173a;
        position: relative;
        margin: 5px 0;
        padding-left: 15px;

        &:before {
            position: absolute;
            content: '';
            top: 5px;
            left: 0;
            width: 0;
            height: 0;
            border-top: 7px solid #000000;
            border-right: 9px solid transparent;
        }
    }
    p {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #4f5260;
        display: flex;
        align-items: center;
        span {
            &:first-child {
                width: 65%;
                display: block;
            }
        }
    }
`;

export default SelfEmployedIncome;
