import { FormGroup } from 'carbon-components-react';
import { MapAutocomplete, Input, Select } from 'components/Form';
import { useFormikContext } from 'formik';
import { STATES_LIST_DROPDOWN } from 'const';
import PropType from 'prop-types';

function AddressBlock({ name, label, className, dontLoadScript }) {
    const { setFieldValue } = useFormikContext();

    // Update address by google map result
    const updateAddress = (location) => {
        const { city, state, zipCode, address } = location;

        setFieldValue(name.address, address);
        setFieldValue(name.city, city);
        setFieldValue(name.state, state);
        setFieldValue(name.zipCode, zipCode);
    };

    return (
        <section className={className}>
            <FormGroup legendText="">
                <MapAutocomplete
                    id={name.address}
                    name={name.address}
                    labelText={label.address}
                    onPlaceSelect={(address) => updateAddress(address)}
                    dontLoadScript={dontLoadScript}
                />
            </FormGroup>

            <FormGroup legendText="">
                <Input
                    id={name.address2}
                    name={name.address2}
                    labelText={label.address2}
                    placeholder="Apt, Unit"
                />
            </FormGroup>

            <FormGroup legendText="">
                <Input id={name.city} name={name.city} labelText={label.city} />
            </FormGroup>

            <div className="bx--grid bx--no-gutter">
                <div className="bx--row">
                    <div className="bx--col">
                        <FormGroup legendText="">
                            <Select
                                id={name.state}
                                name={name.state}
                                labelText={label.state}
                                items={STATES_LIST_DROPDOWN}
                            />
                        </FormGroup>
                    </div>
                    <div className="bx--col">
                        <FormGroup legendText="">
                            <Input
                                id={name.zipCode}
                                name={name.zipCode}
                                labelText={label.zipCode}
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
        </section>
    );
}

AddressBlock.propTypes = {
    name: PropType.object.isRequired,
    label: PropType.object,
};

AddressBlock.defaultProps = {
    name: {
        address: 'address.line_1',
        address2: 'address.line_2',
        city: 'address.city',
        state: 'address.state',
        zipCode: 'address.zip_code',
    },
    label: {
        address: 'Address 1',
        address2: 'Address 2',
        city: 'City',
        state: 'State',
        zipCode: 'Zip Code',
    },
};

export default AddressBlock;
