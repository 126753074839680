import { Switch, Route } from 'react-router-dom';
import { INCOME_SUMMARY } from 'routes/paths';
import SummaryView from './IncomeSummary';

function IncomeSummary() {
    return (
        <Switch>
            <Route exact path={INCOME_SUMMARY}>
                <SummaryView />
            </Route>
        </Switch>
    );
}

export default IncomeSummary;
