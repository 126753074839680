import actions from '../context-action-types';
import store from 'store';

const {
    EMPLOYMENT_TYPE,
    EMPLOYMENT_TYPE_SPOUSE,
    FULL_TIME_ESTIMATE_CORRECT,
    FULL_TIME_RE_ESTIMATE_CORRECT,
    FULL_TIME_ESTIMATE_CORRECT_SPOUSE,
    FULL_TIME_RE_ESTIMATE_CORRECT_SPOUSE,
    NOT_FOUND_ESTIMATE_CORRECT,
    NOT_FOUND_RE_ESTIMATE_CORRECT,
    NOT_FOUND_ESTIMATE_CORRECT_SPOUSE,
    NOT_FOUND_RE_ESTIMATE_CORRECT_SPOUSE,
    ADDITIONAL_INCOME_CORRECT,
    ADDITIONAL_INCOME_CORRECT_SPOUSE,
    HAS_ADDITIONAL_INCOME,
    SPOUSE_HAS_ADDITIONAL_INCOME,
    SELF_ESTIMATE_CORRECT,
    SELF_RE_ESTIMATE_CORRECT,
    SELF_ESTIMATE_CORRECT_SPOUSE,
    SELF_RE_ESTIMATE_CORRECT_SPOUSE,
    SELF_AVERAGE_ACCURATE,
    SELF_AVERAGE_ACCURATE_SPOUSE,
    HAVE_SIDE_BUSINESS,
    HAVE_SIDE_BUSINESS_SPOUSE,
    HAS_EXPENSES,
    HAS_EXPENSES_SPOUSE,
    SELF_HAS_EMPLOYEES,
    SELF_HAS_EMPLOYEES_SPOUSE,
    CLIENT_EMPLOYER_NAME,
    SPOUSE_EMPLOYER_NAME,
    SELECTED_DEPOSITS,
    SPOUSE_SELECTED_DEPOSITS,
    SELECTED_BUSINESS_DEPOSITS,
    SPOUSE_SELECTED_BUSINESS_DEPOSITS,
    IGNORES_ADD_INCOME,
    SPOUSE_IGNORES_ADD_INCOME,
} = actions;

const defaultEmployment = {
    personal: {
        type: 'fulltime',
        fullTimeEstimateCorrect: false,
        fullTimeReEstimateCorrect: false,
        notFoundEstimateCorrect: false,
        notFoundReEstimateCorrect: false,
        selfEstimateCorrect: false,
        selfReEstimateCorrect: false,
        selfAverageAccurate: false,
        haveSideBusiness: false,
        selfHasEmployees: true,
        selectedDeposits: true, // true if Client have selected deposits on W-2 otherwise false
        selectedBusinessDeposits: true, // true if Client have selected deposits on 1099 otherwise false
    },
    spouse: {
        type: 'fulltime',
        fullTimeEstimateCorrect: false,
        fullTimeReEstimateCorrect: false,
        notFoundEstimateCorrect: false,
        notFoundReEstimateCorrect: false,
        selfEstimateCorrect: false,
        selfReEstimateCorrect: false,
        selfAverageAccurate: false,
        haveSideBusiness: false,
        selfHasEmployees: true,
        selectedDeposits: true, // true if Client's Spouse have selected deposits on W-2 otherwise false
        selectedBusinessDeposits: true, // true if Client's Spouse have selected deposits on 1099 otherwise false
    },
    personalHasAdditionalIncome: true,
    spouseHasAdditionalIncome: true,
    personalAdditionalCorrect: false,
    spouseAdditionalCorrect: false,
    hasExpenses: true,
    spouseHasExpenses: true,
    clientEmployerName: '',
    spouseEmployerName: '',
    ignoresAdditionalIncome: true,
    spouseIgnoresAdditionalIncome: true,
};

const employmentData = store.get('employmentContext');

const isEmploymentDataValid = () => {
    if (
        employmentData &&
        typeof employmentData === 'object' &&
        employmentData.constructor === Object
    ) {
        const defaultKeys = Object.keys(defaultEmployment).sort();
        const employmentKeys = Object.keys(employmentData).sort();

        return JSON.stringify(defaultKeys) === JSON.stringify(employmentKeys);
    } else {
        return false;
    }
};

export const initialEmploymentInfo = isEmploymentDataValid()
    ? employmentData
    : defaultEmployment;

const reducerEmploymentInfo = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case EMPLOYMENT_TYPE:
            return { ...state, personal: { ...state.personal, type: payload } };

        case EMPLOYMENT_TYPE_SPOUSE:
            return { ...state, spouse: { ...state.spouse, type: payload } };

        case FULL_TIME_ESTIMATE_CORRECT:
            return {
                ...state,
                personal: {
                    ...state.personal,
                    fullTimeEstimateCorrect: payload,
                },
            };

        case FULL_TIME_RE_ESTIMATE_CORRECT:
            return {
                ...state,
                personal: {
                    ...state.personal,
                    fullTimeReEstimateCorrect: payload,
                },
            };

        case FULL_TIME_ESTIMATE_CORRECT_SPOUSE:
            return {
                ...state,
                spouse: { ...state.spouse, fullTimeEstimateCorrect: payload },
            };

        case FULL_TIME_RE_ESTIMATE_CORRECT_SPOUSE:
            return {
                ...state,
                spouse: { ...state.spouse, fullTimeReEstimateCorrect: payload },
            };

        case NOT_FOUND_ESTIMATE_CORRECT:
            return {
                ...state,
                personal: {
                    ...state.personal,
                    notFoundEstimateCorrect: payload,
                },
            };

        case NOT_FOUND_RE_ESTIMATE_CORRECT:
            return {
                ...state,
                personal: {
                    ...state.personal,
                    notFoundReEstimateCorrect: payload,
                },
            };

        case NOT_FOUND_ESTIMATE_CORRECT_SPOUSE:
            return {
                ...state,
                spouse: { ...state.spouse, notFoundEstimateCorrect: payload },
            };

        case NOT_FOUND_RE_ESTIMATE_CORRECT_SPOUSE:
            return {
                ...state,
                spouse: { ...state.spouse, notFoundReEstimateCorrect: payload },
            };

        case HAS_ADDITIONAL_INCOME:
            return { ...state, personalHasAdditionalIncome: payload };

        case SPOUSE_HAS_ADDITIONAL_INCOME:
            return { ...state, spouseHasAdditionalIncome: payload };

        case ADDITIONAL_INCOME_CORRECT:
            return { ...state, personalAdditionalCorrect: payload };

        case ADDITIONAL_INCOME_CORRECT_SPOUSE:
            return { ...state, spouseAdditionalCorrect: payload };

        case SELF_ESTIMATE_CORRECT:
            return {
                ...state,
                personal: {
                    ...state.personal,
                    selfEstimateCorrect: payload,
                },
            };

        case SELF_RE_ESTIMATE_CORRECT:
            return {
                ...state,
                personal: {
                    ...state.personal,
                    selfReEstimateCorrect: payload,
                },
            };

        case SELF_AVERAGE_ACCURATE:
            return {
                ...state,
                personal: {
                    ...state.personal,
                    selfAverageAccurate: payload,
                },
            };

        case HAVE_SIDE_BUSINESS:
            return {
                ...state,
                personal: {
                    ...state.personal,
                    haveSideBusiness: payload,
                },
            };

        case HAS_EXPENSES:
            return {
                ...state,
                hasExpenses: payload,
            };

        case SELF_ESTIMATE_CORRECT_SPOUSE:
            return {
                ...state,
                spouse: { ...state.spouse, selfEstimateCorrect: payload },
            };

        case SELF_RE_ESTIMATE_CORRECT_SPOUSE:
            return {
                ...state,
                spouse: { ...state.spouse, selfReEstimateCorrect: payload },
            };

        case SELF_AVERAGE_ACCURATE_SPOUSE:
            return {
                ...state,
                spouse: { ...state.spouse, selfAverageAccurate: payload },
            };

        case HAVE_SIDE_BUSINESS_SPOUSE:
            return {
                ...state,
                spouse: {
                    ...state.spouse,
                    haveSideBusiness: payload,
                },
            };

        case HAS_EXPENSES_SPOUSE:
            return {
                ...state,
                spouseHasExpenses: payload,
            };

        case SELF_HAS_EMPLOYEES:
            return {
                ...state,
                personal: {
                    ...state.personal,
                    selfHasEmployees: payload,
                },
            };

        case SELF_HAS_EMPLOYEES_SPOUSE:
            return {
                ...state,
                spouse: {
                    ...state.spouse,
                    selfHasEmployees: payload,
                },
            };
        case CLIENT_EMPLOYER_NAME:
            return {
                ...state,
                clientEmployerName: payload,
            };

        case SPOUSE_EMPLOYER_NAME:
            return {
                ...state,
                spouseEmployerName: payload,
            };

        case SELECTED_DEPOSITS:
            return {
                ...state,
                personal: {
                    ...state.personal,
                    selectedDeposits: payload,
                },
            };

        case SPOUSE_SELECTED_DEPOSITS:
            return {
                ...state,
                spouse: {
                    ...state.spouse,
                    selectedDeposits: payload,
                },
            };

        case SELECTED_BUSINESS_DEPOSITS:
            return {
                ...state,
                personal: {
                    ...state.personal,
                    selectedBusinessDeposits: payload,
                },
            };

        case SPOUSE_SELECTED_BUSINESS_DEPOSITS:
            return {
                ...state,
                spouse: {
                    ...state.spouse,
                    selectedBusinessDeposits: payload,
                },
            };

        case IGNORES_ADD_INCOME:
            return { ...state, ignoresAdditionalIncome: payload };

        case SPOUSE_IGNORES_ADD_INCOME:
            return { ...state, spouseIgnoresAdditionalIncome: payload };

        default:
            throw new Error();
    }
};

export default reducerEmploymentInfo;
