import { useState, useEffect } from 'react';
import api, { endpoints } from 'api';

// Hook to fetch client additional info

function useEmployersList(profile) {
    const [employersList, updateEmployersList] = useState([]);

    useEffect(() => {
        const getEmployersList = async () => {
            try {
                const req = await api({
                    url: endpoints.EMPLOYER_LIST,
                    params: {
                        profile: profile,
                    },
                });
                const res = await req.data;

                if (Array.isArray(res)) {
                    updateEmployersList(res);
                }
            } catch (e) {
                console.log(e.response);
            }
        };

        if (profile) getEmployersList();
    }, [profile]);

    return employersList;
}

export default useEmployersList;
