import { Fragment } from 'react';
import { SwitchInput } from 'components/Form';
import { useFormikContext } from 'formik';
import { FormGroup } from 'carbon-components-react';
import { AmountInput } from 'components/Utilities';

function RentalIncome({ index }) {
    const {
        values: { data },
    } = useFormikContext();

    return (
        <Fragment>
            <p>Do you have any rental expenses?</p>
            <SwitchInput
                className={'mb-16'}
                yesText="Yes"
                noText="No"
                id={`rental-${index}`}
                name={`data[${index}].rental_expense`}
            />

            {data[index].rental_expense && (
                <FormGroup legendText="" className="mb-16">
                    <AmountInput
                        placeholder="$"
                        id="amount"
                        name={`data[${index}].total_rental_expense_amount`}
                        labelText={'Monthly Rental Expense Amount'}
                    />
                </FormGroup>
            )}
        </Fragment>
    );
}

export default RentalIncome;
