import { Fragment, useContext, useState, useEffect } from 'react';
import { RadioButton, RadioButtonGroup } from 'carbon-components-react';
import { useStepController, useAdditionalInfo } from '../../hooks';
import Navigation from 'components/Navigation';
import styled from 'styled-components';
import api, { endpoints } from 'api';
import { history } from 'routes';
import {
    AdditionalInfoContext,
    PersonalInfoContext,
    contextActionTypes,
} from 'contexts';

const { HAS_MANUAL_ADD_INC, SPOUSE_HAS_MANUAL_ADD_INC } = contextActionTypes;

function ManualAdditionalIncomeChoice({ spouse }) {
    const [choose, setChoose] = useState(null);

    const { personalUid, spouseUid } = useContext(PersonalInfoContext);

    const { nextStep, previousStep } = useStepController();
    const { has_manual_additional_income, uid } = useAdditionalInfo(
        spouse ? spouseUid : personalUid
    );
    const { dispatchAdditionalInfo } = useContext(AdditionalInfoContext);

    useEffect(() => {
        if (typeof has_manual_additional_income === 'boolean') {
            setChoose(has_manual_additional_income === true ? 'yes' : 'no');
        }
    }, [has_manual_additional_income]);

    const saveAdditionalIncomeChoice = async () => {
        try {
            const req = await api({
                method: 'patch',
                url: `${endpoints.CLIENT_ADDITIONAL_INFO}${uid}/`,
                data: {
                    has_manual_additional_income: choose === 'yes',
                },
            });

            const res = await req.data;

            if (res) history.push(nextStep.path);
        } catch (e) {
            console.log(e.response);
        }
    };

    const handleManualAdditionalIIncome = (val) => {
        setChoose(val);

        dispatchAdditionalInfo({
            type: spouse ? SPOUSE_HAS_MANUAL_ADD_INC : HAS_MANUAL_ADD_INC,
            payload: val === 'yes',
        });
    };

    // Manual Additional Income Option
    const manualAdditionalIncomeChoice = (
        <RadioButtonGroup
            name="manual-radio-button"
            orientation="vertical"
            valueSelected={choose}
            onChange={handleManualAdditionalIIncome}
        >
            <Choice
                id="yes"
                labelText="Yes I have additional income to add."
                value="yes"
            />
            <Choice
                id="no"
                labelText="No, all my income is accounted for."
                value="no"
            />
        </RadioButtonGroup>
    );

    return (
        <Fragment>
            {spouse ? (
                <h1>Does your{' '}
                    <span className="color-blue">
                        {spouse && " spouse's"}{' '}
                    </span>
                    have additional income?</h1>
            ) : (
                <h1>Do you have additional income?</h1>
            )}
            <p>
                Additional income can be considered anything {' '}
                    <span className="color-blue">
                        {spouse ? " your spouse's": "you"}{' '}
                    </span>
                receive recurring income from besides your
                    <span className="color-blue">
                        {spouse && " spouse's"}{' '}
                    </span>
                main source of income.
            </p>
            {manualAdditionalIncomeChoice}
            <Navigation
                onPrevious={() => history.push(previousStep.path)}
                onNext={saveAdditionalIncomeChoice}
                className="mt-24"
                disableNext={!choose}
            />
        </Fragment>
    );
}

const Choice = styled(RadioButton)`
    .bx--radio-button__label {
        font-weight: 400;
        justify-content: flex-start;
    }
`;

export default ManualAdditionalIncomeChoice;
