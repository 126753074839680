import {
    CONNECT_BANKS,
    CONNECT_PERSONAL_BANKS,
    EMPLOYMENT,
    BANK_PROGRESS,
    CONNECT_BUSINESS_BANKS,
    SEPARATE_BUSINESS,
    SPOUSE_CONTRIBUTION,
    SPOUSE_EMPLOYMENT_TYPE,
    SPOUSE_JOINT_BANK,
    SPOUSE_ONLINE_INFO,
    CONNECT_SPOUSE_PERSONAL_BANKS,
    CONNECT_SPOUSE_BUSINESS_BANKS,
    SEPARATE_BUSINESS_SPOUSE,
    CONNECT_PERSONAL_MANUAL_BANKS,
    CONNECT_SPOUSE_PERSONAL_MANUAL_BANKS,
    CONNECT_BUSINESS_MANUAL_BANKS,
    CONNECT_SPOUSE_BUSINESS_MANUAL_BANKS,
} from '../../routes/paths';
import {
    EMPLOYMENT_TYPE_CONTROLLER,
    STATUS_MARRIED,
    STATUS_MARRIED_SEPARATELY,
} from '../../const';

/**
 * @function linkBankAccountSteps
 *
 * Link Bank Accounts Steps Generator following Clients filing status, spouse detail, additional info and employment info.
 *
 * @param {Object} param
 * @returns {Array} Link Bank Account Steps
 */

const linkBankAccountSteps = ({
    filingStatus,
    spouseDetail,
    additionalInfo,
    employment,
}) => {
    const {
        has_business_banks,
        spouse_contribute_household,
        spouses_online_bank_info,
        spouses_income_in_your_bank,
        has_manual_personal_bank,
        has_manual_business_bank,
        spouse: spouseAdditionalInfo,
    } = additionalInfo;

    // Check client wants to link Personal banks Manually
    const checkManualPersonalAdd = (spouse = false) => {
        if (!spouse && !has_manual_personal_bank) return [];

        if (spouse && !spouseAdditionalInfo.has_manual_personal_bank) return [];

        return [
            {
                path: spouse
                    ? CONNECT_SPOUSE_PERSONAL_MANUAL_BANKS
                    : CONNECT_PERSONAL_MANUAL_BANKS,
                title: 'Link Bank Accounts',
            },
        ];
    };

    // Check client wants to link Business banks manually
    const checkManualBusinessAdd = (spouse = false) => {
        if (!spouse && !has_manual_business_bank) return [];
        if (spouse && !spouseAdditionalInfo.has_manual_business_bank) return [];

        return [
            {
                path: spouse
                    ? CONNECT_SPOUSE_BUSINESS_MANUAL_BANKS
                    : CONNECT_BUSINESS_MANUAL_BANKS,
                title: 'Link Bank Accounts',
            },
        ];
    };

    const checkBusinessAccount = (clientSpouse = false) => {
        const { personal, spouse } = employment;
        const { type } = clientSpouse ? spouse : personal;
        const { selfEmployed, both } = EMPLOYMENT_TYPE_CONTROLLER;

        const step = [
            {
                path: clientSpouse
                    ? SEPARATE_BUSINESS_SPOUSE
                    : SEPARATE_BUSINESS,
                title: 'Link Bank Accounts',
            },
        ];

        if (clientSpouse) {
            if (spouseAdditionalInfo.has_business_banks) {
                step.push(
                    {
                        path: CONNECT_SPOUSE_BUSINESS_BANKS,
                        title: 'Link Bank Accounts',
                    },
                    ...checkManualBusinessAdd(true)
                );
            }
        } else {
            if (has_business_banks) {
                step.push(
                    {
                        path: CONNECT_BUSINESS_BANKS,
                        title: 'Link Bank Accounts',
                    },
                    ...checkManualBusinessAdd()
                );
            }
        }

        if (type === selfEmployed || type === both) {
            return step;
        } else {
            return [];
        }
    };

    const spouseSteps = () => {
        const steps = [
            {
                path: SPOUSE_CONTRIBUTION,
                title: 'Link Bank Accounts',
            },
        ];

        if (spouse_contribute_household) {
            steps.push(
                {
                    path: SPOUSE_EMPLOYMENT_TYPE,
                    title: 'Link Bank Accounts',
                },
                {
                    path: SPOUSE_ONLINE_INFO,
                    title: 'Link Bank Accounts',
                }
            );

            if (spouses_online_bank_info) {
                const sBankSteps = [
                    {
                        path: SPOUSE_JOINT_BANK,
                        title: 'Link Bank Accounts',
                    },
                    ...checkBusinessAccount(true),
                ];

                // Don't need to spouse personal bank if share joint bank account
                if (!spouses_income_in_your_bank) {
                    sBankSteps.push(
                        {
                            path: CONNECT_SPOUSE_PERSONAL_BANKS,
                            title: 'Link Bank Accounts',
                        },
                        ...checkManualPersonalAdd(true)
                    );
                }

                steps.push(...sBankSteps);
            }
        }

        switch (filingStatus) {
            case STATUS_MARRIED:
                return steps;

            case STATUS_MARRIED_SEPARATELY:
                if (spouseDetail) {
                    return steps;
                }
                return [];

            default:
                return [];
        }
    };

    const steps = [
        {
            path: CONNECT_BANKS,
            title: 'Link Bank Accounts',
        },
        {
            path: EMPLOYMENT,
            title: 'Link Bank Accounts',
        },
        ...checkBusinessAccount(),
        {
            path: CONNECT_PERSONAL_BANKS,
            title: 'Link Bank Accounts',
        },
        ...checkManualPersonalAdd(),
        ...spouseSteps(),
        {
            path: BANK_PROGRESS,
            title: 'Link Bank Accounts',
        },
    ];

    return steps;
};

export default linkBankAccountSteps;
