import { useEffect, useState } from 'react';
import { useField } from 'formik';
import { Dropdown as CarbonDropdown } from 'carbon-components-react';
import styled from 'styled-components';

function Dropdown(props) {
    const [selectedItem, setSelectedItem] = useState(null);
    const [itemsAreString, setItemsAreString] = useState(false);

    const { label, name, className, id, items, onChange, titleText } = props;
    const [field, meta, helper] = useField(name);

    const { value } = field;
    const { touched, error } = meta;
    const { setValue } = helper;

    // Check options are array of strings.
    useEffect(() => {
        if (Array.isArray(items) && items.length) {
            setItemsAreString(typeof items[0] === 'string');
        }
    }, [items]);

    // Update selected item base on value;
    useEffect(() => {
        if (value !== null && value !== undefined) {
            let selItemIndex;
            if (itemsAreString) {
                selItemIndex = items.findIndex((item) => item === value);
            } else {
                selItemIndex = items.findIndex((item) => item.id === value);
            }
            if (selItemIndex >= 0) {
                setSelectedItem(items[selItemIndex]);
            }
        }
    }, [value, items, itemsAreString]);

    const handleChange = ({ selectedItem }) => {
        setValue(itemsAreString ? selectedItem : selectedItem.id);
        setSelectedItem(selectedItem);

        if (onChange) {
            onChange(itemsAreString ? selectedItem : selectedItem.id);
        }
    };

    return (
        <StyledDropdown
            id={id}
            className={className}
            items={items}
            label={label}
            name={name}
            selectedItem={selectedItem}
            onChange={handleChange}
            invalid={error && touched}
            invalidText={error && touched ? error : ''}
            titleText={titleText}
        />
    );
}

Dropdown.defaultProps = {
    items: [],
    label: 'Select',
};

const StyledDropdown = styled(CarbonDropdown)`
    &.bx--dropdown--invalid {
        background-color: #fff1f1;
    }
`;

StyledDropdown.displayName = 'Styled Dropdown';

export default Dropdown;
