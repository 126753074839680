import { LinkAccountPhoto } from 'assets';
import Navigation from 'components/Navigation';
import WelcomeWrapper from './WelcomeWrapperStyled';
import { history } from 'routes';
import { useStepController } from 'hooks';

function LinkBankWelcome() {
    const { nextStep, previousStep } = useStepController();

    return (
        <WelcomeWrapper>
            <h1>
                Now, Let’s Verify Your{' '}
                <span className="color-blue"> Bank Accounts.</span>
            </h1>

            <img src={LinkAccountPhoto} alt="Family" className="my-30" />

            <h2>Why do we need this?</h2>

            <p>
                In order for us to verify your income, we use <strong>Plaid™</strong> to securely link
                your accounts. This allows us accurately represent your financial
                situation to the IRS.
            </p>

            <Navigation
                onNext={() => history.push(nextStep.path)}
                onPrevious={() => history.push(previousStep.path)}
            />
        </WelcomeWrapper>
    );
}

export default LinkBankWelcome;
