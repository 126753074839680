import { useMemo } from 'react';
import { Select as SelectInput, SelectItem } from 'carbon-components-react';
import styled from 'styled-components';
import { useField } from 'formik';

function Select(props) {
    const {
        items,
        placeholder,
        labelText,
        name,
        className,
        id,
        onChange,
    } = props;
    const [field, meta, helper] = useField(name);

    const { value, onBlur } = field;
    const { touched, error } = meta;
    const { setValue } = helper;

    const handleChange = (e) => {
        const { value } = e.target;

        setValue(value === 'default' ? '' : value);

        if (onChange) {
            onChange(value === 'default' ? '' : value);
        }
    };

    const renderSelectItems = useMemo(() => {
        return items.map((item, key) => (
            <SelectItem
                key={key}
                value={item.value}
                text={item.label}
                disabled={item.disabled}
            />
        ));
    }, [items]);

    return (
        <SelectStyled
            id={id}
            className={className}
            labelText={labelText}
            name={name}
            value={value}
            onChange={handleChange}
            invalid={error && touched}
            invalidText={error && touched ? error : ''}
            onBlur={onBlur}
        >
            <SelectItem value={'default'} text={placeholder || 'Select'} />
            {renderSelectItems}
        </SelectStyled>
    );
}

const SelectStyled = styled(SelectInput)`
    && {
        width: 100%;

        .bx--select-input__wrapper {
            width: 100%;

            .bx--select-input {
                max-width: none;
                width: 100%;
            }

            &[data-invalid] {
                .bx--select-input {
                    outline: 1px solid #da1e28;
                    outline-offset: -1px;
                    background: #fff1f1;
                }
            }
        }
    }
`;

export default Select;
