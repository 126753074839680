import { Fragment, useContext, useEffect } from 'react';
import { history } from 'routes';
import styled from 'styled-components';
import { ButtonBlock } from 'components/Buttons';
import { MetaContext } from 'contexts';

function TermsOfUse() {
    const {
        updateMeta: { updatePageTitle },
    } = useContext(MetaContext);

    useEffect(() => {
        updatePageTitle('Terms of Use');
    }, [updatePageTitle]);

    return (
        <Fragment>
            <h1>Terms of Use</h1>
            <TermsWrapper>
                <h1>Effective as of 2020 October 12</h1>
                <div>
                    <h2>Our Service</h2>
                    <p>
                        The Verifilink service (the “Service”) is comprised of
                        various web pages operated by Verifilink LLC
                        (“Verifilink”). The Service is offered to you
                        conditioned on your acceptance without modification of
                        the terms, conditions, and notices contained herein (the
                        “Terms of Use”). By using the Service you agree to be
                        bound by these Terms of Use. Verifilink provides the
                        Service in accordance with these Terms of Use. Please
                        read these Terms of Use carefully, and keep a copy of
                        them for your reference.
                    </p>
                </div>

                <div>
                    <h2>Verifilink is a financial data aggregator</h2>
                    <p>
                        Verifilink is an online financial data aggregator system
                        used by consumers to accurately determine their income,
                        expenses and assets. The Service includes features for
                        linking bank accounts and credit reporting data. These
                        Terms of Use apply to all users.
                    </p>
                </div>

                <div>
                    <h2>Your Privacy</h2>
                    <p>
                        Your use of Verifilink.com is subject to Verifilink’s
                        Privacy Policy. Please review our Privacy Policy. If you
                        are under 18, you may use the Service only with
                        permission of a parent or guardian. If you are under the
                        age of 13, you cannot use the Service.
                    </p>
                    <p>
                        Verifilink does not knowingly collect, either online or
                        offline, personal information from persons under the age
                        of 13. Always use caution when giving out any personally
                        identifying information about yourself or your children.
                    </p>
                </div>
                <div>
                    <h2>Electronic communications</h2>
                    <p>
                        You consent to receive electronic communications and you
                        agree that all agreements, notices, disclosures and
                        other communications that we provide to you
                        electronically, via email and through the Service,
                        satisfy any legal requirement that such communications
                        be in writing. These communications are considered part
                        of the Service. You cannot opt out of receiving these
                        communications. Notices to you may be made via either
                        email or regular mail.
                    </p>
                </div>

                <div>
                    <h2>Registration of account</h2>
                    <p>
                        In order to use the Service, you must have a valid
                        Verifilink account (an “Account”). To establish an
                        Account, you must provide Verifilink with a valid email
                        address, a password, and other information. You may
                        update your email address or password at any time. You
                        are responsible for maintaining the confidentiality of
                        your Account and password and for restricting access to
                        your computer. You agree to accept responsibility for
                        all activities that occur through your Account. You may
                        not assign or otherwise transfer your account to any
                        other person or entity.
                    </p>
                    <p>
                        You acknowledge that Verifilink is not responsible for
                        third party access to your Account that results from
                        theft or misappropriation of your Account. You agree to
                        immediately notify Verifilink of any unauthorized use of
                        your Account or any other breach of security, and ensure
                        that you exit from your Account at the end of each
                        session. Verifilink may log you out of your Account if
                        you are inactive for an extended period of time. If your
                        password is stolen or our Account is otherwise
                        compromised, you must immediately notify Verifilink and
                        change your password. Verifilink cannot and will not be
                        liable for any loss or damage arising from your failure
                        to maintain the security of your Account or password.
                    </p>
                    <p>
                        You represent and warrant that all of the information
                        provided by you to Verifilink is correct and current;
                        and you have all necessary right, power, and authority
                        to enter into these Terms of Use and to perform the acts
                        required of you by these Terms of Use. If you provide
                        any information that is untrue, inaccurate, not current,
                        or incomplete, or Verifilink has reasonable grounds to
                        suspect that such information is untrue, inaccurate, not
                        current, or incomplete, Verifilink has the right to
                        suspend or terminate your Account and refuse you access
                        to the Service.
                    </p>
                </div>

                <div>
                    <h2>Collection of your personal Information</h2>
                    <p>
                        “Personal Information” means information that identifies
                        you, such as your name, address, location, social
                        security number, phone number, fax number, or email
                        address. Personal Information includes financial
                        documents and information that you may upload or
                        otherwise transmit to the Service. This information is
                        used to complete certain transactions.
                    </p>
                    <p>
                        From time to time Verifilink may request additional
                        Personal Information to provide you with other benefits
                        of the Service. In all such instances, you will be given
                        the opportunity to provide or to decline to provide that
                        information and it will be used only for the stated
                        purpose.
                    </p>
                </div>

                <div>
                    <h2>Storage of personal information and documents</h2>
                    <p>
                        Verifilink may establish general practices and limits
                        concerning use of the Service and data storage limits
                        for your use of the Service. Verifilink may modify such
                        practices and limits from time to time without notice.
                    </p>
                    <p>
                        The Service is not intended as a backup or storage
                        system. You agree to maintain backup copies of all of
                        your Personal Information, documents, and communications
                        submitted to the Service. Verifilink shall have no
                        responsibility or liability for the deletion of, the
                        failure to store, or the failure to transmit, any
                        Personal Information, documents, or communications
                        maintained by the Service.
                    </p>
                </div>

                <div>
                    <h2>The Service is provided “as is”</h2>
                    <p>
                        Verifilink strives to provide a useful and reliable
                        Service, but there are certain things that we cannot
                        guarantee. TO THE FULLEST EXTENT PERMITTED BY LAW,
                        Verifilink AND ITS AFFILIATES, SUPPLIERS, AND
                        DISTRIBUTORS MAKE NO WARRANTIES, EITHER EXPRESS OR
                        IMPLIED, ABOUT THE SERVICE. THE SERVICE IS PROVIDED “AS
                        IS.” WE ALSO DISCLAIM ANY WARRANTIES OF MERCHANTABILITY,
                        FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
                    </p>
                </div>

                <div>
                    <h2>Limitation of liability</h2>
                    <p>
                        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO
                        EVENT SHALL VERIFILINK OR ITS SUPPLIERS BE LIABLE FOR
                        ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
                        CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER
                        INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE,
                        LOSS OF DATA, OR LOST PROFITS, ARISING OUT OF OR IN ANY
                        WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE
                        SERVICE, WITH THE DELAY OR INABILITY TO USE THE SERVICE
                        OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO
                        PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE,
                        PRODUCTS, OR SERVICES OBTAINED THROUGH THE SERVICE, OR
                        OTHERWISE ARISING OUT OF THE USE OF THE SERVICE, WHETHER
                        BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY,
                        OR OTHERWISE, EVEN IF Verifilink OR ANY OF ITS SUPPLIERS
                        HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE
                        SOME STATES AND JURISDICTIONS DO NOT ALLOW THE EXCLUSION
                        OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
                        INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY
                        TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE
                        SERVICE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE
                        AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE
                        SERVICE.
                    </p>
                    <p>
                        THE INFORMATION, SOFTWARE, PRODUCTS, AND FEATURES
                        INCLUDED IN OR AVAILABLE THROUGH THE SERVICE MAY INCLUDE
                        INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE
                        PERIODICALLY ADDED TO THE INFORMATION HEREIN. VERIFILINK
                        OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS OR CHANGES IN THE
                        SERVICE AT ANY TIME.
                    </p>
                </div>

                <div>
                    <h2>Identification</h2>
                    <p>
                        You agree to indemnify, defend and hold harmless
                        Verifilink, its officers, directors, employees, agents,
                        and third parties, for any losses, costs, liabilities
                        and expenses, including reasonable attorneys’ fees,
                        relating to or arising out of your use of or inability
                        to use the Service.
                    </p>
                    <p>
                        You further agree to indemnify, defend and hold harmless
                        Verifilink, its officers, directors, employees, agents
                        and third parties, for any losses, costs, liabilities
                        and expenses, including reasonable attorneys’ fees,
                        relating to or arising out of any documents or
                        information you upload or download or otherwise transmit
                        through the Service; your violation of any of these
                        Terms of Use; your violation of any rights of a third
                        party; or your violation of any applicable laws, rules
                        or regulations. Verifilink reserves the right, at its
                        own cost, to assume the exclusive defense and control of
                        any matter otherwise subject to indemnification by you,
                        in which event you shall fully cooperate with Verifilink
                        in asserting any available defenses.
                    </p>
                </div>

                <div>
                    <h2>No unlawful or prohibited use</h2>
                    <p>
                        s a condition of your use of the Service, you warrant
                        that you will not use the Service for any purpose that
                        is unlawful or prohibited by these Terms of Use. You may
                        not use the Service in any manner that could damage,
                        disable, overburden, or impair the Service or interfere
                        with any other party’s use and enjoyment of the Service.
                    </p>
                    <p>
                        The Service provides communication features including
                        the uploading and downloading of documents. You agree to
                        use the Service only to upload, download, send and
                        receive documents and information that are proper and
                        related to the Service.
                    </p>
                </div>

                <div>
                    <h2>You agree that you will not:</h2>
                    <p>
                        upload, post, email, or otherwise transmit any computer
                        viruses, routines, files, or programs designed to
                        interrupt, destroy, or limit the functionality of any
                        computer software or hardware or telecommunications
                        equipment;
                    </p>
                    <p>
                        upload, post, email, or otherwise transmit any document
                        or information that infringes any patent, trademark,
                        trade secret, copyright, rights of privacy or publicity,
                        or other proprietary rights of any party; upload, post,
                        email, or otherwise transmit any inappropriate, profane,
                        defamatory, infringing, obscene, harassing or
                        threatening documents or information;
                    </p>
                    <p>
                        interfere with or disrupt the Service or networks
                        connected to the Service, or disobey any requirements,
                        procedures, policies or regulations of networks
                        connected to the Service, or otherwise interfere with
                        the Service in any way, including through the use of
                        JavaScript or other coding; obtain or attempt to obtain
                        any documents or information through any means not
                        intentionally made available or provided for through the
                        Service; take any action that imposes an unreasonable or
                        disproportionately large load on our infrastructure;
                        download or access any document or information that you
                        know, or reasonably should know, should not be be
                        accessible to you; harvest or otherwise collect
                        information about others, including email addresses,
                        without their consent;
                    </p>
                    <p>
                        violate any applicable laws or regulations; or exceed
                        the scope of the Service that you have signed up for;
                        for example, accessing and using features that you do
                        not have a right to use or sharing your Account
                        information with other people.
                    </p>
                    <p>
                        Verifilink reserves the right to monitor your use of the
                        Service and edit, refuse to post, or to remove any
                        information or documents, in whole or in part. In
                        addition Verifilink may terminate your Account at any
                        time without further warning if it determines that you
                        have violated these Terms of Use or their spirit.
                    </p>
                </div>

                <div>
                    <h2>Intellectual Property</h2>
                    <p>
                        You are granted a non-exclusive, non-transferable,
                        revocable license to access and use the Service strictly
                        in accordance with these Terms of Use. You agree not to
                        reproduce, duplicate, copy, sell, resell, or exploit the
                        Service or any portion of the Service without the
                        express permission of Verifilink.
                    </p>
                    <p>
                        All content included as part of the Service, such as
                        text, graphics, logos, images, as well as the
                        compilation thereof, and any software used in the
                        Service, is the property of Verifilink or its suppliers
                        and protected by copyright, trademark, and other laws
                        that protect intellectual property and proprietary
                        rights. You agree to observe and abide by all copyright,
                        trademark, and other proprietary notices, legends or
                        other restrictions contained in any such content and
                        will not make any changes thereto.
                    </p>
                    <p>
                        You will not modify, publish, transmit, reverse
                        engineer, participate in the transfer or sale of the
                        Service; or create derivative works of the Service. No
                        resale of the Service is permitted without the express
                        written consent of Verifilink.
                    </p>
                </div>

                <div>
                    <h2>Third party accounts</h2>
                    <p>
                        You will be able to connect your Verifilink account to
                        third party services. By connecting your Verifilink
                        Account to a third party service, you acknowledge and
                        agree that you are consenting to the continuous release
                        of information about you to that third party in
                        accordance with your privacy settings with those third
                        party services and the terms of service of those third
                        parties. If you do not want information about you to be
                        shared in this manner, do not use these features. You
                        may disconnect your Account from third party services at
                        any time.
                    </p>
                    <p>
                        Verifilink encourages you to review the terms of service
                        and privacy policies of any third-party service you
                        connect to your Verifilink account so that you can
                        understand how those services collect, use, share, and
                        protect your information. Verifilink is not responsible
                        for the terms of service, privacy policies or other
                        content on or of third party services.
                    </p>
                </div>

                <div>
                    <h2>Linked services</h2>
                    <p>
                        The Service may contain links to other services (“Linked
                        Services”). The Linked Services are not under the
                        control of Verifilink and Verifilink is not responsible
                        for the contents of any Linked Service, including
                        without limitation any link contained in a Linked
                        Service, or any changes or updates to a Linked Service.
                        Verifilink provides these links to you only as a
                        convenience, The inclusion of any link does not imply
                        endorsement by Verifilink of the Linked Service or any
                        association with its operators.
                    </p>
                </div>

                <div>
                    <h2>Termination policy</h2>
                    <p>
                        Your data is yours. You can remove it anytime you want.
                        When you request that we terminate your Account, your
                        Personal Information including your documents will be
                        permanently expunged from our primary production servers
                        and further access to your Account will not be possible.
                        Verifilink will disconnect your Account from all third
                        party services. However, there might be some latency in
                        deleting your information and documents from our backup
                        servers and backup storage. Verifilink keeps these
                        backups to ensure our continued ability to provide the
                        Service in the event of malfunction or damage to our
                        primary production servers. In addition we may retain
                        certain information or documents if necessary to comply
                        with our legal obligations, resolve disputes, or enforce
                        our agreements.
                    </p>
                    <p>
                        Verifilink reserves the right, in its sole discretion,
                        to terminate your access to the Service or any portion
                        thereof at any time, without notice. In the event your
                        access is terminated by Verifilink, your Account will be
                        disabled and you will not be granted access to your
                        Account or any document or information hosted by the
                        Service. Verifilink may delete your documents or
                        information.
                    </p>
                    <p>
                        In the event of termination, Verifilink may also
                        withdraw and at its discretion reallocate the public web
                        address of the Service.
                    </p>
                </div>

                <div>
                    <h2>Government and legal requests</h2>
                    <p>
                        Verifilink’s performance under these Terms of Use is
                        subject to existing laws and legal process, and nothing
                        contained in these Terms of Use is in derogation of
                        Verifilink’s right to comply with governmental, court,
                        and law enforcement requests or requirements relating to
                        your use of the Service or documents or information
                        provided to or gathered by Verifilink with respect to
                        such use. Verifilink reserves the right to disclose any
                        documents or information as necessary to satisfy any
                        applicable law, regulation, legal process or
                        governmental request.
                    </p>
                </div>

                <div>
                    <h2>International users</h2>
                    <p>
                        The Service is controlled, operated and administered by
                        Verifilink from our offices within the United States of
                        America. If you access the Service from a location
                        outside the U.S.A., you are responsible for compliance
                        with all local laws. You agree that you will not use the
                        Service in any country or in any manner prohibited by
                        any applicable laws, restrictions or regulations.
                    </p>
                </div>

                <div>
                    <h2>California law applies</h2>
                    <p>
                        To the maximum extent permitted by law, these Terms of
                        Use are governed by the laws of the State of California.
                        Any dispute arising under or in connection with the
                        Service or these Terms of Use shall be subject to the
                        exclusive jurisdiction of the state courts located in
                        Orange County, California. You hereby consent to the
                        exclusive jurisdiction and venue of these courts. Use of
                        the Service is unauthorized in any jurisdiction that
                        does not give effect to all provisions of these Terms of
                        Use, including, without limitation, this section.
                    </p>
                </div>

                <div>
                    <h2>Not a joint venture</h2>
                    <p>
                        You agree that no joint venture, partnership,
                        employment, or agency relationship exists between you
                        and Verifilink as a result of these Terms of Use or use
                        of the Service.
                    </p>
                    <p>
                        If any part of these Terms of Use is determined to be
                        invalid or unenforceable pursuant to applicable law
                        including, but not limited to, the warranty disclaimers
                        and liability limitations set forth above, then the
                        invalid or unenforceable provision will be deemed
                        superseded by a valid, enforceable provision that most
                        closely matches the intent of the original provision and
                        the remainder of the Terms of Use shall continue in
                        effect. Verifilink is not designed or licensed for
                        hazardous environments
                    </p>
                    <p>
                        Without limiting the foregoing, the Service is not
                        designed or licensed for use in hazardous environments
                        requiring fail-safe controls, including without
                        limitation operation of nuclear facilities, aircraft
                        navigation/communication systems, air traffic control,
                        and life support or weapons systems.
                    </p>
                </div>

                <div>
                    <h2>New, modified, and discontinued features</h2>
                    <p>
                        You agree that no joint venture, partnership,
                        employment, or agency relationship exists between you
                        and Verifilink as a result of these Terms of Use or use
                        of the Service.
                    </p>
                    <p>
                        If any part of these Terms of Use is determined to be
                        invalid or unenforceable pursuant to applicable law
                        including, but not limited to, the warranty disclaimers
                        and liability limitations set forth above, then the
                        invalid or unenforceable provision will be deemed
                        superseded by a valid, enforceable provision that most
                        closely matches the intent of the original provision and
                        the remainder of the Terms of Use shall continue in
                        effect. Verifilink is not designed or licensed for
                        hazardous environments
                    </p>
                    <p>
                        Without limiting the foregoing, the Service is not
                        designed or licensed for use in hazardous environments
                        requiring fail-safe controls, including without
                        limitation operation of nuclear facilities, aircraft
                        navigation/communication systems, air traffic control,
                        and life support or weapons systems.
                    </p>
                </div>

                <div>
                    <h2>Access through unauthorized means</h2>
                    <p>
                        You agree not to access the Service by any means other
                        than through the interfaces that are provided by
                        Verifilink for use in accessing the Service.
                    </p>
                </div>
                <div>
                    <h2>Injunctive relief</h2>
                    <p>
                        You acknowledge that any use of the Service contrary to
                        these Terms of Use, or any transfer, sublicensing,
                        copying, or disclosure of technical information or
                        materials related to the Service, may cause irreparable
                        injury to Verifilink, its affiliates, suppliers, and any
                        other party authorized by Verifilink to promote the
                        Service (“Promoters”), and under such circumstances
                        Verifilink, its affiliates, suppliers, and Promoters
                        will be entitled to equitable relief, without posting
                        bond or other security, including, but not limited to,
                        preliminary and permanent injunctive relief.
                    </p>
                    <p>
                        Any and all rights and remedies of Verifilink upon your
                        breach or other default under these Terms of Use will be
                        deemed cumulative and not exclusive of any other right
                        or remedy conferred by these Terms of Use or by law or
                        equity on Verifilink, and the exercise of any one remedy
                        will not preclude the exercise of any other.
                    </p>
                </div>

                <div>
                    <h2>Statue of Limitations</h2>
                    <p>
                        You agree that regardless of any statute or law to the
                        contrary, any claim or cause of action arising out of or
                        related to use of the Service or these Terms of Use must
                        be filed within one year after such claim or cause of
                        action arose or be forever barred.
                    </p>
                </div>

                <div>
                    <h2>Entire agreement</h2>
                    <p>
                        Unless otherwise specified herein, these Terms of Use
                        constitute the entire agreement between you and
                        Verifilink with respect to the Service and they
                        supersede all prior or contemporaneous communications
                        and proposals, whether electronic, oral or written,
                        between you and Verifilink with respect to the Service.
                        A printed version of these Terms of Use and of any
                        notice given in electronic form shall be admissible in
                        judicial or administrative proceedings based upon or
                        relating to this agreement to the same extent and
                        subject to the same conditions as other business
                        documents and records originally generated and
                        maintained in printed form.
                    </p>
                    <p>
                        The captions and headings appearing in these Terms of
                        Use are for reference only and will not be considered in
                        construing this agreement.
                    </p>
                </div>

                <div>
                    <h2>Changes to the terms of use</h2>
                    <p>
                        Verifilink reserves the right, in its sole discretion,
                        to change the Terms of Use under which the Service is
                        offered. If Verifilink changes these Terms of Use, you
                        will be required to agree to them prior to resuming use
                        of the Service. If you choose not to agree to the
                        changes, you may terminate your use of the Service.
                    </p>
                    <p>
                        If you have any questions or concerns regarding the
                        Terms of Use, email{' '}
                        <a
                            className="color-blue"
                            href="mailto:support@verifilink.com"
                        >
                            support@verifilink.com.
                        </a>{' '}
                        Effective as of 2020 October 12
                    </p>
                </div>
            </TermsWrapper>

            <div className="mt-40 fixed-bottom">
                <ButtonBlock type={'button'} onClick={() => history.goBack()}>
                    Previous
                </ButtonBlock>
            </div>
        </Fragment>
    );
}

const TermsWrapper = styled.div`
    h1 {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #6a7581;
        margin-top: 8px;
    }
    h2 {
        font-family: Poppins;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #0f212e;
        margin-top: 15px;
    }
    p {
        font-family: Poppins;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #67581;
    }
`;

export default TermsOfUse;
