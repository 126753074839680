import { useEffect, useState, useContext, useMemo } from 'react';
import {
    enableDateFormatMethod,
    enableFutuerDateMethod,
    enableMinDateCheck,
} from '../../lib/yupValidMethods';
import * as Yup from 'yup';
import { FieldArray, Formik } from 'formik';
import api, { endpoints } from '../../api';
import Dependent from './Dependent';
import { LinkButton } from 'components/Buttons';
import { useStepController, useModal } from 'hooks';
import { history } from 'routes';
import Modal from 'components/Modal';
import { genOptsFromArrOfObjs } from 'lib';
import Navigation from 'components/Navigation';
import { PersonalInfoContext } from 'contexts';
import {
    MAX_NAME_CHARS,
    MIN_NAME_CHARS,
    STATUS_HEAD_OF_HOUSEHOLD,
    STATUS_WIDOW_WITH_DEPENDENT,
} from 'const';

const emptyDependent = {
    uid: '',
    name: '',
    dob: '',
    relationship: '',
    contributes: false,
    has_claim_on_tax_return: false,
    claim_on_tax_year: null,
};

// Add dateFormat custom validation
enableDateFormatMethod();
enableFutuerDateMethod();
enableMinDateCheck('01-01-1920');

function AddDependents() {
    const { filingStatus, personalInfo } = useContext(PersonalInfoContext);

    const [initialValues, updateInitial] = useState({
        deps: [Object.assign({}, emptyDependent)],
        filingStatus,
    }); // Form initial values
    const [relations, updateRels] = useState([]);
    /* 
        Index of dependent to clear and remove method.
        Remove method is necessary to save in state because FieldArray methods can't be accessed outside render. 
    */
    const [depToDelete, setDeleteDep] = useState([]);

    const { previousStep, nextStep } = useStepController();
    const [open, showModal, hideModal] = useModal();

    const requiredDependent =
        filingStatus === STATUS_HEAD_OF_HOUSEHOLD ||
        filingStatus === STATUS_WIDOW_WITH_DEPENDENT;

    useEffect(() => {
        // Fetch types of relations
        const relTypes = async () => {
            try {
                const req = await api.get(endpoints.RELATIONSHIP_LIST);
                const res = await req.data;
                const relations = genOptsFromArrOfObjs(res, 'value');

                updateRels(relations);
            } catch (e) {
                console.log(e.response);
            }
        };

        // Fetch existing dependents
        const fetchExistDependents = async () => {
            try {
                const req = await api.get(endpoints.DEPENDENT);
                const res = await req.data;
                console.log(res);

                if (Array.isArray(res) && res.length) {
                    updateInitial((initialData) => ({
                        ...initialData,
                        deps: res,
                    }));
                }
            } catch (e) {
                console.log(e.response);
            }
        };

        relTypes();
        fetchExistDependents();
    }, []);

    // Update filing status on form values, need for validation
    useEffect(() => {
        updateInitial((initialData) => ({
            ...initialData,
            filingStatus,
        }));
    }, [filingStatus]);

    const saveDependents = async (values) => {
        try {
            const req = await api.post(endpoints.DEPENDENT, values.deps);
            const res = await req.data;

            if (res) {
                history.push(nextStep.path);
            }
        } catch (e) {
            console.log(e.response);
        }
    };

    const handleClear = (index, method, uid) => {
        setDeleteDep([index, method, uid]);
        showModal();
    };

    const deleteDependent = async () => {
        const [depIndex, remove, uid] = depToDelete;

        // Delete Dependent from backend when uid available otherwise remove from the design
        if (!uid) {
            remove(depIndex);
        } else {
            try {
                const deleteReq = await api({
                    method: 'delete',
                    url: `${endpoints.DEPENDENT}${uid}/`,
                });

                if (deleteReq) remove(depIndex);
            } catch (e) {
                console.log(e.response);
            }
        }
    };

    const goBack = () => {
        history.push(previousStep.path);
    };

    const validationRules = useMemo(() => {
        return Yup.object().shape({
            filingStatus: Yup.string(),
            deps: Yup.array()
                .of(
                    Yup.object().shape({
                        uid: Yup.string().nullable(),
                        name: Yup.string()
                            .min(MIN_NAME_CHARS, 'Minimum 1 characters')
                            .max(MAX_NAME_CHARS, 'Maximum 30 characters')
                            .required('Required'),
                        dob: Yup.string()
                            .required('Required')
                            .dateFormat()
                            .futureDate()
                            .minDate()
                            .when('relationship', (relation, schema) => {
                                const { dob: clientDob } = personalInfo;

                                if (relation === 'child') {
                                    return schema.minDate(
                                        "Child can't be older than you",
                                        clientDob
                                    );
                                }

                                if (
                                    relation === 'parent' ||
                                    relation === 'grandparent'
                                ) {
                                    return schema.futureDate(
                                        "Parent/Grand Parent can't be younger than you",
                                        clientDob
                                    );
                                }

                                return schema;
                            }),
                        relationship: Yup.string().required('Required'),
                        contributes: Yup.bool(),
                    })
                )
                .when('filingStatus', (filingStatus, schema) => {
                    if (
                        filingStatus === STATUS_WIDOW_WITH_DEPENDENT ||
                        filingStatus === STATUS_HEAD_OF_HOUSEHOLD
                    ) {
                        return schema.required(
                            'At least one dependent is required'
                        );
                    } else {
                        return schema;
                    }
                }),
        });
    }, [personalInfo]);

    return (
        <Formik
            displayName="Household Form"
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationRules}
            validateOnMount
            onSubmit={saveDependents}
        >
            {({ handleSubmit, values, isValid }) => (
                <>
                    <h1>
                        Please Enter Your{' '}
                        <span className="color-blue">
                            Dependent Information.
                        </span>
                    </h1>

                    <p>
                        To add additional dependents, please select{' '}
                        <strong>“Add a Dependent”</strong>
                    </p>
                    <FieldArray
                        name="deps"
                        render={({ push, remove }) => (
                            <div className="mt-32">
                                <div>
                                    {values.deps.map((dep, i) => (
                                        <Dependent
                                            values={values}
                                            key={i}
                                            index={i}
                                            relations={relations}
                                            onClear={handleClear.bind(
                                                null,
                                                i,
                                                remove,
                                                dep.uid
                                            )}
                                        />
                                    ))}
                                </div>
                                {requiredDependent && (
                                    <p>
                                        *Qualifying Widow With Dependent and
                                        Head of Household require{' '}
                                        <strong>at least one dependent</strong>{' '}
                                        to continue.
                                    </p>
                                )}
                                <LinkButton
                                    type="button"
                                    onClick={() => push(emptyDependent)}
                                    className="mb-32"
                                >
                                    <span>Add a Dependent</span>
                                </LinkButton>
                            </div>
                        )}
                    />

                    <Navigation
                        onPrevious={goBack}
                        onNext={handleSubmit}
                        disableNext={!isValid}
                        typeNext="submit"
                    />

                    <Modal
                        open={open}
                        onClose={hideModal}
                        onSave={deleteDependent}
                        modalHeading="Are you sure you want to remove this dependent?"
                        primaryButtonText="Yes"
                        danger
                    >
                        <p>The information you entered will not be saved.</p>
                    </Modal>
                </>
            )}
        </Formik>
    );
}

export default AddDependents;
