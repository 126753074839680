import { Router } from 'react-router-dom';
import axios from 'axios';
import Routes, { history } from 'routes';
import 'react-toastify/dist/ReactToastify.min.css';
import Wrapper from 'components/Wrappers';
import Header from 'components/Headers';
import { AuthProvider, MetaProvider } from './contexts';
import { baseURL } from 'api';
import { NotificationContainer } from 'components/Notification';
import ErrorBoundary from 'ErrorBoundary';
import ScrollToTop from './ScrollToTop';
import * as Sentry from '@sentry/react';
import { hotjar } from 'react-hotjar';

// Set base api url to axios
axios.defaults.baseURL = baseURL;

hotjar.initialize(`${process.env['REACT_APP_HOTJAR_ID']}`, `${process.env['REACT_APP_HOTJAR_SV']}`);

function App() {
    return (
        <Router history={history}>
            <ScrollToTop />
            <Wrapper>
                <MetaProvider>
                    <Header />
                    <AuthProvider>
                        <Sentry.ErrorBoundary fallback={ErrorBoundary}>
                            <Routes />
                        </Sentry.ErrorBoundary>
                    </AuthProvider>
                </MetaProvider>
            </Wrapper>
            <NotificationContainer />
        </Router>
    );
}

export default App;
