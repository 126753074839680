import { FormGroup } from 'carbon-components-react';
import { Close24 } from '@carbon/icons-react';
import { Input } from 'components/Form';
import styled from 'styled-components';
import { AmountInput } from 'components/Utilities';

const Title = ({ index, is_additional}) => (
    <p className="color-blue text-bold mb-13">
        {is_additional ? 'Additional' : ''} Vehicle ({parseInt(index, 10) + 1})
    </p>
);

function VehicleAdditional(props) {
    const { index, onClear, additional} = props;

    return (
        <Wrapper>
            <Title index={index} is_additional={additional}/>
            <Close onClick={onClear} />
            <HiddenInput
                name={`vehicles[${index}].uid`}
                disabled
                labelText=""
            />
            <div className="bx--grid bx--no-gutter">
                <div className="bx--row">
                    <div className="bx--col">
                        <FormGroup legendText="">
                            <Input
                                name={`vehicles[${index}].year`}
                                labelText="Year"
                            />
                        </FormGroup>
                    </div>
                    <div className="bx--col">
                        <FormGroup legendText="">
                            <Input
                                name={`vehicles[${index}].make`}
                                labelText="Make"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>

            <FormGroup legendText="">
                <Input name={`vehicles[${index}].model`} labelText="Model" />
            </FormGroup>
            <FormGroup legendText="">
                <AmountInput
                    name={`vehicles[${index}].odometer`}
                    labelText="Odometer(Miles)"
                />
            </FormGroup>
            <FormGroup legendText={''}>
                <AmountInput
                    placeholder="$"
                    name={`vehicles[${index}].market_value`}
                    labelText="Estimated Market Value"
                />
            </FormGroup>
            <FormGroup legendText={''}>
                <AmountInput
                    placeholder="$"
                    name={`vehicles[${index}].outstanding_balance`}
                    labelText="Estimated Loan Balance"
                />
            </FormGroup>
        </Wrapper>
    );
}

const Wrapper = styled.section`
    border: 1px solid #d8e5f0;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 32px;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -17px;
        height: 1px;
        background: #cdd3d8;
    }

    &:last-child::after {
        content: none;
    }
`;

const HiddenInput = styled(Input)`
    height: 1px;
    overflow: hidden;
    width: 1px;
    display: none;
`;

const Close = styled(Close24)`
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 8px;
    color: #161616;
    z-index: 9;
`;

Wrapper.displayName = 'Wrapper';
Close.displayName = 'Close';

export default VehicleAdditional;
