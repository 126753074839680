import { useContext, useEffect, useState } from 'react';
import { FormGroup } from 'carbon-components-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import api, { endpoints } from 'api';
import { history } from 'routes';
import { PersonalInfoContext } from '../../../contexts';
import { useStepController } from '../../../hooks';
import Navigation from 'components/Navigation';
import { AmountInput } from 'components/Utilities';
import {
    enableDotAmountCheck,
    enableMaxAmountCheck,
    enableMinAmountCheck,
} from 'lib/yupValidMethods';
import { parseAmount } from 'lib';
import { MAX_AMOUNT, MAX_AMOUNT_US, MIN_AMOUNT } from 'const';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal)

const initialValues = { income: '', net_amount: '' };

function ManualIncome({ spouse }) {
    const { personalUid, spouseUid, employment } = useContext(
        PersonalInfoContext
    );
    const { nextStep, previousStep } = useStepController();
    const [manualEntry, updateManualEntry] = useState(initialValues);
    const { clientEmployerName, spouseEmployerName } = employment;
    const [popupShow, updatePopupShow] = useState(true);

    useEffect(() => {
        const manualEntryIncome = async () => {
            try {
                const req = await api.get(
                    `${endpoints.CLIENT_INCOME}?profile=${spouse ? spouseUid : personalUid
                    }`,
                    {
                        params: {
                            income_type: 1,
                        },
                    }
                );
                const res = await req.data;
                if (res) {
                    updateManualEntry({
                        income: res[0].amount,
                        net_amount: res[0].net_amount,
                    });
                }
            } catch (e) {
                console.log(e.response);
            }
        };

        if ((spouse && spouseUid) || (!spouse && personalUid)) {
            manualEntryIncome();
        }
    }, [personalUid, spouseUid, spouse]);

    const incomeVerification = async (values) => {
        const data = {
            income_type: 1,
            amount: parseAmount(values.income),
            net_amount: parseAmount(values.net_amount),
            remarks: values.comments,
            verified: true,
            profile: spouse ? spouseUid : personalUid,
        };
        try {
            const req = await api.post(endpoints.CLIENT_INCOME, data);
            const res = await req.data;
            if (res) {
                history.push(nextStep.path);
            }
        } catch (e) {
            console.log(e.response);
        }
    };

    const validateValue = async (values) => {
        if (values.target.value == '0' && popupShow) {
            MySwal.fire({
                title: 'Important!',
                html: 'Please verify your income is $0.00',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'I Understand'
            });
            updatePopupShow(false);
        }
    };

    return (
        <Formik
            initialValues={manualEntry}
            onSubmit={incomeVerification}
            validationSchema={inputValidate}
            enableReinitialize={true}
        >
            {({ handleSubmit, values, isValid }) => (
                <form onChange={validateValue}>
                    <h1>
                        Please Enter Your
                        {spouse ? (
                            <span className="color-blue"> Spouse's </span>
                        ) : (
                            ''
                        )}
                        {' '}Monthly Net Income From{' '}
                        {spouse ? (
                            <span className="color-blue">
                                {spouseEmployerName}.
                            </span>
                        ) : (
                            <span className="color-blue">
                                {clientEmployerName}.
                            </span>
                        )}{' '}
                    </h1>

                    <p>
                        Your net income is the amount you receive on your paycheck after
                        taxes have been deducted. It’s okay to estimate this number if you
                        do not have the exact amount.
                    </p>
                    <section className="mt-24">
                        <FormGroup legendText={''}>
                            <AmountInput
                                placeholder="$"
                                name="income"
                                labelText="Monthly Gross Income"
                            />
                        </FormGroup>

                        <FormGroup legendText={''}>
                            <AmountInput
                                placeholder="$"
                                name="net_amount"
                                labelText="Net Income"
                            />
                        </FormGroup>

                        <Navigation
                            disableNext={!isValid}
                            onNext={handleSubmit}
                            onPrevious={() => history.push(previousStep.path)}
                        />
                    </section>
                </form>
            )}
        </Formik>
    );
}

enableDotAmountCheck();
enableMinAmountCheck();
enableMaxAmountCheck();

const inputValidate = Yup.object().shape({
    income: Yup.string()
        .required('Please enter monthly gross income')
        .onlyDot('Monthly gross income is not valid')
        .minAmount(MIN_AMOUNT, 'Monthly gross income is not valid')
        .maxAmount(MAX_AMOUNT, `Maximum gross income can be ${MAX_AMOUNT_US}`),
    net_amount: Yup.string()
        .required('Please enter net income')
        .onlyDot('Net income is not valid')
        .minAmount(MIN_AMOUNT, 'Net income is not valid')
        .maxAmount(MAX_AMOUNT, `Maximum net income can be ${MAX_AMOUNT_US}`),
});

export default ManualIncome;
