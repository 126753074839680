import { Decision } from 'screens/Helper';
import { contextActionTypes } from 'contexts';

function SpouseContribute() {
    return (
        <Decision
            apiKey={'spouses_contribution_to_household_income'}
            contextKey={contextActionTypes.SPOUSE_CONTRIBUTE_HOUSEHOLD}
        >
            <h1>
                Does your spouse add to your{' '}
                <span className="color-blue">household income?</span>
            </h1>
        </Decision>
    );
}

export default SpouseContribute;
