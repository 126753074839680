import { useEffect, useState, useContext, useMemo } from 'react';
import { FormGroup } from 'carbon-components-react';
import { PersonalInfoContext, contextActionTypes } from 'contexts';
import { Input, FormattedInput, TermsCheckbox } from 'components/Form';
import { useStepController, useModal } from 'hooks';
import Modal from 'components/Modal';
import api, { endpoints } from 'api';
import { history } from 'routes';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    enableDateFormatMethod,
    enableZipCodeValidateMethod,
    enableFutuerDateMethod,
    enablePhoneValidateMethod,
    enableMinDateCheck,
} from '../../lib/yupValidMethods';
import { AddressBlock } from 'components/Utilities';
import {
    STATUS_MARRIED_SEPARATELY,
    STATUS_WIDOW_WITH_DEPENDENT,
    DATE_PATTERN,
    DATE_FORMAT,
    MAX_NAME_CHARS,
    MIN_NAME_CHARS,
    MIN_ADDRESS_CHARS,
    MAX_ADDRESS_CHARS,
    MIN_DATE,
    EMAIL_REGEX,
} from 'const';
import Terms from './Terms';
import AlertTerms from './AlertTerms';
import Navigation from 'components/Navigation';
import { isArrayEmpty, removeCountryCodeFromPhone } from 'lib';

const initialValues = {
    first_name: '',
    last_name: '',
    dob: '',
    addresses: [
        {
            line_1: '',
            line_2: '',
            zip_code: '',
            city: '',
            state: '',
        },
    ],
    ssn: '',
    email: '',
    home_phone: '',
    phone: '',
    agree_terms: true,
    call_credit_report: true,
};

const { CHANGE_SPOUSE_DETAIL, STORE_SPOUSE_INFO } = contextActionTypes;

const filingStatusValidation = (filingStatus, spouseDetail) => {
    switch (filingStatus) {
        case STATUS_MARRIED_SEPARATELY:
            return spouseDetail ? validation : validationBasic;

        case STATUS_WIDOW_WITH_DEPENDENT:
            return validationBasic;

        default:
            return validation;
    }
};

function SpouseInformation() {
    const [error, setError] = useState('');
    const [errorModal, setErrorModal] = useState(false);
    const [addressModal, setAddressModal] = useState(false);
    const [initialProfile, updateInitialProfile] = useState(initialValues);
    const [open, showAgreeAlert, hideAgreeAlert] = useModal();
    const [modalOpen, showModal, hideModal] = useModal();
    // Patch to fix Formik doesn't update validation on validation prop change
    const [formKey, updateFormKey] = useState(0);

    const {
        spouseInfo,
        dispatchPersonal,
        spouseUid,
        filingStatus,
        spouseDetail,
    } = useContext(PersonalInfoContext);
    const { nextStep, previousStep } = useStepController();

    useEffect(() => {
        updateSpouseInfo(spouseInfo);
    }, [spouseInfo]);

    const updateSpouseInfo = (values) => {
        const {
            first_name,
            last_name,
            ssn,
            addresses,
            dob,
            email,
            home_phone,
            phone,
        } = values;

        updateInitialProfile({
            first_name,
            last_name,
            ssn,
            dob,
            addresses: isArrayEmpty(addresses)
                ? addresses
                : [
                      {
                          line_1: addresses[0].line_1,
                          line_2: addresses[0].line_2,
                          zip_code: addresses[0].zip_code,
                          city: addresses[0].city,
                          state: addresses[0].state,
                      },
                  ],
            email,
            home_phone: home_phone
                ? removeCountryCodeFromPhone(home_phone)
                : '',
            phone: phone ? removeCountryCodeFromPhone(phone) : '',
            agree_terms: true,
            call_credit_report: true,
        });
    };

    /* 
        Check spouse detail is true for Married Filing Separately. 
        Needed if client change spouse address and then spouse detail become true! 
    */
    const reCheckSpouseDetail = async () => {
        if (filingStatus === STATUS_MARRIED_SEPARATELY) {
            try {
                const req = await api.get(endpoints.FILING_STATUS);
                const res = await req.data;

                if (res) {
                    dispatchPersonal({
                        type: CHANGE_SPOUSE_DETAIL,
                        payload: res['spouse_detail'],
                    });

                    history.push(nextStep.path);
                }
            } catch (e) {
                console.log(e);
            }
        } else {
            history.push(nextStep.path);
        }
    };

    const saveSpouseInformation = async (values) => {
        updateSpouseInfo(values);
        spouseInfoApiUpdate(values);
    };

    const spouseInfoApiUpdate = async (values, data) => {
        if (data === 'addressConfirm') {
            values['call_credit_report'] = false;
        }
        if (!values.agree_terms) {
            showAgreeAlert();
            return;
        }
        // Remove date of birth and address, when they are not avaiable.
        // Only for Married Filing Separately or Qualifying widow with dependent.
        if (
            filingStatus === STATUS_MARRIED_SEPARATELY ||
            filingStatus === STATUS_WIDOW_WITH_DEPENDENT
        ) {
            const { dob, addresses } = values;
            const address = isArrayEmpty(addresses) ? {} : addresses[0];

            if (!dob) values.dob = null;
            if (!address.line_1) values.addresses = null;
        }

        try {
            const data = { ...values, relation: 'spouse' };
            const url = endpoints.PERSONAL_PROFILE;

            const req = await api({
                method: !spouseUid ? 'post' : 'patch',
                url: spouseUid ? `${url}${spouseUid}/` : url,
                data,
                disableBadReqGlobalError: true,
            });

            const res = await req.data;

            if (res) {
                dispatchPersonal({
                    type: STORE_SPOUSE_INFO,
                    payload: res,
                });

                reCheckSpouseDetail();
            }
        } catch (e) {
            if (e.response.data.experian) {
                const errorData = JSON.parse(e.response.data.experian);
                if (errorData.errors[0].message.indexOf('address') !== -1) {
                    setAddressModal(true);
                } else {
                    // string replacement done because experian provide wrong params for first & last mane
                    const conditionsKey = ['surname', 'lastName'];
                    const keyExist = conditionsKey.some((data) =>
                        errorData.errors[0].message.split(' ').includes(data)
                    );
                    let replaceText;
                    keyExist
                        ? (replaceText = 'firstName')
                        : (replaceText = 'lastName');
                    setError(
                        errorData.errors[0].message
                            .replace('lastName', replaceText)
                            .replace('surname', replaceText)
                            .replace('firstName', replaceText)
                    );
                    setErrorModal(true);
                }
            } else {
                setError(e.response.data.detail.errors[0].message);
                setErrorModal(true);
            }
            showModal();
        }
    };

    const addressConfirmation = async () => {
        spouseInfoApiUpdate(initialProfile, 'addressConfirm');
    };

    const errorMessage = (
        <Modal
            open={modalOpen}
            onClose={hideModal}
            modalHeading="Error Message"
            singleButton
        >
            <p>{error}</p>
        </Modal>
    );

    const addressConfirmationModal = (
        <Modal
            open={modalOpen}
            onClose={hideModal}
            onSave={addressConfirmation}
            modalHeading="We weren't able to verify your home address. Is the address correct?"
            primaryButtonText="Yes"
        >
            <p>Would you like to use this address anyway</p>
        </Modal>
    );

    const formValidation = useMemo(() => {
        updateFormKey((key) => key + 1);
        return filingStatusValidation(filingStatus, spouseDetail);
    }, [filingStatus, spouseDetail]);

    return (
        <Formik
            initialValues={initialProfile}
            onSubmit={saveSpouseInformation}
            validationSchema={formValidation}
            enableReinitialize={true}
            validateOnMount
            validateOnChange
            key={formKey}
        >
            {({ handleSubmit, isValid, values }) => (
                <form>
                    <h1>
                        Please Verify your{' '}
                        <span className="color-blue">Spouse information</span>{' '}
                    </h1>
                    <p>
                        Please take a moment to verify your spouse’s information
                        for completeness and accuracy.
                    </p>

                    {errorModal && errorMessage}
                    {addressModal && addressConfirmationModal}

                    <section className="py-24">
                        <h2 className="mb-16">Spouse Information</h2>
                        <FormGroup legendText="">
                            <Input
                                id="f-name"
                                name="first_name"
                                labelText="Spouse's First Name"
                                placeholder=""
                            />
                        </FormGroup>

                        <FormGroup legendText="">
                            <Input
                                id="l-name"
                                name="last_name"
                                labelText="Spouse's Last Name"
                                placeholder=""
                            />
                        </FormGroup>

                        <FormGroup legendText="">
                            <FormattedInput
                                labelText={'Date of Birth'}
                                formatOptions={{
                                    date: true,
                                    delimiter: '-',
                                    dateMin: MIN_DATE,
                                    datePattern: DATE_PATTERN,
                                }}
                                name="dob"
                                placeholder={DATE_FORMAT}
                            />
                        </FormGroup>

                        <AddressBlock
                            name={{
                                address: 'addresses[0].line_1',
                                address2: 'addresses[0].line_2',
                                city: 'addresses[0].city',
                                state: 'addresses[0].state',
                                zipCode: 'addresses[0].zip_code',
                            }}
                            label={{
                                address: "Spouse's Address",
                                address2: 'Address 2',
                                city: 'City',
                                state: 'State',
                                zipCode: 'Zip Code',
                            }}
                        />

                        <FormGroup legendText="">
                            <FormattedInput
                                id="ssn"
                                name="ssn"
                                labelText="SSN"
                                formatOptions={{
                                    delimiter: '-',
                                    blocks: [3, 2, 4],
                                }}
                            />
                        </FormGroup>
                    </section>

                    <h2 className="mb-16">Spouse Contact Information</h2>

                    <section>
                        <FormGroup legendText="">
                            <Input
                                id="email"
                                name="email"
                                type="email"
                                labelText="Email"
                                placeholder=""
                            />
                        </FormGroup>

                        <FormGroup legendText="">
                            <Input
                                id="phone"
                                name="phone"
                                labelText="Mobile Phone"
                                placeholder=""
                                addon="+1"
                            />
                        </FormGroup>

                        <FormGroup legendText="">
                            <Input
                                id="home_phone"
                                name="home_phone"
                                labelText="Home Phone"
                                placeholder=""
                                addon="+1"
                            />
                        </FormGroup>
                    </section>

                    <TermsCheckbox
                        name={'agree_terms'}
                        id={'agree'}
                        terms={
                            <Terms
                                first_name={values.first_name}
                                last_name={values.last_name}
                            />
                        }
                        className={'mb-55'}
                    />

                    <Navigation
                        onNext={handleSubmit}
                        onPrevious={() => history.push(previousStep.path)}
                        disableNext={!isValid}
                        nextText="Continue"
                    />

                    <AlertTerms open={open} hide={hideAgreeAlert} />
                </form>
            )}
        </Formik>
    );
}

// Add dateFormat custom validation
enableDateFormatMethod();
enableFutuerDateMethod();
enableMinDateCheck();
// Add zip code custom validation
enableZipCodeValidateMethod();
enablePhoneValidateMethod();

const validation = Yup.object().shape({
    first_name: Yup.string()
        .min(MIN_NAME_CHARS, 'Minimum 1 characters')
        .max(MAX_NAME_CHARS, 'Maximum 30 characters')
        .required('Required field'),
    last_name: Yup.string()
        .min(MIN_NAME_CHARS, 'Minimum 1 characters')
        .max(MAX_NAME_CHARS, 'Maximum 30 characters')
        .required('Required field'),
    dob: Yup.string()
        .nullable()
        .required('Required field')
        .dateFormat()
        .minDate()
        .futureDate(),
    addresses: Yup.array().of(
        Yup.object().shape({
            line_1: Yup.string()
                .required('Required field')
                .min(MIN_ADDRESS_CHARS, 'Minimum 1 characters')
                .max(MAX_ADDRESS_CHARS, 'Maximum 35 characters'),
            line_2: Yup.string()
                .nullable()
                .min(MIN_ADDRESS_CHARS, 'Minimum 1 characters')
                .max(MAX_ADDRESS_CHARS, 'Maximum 35 characters'),
            city: Yup.string().required('Required field'),
            state: Yup.string().required('Required field'),
            zip_code: Yup.string().required('Required field').zipCodeValidate(),
        })
    ),
    ssn: Yup.string()
        .matches(/^(\d{3}-\d{2}|\*{3}-\*{2})-\d{4}$/, 'Wrong SSN format')
        .required('Required field'),
    email: Yup.string()
        .matches(EMAIL_REGEX, {
            excludeEmptyString: true,
            message: 'Invalid Email',
        })
        .required('Required field'),
    phone: Yup.string().phone(),
    home_phone: Yup.string().phone(),
});

// Address, ssn, date of birth are not required when married filing separately
const validationBasic = Yup.object().shape({
    first_name: Yup.string()
        .min(MIN_NAME_CHARS, 'Minimum 1 characters')
        .max(MAX_NAME_CHARS, 'Maximum 30 characters')
        .required('Required field'),
    last_name: Yup.string()
        .min(MIN_NAME_CHARS, 'Minimum 1 characters')
        .max(MAX_NAME_CHARS, 'Maximum 30 characters')
        .required('Required field'),
    dob: Yup.string().nullable().dateFormat().futureDate().minDate(),
    addresses: Yup.array().of(
        Yup.object().shape({
            line_1: Yup.string()
                .nullable()
                .min(MIN_ADDRESS_CHARS, 'Minimum 1 characters')
                .max(MAX_ADDRESS_CHARS, 'Maximum 35 characters'),
            line_2: Yup.string()
                .nullable()
                .min(MIN_ADDRESS_CHARS, 'Minimum 1 characters')
                .max(MAX_ADDRESS_CHARS, 'Maximum 35 characters'),
            city: Yup.string().nullable(),
            state: Yup.string().nullable(),
            zip_code: Yup.string().nullable(),
        })
    ),
    ssn: Yup.string()
        .matches(/^(\d{3}-\d{2}|\*{3}-\*{2})-\d{4}$/, 'Wrong SSN format')
        .nullable(),
    email: Yup.string()
        .matches(EMAIL_REGEX, {
            excludeEmptyString: true,
            message: 'Invalid Email',
        })
        .nullable(),
    phone: Yup.string().phone().nullable(),
    home_phone: Yup.string().phone().nullable(),
});

export default SpouseInformation;
