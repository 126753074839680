import { EmploymentPhoto } from 'assets';
import Navigation from 'components/Navigation';
import WelcomeWrapper from './WelcomeWrapperStyled';
import { useStepController } from 'hooks';
import { history } from 'routes';

function EmploymentWelcome() {
    const { nextStep, previousStep } = useStepController();
    return (
        <WelcomeWrapper>
            <h1>
                Let’s verify your{' '}
                <span className="color-blue">
                    employment, income & expense information.
                </span>
            </h1>
            <img src={EmploymentPhoto} alt="Employment" className="my-30" />
            <h2>Why do we need this?</h2>
            <p>
                By connecting directly to your accounts, we are able to substantiate
                your monthly income to the IRS and provide the proof of source of any
                additional monies
            </p>

            <Navigation
                onNext={() => history.push(nextStep.path)}
                onPrevious={() => history.push(previousStep.path)}
            />
        </WelcomeWrapper>
    );
}

export default EmploymentWelcome;
