import styled from 'styled-components';
import { APP_WIDTH } from 'config/styles.config';
/* import { history } from 'routes'
import {
    HOUSEHOLD_INFORMATION,
    PERSONAL_CONTACT_VERIFICATION,
    PERSONAL_FILING_INFO,
    PERSONAL_FILING_SELECT,
    PERSONAL_INFORMATION
} from "../../routes/paths";*/

// Wrapper for App
function Wrapper({ children }) {
    /* const [loading, setLoading] = useState(false);

    useEffect(() => {
       setTimeout(() => {
           history.push(PERSONAL_INFORMATION);
           history.push(PERSONAL_FILING_SELECT);
           history.push(PERSONAL_FILING_INFO);
           history.push(PERSONAL_CONTACT_VERIFICATION);
           history.push(HOUSEHOLD_INFORMATION);
           setLoading(true);
       }, 3000);
    }, [])*/

    return (
        <WrapperSection>
            {/* {!loading ? "Please wait" : children }*/}
            {children}
        </WrapperSection>
    );
}

const WrapperSection = styled.section`
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: ${APP_WIDTH};
    padding: 0 32px 40px;
    position: relative;

    @media (max-width: 767px) {
        padding-bottom: 100px;
        padding-top: 10px;
        min-height: 100vh;
    }
`;

export default Wrapper;
