import {
    ContentSwitcher,
    Switch as CarbonSwitch,
} from 'carbon-components-react';
import styled from 'styled-components';
import PropType from 'prop-types';

function Switch(props) {
    const { yesText, noText, onChange, choice, className, style } = props;

    return (
        <Switcher
            onChange={onChange}
            selectedIndex={choice}
            className={className}
            style={style}
        >
            <CarbonSwitch name={'yes'} text={yesText} />
            <CarbonSwitch name={'no'} text={noText} />
        </Switcher>
    );
}

Switch.defaultProps = {
    yesText: 'Yes',
    noText: 'No',
    onChange: () => {},
    choice: 3,
};

Switch.propTypes = {
    yesText: PropType.string,
    noText: PropType.string,
    onChange: PropType.func,
    choice: PropType.number,
    className: PropType.string,
    style: PropType.object,
};

const Switcher = styled(ContentSwitcher)`
    width: 100%;
    .bx--content-switcher-btn {
        border-radius: 0;
        border: 1px solid #008ac8;
        color: #0f212e;
        background-color: #ebf2f7;

        &:first-child {
            border-right: 0px;
        }
        &.bx--content-switcher--selected {
            color: #fff;
            background-color: #008ac8;
        }
    }
`;
export default Switch;
