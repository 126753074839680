import { useContext } from 'react';
import styled from 'styled-components';
import { MetaContext } from '../../contexts';
import { Logo as LogoPath } from 'assets';

// App header
function Header() {
    const {
        meta: { pageTitle },
    } = useContext(MetaContext);

    return (
        <Wrapper>
            <Logo src={LogoPath} alt="Verifilink logo" />
            <Text>{pageTitle}</Text>
        </Wrapper>
    );
}

const Wrapper = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 12px;
    flex-wrap: wrap;
`;

const Logo = styled.img`
    height: 20px;
    border-right: 1px solid #c2c2c2;
    padding-right: 5px;
    margin-right: 8px;

    @media (max-width: 767px) {
        height: 15px;
    }
`;

const Text = styled.span`
    font-size: 1rem;
    margin-top: 2px;
    color: #0f212e;
    font-weight: 600;

    @media (max-width: 767px) {
        font-size: 11px;
        line-height: 20px;
    }
`;

export default Header;
