import { FormGroup } from 'carbon-components-react';
import { Close24 } from '@carbon/icons-react';
import {
    Input,
    FormattedInput,
    Select,
    Checkbox,
} from '../../components/Form/index';
import styled from 'styled-components';
import { DATE_PATTERN, DATE_FORMAT, MIN_DATE, DEPENDENT_TAX_RETURN_YEARS } from 'const';
import Switch from "components/Form/SwitchInput";

const NameLabel = ({ index }) => (
    <span>
        Name <span className="color-blue">({parseInt(index, 10) + 1})</span>
    </span>
);


function Dependent(props) {
    const { index, relations, onClear } = props;
    const has_claim_on_tax_return = props.values.deps[index].has_claim_on_tax_return;
    return (
        <DepStyled key={index}>
            <Close onClick={onClear} />

            <HiddenInput name={`deps[${index}].uid`} disabled labelText="" />

            <FormGroup legendText={''}>
                <Input
                    id={`name-${index}`} // Unique id (id is required by component)
                    labelText={<NameLabel index={index} />}
                    name={`deps[${index}].name`}
                />
            </FormGroup>

            <div className="bx--grid bx--no-gutter">
                <div className="bx--row">
                    <div className="bx--col" style={{ paddingRight: 4 }}>
                        <FormGroup legendText={''}>
                            <FormattedInput
                                labelText={'Date of Birth'}
                                formatOptions={{
                                    date: true,
                                    delimiter: '-',
                                    dateMin: MIN_DATE,
                                    datePattern: DATE_PATTERN,
                                }}
                                name={`deps[${index}].dob`}
                                placeholder={DATE_FORMAT}
                            />
                        </FormGroup>
                    </div>
                    <div className="bx--col" style={{ paddingLeft: 4 }}>
                        <FormGroup legendText={''}>
                            <Select
                                id={`relation-${index}`} // Unique id (id is required by component)
                                items={relations}
                                labelText={'Relationship'}
                                name={`deps[${index}].relationship`}
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <Checkbox
                id={`contributes-${index}`}
                name={`deps[${index}].contributes`}
                labelText={'Contributes To Household Income'}
            />

            <br />
            <br />
            <h6>
                Do you claim this dependent on your Tax Returns?
            </h6>
            <br />
            <Switch
                yesText="Yes"
                noText="No"
                name={`deps[${index}].has_claim_on_tax_return`}
            />
            <br />
            {
                has_claim_on_tax_return ? <FormGroup legendText="">
                    <Select
                        id={`deps[${index}].claim_on_tax_year`}
                        name={`deps[${index}].claim_on_tax_year`}
                        labelText="Select Year"
                        items={DEPENDENT_TAX_RETURN_YEARS}
                    />
                </FormGroup> : ''
            }

            <br />
        </DepStyled>
    );
}

const DepStyled = styled.section`
    border: 1px solid #d8e5f0;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 12px;
    position: relative;
`;

const HiddenInput = styled(Input)`
    height: 1px;
    overflow: hidden;
    width: 1px;
    display: none;
`;

const Close = styled(Close24)`
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 8px;
    color: #161616;
    z-index: 9;
`;

DepStyled.displayName = 'DepStyled';
Close.displayName = 'Close';

export default Dependent;
