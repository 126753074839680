import { useEffect, useState } from 'react';
import styled from 'styled-components';

function TimeCount(props) {
    const [counter, setCounter] = useState(props.counter);

    useEffect(() => {
        let timer;
        if (counter > 0) {
            timer = setTimeout(() => setCounter(counter - 1), 1000);
        } else {
            if (props.onEnd) props.onEnd();
        }
        return () => {
            clearTimeout(timer);
        };
    }, [counter, props]);

    return <Time>{counter}s</Time>;
}

const Time = styled.span`
    color: #0f212e;
`;

export default TimeCount;
