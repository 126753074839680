import {Slide, ToastContainer} from "react-toastify";
import styled from "styled-components";

function NotificationContainer(props) {
    return (
        <Container
            transition={Slide}
            hideProgressBar
        />
    );
}

const Container = styled(ToastContainer)`
  .Toastify__toast{
    padding: 0;
  }
  
  .Toastify__close-button {
    position: absolute;
    right: 10px;
    top: 10px;
  }
`;

export default NotificationContainer;