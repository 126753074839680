import {
    ASSET_INVESTMENT_VERIFICATION,
    ASSET_INVESTMENT_FINANCED_PROPERTIES,
    ASSET_INVESTMENT_ADDITIONAL_PROPERTIES,
    ASSET_INVESTMENT_FINANCED_VEHICLES,
    ASSET_INVESTMENT_ADDITIONAL_VEHICLES,
    ASSET_INVESTMENT_PROPERTY_INFO,
    ASSET_INVESTMENT_VEHICLE_INFO,
    SPOUSE_ASSETS,
    SPOUSE_ASSETS_INFO,
    SPOUSE_FINANCED_PROPERTIES,
    SPOUSE_PROPERTY_INFO,
    SPOUSE_ADDITIONAL_PROPERTIES,
    SPOUSE_FINANCED_VEHICLES,
    SPOUSE_VEHICLE_INFO,
    SPOUSE_ADDITIONAL_VEHICLES,
    ASSET_INVESTMENT,
    ASSET_INVESTMENT_ADD,
    SPOUSE_ASSET_INVESTMENT,
    SPOUSE_ASSET_INVESTMENT_ADD,
} from '../../routes/paths';
import { STATUS_MARRIED, STATUS_MARRIED_SEPARATELY } from '../../const';

const checkFinanced = (type, investments, profile) => {
    if (!investments.length) return [];

    switch (type) {
        case 'VEHICLES': {
            return investments.map((inv) => ({
                path: profile
                    ? `${SPOUSE_FINANCED_VEHICLES}${inv.uid}/`
                    : `${ASSET_INVESTMENT_FINANCED_VEHICLES}${inv.uid}/`,
                title: 'Retirement & Investment',
            }));
        }
        // default is Properties
        default: {
            return investments.map((inv) => ({
                path: profile
                    ? `${SPOUSE_FINANCED_PROPERTIES}${inv.uid}/`
                    : `${ASSET_INVESTMENT_FINANCED_PROPERTIES}${inv.uid}/`,
                title: 'Retirement & Investment',
            }));
        }
    }
};

const checkAdditionalInvestment = (type, hasAdditional, profile) => {
    switch (type) {
        case 'VEHICLES': {
            const additional = [
                {
                    path: profile
                        ? SPOUSE_ADDITIONAL_VEHICLES
                        : ASSET_INVESTMENT_ADDITIONAL_VEHICLES,
                    title: 'Retirement & Investment',
                },
            ];

            if (hasAdditional) {
                additional.push({
                    path: profile
                        ? SPOUSE_VEHICLE_INFO
                        : ASSET_INVESTMENT_VEHICLE_INFO,
                    title: 'Retirement & Investment',
                });
            }

            return additional;
        }
        default: {
            const additional = [
                {
                    path: profile
                        ? SPOUSE_PROPERTY_INFO
                        : ASSET_INVESTMENT_PROPERTY_INFO,
                    title: 'Retirement & Investment',
                },
            ];

            if (hasAdditional) {
                additional.push({
                    path: profile
                        ? SPOUSE_ADDITIONAL_PROPERTIES
                        : ASSET_INVESTMENT_ADDITIONAL_PROPERTIES,
                    title: 'Retirement & Investment',
                });
            }

            return additional;
        }
    }
};

const checkInvestments = (hasInvestment, profile) => {
    const investmentStep = [
        {
            path: profile ? SPOUSE_ASSET_INVESTMENT : ASSET_INVESTMENT,
            title: 'Retirement & Investment',
        },
    ];

    if (hasInvestment) {
        investmentStep.push({
            path: profile ? SPOUSE_ASSET_INVESTMENT_ADD : ASSET_INVESTMENT_ADD,
            title: 'Retirement & Investment',
        });
    }

    return investmentStep;
};

const assetInvestmentSteps = (profile) => {
    const {
        filingStatus,
        spouseUid,
        investments,
        spouseDetail,
        additionalInfo,
    } = profile;

    const {
        properties_financed,
        vehicles_financed,
        properties_financed_spouse,
        vehicles_financed_spouse,
        has_additional_property,
        has_additional_vehicle,
        has_additional_property_spouse,
        has_additional_vehicle_spouse,
    } = investments;

    const {
        spouse_contribute_household,
        has_spouses_asset_investment,
        provide_spouses_asset_investment_info,
        has_investments,
        spouse,
    } = additionalInfo;

    const steps = [
        {
            path: ASSET_INVESTMENT_VERIFICATION,
            title: 'Retirement & Investment',
        },
        ...checkFinanced('PROPERTIES', properties_financed),
        ...checkAdditionalInvestment('PROPERTIES', has_additional_property),
        ...checkFinanced('VEHICLES', vehicles_financed),
        ...checkAdditionalInvestment('VEHICLES', has_additional_vehicle),
        ...checkInvestments(has_investments),
    ];

    // First check: Married or Married Separately but spouse detail is needed
    if (
        filingStatus === STATUS_MARRIED ||
        (filingStatus === STATUS_MARRIED_SEPARATELY && spouseDetail)
    ) {
        // Second check: Spouse contribute to household income
        if (spouse_contribute_household) {
            steps.push({
                path: SPOUSE_ASSETS,
                title: 'Retirement & Investment',
            });

            // Third check: Spouse has asset and investment in their name
            if (has_spouses_asset_investment) {
                steps.push({
                    path: SPOUSE_ASSETS_INFO,
                    title: 'Retirement & Investment',
                });

                // Fourth check: Client able to provide spouse asset and investment info
                if (provide_spouses_asset_investment_info) {
                    const spouseAssetSteps = [
                        ...checkFinanced(
                            'PROPERTIES',
                            properties_financed_spouse,
                            spouseUid
                        ),
                        ...checkAdditionalInvestment(
                            'PROPERTIES',
                            has_additional_property_spouse,
                            spouseUid
                        ),
                        ...checkFinanced(
                            'VEHICLES',
                            vehicles_financed_spouse,
                            spouseUid
                        ),
                        ...checkAdditionalInvestment(
                            'VEHICLES',
                            has_additional_vehicle_spouse,
                            spouseUid
                        ),
                        ...checkInvestments(spouse.has_investments, spouseUid),
                    ];

                    steps.push(...spouseAssetSteps);
                }
            }
        }
    }

    return steps;
};

export default assetInvestmentSteps;
