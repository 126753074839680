import styled from "styled-components";

function DesktopWrapper(props) {
    return (
        <Wrapper>
            {props.children}
        </Wrapper>
    );
}

const Wrapper = styled.section`
  margin-top: 32px;
  padding: 60px 40px;
  border: 1px solid #BDBDBD;
  border-radius: 10px;
  
  @media (max-width: 767px){
    border: none;
    padding: 0;
  }
`;

export default DesktopWrapper;