import Styled from 'styled-components';
import { convertDateToString } from 'lib';
import placeholder_icon from '../../assets/images/bank/placeholder.png';

const baseURL = `${process.env['REACT_APP_API_PROTOCOL']}${process.env['REACT_APP_API_ENDPOINT']}`;

function TransactionLists(props) {
    const { amount, description, from_date, to_date, bank_icon, bank_name, acc_numbers} = props;
    const fromDate = convertDateToString(from_date);
    const toDate = convertDateToString(to_date);
    const bank_image_url = bank_icon ? (baseURL + bank_icon) : placeholder_icon;
    return (
        <Wrapper>
            <span>
                <h1>${amount} - <span className="date">({fromDate}-{toDate})</span></h1>
                <p className="description">
                    <span>{description}</span>
                </p>
                <p className="bank-name">
                    <span>{bank_name}</span>
                </p>
                {acc_numbers.map((acc, id) => {
                    return (
                        <p key={id}>{acc}</p>
                    );
                })}
            </span>
            <span className="img-wrapper">
                <img src={bank_image_url} alt="TaxRise" />
            </span>
        </Wrapper>
    );
}

const Wrapper = Styled.div`
    width: 90%;
    img {
        height: 42px;
        width: 42px;
        margin-bottom: 40px;
    }    
    .img-wrapper {
        position: absolute;
        top: 0;
        right: 0;
    }
    h1 {
        text-align: left;
        font: normal normal 600 14px/21px Poppins;
        letter-spacing: 0px;
        color: #008AC8;
        opacity: 1;
    }
    .description {
        text-align: left;
        font: normal normal medium 14px/21px Poppins;
        letter-spacing: 0px;
        color: #7EA0C7;
        opacity: 1;
        && > span {
           word-break: break-word;
        }
    }
    .bank-name {
        text-align: left;
        font: normal normal medium 14px/21px Poppins;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
    }   
    .date {
        color:#6A7581
    }         
`;
export default TransactionLists;
