import { useMemo } from 'react';
import { Select, SelectItem } from 'carbon-components-react';
import styled from 'styled-components';

function SelectCustom(props) {
    const { items, placeholder } = props;

    const renderSelectItems = useMemo(() => {
        return items.map((item, key) => (
            <SelectItem
                key={key}
                value={item.value}
                text={item.label}
                disabled={item.disabled}
            />
        ));
    }, [items]);

    return (
        <SelectStyled {...props}>
            <SelectItem value={'default'} text={placeholder || 'Select'} />
            {renderSelectItems}
        </SelectStyled>
    );
}

const SelectStyled = styled(Select)`
    && {
        width: 100%;

        .bx--select-input__wrapper {
            width: 100%;

            .bx--select-input {
                max-width: none;
                width: 100%;
            }
        }
    }
`;

export default SelectCustom;
