import { Fragment, useState, useEffect, useContext } from 'react';
import { Checkbox } from 'carbon-components-react';
import styled from 'styled-components';
import api, { endpoints } from 'api';
import { history } from 'routes';
import { PersonalInfoContext, contextActionTypes } from '../../../contexts';
import { TransactionLists } from 'components/ListItems';
import { INCOME_TYPE } from '../../../const';
import { useStepController } from '../../../hooks';
import Navigation from 'components/Navigation';
import { isArrayEmpty } from 'lib';

const { SELECTED_DEPOSITS, SPOUSE_SELECTED_DEPOSITS } = contextActionTypes;

function CalculateNetIncome({ spouse }) {
    const [disabled, setDisabled] = useState();
    const [transactions, setTransactions] = useState([]);
    const {
        personalUid,
        spouseUid,
        employment,
        dispatchEmployment,
    } = useContext(PersonalInfoContext);
    const { nextStep, previousStep } = useStepController();
    const { clientEmployerName, spouseEmployerName } = employment;

    useEffect(() => {
        const transactionList = async () => {
            try {
                const req = await api.get(
                    `${endpoints.AVAILABLE_DEPOSIT}${
                        spouse ? spouseUid : personalUid
                    }/`,
                    {
                        params: {
                            inc_type: INCOME_TYPE.employer,
                            allow_null: INCOME_TYPE.allow_null,
                        },
                    }
                );
                const res = await req.data;
                if (res) {
                    setTransactions(res);
                }
            } catch (e) {
                console.log(e.response);
            }
        };
        if ((spouse && spouseUid) || (!spouse && personalUid)) {
            transactionList();
            setDisabled(false);
        }
    }, [personalUid, spouseUid, spouse]);

    // Update context for updating step
    useEffect(() => {
        const selectedTransactions = [];

        transactions.forEach(function (item) {
            if (item.status === 'in_progress') {
                selectedTransactions.push(item.uid);
            }
        });

        dispatchEmployment({
            type: spouse ? SPOUSE_SELECTED_DEPOSITS : SELECTED_DEPOSITS,
            payload: !isArrayEmpty(selectedTransactions),
        });
    }, [transactions, dispatchEmployment, spouse]);

    const transactionHandle = (value, id, ev) => {
        const currentTransactions = [...transactions];

        if (ev.target.checked) {
            currentTransactions.forEach(function (item) {
                if (item.id === id) {
                    item.status = 'in_progress';
                }
            });
        } else {
            currentTransactions.forEach(function (item) {
                if (item.id === id) {
                    item.status = 'draft';
                    item.verified = false;
                }
            });
        }

        setTransactions(currentTransactions);
    };

    const updateDeposit = async () => {
        const params = {
            income_type: 1,
            uid_list: [],
        };

        transactions.forEach(function (item) {
            if (item.status === 'in_progress') {
                params.uid_list.push(item.uid);
            }
        });

        const req = await api.post(
            `${endpoints.AVAILABLE_DEPOSIT}${
                spouse ? spouseUid : personalUid
            }/`,
            params
        );
        const res = await req.data;
        if (res) {
            history.push(nextStep.path);
        } else {
            console.log('error');
        }
    };

    return (
        <Fragment>
            <h1>
                Please select all direct deposits from{' '}
                {spouse ? (
                    <span className="color-blue">{spouseEmployerName}</span>
                ) : (
                    <span className="color-blue">{clientEmployerName}</span>
                )}{' '}
                from the list below.
            </h1>
            <p>If there are no matches, please select next.</p>

            <section className="mt-32">
                {transactions.map((dataItem, key) => {
                    const {
                        normalized_group_name,
                        id,
                        amount,
                        status,
                        from_date,
                        to_date,
                        bank_icon,
                        bank_name,
                        acc_numbers,
                    } = dataItem;
                    return (
                        <fieldset className="bx--fieldset" key={key}>
                            <CheckboxWrapper
                                labelText={
                                    <TransactionLists
                                        description={normalized_group_name}
                                        amount={amount}
                                        bank_icon={bank_icon}
                                        bank_name={bank_name}
                                        from_date={from_date}
                                        to_date={to_date}
                                        acc_numbers={acc_numbers}
                                    />
                                }
                                id={id}
                                value={status}
                                defaultChecked={
                                    status === 'verified' ||
                                    status === 'in_progress'
                                }
                                onChange={transactionHandle}
                            />
                        </fieldset>
                    );
                })}
            </section>

            <Navigation
                onNext={updateDeposit}
                onPrevious={() => history.push(previousStep.path)}
                disableNext={disabled}
            />
        </Fragment>
    );
}

const CheckboxWrapper = styled(Checkbox)`
    && {
        &.bx--checkbox-label {
            width: 100% !important;
            .bx--checkbox-label-text {
                width: 100% !important;
            }
        }
    }
`;

export default CalculateNetIncome;
