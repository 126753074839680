import Cleave from 'cleave.js/react';
import { useField } from 'formik';

function FormattedInput(props) {
    const { labelText, formatOptions, name, placeholder, className } = props;
    const [field, meta] = useField(name);
    const { value, onChange, onBlur } = field;
    const { error, touched } = meta;

    const classNameHandler = `${error && touched ? 'bx--text-input--invalid' : ''
        } ${className || ''}`;
    return (
        <div className="bx--form-item bx--text-input-wrapper">
            <label htmlFor="name-0" className="bx--label">
                {labelText}
            </label>
            <div className="bx--text-input__field-wrapper">
                {error && touched ? <InvalidSign /> : null}
                <Cleave
                    name={name}
                    value={value || ''}
                    placeholder={placeholder}
                    options={formatOptions}
                    onChange={onChange}
                    onBlur={onBlur}
                    className={`bx--text-input bx--text__input ${classNameHandler}`}
                    data-hj-allow
                    disabled={props.disabled}
                />
            </div>
            {error && touched ? <ErrorMessage invalidText={error} /> : null}
        </div>
    );
}

const ErrorMessage = ({ invalidText }) => (
    <div className="bx--form-requirement" style={{ maxHeight: 20 }}>
        {invalidText}
    </div>
);

const InvalidSign = () => (
    <svg
        focusable="false"
        preserveAspectRatio="xMidYMid meet"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        aria-hidden="true"
        className="bx--text-input__invalid-icon"
    >
        <path d="M8,1C4.2,1,1,4.2,1,8s3.2,7,7,7s7-3.1,7-7S11.9,1,8,1z M7.5,4h1v5h-1C7.5,9,7.5,4,7.5,4z M8,12.2	c-0.4,0-0.8-0.4-0.8-0.8s0.3-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8S8.4,12.2,8,12.2z"></path>
        <path
            d="M7.5,4h1v5h-1C7.5,9,7.5,4,7.5,4z M8,12.2c-0.4,0-0.8-0.4-0.8-0.8s0.3-0.8,0.8-0.8	c0.4,0,0.8,0.4,0.8,0.8S8.4,12.2,8,12.2z"
            data-icon-path="inner-path"
            opacity="0"
        ></path>
    </svg>
);

export default FormattedInput;
