import { useState, Fragment, useEffect, useContext } from 'react';
import { FormGroup } from 'carbon-components-react';
import api, { endpoints } from 'api';
import { genOptsFromArrOfObjs } from 'lib';
import { history } from 'routes';
import { useStepController, useModal, useAdditionalInfo } from '../../hooks';
import { Formik } from 'formik';
import { Dropdown, FormattedInput } from '../../components/Form';
import * as Yup from 'yup';
import {
    PersonalInfoContext,
    contextActionTypes,
    AdditionalInfoContext,
} from '../../contexts';
import {
    enableDateFormatMethod,
    enableFutuerDateMethod,
    enableMinDateCheck,
} from '../../lib/yupValidMethods';
import {
    STATUS_MARRIED,
    STATUS_MARRIED_SEPARATELY,
    STATUS_WIDOW_WITH_DEPENDENT,
    DATE_PATTERN,
    DATE_FORMAT,
    STATUS_HEAD_OF_HOUSEHOLD,
    MIN_DATE,
} from '../../const';
import Modal from 'components/Modal';
import moment from 'moment';
import Navigation from 'components/Navigation';

const {
    STORE_FILING_STATUS,
    CHANGE_SPOUSE_DETAIL,
    CLAIM_DEPENDENT,
} = contextActionTypes;

const initialValue = {
    filing_status: '',
    marriage_date: '',
    spouse_death_date: '',
};

function FilingStatus() {
    const [filingOptions, updateFilingOptions] = useState([]);
    const [initialFiling, updateInitialFiling] = useState(initialValue);

    const { dispatchPersonal, personalUid } = useContext(PersonalInfoContext);
    const { dispatchAdditionalInfo } = useContext(AdditionalInfoContext);

    const { nextStep, previousStep } = useStepController();
    const [open, showAgreeAlert, hideAgreeAlert] = useModal();
    const { uid } = useAdditionalInfo(personalUid);

    useEffect(() => {
        const fetchFilingOptions = async () => {
            try {
                const req = await api.get(endpoints.FILING_OPTIONS);
                const res = await req.data;
                const options = await genOptsFromArrOfObjs(res);

                updateFilingOptions(options);
            } catch (e) {
                console.log(e.response);
            }
        };

        const getFilingStatus = async () => {
            try {
                const req = await api.get(endpoints.FILING_STATUS);
                const res = await req.data;
                if (res.filing_status) {
                    dispatchPersonal({
                        type: contextActionTypes.STORE_FILING_STATUS,
                        payload: res.filing_status,
                    });
                }

                updateInitialFiling({
                    filing_status: res.filing_status,
                    marriage_date: res.marriage_date || null,
                    spouse_death_date: res.spouse_death_date || null,
                });
            } catch (e) {
                console.log(e);
            }
        };

        fetchFilingOptions();
        getFilingStatus();
    }, [dispatchPersonal]);

    const saveFilingStatus = async (values) => {
        const { filing_status, spouse_death_date } = values;
        const requiredDependent =
            filing_status === STATUS_HEAD_OF_HOUSEHOLD ||
            filing_status === STATUS_WIDOW_WITH_DEPENDENT;

        // Filter values by filing status!
        switch (filing_status) {
            case STATUS_MARRIED:
                values.spouse_death_date = null;
                break;

            case STATUS_MARRIED_SEPARATELY:
                values.spouse_death_date = null;
                if (!values.marriage_date) {
                    values.marriage_date = null;
                }
                break;

            case STATUS_WIDOW_WITH_DEPENDENT:
                values.marriage_date = null;
                break;

            default:
                values.marriage_date = null;
                values.spouse_death_date = null;
                break;
        }

        // Checking eligibility as a Qualifying Widow With Dependent
        if (filing_status === STATUS_WIDOW_WITH_DEPENDENT) {
            // Calculating total years from the year (not date) spouse died
            const duration = moment().diff(
                moment(spouse_death_date).startOf('year'),
                'years'
            );

            // Spouse death date should be greater or equal to two years since now
            if (duration >= 3) {
                showAgreeAlert();
                return;
            }
        }

        // Enable dependent add screen in case previously selected don't claim any dependent
        if (requiredDependent) {
            dispatchAdditionalInfo({
                type: CLAIM_DEPENDENT,
                payload: true,
            });
        }

        try {
            const req = await api.post(endpoints.FILING_STATUS, values);
            const res = await req.data;

            if (res) {
                dispatchPersonal({
                    type: CHANGE_SPOUSE_DETAIL,
                    payload: res['spouse_detail'],
                });

                history.push(nextStep.path);
            }

            if (requiredDependent) {
                await api({
                    method: 'patch',
                    url: `${endpoints.CLIENT_ADDITIONAL_INFO}${uid}/`,
                    data: {
                        profile: personalUid,
                        dependents: true,
                    },
                });
            }
        } catch (e) {
            console.log(e.response);
        }
    };

    const filingChangeHandler = (selectedItem) => {
        // Update Personal Info Context
        dispatchPersonal({
            type: STORE_FILING_STATUS,
            payload: selectedItem,
        });
    };

    const marriageDateField = (
        <FormGroup legendText="">
            <FormattedInput
                labelText={'Marriage Date'}
                formatOptions={{
                    date: true,
                    delimiter: '-',
                    dateMin: MIN_DATE,
                    datePattern: DATE_PATTERN,
                }}
                name="marriage_date"
                placeholder={DATE_FORMAT}
            />
        </FormGroup>
    );

    const spouseDeathDateField = (
        <FormGroup legendText="">
            <FormattedInput
                labelText={'Date Spouse Passed Away'}
                formatOptions={{
                    date: true,
                    delimiter: '-',
                    dateMin: MIN_DATE,
                    datePattern: DATE_PATTERN,
                }}
                name="spouse_death_date"
                placeholder={DATE_FORMAT}
            />
        </FormGroup>
    );

    return (
        <Fragment>
            <h1>
                Please Select Your{' '}
                <span className="color-blue"> Filing Status</span>
            </h1>
            <p>
                Please select your filing status on your taxes before
                continuing.
            </p>

            <Formik
                initialValues={initialFiling}
                onSubmit={saveFilingStatus}
                validationSchema={validation}
                enableReinitialize={true}
                validateOnMount={true}
            >
                {({ handleSubmit, values: { filing_status }, isValid }) => (
                    <Fragment>
                        <section className="py-24">
                            <FormGroup legendText="">
                                <Dropdown
                                    id={'filing-options'}
                                    items={filingOptions}
                                    label="Select A Filing Status"
                                    name={'filing_status'}
                                    titleText={'Filing Status'}
                                    onChange={filingChangeHandler}
                                />
                            </FormGroup>

                            {/* Marriage Date Field */}
                            {filing_status === STATUS_MARRIED ||
                            filing_status === STATUS_MARRIED_SEPARATELY
                                ? marriageDateField
                                : null}

                            {/* Spouse Death Date Field */}
                            {filing_status === STATUS_WIDOW_WITH_DEPENDENT &&
                                spouseDeathDateField}

                            {/* Messge regarding Spouse Deadth date eligibility */}
                            {filing_status === STATUS_WIDOW_WITH_DEPENDENT && (
                                <p>
                                    *This filing status allows you to retain the
                                    benefits of the Married Filing Jointly
                                    status for{' '}
                                    <strong>
                                        two years after the year of your
                                        spouse’s death.
                                    </strong>{' '}
                                    You must have a dependent child in order to
                                    file this status.
                                </p>
                            )}
                        </section>

                        <Navigation
                            onPrevious={() => history.push(previousStep.path)}
                            onNext={handleSubmit}
                            disableNext={!isValid}
                        />
                    </Fragment>
                )}
            </Formik>

            <Modal
                open={open}
                onClose={hideAgreeAlert}
                singleButton
                modalHeading={
                    'Not eligible for Qualifying Widow With Dependent'
                }
            >
                <p>
                    You are not eligible for Qualifying Widow With Dependent!
                    Please select another filing status.
                </p>
            </Modal>
        </Fragment>
    );
}

// Add dateFormat custom validation
enableDateFormatMethod();
enableFutuerDateMethod();
enableMinDateCheck();

const validation = Yup.object().shape({
    filing_status: Yup.string().required('Required field'),
    marriage_date: Yup.string()
        .nullable()
        .when('filing_status', (filing_status, schema) => {
            if (filing_status === STATUS_MARRIED) {
                return schema
                    .dateFormat()
                    .futureDate()
                    .minDate()
                    .required('Required field');
            }

            if (filing_status === STATUS_MARRIED_SEPARATELY) {
                return schema.dateFormat().futureDate().minDate();
            }

            return schema;
        }),
    spouse_death_date: Yup.string()
        .nullable()
        .when('filing_status', (filing_status, schema) => {
            if (filing_status === STATUS_WIDOW_WITH_DEPENDENT) {
                return schema
                    .dateFormat()
                    .futureDate()
                    .minDate()
                    .required('Required field');
            }

            return schema;
        }),
});

export default FilingStatus;
