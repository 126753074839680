import { useContext, useEffect, useState } from 'react';
import { FamilyPhoto } from 'assets';
import Navigation from 'components/Navigation';
import WelcomeWrapper from './WelcomeWrapperStyled';
import { PersonalInfoContext } from 'contexts';
import { history } from 'routes';
import { useStepController } from 'hooks';
import { STATUS_HEAD_OF_HOUSEHOLD, STATUS_WIDOW_WITH_DEPENDENT} from '../../const';

function DependentWelcome() {
    const { nextStep, previousStep } = useStepController();
    const { filingStatus } = useContext(PersonalInfoContext);
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(STATUS_HEAD_OF_HOUSEHOLD === filingStatus || STATUS_WIDOW_WITH_DEPENDENT === filingStatus);
    }, [filingStatus]);

    return (
        <WelcomeWrapper>
            <h1>
                Next, lets get your{' '}
                <span className="color-blue">dependent information.</span>
            </h1>
            <img src={FamilyPhoto} alt="Family" className="my-32" />
            <h2>Why do we need this?</h2>
            <p>
                The number of dependents you claim will help maximize your allowable
                living expenses and are crucial in determining your potential for tax
                relief!
            </p>
            {!show &&
                <p>
                    <strong>
                        Don’t claim any dependents? No problem, just select “None” on the
                        next page to complete the step.
                    </strong>
                </p>
            }

            <Navigation
                onNext={() => history.push(nextStep.path)}
                onPrevious={() => history.push(previousStep.path)}
            />
        </WelcomeWrapper>
    );
}

export default DependentWelcome;
