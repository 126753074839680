import { WarningAlt32 } from '@carbon/icons-react';

function PageNotFound(props) {
    return (
        <div>
            <WarningAlt32
                width={55}
                height={55}
                style={{ margin: '40px auto', display: 'block' }}
            />
            <h1>
                Page <span className="color-blue">Not Found</span>
            </h1>

            <h2>
                The page you are looking for is not available! Please check the
                URL and try again!
            </h2>

            <p>If you are lost, please contact with us!</p>
        </div>
    );
}

export default PageNotFound;
