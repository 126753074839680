import { RetirementPhoto } from 'assets';
import Navigation from 'components/Navigation';
import WelcomeWrapper from './WelcomeWrapperStyled';
import { useStepController } from 'hooks';
import { history } from 'routes';

function RetirementWelcome() {
    const { nextStep, previousStep } = useStepController();

    return (
        <WelcomeWrapper>
            <h1>
                Lastly, we need To verify your{' '}
                <span className="color-blue">
                    retirement & investment information.
                </span>
            </h1>
            <img src={RetirementPhoto} alt="Personal" className="my-30" />
            <h2>Why do we need this?</h2>
            <p>
                To gain an accurate financial picture, we need to be aware of any
                investments or retirements funds you currently hold. This may include
                vehicles, homes, boats, or investment portfolios.
            </p>
            <Navigation
                onNext={() => history.push(nextStep.path)}
                onPrevious={() => history.push(previousStep.path)}
                nextText={'Continue'}
            />
        </WelcomeWrapper>
    );
}

export default RetirementWelcome;
