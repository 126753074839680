import { Fragment, useState, useEffect, useContext } from 'react';
import { history } from 'routes';
import api, { endpoints } from 'api';
import { PersonalInfoContext, contextActionTypes } from '../../../contexts';
import { useStepController } from '../../../hooks';
import Switch from 'components/Switch';
import Navigation from 'components/Navigation';
import { useCallback } from 'react';
import { toUsCurrency } from 'lib';

const {
    FULL_TIME_ESTIMATE_CORRECT,
    FULL_TIME_ESTIMATE_CORRECT_SPOUSE,
} = contextActionTypes;

function EstimatedIncome({ spouse }) {
    const [employmentInfo, setEmploymentInfo] = useState({});
    const [choice, setChoice] = useState(null);

    const { personalUid, spouseUid, employment, dispatchEmployment } = useContext(
        PersonalInfoContext
    );
    const { nextStep, previousStep } = useStepController();

    const {
        estimated_salary_income,
        uid,
        agreed_estimated_salary_income,
    } = employmentInfo;

    const {
        clientEmployerName,
        spouseEmployerName
    } = employment;

    useEffect(() => {
        const fetchEstimatedIncome = async () => {
            try {
                const req = await api({
                    url: endpoints.EMPLOYMENT_INCOME_INFO,
                    params: {
                        profile: spouse ? spouseUid : personalUid,
                    },
                });

                const res = await req.data;

                setEmploymentInfo(res);
            } catch (e) {
                console.log(e.response);
            }
        };

        if (spouse && spouseUid) {
            fetchEstimatedIncome();
        }
        if (!spouse && personalUid) {
            fetchEstimatedIncome();
        }
    }, [personalUid, spouseUid, spouse]);

    const handleEstimateChange = useCallback(
        ({ index }) => {
            dispatchEmployment({
                type: spouse
                    ? FULL_TIME_ESTIMATE_CORRECT_SPOUSE
                    : FULL_TIME_ESTIMATE_CORRECT,
                payload: index === 0,
            });

            setChoice(index);
        },
        [spouse, dispatchEmployment]
    );

    // To update step context from api response
    useEffect(() => {
        if (typeof agreed_estimated_salary_income === 'boolean') {
            handleEstimateChange({
                index: agreed_estimated_salary_income ? 0 : 1,
            });
        }
    }, [agreed_estimated_salary_income, handleEstimateChange]);

    const saveChoice = async () => {
        try {
            const req = await api({
                method: 'put',
                url: `${endpoints.EMPLOYMENT_INCOME_INFO}${uid}/`,
                data: {
                    agreed_estimated_salary_income: choice === 0 ? true : false,
                },
            });

            const res = await req.data;

            if (res) history.push(nextStep.path);
        } catch (e) {
            console.log(e.response);
        }
    };

    return (
        <Fragment>
            <h1>
                We detected
                <span className="color-blue">
                    {' '}
                    {toUsCurrency(estimated_salary_income)}
                </span>{' '}
                as your{' '}
                {spouse ? <span className="color-blue">spouse's</span> : ''}{' '}
                monthly income from
                {' '}
                {spouse ?
                    <span className="color-blue">{spouseEmployerName}.</span>
                    :
                    <span className="color-blue">{clientEmployerName}.</span>
                }
            </h1>
            <p>Is this correct?</p>

            <Switch
                className="my-30"
                onChange={handleEstimateChange}
                choice={choice}
            />

            <Navigation
                onPrevious={() => history.push(previousStep.path)}
                onNext={saveChoice}
                disableNext={typeof choice !== 'number'}
            />
        </Fragment>
    );
}

export default EstimatedIncome;
