import { Fragment, useContext, useEffect, useState } from 'react';
import Navigation from 'components/Navigation';
import { useStepController } from 'hooks';
import { history } from 'routes';
import useBusinessInfo from './useBusinessInfo';
import { SELF_PRIMARY_BUSINESS_KEY } from 'const';
import { PersonalInfoContext } from 'contexts';
import api, { endpoints } from 'api';
import { notification } from 'lib';
import Switch from 'components/Switch';

function OtherBusiness({spouse}) {
    const [hasOwnership, setHasOwnership] = useState(null);

    const { nextStep, previousStep } = useStepController();
    const { personalUid, spouseUid} = useContext(PersonalInfoContext);
    const { businessInfo } = useBusinessInfo(
        spouse ? spouseUid : personalUid,
        SELF_PRIMARY_BUSINESS_KEY
    );

    useEffect(() => {
        if (businessInfo['uid']) {
            const { has_other_business } = businessInfo;

            if (typeof has_other_business === 'boolean') {
                setHasOwnership(has_other_business === true ? 0 : 1);
            }
        }
    }, [businessInfo]);

    const submit = async () => {
        const { uid } = businessInfo;

        if (uid) {
            const data = {
                profile: spouse ? spouseUid : personalUid,
                verified: true,
                self_employed_business_type: SELF_PRIMARY_BUSINESS_KEY,
                has_other_business: hasOwnership === 0,
            };

            try {
                const req = await api({
                    method: 'patch',
                    url: `${endpoints.SELF_EMPLOYED_BUSINESS_INFO}${uid}/`,
                    data,
                });

                const res = await req.data;

                if (res) {
                    history.push(nextStep.path);
                }
            } catch (e) {
                console.log(e.response);
            }
        } else {
            notification(
                'No Business',
                'Please add business info at first.',
                'warning'
            );
        }
    };

    const changeHandler = ({ index }) => {
        setHasOwnership(index);
    };

    return (
        <Fragment>
            <h1>
                {spouse ? (
                    <Fragment>
                        Does your{' '}
                        <span className="color-blue">spouse</span>
                    </Fragment>
                ) : (
                    'Do you'
                )}{' '}have part ownership of
                <span className="color-blue"> any other businesses?</span>
            </h1>

            <Switch
                choice={hasOwnership}
                onChange={changeHandler}
                className="my-32"
            />

            <Navigation
                disableNext={!(typeof hasOwnership === 'number')}
                onNext={submit}
                onPrevious={() => history.push(previousStep.path)}
            />
        </Fragment>
    );
}

export default OtherBusiness;
