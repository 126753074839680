import { Fragment, useContext, useEffect } from 'react';
import { history } from 'routes';
import styled from 'styled-components';
import { ButtonBlock } from 'components/Buttons';
import { MetaContext } from 'contexts';

function PrivacyPolicy() {
    const {
        updateMeta: { updatePageTitle },
    } = useContext(MetaContext);

    useEffect(() => {
        updatePageTitle('Privacy Policy');
    }, [updatePageTitle]);

    return (
        <Fragment>
            <h1>Privacy Policy</h1>
            <TermsWrapper>
                <h1>Effective as of 2020 December 12</h1>
                <div>
                    <h2>Overview</h2>
                    <p>
                        Verifilink is a financial data aggregator system. It is
                        owned and operated by Verifilink LLC (“Verifilink”).
                    </p>
                </div>

                <div>
                    <h2>Your Privacy</h2>
                    <p>
                        We take the privacy of your information very seriously,
                        and are committed to protecting it. To do that, we have
                        set up procedures to ensure that your information is
                        handled responsibly and in accordance with applicable
                        data protection and privacy laws. We are grateful for
                        your trust, and we will act that way.
                    </p>
                    <p>
                        Verifilink stresses its privacy and security standards
                        to guard against identity theft and provide security for
                        your information. We regularly reevaluate this Privacy
                        Policy and our security policies and revise them as
                        necessary to deal with new challenges. Verifilink
                        encourages you to periodically review the most current
                        version of this Privacy Policy.
                    </p>
                    <p>
                        This Privacy Policy describes what information we
                        collect when you use Verifilink and its related services
                        (the “Service”), how we use that information, and what
                        choices we offer you to access, update, and control that
                        information. By using the the Service , you consent to
                        the privacy practices described in this Privacy Policy.
                    </p>
                </div>

                <div>
                    <h2>Verifilink is a financial data aggregator system.</h2>
                    <p>
                        Verifilink is an online financial data aggregator system
                        used by consumers to accurately determine their income,
                        expenses and assets. The Service includes features for
                        linking bank accounts and credit reporting data.
                    </p>
                </div>
                <div>
                    <h2>Collection of your Personal information</h2>
                    <p>
                        “Personal Information” means information that identifies
                        you, such as your name, address, location, social
                        security number, phone number, fax number, or email
                        address. Personal Information includes financial
                        documents and information that you may upload or
                        otherwise transmit to the Service. This information is
                        used to complete certain transactions. Verifilink may
                        also collect anonymous demographic information, which is
                        not unique to you, such as your age, gender, household
                        income and financial information.
                    </p>
                    <p>
                        From time to time Verifilink may request additional
                        Personal Information to provide you with other benefits
                        of the Service. In all such instances, you will be given
                        the opportunity to provide or to decline to provide that
                        information and it will be used only for the stated
                        purpose.
                    </p>
                </div>

                <div>
                    <h2>Your privacy is not for sale</h2>
                    <p>
                        Simply put, we do not and will not sell or rent your
                        Personal Information to anyone, for any reason, at any
                        time. Verifilink uses and discloses your Personal
                        Information only as follows: to analyze site usage and
                        improve the Service;
                    </p>
                    <p>
                        to deliver to you any administrative notices and
                        communications relevant to your use of the Service; to
                        fulfill your requests for certain products and services;
                        for market research, project planning, troubleshooting
                        problems, detecting and protecting against error, fraud,
                        or other criminal activity; to third-party contractors
                        that provide services to Verifilink that are bound by
                        these same privacy restrictions; to enforce Verifilink’s
                        Terms of Use; and as otherwise set forth in this Privacy
                        Policy.
                    </p>
                </div>

                <div>
                    <h2>Use of your personal information by Verifilink</h2>
                    <p>
                        Verifilink collects and uses your Personal Information
                        to operate the Service. Verifilink may also use your
                        Personal Information information to inform you of other
                        products or services available from Verifilink and its
                        affiliates. Verifilink may also contact you via surveys
                        to conduct research about your opinion of current
                        services or of potential new services that may be
                        offered.
                    </p>
                    <p>
                        Verifilink may keep track of the pages you visit within
                        the Service, in order to determine what Verifilink
                        features are popular. This data is used to deliver
                        customized content within the Service to customers whose
                        behavior indicates that they are interested in a
                        particular subject area. Information about your computer
                        hardware and software may be automatically collected by
                        Verifilink. This information can include: your IP
                        address, browser type, domain names, access times and
                        referring website addresses. This information is used
                        for the operation of the service, to maintain quality of
                        the service, and to provide general statistics regarding
                        use of the Verifilink website.
                    </p>
                </div>

                <div>
                    <h2>Use of your personal information by third parties</h2>
                    <p>
                        If you disclose Personal Information information or
                        personally sensitive data through the Service, this
                        information may be collected and used by tax
                        professionals, enrolled agents, attorneys, accountants,
                        and other authorized users.
                    </p>
                    <p>
                        Verifilink may aggregate Personal Information or make it
                        anonymous and disclose such information only in a
                        non-personally identifiable manner to: Advertisers and
                        other third parties for their marketing and promotional
                        purposes. The aggregated information may include the
                        number of users who applied for a loan or how many users
                        clicked on a particular Verifilink Offer; or
                        Organizations approved by Verifilink that conduct
                        research into consumer lending.
                    </p>
                    <p>
                        Such information will not identify you individually and
                        we require such third parties to maintain the
                        confidentiality of the information we provide to them.
                    </p>
                    <p>
                        We may also use third party vendors or service providers
                        to help us provide the Service to you, such as sending
                        email messages on our behalf or hosting and operating a
                        particular feature or functionality of the Service. Our
                        contracts with these third parties outline the
                        appropriate use and handling of your information and
                        prohibit them from using any of your Personal
                        Information in an any manner inconsistent with this
                        Privacy Policy
                    </p>
                    <p>
                        Verifilink will disclose your Personal Information,
                        without notice, only if required to do so by law or in
                        the good faith belief that such action is legally
                        required. Nothing contained in this Privacy Policy is in
                        derogation of Verifilink’s right to comply with
                        governmental, court, and law enforcement requests or
                        requirements relating to your use of the Service or
                        documents or information provided to or gathered by
                        Verifilink with respect to such use. Verifilink reserves
                        the right to disclose any documents or information as
                        necessary to satisfy any applicable law, regulation,
                        legal process or governmental request.
                    </p>
                </div>

                <div>
                    <h2>Third party services and sites</h2>
                    <p>
                        You will be able to connect your Verifilink account to
                        third party services. By connecting your Verifilink
                        Account to a third party service, you acknowledge and
                        agree that you are consenting to the continuous release
                        of information about you to that third party (in
                        accordance with your privacy settings with those third
                        party services and the terms of service of those third
                        parties). If you do not want information about you to be
                        shared in this manner, do not use these features. You
                        may disconnect your Account from third party services at
                        any time.
                    </p>
                    <p>
                        There are a number of separate products and services
                        offered by third parties advertised by us through the
                        Service that may be complementary to your use of the
                        Service (collectively, “Verifilink Offers”). If you
                        choose to use these separate products or services,
                        disclose information to the third-party providers, or
                        grant them permission to collect information about you,
                        then their use of your information is governed by their
                        privacy policies. You should evaluate the practices of
                        external services providers before deciding to use their
                        services. Verifilink is not responsible for their
                        privacy practices. If you click on a link to a third
                        party site, Verifilink encourages you to check the
                        privacy policy of that site. Verifilink may present
                        links in a format that enables us to keep track of
                        whether these links have been followed and whether any
                        action has been taken on a third-party web site. We use
                        this information to improve the quality of the
                        Verifilink Offers and customized content on the Service.
                    </p>
                    <p>
                        If you access the Service through one of our cobrand
                        partners or through a cobranded URL, the email used for
                        your registration on the Service may be provided to that
                        cobrand partner.
                    </p>
                </div>

                <div>
                    <h2>Cookies and web beacons</h2>
                    <p>
                        Verifilink takes the privacy of your Personal
                        Information very seriously. We are committed to
                        protecting it. To do that, we have set up procedures to
                        ensure that your information is handled responsibly and
                        in accordance with applicable data protection and
                        privacy laws.
                    </p>
                </div>

                <div>
                    <h2>
                        We are grateful for your trust, and we will act that
                        way.
                    </h2>
                    <p>
                        Verifilink stresses its privacy and security standards
                        to guard against identity theft and provide security for
                        your personal information. We regularly reevaluate our
                        privacy and security policies and adapt them as
                        necessary to deal with new challenges.
                    </p>
                    <p>
                        We use a combination of firewall barriers, encryption
                        techniques, and authentication procedures, among other
                        technologies, to maintain the security of your online
                        session and to protect Verifilink accounts and systems
                        from unauthorized access. All information and documents
                        uploaded, downloaded, or transmitted through the Service
                        are processed using secure encryption. All
                        communications, including website and API access, are
                        transmitted over SSL (HTTPS).
                    </p>
                    <p>
                        When you register for the Service, Verifilink requires a
                        password from you for your privacy and security. Your
                        Account password is stored in our databases with one-way
                        encryption so even we do not have access to it.
                    </p>
                    <p>
                        We regularly update our software and database
                        infrastructure to incorporate patches and updates. All
                        data is backed up on a regular basis to safeguard your
                        data and to ensure our continued ability to provide the
                        Service in the event of malfunction or damage to our
                        primary production servers.
                    </p>
                    <p>
                        Access to your Personal Information is strictly
                        controlled and used in accordance with Verifilink’s
                        internal privacy and security policies and practices.
                        Our databases are protected from general employee access
                        both physically and logically. Our staff is trained and
                        required to safeguard your information. Verifilink
                        employees and contractors with access to your Personal
                        Information have been selected in accordance with our
                        privacy and security policies and are bound by
                        confidentiality obligations. If they fail to meet these
                        obligations, they shall be subject to discipline,
                        including termination and criminal prosecution. No
                        employee or contractor may store any Personal
                        Information on any insecure machine (i.e., nothing can
                        be taken from the database and put on an insecure
                        laptop).
                    </p>
                </div>

                <div>
                    <h2>You can transport or delete your data</h2>
                    <p>
                        Your data is yours. You can download or remove it
                        anytime you want. When you request that we terminate
                        your Account, your Personal Information including your
                        documents will be permanently expunged from our primary
                        production servers and further access to your Account
                        will not be possible. Verifilink will disconnect your
                        Account from all third party services.
                    </p>
                    <p>
                        However, there might be some latency in deleting your
                        information and documents from our back-up servers and
                        back-up storage. Verifilink keeps these backups to
                        ensure our continued ability to provide the Service in
                        the event of malfunction or damage to our primary
                        production servers. In addition we may retain certain
                        information or documents if necessary to comply with our
                        legal obligations, resolve disputes, or enforce our
                        agreements.
                    </p>
                    <p>
                        We reserve the right to retain and use any aggregated or
                        anonymous data derived from your Personal Information.
                        Portions of your information, consisting of aggregated,
                        anonymous data derived from your Personal Information,
                        may remain on our production servers indefinitely.
                    </p>
                </div>

                <div>
                    <h2>Children under thirteen</h2>
                    <p>
                        Verifilink does not knowingly collect Personal
                        Information information from children under the age of
                        thirteen.
                    </p>
                </div>

                <div>
                    <h2>Third-party security</h2>
                    <p>
                        Verifilink chooses its affiliates carefully to ensure
                        they employ appropriate security and privacy measures.
                        Our third party service providers are required to comply
                        fully with this Privacy Policy. However, we exercise no
                        control over how your information is stored, maintained,
                        or displayed by third parties or on third party sites.
                    </p>
                </div>

                <div>
                    <h2>CCPA</h2>
                    <p>
                        Applicable to California consumers, the California
                        Consumer Privacy Act (CCPA) protects you, your rights,
                        and your privacy. Verifilink complies with all
                        applicable terms of the CCPA.
                    </p>
                </div>

                <div>
                    <h2>GDPR</h2>
                    <p>
                        Applicable to residents of the European Union (EU), the
                        EU’s General Data Protection Regulation (GDPR) protects
                        you, your rights, and your privacy.
                    </p>
                    <p>
                        Under the terms of the GDPR, Processing takes place in
                        the United States of America. The Controller is any tax
                        professional and their organization with whom you have
                        shared information.
                    </p>
                </div>

                <div>
                    <h2>Contact information</h2>
                    <p>
                        Verifilink welcomes your questions or comments regarding
                        this Privacy Policy. If you have any concerns related to
                        this Policy, please contact Verifilink at:
                    </p>
                    <p>Verifilink LLC</p>
                    <p>19900 MacArthur Blvd Ste 280</p>
                    <p>Irvine, California 92602</p>
                    <p>
                        <a
                            className="color-blue"
                            href="mailto:support@verifilink.com"
                        >
                            support@verifilink.com
                        </a>
                    </p>
                    <p>
                        <span className="color-blue">Verifilink.com</span>
                    </p>
                    <p>
                        Verifilink has appointed a data protection officer, who
                        can be reached at{' '}
                        <a
                            className="color-blue"
                            href="mailto:privacy@verifilink.com"
                        >
                            privacy@verifilink.com.
                        </a>{' '}
                        Effective as of 2020 December 12
                    </p>
                </div>
            </TermsWrapper>

            <div className="mt-40 fixed-bottom">
                <ButtonBlock type={'button'} onClick={() => history.goBack()}>
                    Previous
                </ButtonBlock>
            </div>
        </Fragment>
    );
}

const TermsWrapper = styled.div`
    h1 {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #6a7581;
        margin-top: 8px;
    }
    h2 {
        font-family: Poppins;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #0f212e;
        margin-top: 15px;
    }
    p {
        font-family: Poppins;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #6a7581;
    }
`;

export default PrivacyPolicy;
