import { useEffect, useState, useContext, Fragment } from 'react';
import * as Yup from 'yup';
import { FieldArray, Formik } from 'formik';
import api, { endpoints } from 'api';
import { LinkButton } from 'components/Buttons';
import { useStepController, useModal } from 'hooks';
import { history } from 'routes';
import Modal from 'components/Modal';
import Navigation from 'components/Navigation';
import { PersonalInfoContext } from 'contexts';
import VehicleAdditional from './VehicleAdditional';
import { InvestmentContext } from 'contexts';
import { MAX_AMOUNT_VEHICLES } from 'const';
import { toUsCurrency } from 'lib';
import {
    enableDotAmountCheck,
    enableMinAmountCheck,
    enableMaxAmountCheck,
} from 'lib/yupValidMethods';
import { parseAmount } from 'lib';

const initVehicle = {
    odometer: '',
    year: '',
    make: '',
    model: '',
    market_value: '',
    outstanding_balance: '',
    verified: true,
    uid: '',
};

function AdditionalVehicles({ spouse }) {
    const [initialValues, updateInitial] = useState({
        vehicles: [Object.assign({}, initVehicle)],
    });
    const {
        investments: { vehicles_financed, vehicles_financed_spouse },
    } = useContext(InvestmentContext);
    /*
        Index of dependent to clear and remove method.
        Remove method is necessary to save in state because FieldArray methods can't be accessed outside render.
    */
    const [delVehicleInfo, setDeleteVehicle] = useState([]);

    const { personalUid, spouseUid } = useContext(PersonalInfoContext);

    const { previousStep, nextStep } = useStepController();
    const [open, showModal, hideModal] = useModal();

    useEffect(() => {
        // Fetch existing dependents
        const fetchExistVehicles = async (profile) => {
            if (!profile) return;

            try {
                const req = await api({
                    url: endpoints.ADDITIONAL_VEHICLE,
                    params: {
                        profile,
                    },
                });
                const res = await req.data;
                if (Array.isArray(res) && res.length) {
                    const existingVehicles = res.map((data) => ({
                        odometer: data.odometer,
                        year: data.year,
                        make: data.make,
                        model: data.model,
                        market_value: data.market_value,
                        outstanding_balance: data.outstanding_balance,
                        verified: true,
                        uid: data.uid,
                    }));

                    updateInitial({ vehicles: existingVehicles });
                }
            } catch (e) {
                console.log(e.response);
            }
        };

        fetchExistVehicles(spouse ? spouseUid : personalUid);
    }, [personalUid, spouse, spouseUid]);

    const saveVehicles = async (values) => {
        try {
            const vehicles = values.vehicles.map((vehicle) => ({
                profile: spouse ? spouseUid : personalUid,
                odometer: parseAmount(vehicle.odometer),
                year: vehicle.year,
                make: vehicle.make,
                model: vehicle.model,
                market_value: parseAmount(vehicle.market_value),
                outstanding_balance: vehicle.outstanding_balance ? parseAmount(vehicle.outstanding_balance) : 0,
                verified: true,
                uid: vehicle.uid ? vehicle.uid: '',
            }));

            const req = await api.post(endpoints.ADDITIONAL_VEHICLE, vehicles);
            const res = await req.data;

            if (res) {
                history.push(nextStep.path);
            }
        } catch (err) {
            console.log(err);
            console.log(err.response);
        }
    };

    const handleClear = (index, method, uid) => {
        setDeleteVehicle([index, method, uid]);
        showModal();
    };

    const deleteVehicle = async () => {
        const [index, remove, uid] = delVehicleInfo;

        // Delete Dependent from backend when uid available otherwise remove from the design
        if (!uid) {
            remove(index);
        } else {
            try {
                const deleteReq = await api({
                    method: 'delete',
                    url: `${endpoints.ADDITIONAL_VEHICLE}${uid}/`,
                });

                if (deleteReq) remove(index);
            } catch (e) {
                console.log(e.response);
            }
        }
    };

    const goBack = () => {
        history.push(previousStep.path);
    };

    const is_additional = spouse
        ? vehicles_financed_spouse.length
        : vehicles_financed.length;

    return (
        <Formik
            displayName="Vehicle Form"
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationRules}
            validateOnMount
            onSubmit={saveVehicles}
        >
            {({ handleSubmit, values, isValid }) => (
                <Fragment>
                    <h1>
                        Please enter your{' '}
                        <span className="color-blue">
                            {spouse && " spouse's"}
                            {is_additional ? 'additional' : ''} vehicle(s) information.
                        </span>
                    </h1>

                    {spouse ? (
                        <p>
                            Add additional vehicles your
                            spouse own by using the{' '}
                            <strong>“Add a Vehicle”</strong> button.
                        </p>
                    ) : (
                        <p>
                            Add additional vehicles you own by using the
                            {' '}<strong>“Add a Vehicle”</strong>{' '}
                            button.
                        </p>
                    )}

                    <FieldArray
                        name="vehicles"
                        render={({ push, remove }) => (
                            <div className="mt-32">
                                <div>
                                    {values.vehicles.map(({ uid }, i) => (
                                        <VehicleAdditional
                                            key={i}
                                            index={i}
                                            additional={is_additional}
                                            onClear={handleClear.bind(
                                                null,
                                                i,
                                                remove,
                                                uid
                                            )}
                                        />
                                    ))}
                                </div>

                                <LinkButton
                                    type="button"
                                    onClick={() => push(initVehicle)}
                                    className="mb-32"
                                >
                                    <span>Add a Vehicle</span>
                                </LinkButton>
                            </div>
                        )}
                    />

                    <Navigation
                        onPrevious={goBack}
                        onNext={handleSubmit}
                        disableNext={!isValid}
                        typeNext="submit"
                    />

                    <Modal
                        open={open}
                        onClose={hideModal}
                        onSave={deleteVehicle}
                        modalHeading="Are you sure you want to remove this vehicle?"
                        primaryButtonText="Yes"
                        danger
                    >
                        <p>The information you entered will not be saved.</p>
                    </Modal>
                </Fragment>
            )}
        </Formik>
    );
}

const currentYear = new Date().getFullYear();
enableDotAmountCheck();
enableMinAmountCheck();
enableMaxAmountCheck();

const validationRules = Yup.object().shape({
    vehicles: Yup.array().of(
        Yup.object().shape({
            odometer: Yup.string()
                    .required('Required field')
                    .maxAmount(MAX_AMOUNT_VEHICLES, `Maximum Odometer is ${(MAX_AMOUNT_VEHICLES)}`),
            year: Yup.number()
                .min(1920, 'Year must be older than 1920')
                .max(currentYear, "Year can't be older than now")
                .typeError('Invalid year')
                .required('Please Enter Year'),
            make: Yup.string().required('Please Enter Valid Maker'),
            model: Yup.string().required('Please Enter Valid Model'),
            market_value: Yup.string()
                            .required('Required field')
                            .onlyDot('Your market value is not valid')
                            .minAmount(500, 'Minimum amount is 10,000')
                            .maxAmount(MAX_AMOUNT_VEHICLES, `Maximum amount is ${toUsCurrency(MAX_AMOUNT_VEHICLES)}`),
        })
    ),
});

export default AdditionalVehicles;
