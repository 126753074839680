import { Fragment, useState, useEffect, useContext } from 'react';
import Navigation from 'components/Navigation';
import { Formik } from 'formik';
import { FormGroup } from 'carbon-components-react';
import { Dropdown, SwitchInput, Checkbox } from 'components/Form';
import * as Yup from 'yup';
import api, { endpoints } from 'api';
import { PersonalInfoContext } from 'contexts';
import { useStepController } from 'hooks';
import { history } from 'routes';
import useBusinessInfo from './useBusinessInfo';
import useFinanceInfo from './useFinanceInfo';
import { MAX_AMOUNT, MAX_AMOUNT_US, TAX_DEPOSIT_FREQUENCY } from 'const';
import { AmountInput } from 'components/Utilities';
import {
    enableDotAmountCheck,
    enableMaxAmountCheck,
    enableMinAmountCheck,
} from 'lib/yupValidMethods';
import { parseAmount } from 'lib';

function EmployeesPayroll({ spouse }) {
    const [initialValues, updateInitialValues] = useState({
        has_payroll_tax_deposit: null,
        tax_deposit_frequency: null,
        average_gross_monthly_payroll: '',
        skip: false,
    });

    const { personalUid, spouseUid } = useContext(PersonalInfoContext);
    const { previousStep, nextStep } = useStepController();
    const { businessInfo } = useBusinessInfo(spouse ? spouseUid : personalUid);
    const { financeInfo } = useFinanceInfo(spouse ? spouseUid : personalUid);

    useEffect(() => {
        const {
            has_payroll_tax_deposit,
            tax_deposit_frequency,
            uid,
            average_gross_monthly_payroll,
        } = financeInfo;

        if (uid) {
            updateInitialValues((initValue) => ({
                ...initValue,
                has_payroll_tax_deposit,
                tax_deposit_frequency,
                average_gross_monthly_payroll,
            }));
        }
    }, [financeInfo]);

    const submit = async (values) => {
        const financeUid = financeInfo.uid;

        if (!values.has_payroll_tax_deposit || values.skip) {
            values['tax_deposit_frequency'] = null;
            values['average_gross_monthly_payroll'] = null;
        } else {
            values['average_gross_monthly_payroll'] = parseAmount(
                values['average_gross_monthly_payroll']
            );
        }

        const data = {
            profile: spouse ? spouseUid : personalUid,
            verified: true,
            business_info: businessInfo.uid,
            ...values,
        };

        try {
            let url = endpoints.SELF_EMPLOYED_FINANCIAL_INFO;
            if (financeUid) url += `${financeUid}/`;

            const req = await api({
                method: financeUid ? 'patch' : 'post',
                data,
                url,
            });

            const res = req.data;

            if (res) history.push(nextStep.path);
        } catch (e) {
            console.log(e.response);
        }
    };

    const goPreviousStep = () => history.push(previousStep.path);

    return (
        <Formik
            initialValues={initialValues}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={submit}
            validationSchema={validation}
        >
            {({ handleSubmit, isValid, values }) => (
                <Fragment>
                    <h1>
                        Did{' '}
                        {spouse ? (
                            <Fragment>
                                your <span className="color-blue">spouse</span>
                            </Fragment>
                        ) : (
                            'you'
                        )}{' '}
                        make all required
                        <span className="color-blue">
                            {' '}
                            payroll tax deposits?
                        </span>
                    </h1>

                    <FormGroup legendText="" className="my-32">
                        <SwitchInput name="has_payroll_tax_deposit" />
                    </FormGroup>

                    {values.has_payroll_tax_deposit && (
                        <Fragment>
                            <h2 className="mt-32">
                                {spouse
                                    ? 'How often does your spouse make payroll tax deposits?'
                                    : 'How often do you make payroll tax deposits?'}
                            </h2>
                            <FormGroup legendText="">
                                <Dropdown
                                    id="tax_deposit_frequency"
                                    label=""
                                    name="tax_deposit_frequency"
                                    titleText="Frequency of Tax Deposits"
                                    items={TAX_DEPOSIT_FREQUENCY}
                                />
                            </FormGroup>

                            <h2 className="mt-32">
                                {spouse
                                    ? 'What is your spouse’s business’ average gross monthly payroll?'
                                    : 'What is your business’ average gross monthly payroll?'}
                            </h2>
                            <FormGroup legendText="">
                                <AmountInput
                                    name="average_gross_monthly_payroll"
                                    labelText="Average Gross Monthly Payroll"
                                />
                            </FormGroup>

                            <FormGroup legendText="" className="mt-32">
                                <Checkbox
                                    name="skip"
                                    id="skip"
                                    labelText="I’m Not Sure (Skip This)"
                                />
                            </FormGroup>
                        </Fragment>
                    )}

                    <Navigation
                        disableNext={!isValid}
                        onNext={handleSubmit}
                        onPrevious={goPreviousStep}
                        typeNext={'submit'}
                    />
                </Fragment>
            )}
        </Formik>
    );
}

enableDotAmountCheck();
enableMinAmountCheck();
enableMaxAmountCheck();

const validation = Yup.object().shape({
    has_payroll_tax_deposit: Yup.boolean().required(),
    tax_deposit_frequency: Yup.string()
        .nullable()
        .when(['has_payroll_tax_deposit', 'skip'], function (
            has_payroll_tax_deposit,
            skip,
            schema
        ) {
            if (has_payroll_tax_deposit && !skip) {
                return Yup.string()
                    .required('Required field')
                    .typeError('Required field');
            }

            return schema;
        }),
    average_gross_monthly_payroll: Yup.mixed()
        .nullable()
        .when(['has_payroll_tax_deposit', 'skip'], function (
            has_payroll_tax_deposit,
            skip,
            schema
        ) {
            if (has_payroll_tax_deposit && !skip) {
                return Yup.string()
                    .required('Required field')
                    .onlyDot()
                    .minAmount(
                        500,
                        'Minimum payroll must be equal or greater than $500'
                    )
                    .maxAmount(
                        MAX_AMOUNT,
                        `Maximum payroll must be equal or less than ${MAX_AMOUNT_US}`
                    );
            }

            return schema;
        }),
});

export default EmployeesPayroll;
