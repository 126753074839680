import {
    EMPLOYMENT_FULLTIME,
    EMPLOYMENT_FULLTIME_REESTIMATE,
    EMPLOYMENT_FULLTIME_MANUAL_ESTIMATE,
    EMPLOYMENT_FULLTIME_DEPOSITS,
    EMPLOYMENT_FULLTIME_OWNERSHIP,
    SPOUSE_WORK_REESTIMATE,
    SPOUSE_WORK_MANUAL_ESTIMATE,
    SPOUSE_WORK_DEPOSITS,
    SPOUSE_WORK_OWNERSHIP,
    SPOUSE_WORK_INFO,
    SPOUSE_WORK,
} from 'routes/paths';
import { EMP_STEP_TITLE } from 'const';

const STEPS = [
    {
        path: EMPLOYMENT_FULLTIME,
        title: EMP_STEP_TITLE,
    },
    {
        path: EMPLOYMENT_FULLTIME_OWNERSHIP,
        title: EMP_STEP_TITLE,
    },
    {
        path: EMPLOYMENT_FULLTIME_DEPOSITS,
        title: EMP_STEP_TITLE,
    },
];

const SPOUSE_STEPS = [
    {
        path: SPOUSE_WORK_INFO,
        title: EMP_STEP_TITLE,
    },
    {
        path: SPOUSE_WORK_OWNERSHIP,
        title: EMP_STEP_TITLE,
    },
    {
        path: SPOUSE_WORK_DEPOSITS,
        title: EMP_STEP_TITLE,
    },
];

export default function generateFullTimeSteps(
    employment,
    profile,
    additionalInfo = {}
) {
    const { personal, spouse } = employment;

    const { fullTimeReEstimateCorrect, selectedDeposits } = profile
        ? spouse
        : personal;

    const {
        spouses_employment_info,
        has_manual_personal_bank,
        spouses_income_in_your_bank: joint_bank,
        spouse: spouseInfo,
    } = additionalInfo;

    let steps = [];

    if (profile) {
        steps = [
            {
                path: SPOUSE_WORK,
                title: EMP_STEP_TITLE,
            },
        ];

        if (spouses_employment_info) {
            steps.push(...SPOUSE_STEPS);

            const setManualBankIncomeFlow = () => {
                steps.splice(3, 1, {
                    path: SPOUSE_WORK_MANUAL_ESTIMATE,
                    title: EMP_STEP_TITLE,
                });
            };

            const setRegularIncomeFlow = () => {
                if (selectedDeposits) {
                    steps.push({
                        path: SPOUSE_WORK_REESTIMATE,
                        title: EMP_STEP_TITLE,
                    });

                    if (!fullTimeReEstimateCorrect) {
                        steps.push({
                            path: SPOUSE_WORK_MANUAL_ESTIMATE,
                            title: EMP_STEP_TITLE,
                        });
                    }
                } else {
                    steps.push({
                        path: SPOUSE_WORK_MANUAL_ESTIMATE,
                        title: EMP_STEP_TITLE,
                    });
                }
            };

            if (joint_bank) {
                if (has_manual_personal_bank) {
                    setManualBankIncomeFlow();
                } else {
                    setRegularIncomeFlow();
                }
            } else {
                if (spouseInfo.has_manual_personal_bank) {
                    setManualBankIncomeFlow();
                } else {
                    setRegularIncomeFlow();
                }
            }
        }
    } else {
        steps = [...STEPS];

        // If client set manual personal bank then only take manual income
        if (has_manual_personal_bank) {
            steps.splice(2, 1, {
                path: EMPLOYMENT_FULLTIME_MANUAL_ESTIMATE,
                title: EMP_STEP_TITLE,
            });
        } else if (selectedDeposits) {
            steps.push({
                path: EMPLOYMENT_FULLTIME_REESTIMATE,
                title: EMP_STEP_TITLE,
            });

            if (!fullTimeReEstimateCorrect) {
                steps.push({
                    path: EMPLOYMENT_FULLTIME_MANUAL_ESTIMATE,
                    title: EMP_STEP_TITLE,
                });
            }
        } else {
            steps.push({
                path: EMPLOYMENT_FULLTIME_MANUAL_ESTIMATE,
                title: EMP_STEP_TITLE,
            });
        }
    }

    return steps;
}
