import { Switch, Route } from 'react-router-dom';
import AddDependents from './AddDependents';
import {
    ADD_DEPENDENTS,
    HOUSEHOLD_INFORMATION,
    CLAIM_DEPENDENTS,
    DEPENDENTS_PROGRESS,
} from '../../routes/paths';
import ClaimDependent from './ClaimDependent';
import DependentWelcome from 'screens/Welcome/DependentWelcome';
import Progress from 'screens/Progress';
import { PROGRESS_KEY } from 'const';
import { PageNotFound } from 'screens/Errors';

function HouseHoldIndex() {
    return (
        <Switch>
            <Route exact path={DEPENDENTS_PROGRESS}>
                <Progress completedProgress={PROGRESS_KEY.dependent} />
            </Route>
            <Route exact path={ADD_DEPENDENTS}>
                <AddDependents />
            </Route>
            <Route exact path={CLAIM_DEPENDENTS}>
                <ClaimDependent />
            </Route>
            <Route exact path={HOUSEHOLD_INFORMATION}>
                <DependentWelcome />
            </Route>
            <Route path="*">
                <PageNotFound />
            </Route>
        </Switch>
    );
}

export default HouseHoldIndex;
