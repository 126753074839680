import { Fragment } from 'react';
import {Link } from "react-router-dom";
import {TERMS_OF_USE, PRIVACY_POLICY} from '../../routes/paths';

function Terms({ first_name, last_name }) {
    return (
        <Fragment>
            I, {first_name} {last_name}, authorize Priceholdings Inc. to obtain
            information from my personal credit profile or other information
            from Experian. I've also read and agreed to Verifilink's{' '}
            <strong>
                <u>
                    <Link to={TERMS_OF_USE} className="inherit-color">
                        Terms of Use
                    </Link>
                </u>
            </strong>{' '}
            and{' '}
            <strong>
                <u>
                    <Link to={PRIVACY_POLICY} className="inherit-color">
                        Privacy Policy
                    </Link>
                </u>
            </strong>
            .
        </Fragment>
    );
}

export default Terms;
