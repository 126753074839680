import { Fragment } from 'react';
import { Input, Dropdown } from 'components/Form';
import {FormGroup} from "carbon-components-react";

const incomeType = ['W-2', '1099'];

function PartTimeJob({ index }) {
    return (
        <Fragment>
            <FormGroup legendText="" className={'mb-16'}>
                <Input
                    labelText={'Name of Employer'}
                    id="part_time_business_name"
                    name={`data[${index}].business_name`}
                    type="text"
                    placeholder="Type your employer name"
                />
            </FormGroup>

            <Dropdown
                id={`incomeType-${index}`}
                name={`data[${index}].income_type`}
                items={incomeType}
                className={'mb-16'}
                titleText={'Income Type'}
            />
        </Fragment>
    );
}

export default PartTimeJob;
