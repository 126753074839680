import { Decision } from 'screens/Helper';
import { contextActionTypes } from 'contexts';

function SpouseOnlineInfo() {
    return (
        <Decision
            apiKey="spouses_online_bank_info"
            contextKey={contextActionTypes.CLIENT_SPOUSE_ONLINE_INFO}
        >
            <h1 className="font-size-20">
                Are you able to provide your{' '}
                <span className="color-blue">
                    spouse’s online user name and password
                </span>
                {' '}
                or
                {' '}
                <span className="color-blue">
                    banking information?
                </span>
            </h1>

            <p>
                We need their online banking username and password.{' '}
                <strong>
                    If you don’t know these details, we will ask at a later
                    time.
                </strong>
            </p>
        </Decision>
    );
}

export default SpouseOnlineInfo;
