import { parseAmount } from 'lib';
function mapManualAdditionalIncome(data, reasons, profile) {
    const {
        deposit_reason,
        gross_income,
        source_of_income,
        rental_expense,
        total_rental_expense_amount,
        business_name,
        worked_from,
        worked_to,
        income_type,
        deposit_description,
        uid,
    } = data;

    switch (reasons[deposit_reason]) {
        case 'Rental Income': {
            let rental_income = {};
            if (total_rental_expense_amount) {
                rental_income = {
                    rental_expense,
                    rental_expense_amount: parseAmount(
                        total_rental_expense_amount
                    ),
                };
            } else {
                rental_income = {
                    rental_expense,
                };
            }
            return {
                profile: profile,
                deposit_reason: deposit_reason,
                verified: 'true',
                uid: uid,
                rental_income,
                source_of_income,
                gross_income: parseAmount(gross_income),
            };
        }

        case 'No Longer Working Here': {
            return {
                profile: profile,
                deposit_reason: deposit_reason,
                verified: 'true',
                uid: uid,
                source_of_income,
                gross_income: parseAmount(gross_income),
                others_income: {
                    business_name,
                    worked_from,
                    worked_to,
                },
            };
        }

        case 'Part Time Job': {
            return {
                profile: profile,
                deposit_reason: deposit_reason,
                verified: 'true',
                uid: uid,
                source_of_income,
                gross_income: parseAmount(gross_income),
                others_income: {
                    business_name,
                    income_type,
                },
            };
        }

        case 'Other': {
            return {
                profile: profile,
                deposit_reason: deposit_reason,
                verified: 'true',
                uid: uid,
                source_of_income,
                gross_income: parseAmount(gross_income),
                others_income: {
                    deposit_description,
                },
            };
        }
        default:
            return {
                profile: profile,
                deposit_reason: deposit_reason,
                source_of_income,
                gross_income: parseAmount(gross_income),
                verified: 'true',
                uid: uid,
            };
    }
}

export default mapManualAdditionalIncome;
