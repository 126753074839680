import {
    PERSONAL_FILING_SELECT,
    PERSONAL_INFORMATION,
    PERSONAL_SPOUSE_INFO,
    PERSONAL_INFO_VERIFY,
    PERSONAL_PROGRESS,
} from '../../routes/paths';
import {
    STATUS_MARRIED,
    STATUS_MARRIED_SEPARATELY,
    STATUS_WIDOW_WITH_DEPENDENT,
} from '../../const';

/**
 * @function personalInfoSteps
 * @param {string} filingStatus
 *
 * Generates Personal Info Steps following clients filing status.
 *
 * @return {Array} Personal Info Steps
 */
const personalInfoSteps = (filingStatus) => {
    const checkFilingRoutes = () => {
        switch (filingStatus) {
            case STATUS_MARRIED:
                return [
                    {
                        path: PERSONAL_SPOUSE_INFO,
                        title: 'Personal Info Verification',
                    },
                ];
            case STATUS_MARRIED_SEPARATELY:
                return [
                    {
                        path: PERSONAL_SPOUSE_INFO,
                        title: 'Personal Info Verification',
                    },
                ];

            case STATUS_WIDOW_WITH_DEPENDENT:
                return [
                    {
                        path: PERSONAL_SPOUSE_INFO,
                        title: 'Personal Info Verification',
                    },
                ];
            default:
                return [];
        }
    };

    const steps = [
        {
            path: PERSONAL_INFORMATION,
            title: 'Personal Info Verification',
        },
        {
            path: PERSONAL_INFO_VERIFY,
            title: 'Personal Info Verification',
        },
        {
            path: PERSONAL_FILING_SELECT,
            title: 'Personal Info Verification',
        },
        ...checkFilingRoutes(),
        {
            path: PERSONAL_PROGRESS,
            title: 'Personal Info Verification',
        },
    ];

    return steps;
};

export default personalInfoSteps;
