import { Fragment } from 'react';
import styled from 'styled-components';
import { toUsCurrency } from 'lib';

function EmploymentIncome({ data, info }) {
    return (
        <Fragment>
            <div>
                {info && <h2>{info.name}</h2>}
                <EmploymentWrapper>
                    <h1>Employer: {data.employer}</h1>
                    {data.occupation && <p>{data.occupation}</p>}
                    {data.reported_date && (
                        <p>
                            <span>Start Date</span>
                            <span>:&nbsp;{data.reported_date}</span>
                        </p>
                    )}
                    {data.address && <p>{data.address}</p>}
                    {data.monthly_income && (
                        <p>
                            <span>Estimated Monthly Income</span>
                            <span>:&nbsp;{data.monthly_income}</span>
                        </p>
                    )}
                    {data.net_amount && (
                        <p>
                            <span>Net Income</span>
                            <span>:&nbsp;{data.net_amount}</span>
                        </p>
                    )}
                    {data.additional_income > 0 && (
                        <p>
                            <span>Additional Income</span>
                            <span>
                                :&nbsp;{toUsCurrency(data.additional_income)}
                            </span>
                        </p>
                    )}
                </EmploymentWrapper>
            </div>
        </Fragment>
    );
}

const EmploymentWrapper = styled.div`
    h1 {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #0f173a;
        position: relative;
        margin: 5px 0;
        padding-left: 15px;

        &:before {
            position: absolute;
            content: '';
            top: 5px;
            left: 0;
            width: 0;
            height: 0;
            border-top: 7px solid #000000;
            border-right: 9px solid transparent;
        }
    }
    p {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #4f5260;
        display: flex;
        align-items: center;
        span {
            &:first-child {
                width: 65%;
                display: block;
            }
        }
    }
`;

export default EmploymentIncome;
