import { parseAmount } from 'lib';
function mapAdditionalIncome(data) {
    const {
        deposit_reason,
        income_transaction,
        profile,
        rental_expense,
        total_rental_expense_amount,
        business_name,
        worked_from,
        worked_to,
        income_type,
        deposit_description,
        uid,
        reasons,
    } = data;

    switch (reasons[deposit_reason]) {
        case 'Rental Income': {
            let rental_income = {};
            if (total_rental_expense_amount) {
                rental_income = {
                    rental_expense,
                    total_rental_expense_amount: parseAmount(
                        total_rental_expense_amount
                    ),
                };
            } else {
                rental_income = {
                    rental_expense,
                };
            }
            return {
                profile: profile,
                source: 'plaid',
                deposit_reason: deposit_reason,
                income_transaction: income_transaction,
                verified: 'true',
                uid: uid,
                rental_income,
            };
        }

        case 'No Longer Working Here': {
            return {
                profile: profile,
                source: 'plaid',
                deposit_reason: deposit_reason,
                income_transaction: income_transaction,
                verified: 'true',
                uid: uid,
                others_income: {
                    business_name,
                    worked_from,
                    worked_to,
                },
            };
        }

        case 'Part Time Job': {
            return {
                profile: profile,
                source: 'plaid',
                deposit_reason: deposit_reason,
                income_transaction: income_transaction,
                verified: 'true',
                uid: uid,
                others_income: {
                    business_name,
                    income_type,
                },
            };
        }

        case 'Other': {
            return {
                profile: profile,
                source: 'plaid',
                deposit_reason: deposit_reason,
                income_transaction: income_transaction,
                verified: 'true',
                uid: uid,
                others_income: {
                    deposit_description,
                },
            };
        }
        default:
            return {
                profile: profile,
                source: 'plaid',
                deposit_reason: deposit_reason,
                income_transaction: income_transaction,
                verified: 'true',
                uid: uid,
            };
    }
}

export default mapAdditionalIncome;
