/* eslint-disable no-undef */
import { Fragment, useState, useEffect } from 'react';
import { RadioButtonGroup, RadioButton } from 'carbon-components-react';
import axios from 'axios';
import { ButtonBlock } from 'components/Buttons';
import { endpoints } from 'api';
import SetOtp from './SetOtp';
import { hideAppLoader, showAppLoader } from '../../lib';

const phoneTypeValue = {
    Cell: 'cell',
    Home: 'home_phone',
    WorkPhone: 'work_phone',
};

function RequestOtpPhase({ client }) {
    const [phoneType, setPhoneType] = useState('cell');
    const [method, setMethod] = useState('sms');
    const [authId, setAuthId] = useState(null);
    const [isResend, toggleIsResend] = useState(false); // To show Resend OTP code on OTP request button

    const { Email, Cell, Home, WorkPhone, CaseID } = client;
    const phoneNumbers = {
        Cell,
        Home,
        WorkPhone,
    };

    useEffect(() => {
        // On initialize check which number available, then set the number as default phone type
        if (Cell) {
            setPhoneType(phoneTypeValue['Cell']);
        } else if (Home) {
            setPhoneType(phoneTypeValue['Home']);
        } else if (WorkPhone) {
            setPhoneType(phoneTypeValue['WorkPhone']);
        } else {
            return;
        }
    }, [Cell, Home, WorkPhone]);

    // Filtering client's available numbers
    // The array filter is used to refine only RadioButton(s).
    // undefined/null option is not accepted as child of RadioButtonGroup
    const phoneOptions = Object.keys(phoneNumbers)
        .map((type, key) => {
            if (phoneNumbers[type]) {
                return (
                    <RadioButton
                        id={`phone_${type}`}
                        labelText={phoneNumbers[type]}
                        value={phoneTypeValue[type]}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        })
        .filter((option) => !!option);

    const handlePhoneSelection = (phoneType) => {
        setPhoneType(phoneType);

        // Method 'sms' is available for only cell thus make phone method available when sms is disabled.
        if (phoneType !== 'Cell') setMethod('call');
    };

    const requestOtp = async () => {
        const body = {
            method:
                process.env['REACT_APP_SIGN_HARDCODED'] === 'true'
                    ? 'hardcoded'
                    : method,
            email: Email,
            case_id: CaseID,
            phone: phoneType,
        };

        try {
            showAppLoader();
            const req = await axios.post(endpoints.OTP_INIT, body);
            const res = await req.data;
            hideAppLoader();

            setAuthId(res.result.authy_id);
        } catch (e) {
            hideAppLoader();
            console.log('Error', e.response);
        }
    };

    // Resend OTP Callback
    const enableResendOtp = () => {
        setAuthId(null);
        toggleIsResend(true);
    };

    return (
        <Fragment>
            <h1>
                Select <span className="color-blue">Verification</span> Method{' '}
            </h1>
            <p className="welcome-text">
                To complete your account authorization, please select a delivery method
                to receive a 7-digit access code.
            </p>
            <h2>Phone Number</h2>
            <RadioButtonGroup
                orientation="vertical"
                labelPosition="right"
                defaultSelected={'cell'}
                legend="Group Legend"
                name="sign-in-type"
                valueSelected={phoneType}
                className="sign-phase-radio-button"
                onChange={handlePhoneSelection}
            >
                {phoneOptions}
            </RadioButtonGroup>

            <h2>Delivery Method</h2>

            <RadioButtonGroup
                orientation="vertical"
                labelPosition="right"
                defaultSelected="call"
                legend="Group Legend"
                name="otp-method"
                valueSelected={method}
                className="sign-phase-radio-button"
                onChange={setMethod}
            >
                <RadioButton
                    id="sms"
                    labelText="Send Text Message"
                    value="sms"
                    disabled={phoneType !== 'cell'}
                />
                <RadioButton id="call" labelText="Call Me" value="call" />
            </RadioButtonGroup>

            {authId ? (
                <SetOtp
                    authId={authId}
                    onResend={enableResendOtp}
                    caseId={CaseID}
                />
            ) : (
                <ButtonBlock
                    kind="primary"
                    size="field"
                    className="mt-24"
                    onClick={requestOtp}
                >
                    {isResend ? 'Resend OTP Code' : 'Send Verification Code'}
                </ButtonBlock>
            )}
        </Fragment>
    );
}

export default RequestOtpPhase;
