import {
    EXPENSES,
    EXPENSES_AMOUNTS,
    EXPENSES_SPOUSE,
    EXPENSES_AMOUNTS_SPOUSE,
} from 'routes/paths';

import { EMP_STEP_TITLE } from 'const';

function generateExpenseSteps(employment, spouse) {
    if (spouse) {
        const { spouseHasExpenses } = employment;
        const expenseSteps = [
            {
                path: EXPENSES_SPOUSE,
                title: EMP_STEP_TITLE,
            },
            {
                path: EXPENSES_AMOUNTS_SPOUSE,
                title: EMP_STEP_TITLE,
            },
        ];

        if (!spouseHasExpenses) {
            expenseSteps.pop();
        }

        return expenseSteps;
    } else {
        const { hasExpenses } = employment;
        const expenseSteps = [
            {
                path: EXPENSES,
                title: EMP_STEP_TITLE,
            },
            {
                path: EXPENSES_AMOUNTS,
                title: EMP_STEP_TITLE,
            },
        ];

        if (!hasExpenses) {
            expenseSteps.pop();
        }

        return expenseSteps;
    }
}

export default generateExpenseSteps;
