import generateSelfEmpSteps from './generateSelfEmpSteps';
import generateFullTimeSteps from './generateFullTimeSteps';
import generateAdditionalIncomeSteps from './generateAdditionalIncomeSteps';
import {
    EMPLOYMENT_INCOME_VERIFICATION,
    EMPLOYMENT_PROGRESS,
} from 'routes/paths';
import {
    EMPLOYMENT_TYPE_CONTROLLER,
    STATUS_MARRIED,
    STATUS_MARRIED_SEPARATELY,
    EMP_STEP_TITLE,
} from 'const';
import generateExpenseSteps from './generateExpenseSteps';

const generateEmploymentTypeSteps = (
    employment,
    profile,
    additionalInfo = {}
) => {
    const { personal, spouse } = employment;
    const { type } = profile ? spouse : personal;

    switch (type) {
        // Both
        case EMPLOYMENT_TYPE_CONTROLLER.both:
            return [
                ...generateFullTimeSteps(employment, profile, additionalInfo),
                ...generateSelfEmpSteps(employment, profile, additionalInfo),
            ];

        // Self-Employed / 1099
        case EMPLOYMENT_TYPE_CONTROLLER.selfEmployed:
            return generateSelfEmpSteps(employment, profile, additionalInfo);

        // Retired
        case EMPLOYMENT_TYPE_CONTROLLER.retired:
            return [];

        // Unemployed
        case EMPLOYMENT_TYPE_CONTROLLER.unemployed:
            return [];

        // Default is Full Time / W-2
        default:
            return generateFullTimeSteps(employment, profile, additionalInfo);
    }
};

const spouseEmploymentSteps = (profile) => {
    const {
        filingStatus,
        spouseUid,
        employment,
        spouseDetail,
        additionalInfo,
    } = profile;

    const steps = [];

    if (
        filingStatus === STATUS_MARRIED ||
        (filingStatus === STATUS_MARRIED_SEPARATELY && spouseDetail)
    ) {
        // Client additional info
        const {
            spouse_contribute_household,
            spouses_online_bank_info,
        } = additionalInfo;

        if (spouse_contribute_household && spouses_online_bank_info) {
            steps.push(
                ...generateEmploymentTypeSteps(
                    employment,
                    spouseUid,
                    additionalInfo
                ),
                ...generateAdditionalIncomeSteps(
                    employment,
                    spouseUid,
                    additionalInfo
                )
            );
        }
    }

    return steps;
};

const employmentSteps = (profile) => {
    const { employment, additionalInfo } = profile;
    const steps = [
        {
            path: EMPLOYMENT_INCOME_VERIFICATION,
            title: EMP_STEP_TITLE,
        },
        ...generateEmploymentTypeSteps(employment, null, additionalInfo),
        ...generateExpenseSteps(employment),
        ...generateAdditionalIncomeSteps(employment, false, additionalInfo),
        ...spouseEmploymentSteps(profile),
        {
            path: EMPLOYMENT_PROGRESS,
            title: EMP_STEP_TITLE,
        },
    ];

    return steps;
};

export default employmentSteps;
