import {
    ADDITIONAL_INCOME_VERIFICATION,
    ESTIMATE_ADDITIONAL_INCOME,
    MANUAL_ENTRY_ADDITIONAL_INCOME,
    SPOUSE_ADDITIONAL_INCOME_VERIFICATION,
    SPOUSE_ESTIMATE_ADDITIONAL_INCOME,
    SPOUSE_MANUAL_ENTRY_ADDITIONAL_INCOME,
    ADDITIONAL_INCOME,
    SPOUSE_ADDITIONAL_INCOME,
    MANUAL_ADDITIONAL_INCOME,
    MANUAL_ADDITIONAL_INCOME_ADD,
    SPOUSE_MANUAL_ADDITIONAL_INCOME,
    SPOUSE_MANUAL_ADDITIONAL_INCOME_ADD,
} from 'routes/paths';

import { EMP_STEP_TITLE } from 'const';

export default function generateAdditionalIncomeSteps(
    employment,
    spouse,
    additionalInfo
) {
    const {
        personalAdditionalCorrect,
        spouseAdditionalCorrect,
        personalHasAdditionalIncome,
        spouseHasAdditionalIncome,
        ignoresAdditionalIncome,
        spouseIgnoresAdditionalIncome,
    } = employment;

    if (spouse) {
        const {
            spouses_income_in_your_bank: joint_bank,
            has_manual_personal_bank,
            spouse: spouseInfo,
        } = additionalInfo;
        const steps = [
            {
                path: SPOUSE_ADDITIONAL_INCOME,
                title: EMP_STEP_TITLE,
            },
        ];

        const setRegularFlow = () => {
            if (spouseHasAdditionalIncome) {
                steps.push({
                    path: SPOUSE_ADDITIONAL_INCOME_VERIFICATION,
                    title: EMP_STEP_TITLE,
                });

                if (spouseIgnoresAdditionalIncome) {
                    steps.push({
                        path: SPOUSE_MANUAL_ENTRY_ADDITIONAL_INCOME,
                        title: EMP_STEP_TITLE,
                    });
                } else {
                    steps.push({
                        path: SPOUSE_ESTIMATE_ADDITIONAL_INCOME,
                        title: EMP_STEP_TITLE,
                    });

                    if (!spouseAdditionalCorrect) {
                        steps.push({
                            path: SPOUSE_MANUAL_ENTRY_ADDITIONAL_INCOME,
                            title: EMP_STEP_TITLE,
                        });
                    }
                }
            }
        };

        const setManualFlow = () => {
            steps.splice(0, 1, {
                path: SPOUSE_MANUAL_ADDITIONAL_INCOME,
                title: EMP_STEP_TITLE,
            });

            if (spouseInfo.has_manual_additional_income) {
                steps.push({
                    path: SPOUSE_MANUAL_ADDITIONAL_INCOME_ADD,
                    title: EMP_STEP_TITLE,
                });
            }
        };

        if (joint_bank) {
            has_manual_personal_bank ? setManualFlow() : setRegularFlow();
        } else {
            spouseInfo.has_manual_personal_bank
                ? setManualFlow()
                : setRegularFlow();
        }

        return steps;
    } else {
        const {
            has_manual_personal_bank,
            has_manual_additional_income,
        } = additionalInfo;

        const steps = [
            {
                path: ADDITIONAL_INCOME,
                title: EMP_STEP_TITLE,
            },
        ];

        const setRegularFlow = () => {
            if (personalHasAdditionalIncome) {
                steps.push({
                    path: ADDITIONAL_INCOME_VERIFICATION,
                    title: EMP_STEP_TITLE,
                });

                if (ignoresAdditionalIncome) {
                    steps.push({
                        path: MANUAL_ENTRY_ADDITIONAL_INCOME,
                        title: EMP_STEP_TITLE,
                    });
                } else {
                    steps.push({
                        path: ESTIMATE_ADDITIONAL_INCOME,
                        title: EMP_STEP_TITLE,
                    });

                    if (!personalAdditionalCorrect) {
                        steps.push({
                            path: MANUAL_ENTRY_ADDITIONAL_INCOME,
                            title: EMP_STEP_TITLE,
                        });
                    }
                }
            }
        };

        const setManualFlow = () => {
            steps.splice(0, 1, {
                path: MANUAL_ADDITIONAL_INCOME,
                title: EMP_STEP_TITLE,
            });

            if (has_manual_additional_income) {
                steps.push({
                    path: MANUAL_ADDITIONAL_INCOME_ADD,
                    title: EMP_STEP_TITLE,
                });
            }
        };

        if (has_manual_personal_bank) {
            setManualFlow();
        } else {
            setRegularFlow();
        }

        return steps;
    }
}
