import { FormGroup } from 'carbon-components-react';
import { Close24 } from '@carbon/icons-react';
import { Input } from 'components/Form';
import styled from 'styled-components';
import { BANK_ACCOUNTS_TYPE, BANK_TYPE} from 'const';
import { Dropdown } from 'components/Form';
import { AmountInput } from 'components/Utilities';

function BankInformation(props) {
    const { index, onClear, values} = props;

    return (
        <Wrapper>
            {values.bankInformation.length > 1 &&
                <Close onClick={onClear}/>
            }
            <HiddenInput
                name={`bankInformation[${index}].uid`}
                disabled
                labelText=""
            />
            <FormGroup legendText="">
                <Input
                    name={`bankInformation[${index}].bank_name`}
                    labelText="Name of Institution"
                />
            </FormGroup>
            <FormGroup legendText="">
                <AmountInput
                    name={`bankInformation[${index}].balance`}
                    labelText="Estimated Balance"
                />
            </FormGroup>

            <FormGroup legendText="">
                <Input
                    name={`bankInformation[${index}].acc_numbers`}
                    labelText="Account Number"
                    type="number"
                />
            </FormGroup>
            <FormGroup legendText="">
                <Dropdown
                    id={`bankInformation[${index}].bank_source`}
                    name={`bankInformation[${index}].bank_source`}
                    titleText="Account Type"
                    items={BANK_ACCOUNTS_TYPE}
                />
            </FormGroup>
            <FormGroup legendText="">
                <Dropdown
                    id={`bankInformation[${index}].bank_type`}
                    name={`bankInformation[${index}].bank_type`}
                    titleText="Bank Type"
                    items={BANK_TYPE}
                />
            </FormGroup>
        </Wrapper>
    );
}

const Wrapper = styled.section`
    border: 1px solid #d8e5f0;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 32px;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -17px;
        height: 1px;
        background: #cdd3d8;
    }

    &:last-child::after {
        content: none;
    }
`;

const HiddenInput = styled(Input)`
    height: 1px;
    overflow: hidden;
    width: 1px;
    display: none;
`;

const Close = styled(Close24)`
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 8px;
    color: #161616;
    z-index: 9;
`;

Wrapper.displayName = 'Wrapper';
Close.displayName = 'Close';

export default BankInformation;
