import { Fragment } from 'react';
import styled from 'styled-components';

function HouseHoldInfo({ data }) {
    return (
        <Fragment>
            {data.length === 0 && (
                <HouseHoldWrapper>
                    <h1>No Dependent Found.</h1>
                </HouseHoldWrapper>
            )}
            {data.map((dataItem, id) => {
                return (
                    <HouseHoldWrapper key={`dependent_${id}`}>
                        <h1>
                            Dependent <strong>({id + 1})</strong>
                        </h1>
                        <p>{dataItem.dependent}</p>
                        <p>{dataItem.dob}</p>
                        <p>{dataItem.relationship}</p>
                        {
                            dataItem.has_claim_on_tax_return ? <p>Claimed on Tax Year <strong> {dataItem.claim_on_tax_year}</strong></p> : ''
                        }
                    </HouseHoldWrapper>
                );
            })}
        </Fragment>
    );
}

const HouseHoldWrapper = styled.div`
    &:not(:last-child) {
        border-bottom: 1px solid rgb(112 112 112 / 0.16);
    }

    h1 {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #0f173a;
        padding-left: 15px;
        position: relative;
        margin: 10px 0;
        &:before {
            position: absolute;
            content: '';
            top: 5px;
            left: 0;
            width: 0;
            height: 0;
            border-top: 7px solid #000000;
            border-right: 9px solid transparent;
        }
        strong {
            color: #008ac8;
        }
    }
    p {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #4f5260;
    }
`;

export default HouseHoldInfo;
