import { useState } from 'react';
import MetaContext from './MetaContext';

const brandName = 'Verifilink';

function MetaProvider({ children }) {
    const [pageTitle, setPageTitle] = useState(brandName);

    const updatePageTitle = (title) => {
        // Updating title
        document.title = title ? `${brandName} | ${title}` : brandName;
        // Update page title
        setPageTitle(title || brandName);
    };

    return (
        <MetaContext.Provider
            value={{
                meta: {
                    pageTitle,
                },
                updateMeta: {
                    updatePageTitle,
                },
            }}
        >
            {children}
        </MetaContext.Provider>
    );
}

export default MetaProvider;
