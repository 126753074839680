import { useField } from 'formik';
import { Checkbox as CarbonCheckbox } from 'carbon-components-react';
import PropType from 'prop-types';

function Checkbox(props) {
    const {
        labelText,
        name,
        className,
        id,
        disabled,
        hideLabel,
        title,
        wrapperClassName,
        onChange,
    } = props;
    // eslint-disable-next-line no-unused-vars
    const [field, meta, helper] = useField(name);

    const { value } = field;
    const { setValue } = helper;

    const handleChange = (e) => {
        setValue(e);
        if(onChange){
            onChange(e);
        }
    };

    return (
        <CarbonCheckbox
            checked={value}
            className={className}
            disabled={disabled}
            id={id}
            labelText={labelText}
            hideLabel={hideLabel}
            onChange={handleChange}
            title={title}
            wrapperClassName={wrapperClassName}
        />
    );
}

Checkbox.propTypes = {
    labelText: PropType.string,
    name: PropType.string.isRequired,
    className: PropType.string,
    id: PropType.string.isRequired,
    disabled: PropType.bool,
    hideLabel: PropType.bool,
    title: PropType.string,
    wrapperClassName: PropType.string,
};

export default Checkbox;
