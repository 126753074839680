import { Fragment } from 'react';
import styled from 'styled-components';
import { toUsCurrency } from 'lib';

function BothIncome({ business, fulltime, info }) {
    return (
        <Fragment>
            <div>
                {info && <h2>{info.name}</h2>}
                <EmploymentWrapper>
                    {fulltime && (
                        <Fragment>
                            {fulltime.employer && (
                                <h1>Employer: {fulltime.employer}</h1>
                            )}
                            {fulltime.occupation && (
                                <p>{fulltime.occupation}</p>
                            )}
                            {fulltime.reported_date && (
                                <p>
                                    <span>Start Date</span>
                                    <span>:&nbsp;{fulltime.reported_date}</span>
                                </p>
                            )}
                            {fulltime.address && <p>{fulltime.address}</p>}
                            {fulltime.monthly_income && (
                                <p>
                                    <span>
                                        Estimated Monthly Income For W-2
                                    </span>
                                    <span>
                                        :&nbsp;{fulltime.monthly_income}
                                    </span>
                                </p>
                            )}
                        </Fragment>
                    )}
                    {business && (
                        <div>
                            {business.business_info.business_name && (
                                <h1>
                                    Business Name:{' '}
                                    {business.business_info.business_name}
                                </h1>
                            )}
                            {business.business_info.business_structure_type && (
                                <p>
                                    Business Type:&nbsp;
                                    {
                                        business.business_info
                                            .business_structure_type
                                    }
                                </p>
                            )}
                            {business.business_info.address.business && (
                                <p>{business.business_info.address.business}</p>
                            )}
                            {business.business_info
                                .employer_identification_number && (
                                    <p>
                                        <span>EIN</span>
                                        <span>
                                            :&nbsp;
                                            {business.business_info.employer_identification_number.replace(
                                                /(\d{2})/,
                                                '$1-'
                                            )}
                                        </span>
                                    </p>
                                )}
                        </div>
                    )}
                    {business && (
                        <div>
                            {business.financial_info.no_of_employees > 0 &&
                                business.financial_info
                                    .tax_deposit_frequency && (
                                    <p>
                                        <span>Frequency of Tax Deposits</span>
                                        <span>
                                            :&nbsp;
                                            {
                                                business.financial_info
                                                    .tax_deposit_frequency
                                            }
                                        </span>
                                    </p>
                                )}
                            {business.financial_info.no_of_employees > 0 && (
                                <p>
                                    <span>Total Number of Employees</span>
                                    <span>
                                        :&nbsp;
                                        {
                                            business.financial_info
                                                .no_of_employees
                                        }
                                    </span>
                                </p>
                            )}
                            {business.financial_info.no_of_employees > 0 &&
                                business.financial_info
                                    .employee_income_type && (
                                    <p>
                                        <span>Employee Income Type</span>
                                        <span>
                                            :&nbsp;
                                            {
                                                business.financial_info
                                                    .employee_income_type
                                            }
                                        </span>
                                    </p>
                                )}
                        </div>
                    )}
                    {business && (
                        <div>
                            <p>
                                <span>
                                    Estimated Monthly Income For Business
                                </span>
                                <span>
                                    :&nbsp;
                                    {toUsCurrency(
                                        business.gross_monthly_self_employed_income
                                    )}
                                </span>
                            </p>
                            {business.additional_income.amount > 0 && (
                                <p>
                                    <span>Additional Income</span>
                                    <span>
                                        :&nbsp;
                                        {toUsCurrency(
                                            business.additional_income.amount
                                        )}
                                    </span>
                                </p>
                            )}
                        </div>
                    )}
                </EmploymentWrapper>
            </div>
        </Fragment>
    );
}

const EmploymentWrapper = styled.div`
    h1 {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #0f173a;
        position: relative;
        margin: 5px 0;
        padding-left: 15px;

        &:before {
            position: absolute;
            content: '';
            top: 5px;
            left: 0;
            width: 0;
            height: 0;
            border-top: 7px solid #000000;
            border-right: 9px solid transparent;
        }
    }
    p {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #4f5260;
        display: flex;
        align-items: center;
        span {
            &:first-child {
                width: 65%;
                display: block;
            }
        }
    }
`;

export default BothIncome;
