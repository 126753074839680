import { Fragment } from 'react';
import { FormattedInput } from 'components/Form';
import { FormGroup } from 'carbon-components-react';
import { Input } from '../../../components/Form';
import styled from 'styled-components';
import { DATE_FORMAT, DATE_PATTERN, MIN_DATE } from 'const';

function NoLongerWorkingHere({ index }) {
    return (
        <Fragment>
            <FormGroup legendText="" className={'mb-16'}>
                <Input
                    labelText={'Name of Employer'}
                    id="business_name"
                    name={`data[${index}].business_name`}
                    type="text"
                    placeholder="Type your employer name"
                />
            </FormGroup>

            <NoLongerWorkingHereWrapper>
                <div className="bx--grid bx--no-gutter">
                    <div className="bx--row">
                        <div className="bx--col mb-16">
                            <FormattedInput
                                labelText={'Worked From'}
                                formatOptions={{
                                    date: true,
                                    delimiter: '-',
                                    dateMin: MIN_DATE,
                                    datePattern: DATE_PATTERN,
                                }}
                                name={`data[${index}].worked_from`}
                                placeholder={DATE_FORMAT}
                            />
                        </div>
                        <div className="bx--col mb-16">
                            <FormattedInput
                                labelText={'Worked To'}
                                formatOptions={{
                                    date: true,
                                    delimiter: '-',
                                    dateMin: MIN_DATE,
                                    datePattern: DATE_PATTERN,
                                }}
                                name={`data[${index}].worked_to`}
                                placeholder={DATE_FORMAT}
                            />
                        </div>
                    </div>
                </div>
            </NoLongerWorkingHereWrapper>
        </Fragment>
    );
}

const NoLongerWorkingHereWrapper = styled.div`
    margin-top: 16px;
`;

export default NoLongerWorkingHere;
