/* eslint-disable no-undef */
import { useContext, useState } from 'react';
import { AuthContext } from '../../contexts';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { PERSONAL_INFORMATION } from '../../routes/paths';
import { TextInput } from 'carbon-components-react';
import { ButtonBlock } from 'components/Buttons';
import { endpoints } from 'api';
import { hideAppLoader, showAppLoader, showServerErrorAlert } from '../../lib';
import { history } from 'routes';
import TimeCount from 'components/TimeCount';
import styled from 'styled-components';
import { OTP_MIN_LEN, OTP_MAX_LEN} from 'const';

/* const handlePersistenceLogin = (routes, path) => {
    const index = routes.findIndex(r => r.path === path);

    for(let i=0; i<index; i++){
        history.push(routes[i].path);
    }

    history.push(path);
}*/

const SetOtp = ({ authId, caseId, onResend }) => {
    const [timerRunning, toggleTimerRunning] = useState(true);
    const auth = useContext(AuthContext);
    const { authDispatch } = auth;

    const signIn = async (values) => {
        try {
            showAppLoader();
            const body = new FormData();
            body.append('grant_type', 'password');
            body.append('otp', '1');
            body.append('username', authId);
            body.append('password', values.password);
            body.append('case_id', caseId);

            const req = await axios.post(endpoints.OTP_LOGIN, body, {
                auth: {
                    username: process.env['REACT_APP_CLIENT_ID'],
                    password: process.env['REACT_APP_CLIENT_SECRET'],
                },
            });

            const res = await req.data;

            authDispatch({
                type: 'LOGIN',
                payload: {
                    access_token: res.access_token,
                    refresh_token: res.refresh_token,
                },
            });

            hideAppLoader();

            if (
                res['request_meta'] &&
                res.request_meta.client_path &&
                res.request_meta.client_path !== '/'
            ) {
                // handlePersistenceLogin(routes, res.request_meta.client_path);
                history.push(res.request_meta.client_path);
            } else {
                history.push(PERSONAL_INFORMATION);
            }
        } catch (error) {
            hideAppLoader();
            const { status } = error.response;

            if (status === 400) {
                form.setFieldError('password', 'Invalid verification code');
            }

            if (status === 500) {
                showServerErrorAlert();
            }
        }
    };

    const form = useFormik({
        initialValues: {
            password: '',
        },
        onSubmit: signIn,
        validationSchema: validSchema,
    });

    return (
        <form>
            <TextInput
                invalid={form.errors.password && form.touched.password}
                id="otp_token"
                name="password"
                invalidText={form.errors.password}
                labelText="Enter Verification Code"
                placeholder="Enter Verification Code"
                onChange={form.handleChange}
            />

            <div className="mt-16">
                {timerRunning ? (
                    <p style={{ fontSize: 12 }}>
                        Request OTP again in{' '}
                        <TimeCount
                            counter={60}
                            onEnd={() => toggleTimerRunning(false)}
                        />
                    </p>
                ) : (
                    <ResendBtn onClick={onResend}>
                        <span className="color-blue">Request OTP again</span>
                    </ResendBtn>
                )}
            </div>

            <ButtonBlock
                type={'submit'}
                kind="primary"
                size="field"
                className="mt-24"
                onClick={form.handleSubmit}
            >
                Continue
            </ButtonBlock>
        </form>
    );
};

const validSchema = Yup.object().shape({
    password: Yup
                .string()
                .required('Provide Proper OTP')
                .matches(/^[0-9]+$/, "Must be only digits")
                .min(OTP_MIN_LEN, `Must be exactly ${OTP_MIN_LEN} digits`)
                .max(OTP_MAX_LEN, `Must be exactly ${OTP_MAX_LEN} digits`)
});

const ResendBtn = styled.span`
    cursor: pointer;
    font-weight: normal;
    font-size: 12px;
`;

export default SetOtp;
