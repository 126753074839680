import { Checkbox } from '../Form';
import styled from 'styled-components';

function BankType({ icon, disabled, id, name, defaultChecked, label }) {
    return (
        <Container>
            <Checkbox labelText={''} name={name} id={id} disabled={disabled} />
            <span className="icon-box">
                <img src={icon} alt="" />
            </span>
            <p>{label}</p>
        </Container>
    );
}

const Container = styled('div')`
    display: flex;
    background: #f2f9fc;
    padding: 15px 10px 15px 10px;
    align-items: center;
    margin-bottom: 10px;

    .bx--checkbox-label::before {
        border: 2px solid #008ac8;
    }
    .bx--checkbox:checked + .bx--checkbox-label::before {
        background: #008ac8;
        border: 2px solid #008ac8;
    }
    .bx--form-item {
        flex: inherit;
        margin-right: 12px;
    }
    .icon-box {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #008ac8;
        box-shadow: 0 8px 32px rgb(18 133 187 / 20%);
    }
    p {
        font-size: 14px;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0px;
        color: #008ac8;
        margin: 0 0 0 10px;
    }
`;

export default BankType;
