import { Fragment } from 'react';
import styled from 'styled-components';
import { toUsCurrency } from 'lib';

function Vehicles({ vehicles }) {
    const financedVehicle = vehicles.filter(
        (data) => data.source !== 'manual' && data.ownership_status === 'owned'
    );
    const additionalVehicle = vehicles.filter(
        (data) => data.source === 'manual' && data.ownership_status === 'owned'
    );

    return (
        <Fragment>
            <TitlePart>
                <h1>
                    Vehicle Info{' '}
                    {vehicles.length === 0 ? (
                        <span className="color-blue">(0)</span>
                    ) : (
                        ''
                    )}
                </h1>
            </TitlePart>
            {financedVehicle.length > 0 && (
                <Fragment>
                    {financedVehicle.map((dataItem, id) => {
                        return (
                            <VehiclesWrapper key={`vehicle_${id}`}>
                                <h1>
                                    FINANCED VEHICLE
                                    <span className="color-blue">
                                        ({id + 1})
                                    </span>
                                </h1>
                                <p>
                                    <span>Monthly Payment </span>
                                    <span>
                                        :&nbsp;{dataItem.monthly_payment}
                                    </span>
                                </p>
                                <p>
                                    <span>Year</span>
                                    <span>
                                        :&nbsp;{dataItem.vehicle_info.year}
                                    </span>
                                </p>
                                <p>
                                    <span>Make</span>
                                    <span>
                                        :&nbsp;{dataItem.vehicle_info.make}
                                    </span>
                                </p>
                                <p>
                                    <span>Model</span>
                                    <span>
                                        :&nbsp;{dataItem.vehicle_info.model}
                                    </span>
                                </p>
                                <p>
                                    <span>Odometer(Mile)</span>
                                    <span>
                                        :&nbsp;{dataItem.vehicle_info.odometer}
                                    </span>
                                </p>
                                {dataItem.market_value > 0 && (
                                    <p>
                                        <span> Market Value</span>
                                        <span>
                                            :&nbsp;
                                            {toUsCurrency(
                                                dataItem.market_value
                                            )}
                                        </span>
                                    </p>
                                )}
                            </VehiclesWrapper>
                        );
                    })}
                </Fragment>
            )}
            {additionalVehicle.length > 0 && (
                <Fragment>
                    {additionalVehicle.map((dataItem, id) => {
                        return (
                            <VehiclesWrapper key={`vehicle_${id}`}>
                                <h1>
                                    {financedVehicle.length > 0
                                        ? 'ADDITIONAL'
                                        : ''}{' '}
                                    VEHICLE
                                    <span className="color-blue">
                                        ({id + 1})
                                    </span>
                                </h1>
                                <p>
                                    <span>Year</span>
                                    <span>
                                        :&nbsp;{dataItem.vehicle_info.year}
                                    </span>
                                </p>
                                <p>
                                    <span>Make</span>
                                    <span>
                                        :&nbsp;{dataItem.vehicle_info.make}
                                    </span>
                                </p>
                                <p>
                                    <span>Model</span>
                                    <span>
                                        :&nbsp;{dataItem.vehicle_info.model}
                                    </span>
                                </p>
                                <p>
                                    <span>Odometer(Mile)</span>
                                    <span>
                                        :&nbsp;{dataItem.vehicle_info.odometer}
                                    </span>
                                </p>
                                {dataItem.market_value > 0 && (
                                    <p>
                                        <span>Market Value</span>
                                        <span>
                                            :&nbsp;
                                            {toUsCurrency(
                                                dataItem.market_value
                                            )}
                                        </span>
                                    </p>
                                )}
                            </VehiclesWrapper>
                        );
                    })}
                </Fragment>
            )}
        </Fragment>
    );
}

const TitlePart = styled.div`
    h1 {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #0f173a;
        position: relative;
        padding-left: 15px;
        &:before {
            position: absolute;
            content: '';
            top: 5px;
            left: 0;
            width: 0;
            height: 0;
            border-top: 7px solid #000000;
            border-right: 9px solid transparent;
        }
    }
`;
const VehiclesWrapper = styled.div`
    &:not(:last-child) {
        border-bottom: 1px solid rgb(112 112 112 / 0.16);
        margin-bottom: 7px;
    }
    h1 {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #0f173a;
    }
    p {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #4f5260;
        display: flex;
        align-items: center;
        span {
            &:first-child {
                width: 65%;
                display: block;
            }
        }
    }
`;

export default Vehicles;
