import { Switch, Route } from 'react-router-dom';
import PersonalInfoVerification from './PersonalInfoVerification';
import FilingStatus from './FilingStatus';
import {
    PERSONAL_FILING_SELECT,
    PERSONAL_INFORMATION,
    PERSONAL_SPOUSE_INFO,
    PERSONAL_INFO_VERIFY,
    PERSONAL_PROGRESS,
} from '../../routes/paths';
import SpouseInformation from './SpouseInformation';
import PersonalWelcome from 'screens/Welcome/PersonalWelcome';
import Progress from 'screens/Progress';
import { PageNotFound } from 'screens/Errors';

export default function PersonalInfo() {
    return (
        <Switch>
            <Route exact path={PERSONAL_PROGRESS}>
                <Progress />
            </Route>
            <Route exact path={PERSONAL_SPOUSE_INFO}>
                <SpouseInformation />
            </Route>
            <Route exact path={PERSONAL_FILING_SELECT}>
                <FilingStatus />
            </Route>
            <Route exact path={PERSONAL_INFO_VERIFY}>
                <PersonalInfoVerification />
            </Route>
            <Route exact path={PERSONAL_INFORMATION}>
                <PersonalWelcome />
            </Route>
            <Route path="*">
                <PageNotFound />
            </Route>
        </Switch>
    );
}
