import { toUsCurrency } from 'lib';
import states from './states.json';
export const STATES_LIST_DROPDOWN = states;
export const ORDINAL_NUMBERS = {
    1: 'first',
    2: 'second',
    3: 'third',
    4: 'fourth',
    5: 'fifth',
    6: 'sixth',
    7: 'seventh',
    8: 'eighth',
    9: 'ninth',
    10: 'tenth',
};

export const STATUS_MARRIED = 'married_filing_jointly';
export const STATUS_MARRIED_SEPARATELY = 'married_filing_seperately';
export const STATUS_HEAD_OF_HOUSEHOLD = 'head_of_household';
export const STATUS_WIDOW_WITH_DEPENDENT = 'qualifying_widow_with_dependent';

export const INCOME_TYPE = {
    employer: 1,
    allow_null: 1,
    additional: 2,
};

export const ADDRESS_TYPE = {
    primary: 'primary',
    mailing: 'mailing',
    primary_mailing: 'primary_and_mailing',
};

export const EMPLOYMENT_TYPE_CONTROLLER = {
    fullTime: 'fullTime',
    notFound: 'Not Found',
    selfEmployed: 'Self Employed',
    retired: 'Retired',
    unemployed: 'Unemployed',
    both: 'Both',
};

export const EMPLOYMENT_TYPES = {
    'W-2': {
        id: 'W-2',
        label: 'W-2 (Receive a Paycheck)',
        contextType: EMPLOYMENT_TYPE_CONTROLLER.fullTime,
    },
    '1099': {
        id: '1099',
        label: '1099 (Self Employed)',
        contextType: EMPLOYMENT_TYPE_CONTROLLER.selfEmployed,
    },
    both: {
        id: 'both',
        label: 'Both (W-2 & 1099)',
        contextType: EMPLOYMENT_TYPE_CONTROLLER.both,
    },
    Retired: {
        id: 'Retired',
        label: 'I’m Retired',
        contextType: EMPLOYMENT_TYPE_CONTROLLER.retired,
    },
    Unemployed: {
        id: 'Unemployed',
        label: 'I’m Unemployed',
        contextType: EMPLOYMENT_TYPE_CONTROLLER.unemployed,
    },
    NOT_SURE: {
        id: 'NOT_SURE',
        label: 'I’m Not Sure',
    },
};

export const GOOGLE_MAP_API_KEY = 'AIzaSyAETEY0hDqXxhYaGytH-eBO0OcamD_wUns';

export const FILING_STATUS_LABEL = {
    single: 'Single',
    married_filing_jointly: 'Married Filing Jointly',
    married_filing_seperately: 'Married Filing Seperately',
    head_of_household: 'Head of Household',
    qualifying_widow_with_dependent: 'Qualifying Widow With Dependent',
};

export const SELF_PRIMARY_BUSINESS_KEY = 'self_employed_primary';
export const SELF_SECONDARY_BUSINESS_KEY = 'self_employed_secondary';

export const TAX_DEPOSIT_FREQUENCY = [
    { id: 'monthly', label: 'Monthly' },
    { id: 'semi_weekly', label: 'Semi-Weekly' },
    { id: 'Quarterly', label: 'Quarterly' },
];

export const NUMBER_OF_EMPLOYEES = [
    { id: '1-100', label: '1 - 100' },
    { id: '101-200', label: '101 - 200' },
    { id: '201-300', label: '201 - 300' },
    { id: '301-400', label: '301 - 400' },
    { id: '401-500', label: '401 - 500' },
];

export const BASIC_CHOICE = [
    { id: true, label: 'Yes' },
    { id: false, label: 'No' },
];

export const SPOUSE_INCOME_TYPE = [
    { id: 'wage_earner', label: 'W-2(Wage Earner)' },
    { id: 'self_employed', label: '1099(Self Employed)' },
    { id: 'both', label: 'Both' },
    { id: 'not_sure', label: "I'm Not Sure" },
];

export const DATE_PATTERN = ['m', 'd', 'Y'];
export const DATE_FORMAT = 'MM-DD-YYYY';
export const COMBINED_EMPLOYMENT = 'Combined';

// Object key for progress screen steps
export const PROGRESS_KEY = {
    personal: 'personal',
    dependent: 'dependent',
    bank: 'bank',
    employment: 'employment',
    investment: 'investment',
};

export const BUSINESS_STRUCTURES = [
    { id: 'Sole-Proprietorship', label: 'Sole Proprietorship' },
    { id: 'Single-Member-LLC', label: 'Single Member LLC' },
    { id: 'Multi-Member-LLC', label: 'Multi Member LLC' },
    { id: 'Corporation', label: 'Corporation (S or C)' },
    { id: 'Partnership', label: 'Partnership' },
    { id: 'Other', label: 'Other' },
];

export const BANK_ACCOUNTS_TYPE = [
    { id: 'personal', label: 'Personal' },
    { id: 'business', label: 'Business' },
];

export const BANK_TYPE = [
    { id: 'checking', label: 'Checking' },
    { id: 'savings', label: 'Savings' },
];

/*
STOCKS = 'stocks'
_401K = '401k'
_403A = '403A'
_403B = '403B'
_403C = '403C'
IRA = 'ira'
BONDS = 'bonds'
KEOGH = 'keogh'
WHOLE_LIFE_INSURANCE = 'wli'
VIRTUAL_CURRENCY = 'virtual_currency'
*/

export const INVESTMENT_ACCOUNT_TYPE = [
    { id: 'stocks', label: 'Stocks' },
    { id: '401k', label: '401k' },
    { id: 'ira', label: 'IRA' },
    { id: 'bonds', label: 'Bonds' },
    { id: 'wli', label: 'Whole Life Insurance' },
    { id: 'keogh', label: 'Keogh' },
    { id: '403A', label: '403A' },
    { id: '403B', label: '403B' },
    { id: '403C', label: '403C' },
    { id: 'virtual_currency', label: 'Virtual Currency' }
];

export const DEPENDENT_TAX_RETURN_YEARS = [
    { label: '2010', value: '2010' },
    { label: '2011', value: '2011' },
    { label: '2012', value: '2012' },
    { label: '2013', value: '2013' },
    { label: '2014', value: '2014' },
    { label: '2014', value: '2015' },
    { label: '2016', value: '2016' },
    { label: '2017', value: '2017' },
    { label: '2018', value: '2018' },
    { label: '2019', value: '2019' },
    { label: '2020', value: '2020' },
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
];

export const MIN_NAME_CHARS = 1;
export const MAX_NAME_CHARS = 30;
export const MIN_ADDRESS_CHARS = 1;
export const MAX_ADDRESS_CHARS = 35;
export const MAX_BANK_NAME = 64;
export const MAX_ACCOUNT_NUMBER = 17;
export const MAX_AMOUNT_PROPERTIES = 999999999;
export const MAX_AMOUNT_VEHICLES = 999999999;
export const MIN_DATE = '1920-01-01';
// Taken from https://regexr.com/3ashn
export const EMAIL_REGEX = /^(?:(?:[\w`~!#$%^&*\-=+;:{}'|,?/]+(?:(?:\.(?:"(?:\\?[\w`~!#$%^&*\-=+;:{}'|,?/.()<>[\] @]|\\"|\\\\)*"|[\w`~!#$%^&*\-=+;:{}'|,?/]+))*\.[\w`~!#$%^&*\-=+;:{}'|,?/]+)?)|(?:"(?:\\?[\w`~!#$%^&*\-=+;:{}'|,?/.()<>[\] @]|\\"|\\\\)+"))@(?:[a-zA-Z\d-]+(?:\.[a-zA-Z\d-]+)*|\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])$/;
export const MAX_AMOUNT = 999999999;
export const MAX_AMOUNT_US = toUsCurrency(MAX_AMOUNT);
export const EMP_STEP_TITLE = 'Employment, Income & Expenses';
export const MIN_AMOUNT = 1;
export const MIN_AMOUNT_US = toUsCurrency(MIN_AMOUNT);
export const OTP_MIN_LEN =
    process.env['REACT_APP_OTP_ENV'] === 'development' ? 1 : 7;
export const OTP_MAX_LEN =
    process.env['REACT_APP_OTP_ENV'] === 'development' ? 15 : 7;
