import { Fragment, useState, useEffect, useContext } from 'react';
import Navigation from 'components/Navigation';
import { Formik } from 'formik';
import { FormGroup } from 'carbon-components-react';
import { Input, SwitchInput } from 'components/Form';
import * as Yup from 'yup';
import api, { endpoints } from 'api';
import { PersonalInfoContext, contextActionTypes } from 'contexts';
import { useStepController } from 'hooks';
import { history } from 'routes';
import useBusinessInfo from './useBusinessInfo';
import useFinanceInfo from './useFinanceInfo';

const { SELF_HAS_EMPLOYEES, SELF_HAS_EMPLOYEES_SPOUSE } = contextActionTypes;

function BusinessEmployees({ spouse }) {
    const [initialValues, updateInitialValues] = useState({
        employees: '',
        no_of_employees: '',
    });

    const { personalUid, spouseUid, dispatchEmployment } = useContext(
        PersonalInfoContext
    );
    const { previousStep, nextStep } = useStepController();
    const { businessInfo } = useBusinessInfo(spouse ? spouseUid : personalUid);
    const { financeInfo } = useFinanceInfo(spouse ? spouseUid : personalUid);

    useEffect(() => {
        const { employees, no_of_employees, uid } = financeInfo;

        if (uid) {
            updateInitialValues({
                employees,
                no_of_employees,
            });

            dispatchEmployment({
                type: spouse ? SELF_HAS_EMPLOYEES_SPOUSE : SELF_HAS_EMPLOYEES,
                payload: employees,
            });
        }
    }, [financeInfo, spouse, dispatchEmployment]);

    const submit = async (values) => {
        const financeUid = financeInfo.uid;

        if (!values.employees) {
            values['no_of_employees'] = null;
        }

        const data = {
            profile: spouse ? spouseUid : personalUid,
            verified: true,
            business_info: businessInfo.uid,
            ...values,
        };

        try {
            let url = endpoints.SELF_EMPLOYED_FINANCIAL_INFO;
            if (financeUid) url += `${financeUid}/`;

            const req = await api({
                method: financeUid ? 'patch' : 'post',
                data,
                url,
            });

            const res = req.data;

            if (res) history.push(nextStep.path);
        } catch (e) {
            console.log(e.response);
        }
    };

    const goPreviousStep = () => history.push(previousStep.path);

    const changeHandler = (hasEmployees) => {
        dispatchEmployment({
            type: spouse ? SELF_HAS_EMPLOYEES_SPOUSE : SELF_HAS_EMPLOYEES,
            payload: hasEmployees,
        });
    };

    return (
        <Formik
            initialValues={initialValues}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={submit}
            validationSchema={validation}
        >
            {({ values, handleSubmit, isValid }) => (
                <Fragment>
                    <h1 className="mb-32">
                        Does your{' '}
                        {spouse ? (
                            <span className="color-blue">spouse's</span>
                        ) : (
                            ''
                        )}{' '}
                        business have
                        <span className="color-blue"> employees?</span>
                    </h1>

                    <FormGroup legendText="">
                        <SwitchInput
                            id={'employees'}
                            name={'employees'}
                            onChange={changeHandler}
                        />
                    </FormGroup>

                    {values.employees && (
                        <FormGroup legendText="" className="mt-32">
                            {spouse ? (
                                <h2>
                                    How many employees does your spouse have?
                                </h2>
                            ) : (
                                <h2>
                                    How many employees does your business have?
                                </h2>
                            )}

                            <Input
                                labelText="Number of Employees"
                                name={'no_of_employees'}
                                type="number"
                            />
                        </FormGroup>
                    )}

                    <Navigation
                        disableNext={!isValid}
                        onNext={handleSubmit}
                        onPrevious={goPreviousStep}
                        typeNext={'submit'}
                    />
                </Fragment>
            )}
        </Formik>
    );
}

const validation = Yup.object().shape({
    employees: Yup.boolean().required('Required field'),
    no_of_employees: Yup.mixed()
        .nullable()
        .when('employees', {
            is: true,
            then: Yup.number()
                .integer()
                .min(2, 'Minimum number of employees are greater or equal to 2')
                .max(2147483647)
                .required('Required field'),
        }),
});

export default BusinessEmployees;
