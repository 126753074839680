// eslint-disable-next-line no-unused-vars
import { useState, useContext, useEffect, useMemo } from 'react';
import {
    InvestmentContext,
    MetaContext,
    PersonalInfoContext,
    AdditionalInfoContext,
} from '../../contexts';
import personalInfoSteps from './personalInfoSteps';
import linkBankAccountSteps from './linkBankAccountSteps';
import { INCOME_SUMMARY } from '../../routes/paths';
import assetInvestmentSteps from './assetInvestmentSteps';
import { history } from 'routes';
import householdSteps from './householdSteps';
import employmentSteps from './employmentSteps';

const summary = {
    path: INCOME_SUMMARY,
    title: 'Summary',
};

/**
 * useStepController
 * A custom react hook, that determines what will be the next step and previous step depending on the current step!
 *
 * Idea:
 * List all the available steps, then add or remove step from the list depending on the business logic.
 *
 * Suppose following is our steps list:
 * const steps = [
 *  "Personal Info Step",
 *  "Filing Status Step",
 *  "Spouse Info",
 *  "Add Dependent"
 *  ...
 * ]
 *
 * If the client is Single then remove the "Spouse Info" step from the list.
 *
 * So the steps list will look like,
 *
 * const steps = [
 *  "Personal Info Step",
 *  "Filing Status Step",
 *  "Add Dependent",
 *  ...
 * ]
 *
 * If the client is on the "Filing Status Step" then useStepController will return the following.
 * {
 *  perviousStep: "Personal Info Step",
 *  nextStep: "Add Dependent",
 *  activeStep: Filing Status Step",
 *  steps: [
 *      "Personal Info Step",
 *      "Filing Status Step",
 *      "Add Dependent",
 *      ...
 *  ]
 * }
 *
 * The data of the business logic e.g. Filing Status, Earning Type, Dependents, Assets, Investments etc can be grabbed from the contexts.
 *
 * @return {object} The next step, previous step, current step, and full steps list.
 */
const useStepController = () => {
    const [flow, updateFlow] = useState([]); // Steps list state

    // Personal context
    const {
        filingStatus,
        spouseUid,
        employment,
        noOfDependents,
        spouseDetail,
    } = useContext(PersonalInfoContext);

    // Asset Investment context
    const { investments } = useContext(InvestmentContext);

    // Title and Progress context
    const {
        updateMeta: { updatePageTitle },
    } = useContext(MetaContext);

    // Client Additional Info Context
    const { additionalInfo } = useContext(AdditionalInfoContext);

    // Browser history, depending on it next and previous step are calculated.
    const {
        location: { pathname },
    } = history;

    // Effect to generate routes based on cases
    useEffect(() => {
        // Steps List
        const steps = [
            // Personal Info Steps Generator
            ...personalInfoSteps(filingStatus),
            // Household and Dependents Steps Generator
            ...householdSteps(additionalInfo, filingStatus),
            // Link bank Account Steps Generator
            ...linkBankAccountSteps({
                filingStatus,
                spouseDetail,
                additionalInfo,
                employment,
            }),
            // Employment Steps Generator
            ...employmentSteps({
                filingStatus,
                spouseUid,
                employment,
                spouseDetail,
                additionalInfo,
            }),
            // Asset, Vehicle and Investments Steps Generator
            ...assetInvestmentSteps({
                filingStatus,
                spouseUid,
                investments,
                spouseDetail,
                additionalInfo,
            }),
            // Summary Step, Hardcoded
            summary,
        ];

        /**
         * When any of the dependencies update, steps list regenerates using the new update.
         * E.g. Client select "Married" on the filing status thus "filingStatus" dependency is changed/updated,
         * "personalInfoSteps" function is called again and insert the Spouse Personal Info to the steps list.
         */
        updateFlow(steps.filter((el) => el != null));
    }, [
        filingStatus,
        spouseUid,
        employment,
        investments,
        noOfDependents,
        spouseDetail,
        additionalInfo,
    ]);

    // Check the index of the current step in the steps list
    const indexOfCurrentRoute = useMemo(() => {
        return flow.findIndex((el) => {
            // Handling trailing slash of url
            const path = pathname.slice(-1) === '/' ? pathname : `${pathname}/`;
            return el.path === path;
        });
    }, [pathname, flow]);

    // Update steps title and page title on step change
    useEffect(() => {
        const activeStep = flow[indexOfCurrentRoute];

        if (activeStep) {
            updatePageTitle(activeStep.title);
        } else {
            updatePageTitle('');
        }
    }, [indexOfCurrentRoute, flow, updatePageTitle]);

    // Return Previous Step, Next Step, Active Step and Steps List
    return {
        previousStep:
            indexOfCurrentRoute <= 0 ? flow[0] : flow[indexOfCurrentRoute - 1],
        nextStep: flow[indexOfCurrentRoute + 1],
        activeStep: flow[indexOfCurrentRoute],
        steps: flow,
    };
};

export default useStepController;
