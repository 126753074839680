import { Fragment, useContext, useState, useEffect } from 'react';
import { Dropdown } from 'carbon-components-react';
import { useStepController, useAdditionalInfo } from '../../hooks';
import Navigation from 'components/Navigation';
import api, { endpoints } from 'api';
import { history } from 'routes';
import { PersonalInfoContext, contextActionTypes } from 'contexts';
import { EMPLOYMENT_TYPES, EMPLOYMENT_TYPE_CONTROLLER } from 'const';

const { EMPLOYMENT_TYPE, EMPLOYMENT_TYPE_SPOUSE } = contextActionTypes;

const TYPES = [
    EMPLOYMENT_TYPES['W-2'],
    EMPLOYMENT_TYPES['1099'],
    EMPLOYMENT_TYPES.both,
    EMPLOYMENT_TYPES.Retired,
    EMPLOYMENT_TYPES.Unemployed,
    EMPLOYMENT_TYPES.NOT_SURE,
];

const SPOUSE_TYPES = [
    EMPLOYMENT_TYPES['W-2'],
    EMPLOYMENT_TYPES['1099'],
    EMPLOYMENT_TYPES.both,
    {
        id: 'Retired',
        label: 'They’re Retired',
        contextType: EMPLOYMENT_TYPE_CONTROLLER.retired,
    },
    {
        id: 'Unemployed',
        label: 'They’re Unemployed',
        contextType: EMPLOYMENT_TYPE_CONTROLLER.unemployed,
    },
    EMPLOYMENT_TYPES.NOT_SURE,
];

const header = (
    <h1>
        How do you receive income?
    </h1>
);

const spouseHeader = (
    <h1>
        How does your <span className="color-blue">Spouse</span> earn their{' '}
        <span className="color-blue">income?</span>
    </h1>
);

function EmploymentType({ spouse }) {
    const [type, setType] = useState(null);

    const { personalUid, dispatchEmployment, spouseUid } = useContext(
        PersonalInfoContext
    );

    const { nextStep, previousStep } = useStepController();
    const { income_type, uid } = useAdditionalInfo(
        spouse ? spouseUid : personalUid
    );

    useEffect(() => {
        if (income_type) {
            const income_employments = TYPES.map((t) => t.id);

            if (income_employments.includes(income_type)) {
                const type = Object.assign({}, EMPLOYMENT_TYPES[income_type]);

                // Change label for Spouse, when retired
                if (spouse && type.label === EMPLOYMENT_TYPES.Retired.label) {
                    type.label = 'They’re Retired';
                }

                // Change label for Spouse, when unemployed
                if (
                    spouse &&
                    type.label === EMPLOYMENT_TYPES.Unemployed.label
                ) {
                    type.label = 'They’re Unemployed';
                }

                setType(type);

                dispatchEmployment({
                    type: spouse ? EMPLOYMENT_TYPE_SPOUSE : EMPLOYMENT_TYPE,
                    payload: type.contextType,
                });
            }
        }
    }, [income_type, spouse, dispatchEmployment]);

    const onChange = ({ selectedItem }) => {
        setType(selectedItem);

        // Update employment context for step controller
        dispatchEmployment({
            type: spouse ? EMPLOYMENT_TYPE_SPOUSE : EMPLOYMENT_TYPE,
            payload: EMPLOYMENT_TYPES[selectedItem.id].contextType,
        });
    };

    const saveEmploymentType = async () => {
        try {
            const req = await api({
                method: 'patch',
                url: `${endpoints.CLIENT_ADDITIONAL_INFO}${uid}/`,
                data: {
                    profile: spouse ? spouseUid : personalUid,
                    income_type: type.id,
                },
            });

            const res = await req.data;

            if (res) history.push(nextStep.path);
        } catch (e) {
            console.log(e.response);
        }
    };

    return (
        <Fragment>
            {spouse ? spouseHeader : header}
            <div className="my-30">
                <Dropdown
                    items={spouse ? SPOUSE_TYPES : TYPES}
                    id="income_type"
                    label=""
                    titleText="Income Type"
                    onChange={onChange}
                    selectedItem={type}
                />
            </div>

            {type && type.id === 'NOT_SURE' && <MessageNotSure />}

            <Navigation
                disableNext={!type || (type && type.id === 'NOT_SURE')}
                onPrevious={() => history.push(previousStep.path)}
                onNext={saveEmploymentType}
                className="mt-24"
            />
        </Fragment>
    );
}

function MessageNotSure() {
    return (
        <Fragment>
            <section className="mb-25">
                <h1>
                    <span className="underline-bold">W-2</span> (Wage Earner)
                </h1>
                <p>
                    If you’re a full time employee that receives a paycheck from
                    their employer. Your taxes are either exempt or withheld
                    from your paycheck.
                </p>
            </section>

            <section className="mb-25">
                <h1>
                    <span className="underline-bold">1099</span> (Self Employed)
                </h1>
                <p>
                    You are a self employed contractor or freelancer. Taxes are
                    not withheld from your paycheck, and are required to be paid
                    either quarterly or at the end of the year.
                </p>
            </section>
        </Fragment>
    );
}

export default EmploymentType;
