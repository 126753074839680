import { Fragment, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    STATUS_MARRIED,
    FILING_STATUS_LABEL,
    STATUS_MARRIED_SEPARATELY,
    STATUS_WIDOW_WITH_DEPENDENT,
} from '../../const';
const spouseInfoFilings = [
    STATUS_MARRIED,
    STATUS_MARRIED_SEPARATELY,
    STATUS_WIDOW_WITH_DEPENDENT,
];
function PersonalInfo({ personal, spouse, filing, extraInfo, spouseInfo }) {
    const [show, setShow] = useState();

    useEffect(() => {
        setShow(STATUS_WIDOW_WITH_DEPENDENT === filing);
    }, [filing]);

    const showSpouseInfo = useCallback(() => {
        return spouseInfoFilings.indexOf(filing) >= 0;
    }, [filing]);

    return (
        <Fragment>
            <PersonalInfoWrapper>
                <h1>{personal.name}</h1>
                <p>{personal.address}</p>
                <p>{personal.dob}</p>
                {personal.email && <p>{personal.email}</p>}
                {personal.home_phone && <p>Home: {personal.home_phone}</p>}
                {personal.mobile_phone && (
                    <p>Mobile: {personal.mobile_phone}</p>
                )}
                <hr className="border-line" />
                <h1>Filing Status</h1>
                <p>{FILING_STATUS_LABEL[filing]}</p>

                {extraInfo.marriage_date && <p>{extraInfo.marriage_date}</p>}
                {show && <p>{spouse.spouse_death_date}</p>}

                {showSpouseInfo() && (
                    <div>
                        <hr className="border-line" />
                        {spouse && (
                            <Fragment>
                                <h1>{spouseInfo.name}</h1>
                                <p>{spouse.address}</p>
                                <p>{spouse.dob}</p>
                            </Fragment>
                        )}
                        {spouse.email && <p>{spouse.email}</p>}
                        {spouse.home_phone && <p>Home: {spouse.home_phone}</p>}
                        {spouse.mobile_phone && (
                            <p>Mobile: {spouse.mobile_phone}</p>
                        )}
                    </div>
                )}
            </PersonalInfoWrapper>
        </Fragment>
    );
}

const PersonalInfoWrapper = styled.div`
    h1 {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #0f173a;
        position: relative;
        margin: 8px 0;
        padding-left: 15px;
        &:before {
            position: absolute;
            content: '';
            top: 5px;
            left: 0;
            width: 0;
            height: 0;
            border-top: 7px solid #000000;
            border-right: 9px solid transparent;
        }
    }
    p {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #4f5260;
        margin-top: 8px;
    }
`;

export default PersonalInfo;
