import { useMemo } from 'react';
import { useFormikContext } from 'formik';
import RentalIncome from './RentalIncome/RentalIncome';
import PartTimeJob from './PartTimeJob/PartTimeJob';
import { convertDateToString, toUsCurrency } from 'lib';
import Other from './Other/Other';
import NoLongerWorkingHere from './NoLongerWorkingHere/NoLongerWorkingHere';
import styled from 'styled-components';
import { Dropdown } from 'components/Form';
import placeholder_icon from '../../assets/images/bank/placeholder.png';

const baseURL = `${process.env['REACT_APP_API_PROTOCOL']}${process.env['REACT_APP_API_ENDPOINT']}`;

const renderType = (type, index) => {
    switch (type) {
        case 'Rental Income':
            return <RentalIncome index={index} />;
        case 'No Longer Working Here':
            return <NoLongerWorkingHere index={index} />;
        case 'Part Time Job':
            return <PartTimeJob index={index} />;
        case 'Other':
            return <Other index={index} />;
        default:
            return null;
    }
};

function DepositReasonDecision({ reasonTypes, index, reason, typeObj }) {
    const { amount, normalized_group_name, from_date, to_date, bank_icon, bank_name, acc_numbers} = reason;
    const fromDate = convertDateToString(from_date);
    const toDate = convertDateToString(to_date);
    const {
        values: { data },
    } = useFormikContext();

    const value = useMemo(() => {
        if (data[index]) {
            return data[index].deposit_reason;
        } else {
            return '';
        }
    }, [data, index]);

    const bank_image_url = bank_icon ? (baseURL + bank_icon) : placeholder_icon;

    return (
        <AdditionalIncomeWrapper>
            <div className="additional-income">
                <span>
                    <h1>{toUsCurrency(amount)} - <span className="date">({fromDate}-{toDate})</span></h1>
                    <p className="description">{normalized_group_name}</p>
                    <p className="bank-name">{bank_name}</p>
                    {acc_numbers.map((acc, id) => {
                        return (
                            <p key={id}>{acc}</p>
                        );
                    })}
                </span>
                <span>
                    <img src={bank_image_url} alt="TaxRise" />
                </span>
            </div>

            <Dropdown
                id={`data[${index}].deposit_reason`}
                name={`data[${index}].deposit_reason`}
                items={reasonTypes}
                className="mb-16"
            />

            {renderType(typeObj[value], index)}
        </AdditionalIncomeWrapper>
    );
}

const AdditionalIncomeWrapper = styled.div`
    top: 343px;
    left: 32px;
    border: 1px solid #d8e5f0;
    border-radius: 4px;
    opacity: 1;
    margin-bottom: 12px;
    padding: 16px 16px 0;
    h1 {
        color: var(--unnamed-color-008ac8);
        text-align: left;
        font: normal normal 600 14px/21px Poppins;
        letter-spacing: 0px;
        color: #008ac8;
        opacity: 1;
    }
    p {
        color: var(--unnamed-color-0f212e);
        text-align: left;
        font: normal normal medium 14px/21px Poppins;
        letter-spacing: 0px;
        color: #0f212e;
        opacity: 1;
    }
    .date {
        color:#6A7581
    } 
    .description {
        text-align: left;
        font: normal normal medium 14px/21px Poppins;
        letter-spacing: 0px;
        color: #7EA0C7;
        opacity: 1;
        &>span {
           word-break: break-word;
        }
    }
    .bank-name {
        text-align: left;
        font: normal normal medium 14px/21px Poppins;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
    }
    img {
        height: 42px;
        width: 42px;
        margin-bottom: 40px;
    }
    .additional-income {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #4f5260;
        display: flex;
        align-items: center;
        span {
            &:first-child {
                width: 90%;
            }
        }
    }               
`;

export default DepositReasonDecision;
