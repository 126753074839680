import Styled from 'styled-components';

function SignInList(props) {
    const { header, subHeader } = props;

    return (
        <Wrapper>
            <h1>
                {header}<br/>
                {subHeader}
            </h1>
        </Wrapper>
    );
}

const Wrapper = Styled.div`        
    h1 {
        font: var(--unnamed-font-style-normal) normal medium var(--unnamed-font-size-14)/var(--unnamed-line-spacing-21) var(--unnamed-font-family-poppins);
        text-align: left;
        font: normal normal medium 14px/21px Poppins;
        letter-spacing: 0px;
        color: #0F212E;
        opacity: 1;
        margin-bottom: 0px;
    }
`;
export default SignInList;
