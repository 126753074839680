import { FormGroup } from 'carbon-components-react';
import { Close24 } from '@carbon/icons-react';
import { Input, FormattedInput } from 'components/Form';
import styled from 'styled-components';
import {
    INVESTMENT_ACCOUNT_TYPE,
    DATE_PATTERN,
    DATE_FORMAT,
    MIN_DATE,
} from 'const';
import { Dropdown } from 'components/Form';
import { AmountInput } from 'components/Utilities';
import { AmountWrapper } from 'components/Styled';
import Switch from "components/Form/SwitchInput";

function InvestmentForm(props) {
    const { index, onClear, values } = props;
    const { account_sub_type, current_market_value } = values.investmentAccounts[index];
    const currentMarketValue = current_market_value + '';
    return (
        <Wrapper>
            <h2 className="color-blue">Investment Account ({index + 1})</h2>
            <Close onClick={onClear} />
            <HiddenInput
                name={`investmentAccounts[${index}].uid`}
                disabled
                labelText=""
            />

            <FormGroup legendText="">
                <Dropdown
                    id={`investmentAccounts[${index}].account_sub_type`}
                    name={`investmentAccounts[${index}].account_sub_type`}
                    titleText="Account Type"
                    items={INVESTMENT_ACCOUNT_TYPE}
                />
            </FormGroup>

            {
                account_sub_type === 'virtual_currency' ?
                    <>
                        <FormGroup legendText="">
                            <Input
                                name={`investmentAccounts[${index}].institution_name`}
                                labelText="Name of Broker"
                            />
                        </FormGroup>
                        <FormGroup legendText="">
                            <AmountWrapper>
                                <AmountInput
                                    name={`investmentAccounts[${index}].current_market_value`}
                                    labelText="Market Value"
                                />
                                <span>$</span>
                            </AmountWrapper>
                        </FormGroup>
                        <FormGroup legendText="">
                            <FormattedInput
                                labelText={'Date of Market Value'}
                                formatOptions={{
                                    date: true,
                                    delimiter: '-',
                                    dateMin: MIN_DATE,
                                    datePattern: DATE_PATTERN,
                                }}
                                name={`investmentAccounts[${index}].date_of_current_market_value`}
                                placeholder={DATE_FORMAT}
                            />
                        </FormGroup>
                    </>
                    : <>

                        <FormGroup legendText="">
                            <Input
                                name={`investmentAccounts[${index}].institution_name`}
                                labelText="Name of Institution"
                            />
                        </FormGroup>
                        <FormGroup legendText="">
                            <AmountWrapper>
                                <AmountInput
                                    name={`investmentAccounts[${index}].current_market_value`}
                                    labelText="Market Value"
                                />
                                <span>$</span>
                            </AmountWrapper>
                        </FormGroup>

                        <FormGroup legendText="">
                            <AmountWrapper>
                                <AmountInput disabled={currentMarketValue > 0 || currentMarketValue.includes(',') ? false : true}
                                    name={`investmentAccounts[${index}].loan_balance`}
                                    labelText="Loan Value"
                                />
                                <span>$</span>
                            </AmountWrapper>
                        </FormGroup>

                        <FormGroup legendText="">
                            <Input
                                name={`investmentAccounts[${index}].account_number`}
                                labelText="Account Number"
                                type="number"
                            />
                        </FormGroup>

                        {
                            account_sub_type === '401k' || account_sub_type === 'ira' || account_sub_type === 'wli' ?
                                <FormGroup legendText="">
                                    <h5>
                                        Are you able to withdraw funds from this account?
                                    </h5>
                                    <br />
                                    <Switch
                                        yesText="Yes"
                                        noText="No"
                                        name={`investmentAccounts[${index}].withdrawable`}
                                    />
                                </FormGroup>
                                : ''
                        }
                    </>
            }

        </Wrapper>
    );
}

const Wrapper = styled.section`
    border: 1px solid #d8e5f0;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 32px;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -17px;
        height: 1px;
        background: #cdd3d8;
    }

    &:last-child::after {
        content: none;
    }
`;

const HiddenInput = styled(Input)`
    height: 1px;
    overflow: hidden;
    width: 1px;
    display: none;
`;

const Close = styled(Close24)`
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 8px;
    color: #161616;
    z-index: 9;
`;

Wrapper.displayName = 'Wrapper';
Close.displayName = 'Close';

export default InvestmentForm;
