import { Fragment } from 'react';
import styled from 'styled-components';

function Properties({ properties }) {
    const financedProperty = properties.filter(
        (data) => data.source !== 'manual' && data.ownership_status === 'owned'
    );
    const additionalProperty = properties.filter(
        (data) => data.source === 'manual' && data.ownership_status === 'owned'
    );
    return (
        <Fragment>
            <TitlePart>
                <h1>
                    Property info
                    {properties.length === 0 ? (
                        <span className="color-blue">(0)</span>
                    ) : (
                        ''
                    )}
                </h1>
            </TitlePart>
            {financedProperty.length > 0 && (
                <Fragment>
                    {financedProperty.map((dataItem, id) => {
                        console.log({ dataItem });

                        return (
                            <PropertiesWrapper key={`property_${id}`}>
                                <h1>
                                    MORTGAGED PROPERTIES{' '}
                                    <span className="color-blue">
                                        ({id + 1})
                                    </span>
                                </h1>
                                <p>
                                    <span>Outstanding Property Balance</span>
                                    <span>
                                        :&nbsp;{dataItem.outstanding_balance}
                                    </span>
                                </p>
                                <p>
                                    <span>Financed With</span>
                                    <span>
                                        :&nbsp;{dataItem.source_details}
                                    </span>
                                </p>
                                <p>{dataItem.address}</p>

                                <p>
                                    <span>Current Market Value</span>
                                    <span>:&nbsp;{dataItem.market_value}</span>
                                </p>

                                {
                                    dataItem.second_mortgages.length > 0 && <StyledSecondMortgage>Second Mortgage(s)</StyledSecondMortgage>
                                }

                                {
                                    dataItem.second_mortgages.length > 0
                                    && dataItem.second_mortgages.map((mortgage) => (<SecondMortgage key={mortgage.id} mortgage={mortgage} />))
                                }
                                <br />
                            </PropertiesWrapper>
                        );
                    })}
                </Fragment>
            )}
            {additionalProperty.length > 0 && (
                <Fragment>
                    {additionalProperty.map((dataItem, id) => {
                        return (
                            <PropertiesWrapper key={`property_${id}`}>
                                <h1>
                                    {financedProperty.length > 0
                                        ? 'ADDITIONAL'
                                        : ''}{' '}
                                    PROPERTIES{' '}
                                    <span className="color-blue">
                                        ({id + 1})
                                    </span>
                                </h1>
                                <p>{dataItem.address}</p>
                                <p>
                                    <span>Current Market Value</span>
                                    <span>:&nbsp;{dataItem.market_value}</span>
                                </p>
                            </PropertiesWrapper>
                        );
                    })}
                </Fragment>
            )}
        </Fragment>
    );
}

const StyledSecondMortgage = styled.div`
    font-size: 13px;
    font-weight: bold;
    color: #989898;
`;

const SecondMortgage = ({ mortgage }) => (
    <Fragment>
        <p>
            <span>Outstanding Balance</span>
            <span>:&nbsp;{mortgage.outstanding_balance}</span>
        </p>
        <p>
            <span>Financed with</span>
            <span>:&nbsp;{mortgage.source_details}</span>
        </p>
    </Fragment>
);


const TitlePart = styled.div`
    h1 {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #0f173a;
        position: relative;
        padding-left: 15px;
        &:before {
            position: absolute;
            content: '';
            top: 5px;
            left: 0;
            width: 0;
            height: 0;
            border-top: 7px solid #000000;
            border-right: 9px solid transparent;
        }
    }
`;
const PropertiesWrapper = styled.div`
    h1 {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #0f173a;
    }
    p {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.02px;
        text-align: left;
        color: #4f5260;
        display: flex;
        align-items: center;
        span {
            &:first-child {
                width: 65%;
                display: block;
            }
        }
    }
`;

export default Properties;
