import { useMemo } from 'react';
import { useField } from 'formik';
import PropType from 'prop-types';
import Switch from 'components/Switch';

function SwitchInput(props) {
    const { name, onChange } = props;
    // eslint-disable-next-line no-unused-vars
    const [field, meta, helper] = useField(name);

    const { value } = field;
    const { setValue } = helper;

    const handleChange = ({ name }) => {
        const val = name === 'yes';

        setValue(val);

        if (onChange) {
            onChange(val);
        }
    };

    const choice = useMemo(() => {
        if (typeof value === 'boolean') {
            return value === true ? 0 : 1;
        }
    }, [value]);

    return <Switch {...props} choice={choice} onChange={handleChange} />;
}

SwitchInput.propTypes = {
    name: PropType.string.isRequired,
    onChange: PropType.func,
};

export default SwitchInput;
