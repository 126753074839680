import { Fragment, useContext, useEffect, useState } from 'react';
import Navigation from 'components/Navigation';
import { useStepController } from 'hooks';
import { history } from 'routes';
import { FormGroup } from 'carbon-components-react';
import useBusinessInfo from './useBusinessInfo';
import { SELF_PRIMARY_BUSINESS_KEY } from 'const';
import { PersonalInfoContext } from 'contexts';
import { Formik } from 'formik';
import { Input, SwitchInput, Checkbox } from 'components/Form';
import * as Yup from 'yup';
import api, { endpoints } from 'api';
import { notification } from 'lib';

function BusinessIdentity({ spouse }) {
    const [initialValues, updateInitialValues] = useState({
        has_ein: null,
        employer_identification_number: '',
        skip: false,
    });

    const { nextStep, previousStep } = useStepController();
    const { personalUid, spouseUid } = useContext(PersonalInfoContext);
    const { businessInfo } = useBusinessInfo(
        spouse ? spouseUid : personalUid,
        SELF_PRIMARY_BUSINESS_KEY
    );

    useEffect(() => {
        if (businessInfo['uid']) {
            const { has_ein, employer_identification_number } = businessInfo;

            updateInitialValues((initValue) => ({
                ...initValue,
                has_ein,
                employer_identification_number:
                    employer_identification_number || '',
            }));
        }
    }, [businessInfo]);

    const submit = async (values) => {
        const { uid } = businessInfo;

        if (uid) {
            const { has_ein, skip } = values;

            // Reset EIN when user has no EIN or user not sure
            if (!has_ein || skip) {
                values['employer_identification_number'] = '';
            }

            const data = {
                ...values,
                profile: spouse ? spouseUid : personalUid,
                verified: true,
                self_employed_business_type: SELF_PRIMARY_BUSINESS_KEY,
            };

            try {
                const req = await api({
                    method: 'patch',
                    url: `${endpoints.SELF_EMPLOYED_BUSINESS_INFO}${uid}/`,
                    data,
                });

                const res = await req.data;

                if (res) {
                    history.push(nextStep.path);
                }
            } catch (e) {
                console.log(e.response);
            }
        } else {
            notification(
                'No Business',
                'Please add business info at first.',
                'warning'
            );
        }
    };

    return (
        <Fragment>
            <h1 className="mb-32">
                Does your{' '}
                {spouse ? <span className="color-blue">spouse's</span> : ''}{' '}
                business have an
                <span className="color-blue"> EIN number?</span>
            </h1>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={submit}
                validateOnMount
                validationSchema={validSchema}
            >
                {({ values, isValid, handleSubmit, errors }) => (
                    <Fragment>
                        <SwitchInput name="has_ein" />

                        {values.has_ein && (
                            <Fragment>
                                <FormGroup className="mt-32" legendText="">
                                    <h2>
                                        {spouse
                                            ? 'What is your spouse EIN number?'
                                            : 'What is your EIN number?'}
                                    </h2>
                                    <Input
                                        name="employer_identification_number"
                                        labelText="EIN Number"
                                    />
                                </FormGroup>
                                <FormGroup className="mt-32" legendText="">
                                    <Checkbox
                                        name="skip"
                                        id="skip"
                                        labelText="I’m Not Sure (Skip This)"
                                    />
                                </FormGroup>
                            </Fragment>
                        )}

                        <Navigation
                            disableNext={!isValid}
                            onNext={handleSubmit}
                            onPrevious={() => history.push(previousStep.path)}
                        />
                    </Fragment>
                )}
            </Formik>
        </Fragment>
    );
}

const validSchema = Yup.object().shape({
    has_ein: Yup.boolean().required('Select a choice'),
    skip: Yup.boolean().nullable(),
    employer_identification_number: Yup.string()
        .nullable()
        .when(['has_ein', 'skip'], function (has_ein, skip, schema) {
            if (has_ein && !skip) {
                return Yup.string()
                    .matches(
                        /^([07][1-7]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|9[0-589])-?\d{7}$/,
                        'Wrong EIN Format'
                    )
                    .required('Required field');
            }

            return schema;
        }),
});

export default BusinessIdentity;
