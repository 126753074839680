import { useState } from 'react';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { TextInput } from 'carbon-components-react';
import { useField } from 'formik';
import { formateAddressComponent } from 'lib';
import PropTypes from 'prop-types';
import { LoadGoogleMapScript } from 'components/GoogleMap';

function GoogleMapAutocomplete(props) {
    const {
        labelText,
        name,
        placeholder,
        className,
        id,
        onPlaceSelect,
        fillBy,
        dontLoadScript,
    } = props;

    const [field, meta, helper] = useField(name);

    const { value, onBlur } = field;
    const { touched, error } = meta;
    const { setValue } = helper;

    const onChange = (e) => {
        setValue(e.target.value);
    };

    const [searchBox, updateSearchBox] = useState({});

    // Save google map searchbox instance
    const onLoad = (ref) => {
        updateSearchBox(ref);
    };

    // Place selection change handler
    const onPlacesChanged = () => {
        const places = searchBox.getPlaces();
        const location = formateAddressComponent(places);
        const { address } = location;

        // Callback with address, city, zipCode and state
        onPlaceSelect(location);

        if (fillBy) {
            const result = places[0];
            const data = result[fillBy];

            if (data && typeof data === 'string') {
                setValue(data);
            } else {
                setValue(address);
            }
        } else {
            setValue(address);
        }
    };

    const searchField = (
        <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
            <TextInput
                type="text"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                labelText={labelText}
                id={id}
                placeholder={placeholder || ' '}
                className={className}
                invalid={error && touched}
                invalidText={error && touched ? error : ''}
            />
        </StandaloneSearchBox>
    );

    /**
     * For using this component multiple time on a page/component,
     * User have to Load Google Map Externally.
     * And pass the prop dontLoadScript to false!
     * Otherwise only the first Google Map Autocomplete will work!
     */
    if (dontLoadScript) {
        return searchField;
    }

    return <LoadGoogleMapScript>{searchField}</LoadGoogleMapScript>;
}

GoogleMapAutocomplete.propTypes = {
    labelText: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    onPlaceSelect: PropTypes.func,
    dontLoadScript: PropTypes.bool,
};

GoogleMapAutocomplete.defaultProps = {
    onPlaceSelect: () => {},
};

export default GoogleMapAutocomplete;
