import { Fragment, useState, useEffect, useContext } from 'react';
import Switch from 'components/Switch';
import Navigation from 'components/Navigation';
import { useAdditionalInfo, useStepController } from 'hooks';
import api, { endpoints } from 'api';
import { history } from 'routes';
import { PersonalInfoContext, AdditionalInfoContext } from 'contexts';
import PropType from 'prop-types';
import { notification } from 'lib';

/*
    Clients Decision Component
    ==========================
    This component update context and patch updates to Client Additional Info
 */

function Decision(props) {
    const { children, apiKey, contextKey, spouse } = props;

    const [selectedChoice, setChoice] = useState(null);

    const { personalUid, spouseUid } = useContext(PersonalInfoContext);
    const { dispatchAdditionalInfo } = useContext(AdditionalInfoContext);
    const { [apiKey]: apiChoice, uid } = useAdditionalInfo(
        spouse ? spouseUid : personalUid
    );
    const { nextStep, previousStep } = useStepController();

    useEffect(() => {
        if (typeof apiChoice === 'boolean') {
            setChoice(apiChoice ? 0 : 1);
        }
    }, [apiChoice]);

    const handleChoice = ({ index, name }) => {
        setChoice(index);

        if (contextKey) {
            dispatchAdditionalInfo({
                type: contextKey,
                payload: name === 'yes',
            });
        }
    };

    const saveChoice = async () => {
        if (!uid) {
            notification(
                'Something went wrong!',
                'Please refresh the page and try again. We are really sorry for this inconvenience!',
                'warning'
            );

            return;
        }

        try {
            const req = await api({
                method: 'patch',
                url: `${endpoints.CLIENT_ADDITIONAL_INFO}${uid}/`,
                data: {
                    profile: spouse ? spouseUid : personalUid,
                    [apiKey]: selectedChoice === 0,
                },
            });

            const res = await req.data;

            if (res) history.push(nextStep.path);
        } catch (e) {
            console.log(e.response);
        }
    };

    const goBack = () => {
        history.push(previousStep.path);
    };

    return (
        <Fragment>
            {children}

            <Switch
                className="mt-32"
                onChange={handleChoice}
                choice={selectedChoice}
            />

            <Navigation
                disableNext={!(typeof selectedChoice === 'number')}
                onNext={saveChoice}
                onPrevious={goBack}
            />
        </Fragment>
    );
}

Decision.propTypes = {
    children: PropType.any,
    apiKey: PropType.string.isRequired,
    contextKey: PropType.string,
};

export default Decision;
