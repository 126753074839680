import CalculateNetIncome from './CalculateNetIncome';
import {
    EMPLOYMENT_FULLTIME,
    EMPLOYMENT_FULLTIME_ESTIMATE,
    EMPLOYMENT_FULLTIME_DEPOSITS,
    EMPLOYMENT_FULLTIME_MANUAL_ESTIMATE,
    EMPLOYMENT_FULLTIME_REESTIMATE,
    EMPLOYMENT_FULLTIME_OWNERSHIP,
    SPOUSE_WORK,
    SPOUSE_WORK_INFO,
    SPOUSE_WORK_OWNERSHIP,
    SPOUSE_WORK_ESTIMATE,
    SPOUSE_WORK_DEPOSITS,
    SPOUSE_WORK_REESTIMATE,
    SPOUSE_WORK_MANUAL_ESTIMATE,
} from 'routes/paths';
import ManualIncome from './ManualIncome';
import EstimatedIncome from './EstimatedIncome';
import WorkInformation from './WorkInformation';
import PartOwnership from './PartOwnership';
import { InnerRoutes } from 'routes';
import ClientKnowsSpouseWork from './ClientKnowsSpouseWork';
import ReEstimatedIncome from './ReEstimatedIncome';

const workRoutes = [
    {
        path: SPOUSE_WORK_REESTIMATE,
        component: <ReEstimatedIncome spouse />,
        options: { exact: true },
    },
    {
        path: EMPLOYMENT_FULLTIME_REESTIMATE,
        component: <ReEstimatedIncome />,
        options: { exact: true },
    },
    {
        path: SPOUSE_WORK_MANUAL_ESTIMATE,
        component: <ManualIncome spouse />,
        options: { exact: true },
    },
    {
        path: EMPLOYMENT_FULLTIME_MANUAL_ESTIMATE,
        component: <ManualIncome />,
        options: { exact: true },
    },
    {
        path: SPOUSE_WORK_DEPOSITS,
        component: <CalculateNetIncome spouse />,
        options: { exact: true },
    },
    {
        path: EMPLOYMENT_FULLTIME_DEPOSITS,
        component: <CalculateNetIncome />,
        options: { exact: true },
    },
    {
        path: SPOUSE_WORK_ESTIMATE,
        component: <EstimatedIncome spouse />,
        options: { exact: true },
    },
    {
        path: EMPLOYMENT_FULLTIME_ESTIMATE,
        component: <EstimatedIncome />,
        options: { exact: true },
    },
    {
        path: SPOUSE_WORK_OWNERSHIP,
        component: <PartOwnership spouse />,
        options: { exact: true },
    },
    {
        path: EMPLOYMENT_FULLTIME_OWNERSHIP,
        component: <PartOwnership />,
        options: { exact: true },
    },
    {
        path: SPOUSE_WORK_INFO,
        component: <WorkInformation spouse />,
        options: { exact: true },
    },
    {
        path: SPOUSE_WORK,
        component: <ClientKnowsSpouseWork />,
        options: { exact: true },
    },
    {
        path: EMPLOYMENT_FULLTIME,
        component: <WorkInformation />,
        options: { exact: true },
    },
];

function FullTime() {
    return <InnerRoutes routes={workRoutes} />;
}

export default FullTime;
