import { contextActionTypes } from 'contexts';
import { Decision } from 'screens/Helper';

const {
    HAS_SEPARATE_BUSINESS_BANK,
    SPOUSE_HAS_BUSINESS_BANK,
} = contextActionTypes;

function SeparateBusinessBank({ spouse }) {
    if (spouse) return <SeparateBusinessSpouse />;

    return (
        <Decision
            apiKey="has_separate_business_account"
            contextKey={HAS_SEPARATE_BUSINESS_BANK}
        >
            <h1>
                Do you use a{' '}
                <span className="color-blue">separate business account</span>{' '}
                for your 1099 income?
            </h1>
        </Decision>
    );
}

function SeparateBusinessSpouse() {
    return (
        <Decision
            apiKey="has_separate_business_account"
            contextKey={SPOUSE_HAS_BUSINESS_BANK}
            spouse
        >
            <h1>
                Does your spouse use a{' '}
                <span className="color-blue">separate business bank</span> for
                their 1099 income?
            </h1>
        </Decision>
    );
}

export default SeparateBusinessBank;
