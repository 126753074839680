import { useField } from "formik";
import {TextArea} from "carbon-components-react";

function TextAreaInput(props) {
    const { labelText, name, placeholder, className, id } = props;
    const [field, meta, helper] = useField(name);

    const { value } = field;
    const { touched, error } = meta;
    const { setValue } = helper;

    const onChange = e => {
        setValue(e.target.value);
    }

    return (
        <TextArea
            id={id}
            labelText={labelText}
            placeholder={placeholder}
            className={className}
            value={value}
            onChange={onChange}
            invalid={error && touched}
            invalidText={error && touched ? error : ""}
            data-hj-allow
        />
    );
}

export default TextAreaInput;
