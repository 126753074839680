import { Fragment } from 'react';
import { TextAreaInput } from "../../../components/Form";
import {FormGroup} from "carbon-components-react";

function Other({index}) {
    return (
        <Fragment>
            <FormGroup legendText="" className={"mb-16"}>
                <TextAreaInput
                    labelText={"Deposit Description"}
                    id="deposit_description"
                    name={`data[${index}].deposit_description`}
                    type="text"
                    placeholder="Type Here"
                />
            </FormGroup>
        </Fragment>
    )
}

export default Other

