import {
    SELF_EMPLOYMENT,
    SELF_MONTHLY_AVERAGE,
    SELF_DEPOSIT_SELECTION,
    SELF_RE_ESTIMATE_INCOME,
    SELF_MANUAL_ESTIMATE_INCOME,
    SELF_BUSINESS_STRUCTURE,
    SELF_BUSINESS_IDENTITY,
    SELF_BUSINESS_EMPLOYEES,
    SELF_EMPLOYEES_PAYMENT,
    SELF_EMPLOYEES_PAYROLL,
    SELF_OTHER_BUSINESS,
    SPOUSE_BUSINESS_INFO,
    SPOUSE_BUSINESS_MONTHLY_AVERAGE,
    SPOUSE_BUSINESS_DEPOSIT_SELECTION,
    SPOUSE_BUSINESS_RE_ESTIMATE_INCOME,
    SPOUSE_BUSINESS_MANUAL_ESTIMATE_INCOME,
    SPOUSE_BUSINESS_STRUCTURE,
    SPOUSE_BUSINESS_IDENTITY,
    SPOUSE_BUSINESS_EMPLOYEES,
    SPOUSE_EMPLOYEES_PAYMENT,
    SPOUSE_EMPLOYEES_PAYROLL,
    SPOUSE_OTHER_BUSINESS,
    SPOUSE_BUSINESS,
} from 'routes/paths';

import { EMP_STEP_TITLE } from 'const';

const BUSINESS_STEPS = [
    {
        path: SELF_BUSINESS_STRUCTURE,
        title: EMP_STEP_TITLE,
    },
    {
        path: SELF_EMPLOYMENT,
        title: EMP_STEP_TITLE,
    },
    {
        path: SELF_BUSINESS_IDENTITY,
        title: EMP_STEP_TITLE,
    },
    {
        path: SELF_BUSINESS_EMPLOYEES,
        title: EMP_STEP_TITLE,
    },
    {
        path: SELF_OTHER_BUSINESS,
        title: EMP_STEP_TITLE,
    },
    {
        path: SELF_MONTHLY_AVERAGE,
        title: EMP_STEP_TITLE,
    },
];

const SPOUSE_BUSINESS_STEPS = [

    {
        path: SPOUSE_BUSINESS_STRUCTURE,
        title: EMP_STEP_TITLE,
    },
    {
        path: SPOUSE_BUSINESS_INFO,
        title: EMP_STEP_TITLE,
    },
    {
        path: SPOUSE_BUSINESS_IDENTITY,
        title: EMP_STEP_TITLE,
    },
    {
        path: SPOUSE_BUSINESS_EMPLOYEES,
        title: EMP_STEP_TITLE,
    },
    {
        path: SPOUSE_OTHER_BUSINESS,
        title: EMP_STEP_TITLE,
    },
    {
        path: SPOUSE_BUSINESS_MONTHLY_AVERAGE,
        title: EMP_STEP_TITLE,
    },
];

export default function generateSelfEmpSteps(
    employment,
    profile,
    additionalInfo = {}
) {
    const { personal, spouse } = employment;

    const {
        selfReEstimateCorrect,
        selfAverageAccurate,
        selfHasEmployees,
        selectedBusinessDeposits,
    } = profile ? spouse : personal;

    const {
        spouses_business_structure_info,
        has_business_banks,
        has_manual_business_bank,
        has_manual_personal_bank,
        spouses_income_in_your_bank: joint_bank,
        spouse: spouseInfo,
    } = additionalInfo;

    let steps = [...BUSINESS_STEPS];

    if (profile) {
        steps = [
            {
                path: SPOUSE_BUSINESS,
                title: EMP_STEP_TITLE,
            },
        ];

        if (spouses_business_structure_info) {
            steps.push(...SPOUSE_BUSINESS_STEPS);

            if (selfHasEmployees) {
                steps.splice(
                    5,
                    0,
                    {
                        path: SPOUSE_EMPLOYEES_PAYMENT,
                        title: EMP_STEP_TITLE,
                    },
                    {
                        path: SPOUSE_EMPLOYEES_PAYROLL,
                        title: EMP_STEP_TITLE,
                    }
                );
            }

            const setSpouseRegularFlow = () => {
                if (!selfAverageAccurate) {
                    steps.push({
                        path: SPOUSE_BUSINESS_DEPOSIT_SELECTION,
                        title: EMP_STEP_TITLE,
                    });

                    if (selectedBusinessDeposits) {
                        steps.push({
                            path: SPOUSE_BUSINESS_RE_ESTIMATE_INCOME,
                            title: EMP_STEP_TITLE,
                        });

                        if (!selfReEstimateCorrect) {
                            steps.push({
                                path: SPOUSE_BUSINESS_MANUAL_ESTIMATE_INCOME,
                                title: EMP_STEP_TITLE,
                            });
                        }
                    } else {
                        steps.push({
                            path: SPOUSE_BUSINESS_MANUAL_ESTIMATE_INCOME,
                            title: EMP_STEP_TITLE,
                        });
                    }
                }
            };

            const setSpouseManualFlow = () => {
                steps.splice(-1, 1, {
                    path: SPOUSE_BUSINESS_MANUAL_ESTIMATE_INCOME,
                    title: EMP_STEP_TITLE,
                });
            };

            if (spouseInfo.has_business_banks) {
                if (spouseInfo.has_manual_business_bank) {
                    setSpouseManualFlow();
                } else {
                    setSpouseRegularFlow();
                }
            } else {
                if (joint_bank) {
                    has_manual_personal_bank
                        ? setSpouseManualFlow()
                        : setSpouseRegularFlow();
                } else {
                    spouseInfo.has_manual_personal_bank
                        ? setSpouseManualFlow()
                        : setSpouseRegularFlow();
                }
            }
        }
    } else {
        if (selfHasEmployees) {
            steps.splice(
                4,
                0,
                {
                    path: SELF_EMPLOYEES_PAYMENT,
                    title: EMP_STEP_TITLE,
                },
                {
                    path: SELF_EMPLOYEES_PAYROLL,
                    title: EMP_STEP_TITLE,
                }
            );
        }

        const setRegularFlow = () => {
            if (!selfAverageAccurate) {
                steps.push({
                    path: SELF_DEPOSIT_SELECTION,
                    title: EMP_STEP_TITLE,
                });

                if (selectedBusinessDeposits) {
                    steps.push({
                        path: SELF_RE_ESTIMATE_INCOME,
                        title: EMP_STEP_TITLE,
                    });

                    if (!selfReEstimateCorrect) {
                        steps.push({
                            path: SELF_MANUAL_ESTIMATE_INCOME,
                            title: EMP_STEP_TITLE,
                        });
                    }
                } else {
                    steps.push({
                        path: SELF_MANUAL_ESTIMATE_INCOME,
                        title: EMP_STEP_TITLE,
                    });
                }
            }
        };

        const setManualFlow = () => {
            steps.splice(-1, 1, {
                path: SELF_MANUAL_ESTIMATE_INCOME,
                title: EMP_STEP_TITLE,
            });
        };

        if (has_business_banks) {
            if (has_manual_business_bank) {
                setManualFlow();
            } else {
                setRegularFlow();
            }
        } else {
            if (has_manual_personal_bank) {
                setManualFlow();
            } else {
                setRegularFlow();
            }
        }
    }

    return steps;
}
