import { useContext, useState } from 'react';
import { PersonalPhoto } from 'assets';
import WelcomeWrapper from './WelcomeWrapperStyled';
import { history } from 'routes';
import { useStepController } from 'hooks';
import { ButtonBlock } from 'components/Buttons';
import { PersonalInfoContext } from 'contexts';
import SweetAlert2 from 'react-sweetalert2';

function PersonalWelcome() {
    const { personalInfo } = useContext(PersonalInfoContext);
    const { nextStep } = useStepController();

    const [swalProps, setSwalProps] = useState({
        show: true,
        title: 'Important!',
        html: 'We are collecting this information in order to determine which resolution programs you qualify for. <br/><br/> In order to assure the highest level of accuracy in your eligibility determination, it is critical that you submit accurate and up-to-date information.',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'I Understand'
    });
    return (
        <>
            <SweetAlert2 {...swalProps} />
            <WelcomeWrapper>
                <h1>
                    Welcome,{' '}<span className="color-blue">{personalInfo.first_name}!</span>
                    {' '}
                    Let’s verify your personal information.
                </h1>
                <img src={PersonalPhoto} alt="Personal" />
                <h2>Why do we need this?</h2>
                <p>
                    We want to make sure that all the information we have is as accurate as
                    possible. If there’s anything missing or misrepresented in your file,
                    you can fix it here.
                </p>

                <div className="mt-40 fixed-bottom">
                    <ButtonBlock
                        type={'button'}
                        onClick={() => history.push(nextStep.path)}
                    >
                        Let's Begin
                    </ButtonBlock>
                </div>
            </WelcomeWrapper>
        </>
    );
}

export default PersonalWelcome;
