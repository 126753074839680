import Select from 'react-select';
import styled from 'styled-components';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

function MultiSelect(props) {
    return (
        <StyledSelect
            placeholder={props.placeholder}
            options={props.options}
            components={animatedComponents}
            isMulti
            onChange={props.onChange}
            defaultValue={props.defaultValue}
        />
    );
}

const StyledSelect = styled(Select)`
    .css-1s2u09g-control {
        font-size: 14px;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid #008AC8;
        background-color: #EBF2F7;
        color: #0F212E;
        cursor: pointer;  
        transition: all 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
        padding: 0px 0px 0px 5px;
    }

    .css-1pahdxg-control {
        font-size: 14px;
        border: none;
        background-color: #EBF2F7;
        cursor: pointer;
        transition: all 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
        padding: 0px 0px 0px 5px;
        outline: none;
        border-radius: 0;
    }

    .css-1rhbuit-multiValue {
        background-color: #FFF;
        font-size: 95%;
        border: 1px solid #008AC8;
    }

    #react-select-3-placeholder, #react-select-5-placeholder {
        color: #0F212E;
    }

    #react-select-5-listbox, #react-select-3-listbox {
        font-size: 14px;
        border-radius: 0;
        margin-top: -5px;
        border: 1px solid #008ac8;
    }

    #react-select-5-listbox > div > div:not(:last-child), #react-select-3-listbox > div > div:not(:last-child) {
        cursor: pointer;
        border-bottom: 1px solid #f7f5f5;
    }

    #react-select-5-listbox > div > div:hover, #react-select-3-listbox > div > div:hover {
        background-color: #008ac8;
        color: #FFF;
    }
`;

export default MultiSelect;