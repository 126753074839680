import BusinessInformation from './BusinessInformation';
import EstimateIncome from './EstimateIncome';
import BusinessReEstimatedIncome from './BusinessReEstimatedIncome';
import MonthlyAverage from './MonthlyAverage';
import SelectDeposits from './SelectDeposits';
import ManualIncome from './ManualIncome';

import {
    SELF_EMPLOYMENT,
    SELF_ESTIMATE_INCOME,
    SELF_MONTHLY_AVERAGE,
    SELF_DEPOSIT_SELECTION,
    SELF_RE_ESTIMATE_INCOME,
    SELF_MANUAL_ESTIMATE_INCOME,
    SELF_BUSINESS_STRUCTURE,
    SELF_BUSINESS_IDENTITY,
    SELF_BUSINESS_EMPLOYEES,
    SELF_EMPLOYEES_PAYMENT,
    SELF_EMPLOYEES_PAYROLL,
    SELF_OTHER_BUSINESS,
    SPOUSE_BUSINESS,
    SPOUSE_BUSINESS_INFO,
    SPOUSE_BUSINESS_ESTIMATE_INCOME,
    SPOUSE_BUSINESS_MONTHLY_AVERAGE,
    SPOUSE_BUSINESS_DEPOSIT_SELECTION,
    SPOUSE_BUSINESS_RE_ESTIMATE_INCOME,
    SPOUSE_BUSINESS_MANUAL_ESTIMATE_INCOME,
    SPOUSE_BUSINESS_STRUCTURE,
    SPOUSE_BUSINESS_IDENTITY,
    SPOUSE_BUSINESS_EMPLOYEES,
    SPOUSE_EMPLOYEES_PAYMENT,
    SPOUSE_EMPLOYEES_PAYROLL,
    SPOUSE_OTHER_BUSINESS,
} from 'routes/paths';
import BusinessStructure from './BusinessStructure';
import BusinessIdentity from './BusinessIdentity';
import BusinessEmployees from './BusinessEmployees';
import EmployeesPayment from './EmployeesPayment';
import EmployeesPayroll from './EmployeesPayroll';
import OtherBusiness from './OtherBusiness';
import { InnerRoutes } from 'routes';
import ClientKnowsSpouseBusiness from './ClientKnowsSpouseBusiness';

const businessRoutes = [
    {
        path: SPOUSE_BUSINESS_ESTIMATE_INCOME,
        component: <EstimateIncome spouse />,
        options: { exact: true },
    },
    {
        path: SPOUSE_BUSINESS_MONTHLY_AVERAGE,
        component: <MonthlyAverage spouse />,
        options: { exact: true },
    },
    {
        path: SPOUSE_BUSINESS_DEPOSIT_SELECTION,
        component: <SelectDeposits spouse />,
        options: { exact: true },
    },
    {
        path: SPOUSE_BUSINESS_RE_ESTIMATE_INCOME,
        component: <BusinessReEstimatedIncome spouse />,
        options: { exact: true },
    },
    {
        path: SPOUSE_BUSINESS_MANUAL_ESTIMATE_INCOME,
        component: <ManualIncome spouse />,
        options: { exact: true },
    },
    {
        path: SPOUSE_OTHER_BUSINESS,
        component: <OtherBusiness spouse />,
        options: { exact: true },
    },
    {
        path: SPOUSE_EMPLOYEES_PAYROLL,
        component: <EmployeesPayroll spouse />,
        options: { exact: true },
    },
    {
        path: SPOUSE_EMPLOYEES_PAYMENT,
        component: <EmployeesPayment spouse />,
        options: { exact: true },
    },
    {
        path: SPOUSE_BUSINESS_EMPLOYEES,
        component: <BusinessEmployees spouse />,
        options: { exact: true },
    },
    {
        path: SPOUSE_BUSINESS_IDENTITY,
        component: <BusinessIdentity spouse />,
        options: { exact: true },
    },
    {
        path: SPOUSE_BUSINESS_STRUCTURE,
        component: <BusinessStructure spouse />,
        options: { exact: true },
    },
    {
        path: SPOUSE_BUSINESS_INFO,
        component: <BusinessInformation spouse />,
        options: { exact: true },
    },
    {
        path: SPOUSE_BUSINESS,
        component: <ClientKnowsSpouseBusiness />,
        options: { exact: true },
    },
    {
        path: SELF_ESTIMATE_INCOME,
        component: <EstimateIncome />,
        options: { exact: true },
    },
    {
        path: SELF_MONTHLY_AVERAGE,
        component: <MonthlyAverage />,
        options: { exact: true },
    },
    {
        path: SELF_DEPOSIT_SELECTION,
        component: <SelectDeposits />,
        options: { exact: true },
    },
    {
        path: SELF_RE_ESTIMATE_INCOME,
        component: <BusinessReEstimatedIncome />,
        options: { exact: true },
    },
    {
        path: SELF_MANUAL_ESTIMATE_INCOME,
        component: <ManualIncome />,
        options: { exact: true },
    },
    {
        path: SELF_OTHER_BUSINESS,
        component: <OtherBusiness />,
        options: { exact: true },
    },
    {
        path: SELF_EMPLOYEES_PAYROLL,
        component: <EmployeesPayroll />,
        options: { exact: true },
    },
    {
        path: SELF_EMPLOYEES_PAYMENT,
        component: <EmployeesPayment />,
        options: { exact: true },
    },
    {
        path: SELF_BUSINESS_EMPLOYEES,
        component: <BusinessEmployees />,
        options: { exact: true },
    },
    {
        path: SELF_BUSINESS_IDENTITY,
        component: <BusinessIdentity />,
        options: { exact: true },
    },
    {
        path: SELF_BUSINESS_STRUCTURE,
        component: <BusinessStructure />,
        options: { exact: true },
    },
    {
        path: SELF_EMPLOYMENT,
        component: <BusinessInformation />,
        options: { exact: true },
    },
];

function SelfEmployed() {
    return <InnerRoutes routes={businessRoutes} />;
}

export default SelfEmployed;
