import { Fragment, useCallback, useState, useEffect, useContext } from 'react';
import {
    FormGroup,
    TextInput,
    InlineLoading,
    RadioButton,
    RadioButtonGroup,
    Loading
} from 'carbon-components-react';
import { useFormik } from 'formik';
import { usePlaidLink } from 'react-plaid-link';
import * as Yup from 'yup';
import './styles.css';
import { ButtonBlock, LinkButton } from '../../components/Buttons';
import { BankInfo } from '../../components/ListItems';
import api, { endpoints } from 'api';
import Modal from 'components/Modal';
import {
    AdditionalInfoContext,
    PersonalInfoContext,
    contextActionTypes,
} from 'contexts';
import { history } from 'routes';
import { useStepController, useModal, useAdditionalInfo } from '../../hooks';
import styled from 'styled-components';
import Navigation from 'components/Navigation';
import { isArrayEmpty } from 'lib';
// import SweetAlert2 from 'react-sweetalert2';
// import Swal from 'sweetalert2'
// import withReactContent from 'sweetalert2-react-content';

// const MySwal = withReactContent(Swal)

const {
    MANUAL_PERSONAL_BANK,
    SPOUSE_MANUAL_PERSONAL_BANK,
} = contextActionTypes;

function LinkPersonalAccounts({ spouse, bank_account_type }) {
    const [show, setShow] = useState(false);
    const [manualBank, setManualBank] = useState(true);
    const [choose, setChoose] = useState(false);
    const [bankItem, setBankItem] = useState({});
    const [showTokenInput, hideTokenInput] = useState(true);
    const [loading, setLoading] = useState(true);
    const [bankInfo, setBankInfo] = useState([]);
    const [errorModal, setErrorModal] = useState(false);
    const [errorData, setErrorData] = useState('');
    const [transPullStatus, setTransPullStatus] = useState(false);

    const { personalUid, spouseUid } = useContext(PersonalInfoContext);
    const { dispatchAdditionalInfo } = useContext(AdditionalInfoContext);

    const { nextStep, previousStep } = useStepController();
    const [modalOpen, showModal, hideModal] = useModal();
    const addInfo = useAdditionalInfo(spouse ? spouseUid : personalUid);

    // function handleSwalClick() {
    //     MySwal.fire({
    //         title: 'Important!',
    //         html: 'Manually linking your bank account does not allow us to verify your information with your financial institution and may effect your qualification. Please ensure to be accurate with your input.',
    //         confirmButtonColor: '#3085d6',
    //         confirmButtonText: 'I Understand'
    //     });
    // }

    const getBankInfo = useCallback(
        /* initialCall prevents update the state "choose" on mount, 
        "choose" is being updated by addInfo effect or existing data on mount */
        async (initialCall = false) => {
            try {
                const req = await api({
                    url: endpoints.CONNECTED_BANK_INFO,
                    params: {
                        profile: spouse ? spouseUid : personalUid,
                        bank_source: bank_account_type,
                        source: 'plaid',
                    },
                });

                const res = await req.data;

                const noBankAdded = isArrayEmpty(res);

                if (!initialCall) {
                    if (!noBankAdded) setChoose(false);
                }

                setManualBank(!res.length > 0);

                if (res) {
                    setBankInfo(res);
                    setLoading(false);

                    dispatchAdditionalInfo({
                        type: spouse
                            ? SPOUSE_MANUAL_PERSONAL_BANK
                            : MANUAL_PERSONAL_BANK,
                        payload: noBankAdded,
                    });
                }
            } catch (e) {
                setLoading(false);
                console.log(e.response);
            }
        },
        [
            bank_account_type,
            personalUid,
            spouse,
            spouseUid,
            dispatchAdditionalInfo,
        ]
    );

    const uploadAccessToken = useCallback(
        async (public_token, metadata, plaid_manual_acc_ids) => {
            setLoading(true);
            const data = {
                hardcoded:
                    // eslint-disable-next-line no-undef
                    process.env['REACT_APP_PLAID_ENV'] === 'sandbox'
                        ? 'true'
                        : 'false',
                public_token,
                bank_name: metadata.institution.name,
                bank_source: bank_account_type,
                profile_uid: spouse ? spouseUid : personalUid,
                meta_data: process.env['REACT_APP_PLAID_ENV'] === 'sandbox' ? null : metadata,
                plaid_acc_ids:
                    // eslint-disable-next-line no-undef
                    process.env['REACT_APP_PLAID_ENV'] === 'sandbox'
                        ? (plaid_manual_acc_ids === undefined ? null : plaid_manual_acc_ids.split(","))
                        : metadata.accounts.map(account => account.id),
            };
            try {
                const req = await api({
                    method: 'post',
                    url: endpoints.ADD_BANK_TOKEN,
                    data,
                    disableBadReqGlobalError: true,
                });
                const res = req.data;
                if (res) {
                    getBankInfo();
                }
            } catch (e) {
                setLoading(false);
                if (e.response) {
                    setErrorData(e.response.data.message);
                } else {
                    setErrorData("Something went wrong, please refresh");
                }

                setErrorModal(true);
                showModal();
            }
        },
        [
            bank_account_type,
            getBankInfo,
            personalUid,
            showModal,
            spouse,
            spouseUid,
        ]
    );

    useEffect(() => {
        if (spouse && spouseUid) {
            getBankInfo(true);
        }
        if (!spouse && personalUid) {
            getBankInfo(true);
        }
        hideTokenInput(process.env['REACT_APP_PLAID_ENV'] === 'sandbox');
    }, [spouseUid, personalUid, spouse, bank_account_type, getBankInfo]);

    useEffect(() => {
        setChoose(addInfo['has_manual_personal_bank']);
    }, [addInfo]);

    // added code from PlaidLink component
    const onSuccess = useCallback(
        (public_token, metadata) => {
            uploadAccessToken(public_token, metadata, null);
        },
        [uploadAccessToken]
    );
    const onExit = useCallback((public_token, metadata) => { }, []);

    const config = {
        clientName: 'Verifilink',
        env: `${process.env['REACT_APP_PLAID_ENV']}`,
        product: ['auth', 'transactions'],
        publicKey: `${process.env['REACT_APP_PLAID_PUBLIC_KEY']}`,
        webhook: `${process.env['REACT_APP_WEBHOOK_URL']}`,
        onSuccess,
        onExit,
        // ...
    };

    const goPrevious = () => {
        history.push(previousStep.path);
    };

    const accessTokenInputTest = async (values) => {
        uploadAccessToken(values.access_token_input, {
            institution: { name: values.bank_name_input },
        }, values.plaid_manual_acc_ids);
    };

    const confirmBankIItem = (item) => {
        setBankItem(item);
        setShow(true);
        showModal();
    };

    const deleteBankItem = async () => {
        try {
            await api.delete(
                `${endpoints.CONNECTED_BANK_INFO}${bankItem.uid}/`
            );
            getBankInfo();
        } catch (e) {
            console.log(e.response);
        }
    };

    const postProcessBank = async () => {
        try {
            const req = await api({
                url: endpoints.PLAID_TRANSACTION_PULL_STATUS,
                params: {
                    profile: spouse ? spouseUid : personalUid
                },
            });
            const res = req.data;
            if (res.status === true) {
                updateAddInfo(false, {
                    has_manual_additional_income:
                        addInfo.has_manual_additional_income === null
                            ? null
                            : false,
                });
            } else {
                setTransPullStatus(true);
                setTimeout(async () => {
                    postProcessBank();
                }, `${process.env['REACT_APP_PLAID_TRANS_PULL_TIME_OUT']}`)
            }
        } catch (e) {
            console.log(e.response);
        }
    };

    const handleManualBank = (val) => {
        // Update context to change step
        dispatchAdditionalInfo({
            type: spouse ? SPOUSE_MANUAL_PERSONAL_BANK : MANUAL_PERSONAL_BANK,
            payload: true, // true is default value because only one radio option is available
        });

        setChoose(true);
    };

    const decideNextStep = async () => {
        if (choose) {
            updateAddInfo(true);
        } else {
            postProcessBank();
        }
    };

    // Patch API: Update client/spouse wants to add manual bank account or not
    const updateAddInfo = async (value, otherData = {}) => {
        try {
            const req = await api({
                method: 'patch',
                url: `${endpoints.CLIENT_ADDITIONAL_INFO}${addInfo.uid}/`,
                data: {
                    has_manual_personal_bank: value,
                    ...otherData,
                },
            });

            const res = await req.data;

            if (res) {
                setTransPullStatus(false);
                history.push(nextStep.path);
            }
        } catch (e) {
            console.log(e.response);
        }
    };

    const form = useFormik({
        initialValues: {
            access_token_input: '',
            bank_name_input: ''
        },
        onSubmit: accessTokenInputTest,
        validationSchema: inputValidate,
    });

    const { open, ready } = usePlaidLink(config);

    const deleteWarning = (
        <Modal
            open={modalOpen}
            onClose={hideModal}
            onSave={deleteBankItem}
            modalHeading="Unlink Bank Account?"
            primaryButtonText="Yes"
            danger
        >
            <p>Are you sure that you want to unlink this bank account</p>
        </Modal>
    );

    const errorMessage = (
        <Modal
            open={modalOpen}
            onClose={hideModal}
            modalHeading="Try Another Bank Account"
            singleButton
        >
            <p>{errorData}</p>
        </Modal>
    );

    // Bank add using token for testing
    const sandboxBankAdd = (
        <Wrapper>
            <form>
                <FormGroup legendText={''}>
                    <TextInput
                        id="access_token_input"
                        name="access_token_input"
                        labelText="Enter Access Token"
                        invalid={
                            form.errors.access_token_input &&
                            form.touched.access_token_input
                        }
                        invalidText={form.errors.access_token_input}
                        onChange={form.handleChange}
                    />
                </FormGroup>
                <FormGroup legendText={''}>
                    <TextInput
                        id="bank_name_input"
                        name="bank_name_input"
                        labelText="Enter Bank Name"
                        invalid={
                            form.errors.bank_name_input &&
                            form.touched.bank_name_input
                        }
                        invalidText={form.errors.bank_name_input}
                        onChange={form.handleChange}
                    />
                </FormGroup>
                <FormGroup legendText={''}>
                    <TextInput
                        id="plaid_manual_acc_ids"
                        name="plaid_manual_acc_ids"
                        labelText="Enter Account IDs"
                        onChange={form.handleChange}
                    />
                </FormGroup>
                <div className="bx--grid bx--no-gutter">
                    <div className="bx--row">
                        <div className="bx--col">
                            <ButtonBlock
                                kind="primary"
                                size="field"
                                onClick={form.handleSubmit}
                            >
                                Submit
                            </ButtonBlock>
                        </div>
                    </div>
                </div>
            </form>
        </Wrapper>
    );

    // Manual Bank Add Option
    const manualBankChoice = (
        <RadioButtonGroup
            name="manual-radio-button"
            valueSelected={choose === false ? 'manual_bank' : null}
            onChange={handleManualBank}
        >
            <Choice
                id="manual_bank"
                labelText="I wasn’t able to link my online bank account, or I don’t use online banking."
                value="manual_bank"
                disabled={false}
                checked={false}
            // onClick={() => handleSwalClick()}
            />
        </RadioButtonGroup>
    );

    return (
        <Fragment>
            <h1>
                Please connect {spouse ? "your spouse's" : 'your'}{' '}
                <span className="color-blue">personal bank accounts</span>{' '} for verification.
            </h1>
            <p className="mb-32">
                Please link all accounts that{' '}
                <strong>
                    receive deposited income.
                </strong>
                Verifilink uses <strong>Plaid™</strong> to securely access your account information.
            </p>
            {showTokenInput && sandboxBankAdd}

            {loading ? (
                <InlineLoading
                    status="active"
                    description="Please wait.. It usually takes more than a minute to get a response...."
                    className="mb-40"
                />
            ) : (
                <Wrapper>
                    <BankInfo bank={bankInfo} bankItem={confirmBankIItem} />
                </Wrapper>
            )}
            {transPullStatus &&
                <Loading
                    status="active"
                    description="Please wait.. It usually takes more than a minute to get a response...."
                    className="mb-40"
                />
            }
            {show && deleteWarning}

            {errorModal && errorMessage}

            <LinkButton onClick={open} disabled={!ready} className="mb-32">
                Link Your Bank Account
            </LinkButton>

            {manualBank && manualBankChoice}

            <Navigation
                onNext={decideNextStep}
                onPrevious={goPrevious}
                disableNext={choose === false && isArrayEmpty(bankInfo)}
            />
        </Fragment>
    );
}

const Wrapper = styled.section`
    text-align: center;
    margin-bottom: 32px;
`;

const inputValidate = Yup.object().shape({
    access_token_input: Yup.string().required('Please Enter Access Token'),
    bank_name_input: Yup.string().required('Please Enter Bank Name'),
});

const Choice = styled(RadioButton)`
    .bx--radio-button__label {
        font-weight: 400;
        justify-content: flex-start;
    }
`;

export default LinkPersonalAccounts;
