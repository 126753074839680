import { contextActionTypes } from 'contexts';
import { Decision } from 'screens/Helper';

const { HAS_INVESTMENTS, SPOUSE_HAS_INVESTMENTS } = contextActionTypes;

function CheckInvestments({ spouse }) {
    if (spouse) return <CheckInvestmentsSpouse />;

    return (
        <Decision apiKey="has_investments" contextKey={HAS_INVESTMENTS}>
            <h1>
                Do you have any{' '}
                <span className="color-blue">
                    investment or retirement accounts?
                </span>
            </h1>
            <p>
                This includes IRAs, 401k plans, stocks, bonds, and mutual funds.
            </p>
        </Decision>
    );
}

function CheckInvestmentsSpouse() {
    return (
        <Decision
            apiKey="has_investments"
            contextKey={SPOUSE_HAS_INVESTMENTS}
            spouse
        >
            <h1>
                Does your <span className="color-blue">spouse</span> have any{' '}
                <span className="color-blue">
                    investment or retirement accounts?
                </span>
            </h1>
            <p>
                This includes IRAs, 401k plans, stocks, bonds, and mutual funds.
            </p>
        </Decision>
    );
}

export default CheckInvestments;
