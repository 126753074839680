import { Fragment, useEffect, useContext, useState } from 'react';
import { PersonalInfoContext } from 'contexts';
import * as Yup from 'yup';
import { FormGroup } from 'carbon-components-react';
import { useStepController } from 'hooks';
import Navigation from 'components/Navigation';
import { ExpenseList } from 'components/ListItems';
import { Checkbox } from 'components/Form';
import api, { endpoints } from 'api';
import { Formik } from 'formik';
import { history } from 'routes';

import { AmountInput } from 'components/Utilities';
import {
    enableDotAmountCheck,
    enableMaxAmountCheck,
    enableMinAmountCheck,
} from 'lib/yupValidMethods';
import { MAX_AMOUNT, MAX_AMOUNT_US, MIN_AMOUNT, MIN_AMOUNT_US } from 'const';
import { parseAmount } from 'lib';
import { AmountWrapper } from 'components/Styled';

const initialValue = {
    court_ordered_payments: false,
    child_dependent_care_payments: false,
    term_life_insurance_premiums: false,
    court_ordered_payments_amount: 0,
    child_dependent_care_payments_amount: 0,
    term_life_insurance_premiums_amount: 0,
    skip_these: false,
};

function AmountExpenses({ spouse }) {
    const [initialValues, updateInitialValues] = useState(initialValue);
    const { personalUid, spouseUid } = useContext(PersonalInfoContext);
    const [expenseUid, setExpenseUid] = useState(null);
    const [courtOrderPayment, setCourOrderPayment] = useState(false);
    const [childCarePayment, setChildCarePayment] = useState(false);
    const [insurancePremiumPayment, setInsurancePremiumPayment] =
        useState(false);
    const { nextStep, previousStep } = useStepController();
    useEffect(() => {
        const getExpenses = async () => {
            const req = await api.get(
                `${endpoints.CLIENT_EMPLOYMENT_EXPENSES}?profile=${
                    spouse ? spouseUid : personalUid
                }`
            );
            const res = await req.data;
            if (Array.isArray(res) && res.length > 0) {
                const expenseInfo = res[0];

                const {
                    court_ordered_payments,
                    child_dependent_care_payments,
                    term_life_insurance_premiums,
                    court_ordered_payments_amount,
                    child_dependent_care_payments_amount,
                    term_life_insurance_premiums_amount,
                    uid,
                } = expenseInfo;

                setExpenseUid(uid);
                setCourOrderPayment(court_ordered_payments);
                setChildCarePayment(child_dependent_care_payments);
                setInsurancePremiumPayment(term_life_insurance_premiums);
                updateInitialValues({
                    court_ordered_payments,
                    child_dependent_care_payments,
                    term_life_insurance_premiums,
                    court_ordered_payments_amount,
                    child_dependent_care_payments_amount,
                    term_life_insurance_premiums_amount,
                    skip_these: false,
                });
            }
        };

        if ((spouse && spouseUid) || (!spouse && personalUid)) {
            getExpenses();
        }
    }, [personalUid, spouseUid, spouse]);

    const saveAmountExpenses = async (values) => {
        values['profile'] = spouse ? spouseUid : personalUid;
        if (values.skip_these) {
            if (expenseUid) {
                values['court_ordered_payments_amount'] = '0.00';
                values['child_dependent_care_payments_amount'] = '0.00';
                values['term_life_insurance_premiums_amount'] = '0.00';
                try {
                    let url = endpoints.CLIENT_EMPLOYMENT_EXPENSES;
                    if (expenseUid) url += `${expenseUid}/`;

                    const req = await api({
                        method: 'patch',
                        data: values,
                        url,
                    });
                    const res = req.data;
                    if (res) {
                        history.push(nextStep.path);
                    }
                } catch (e) {
                    console.log(e.response);
                }
            } else {
                history.push(nextStep.path);
            }
        } else {
            // Convert comma amount to real amount
            values['court_ordered_payments_amount'] = parseAmount(
                values['court_ordered_payments_amount']
            );
            values['child_dependent_care_payments_amount'] = parseAmount(
                values['child_dependent_care_payments_amount']
            );
            values['term_life_insurance_premiums_amount'] = parseAmount(
                values['term_life_insurance_premiums_amount']
            );

            try {
                let url = endpoints.CLIENT_EMPLOYMENT_EXPENSES;
                if (expenseUid) url += `${expenseUid}/`;

                const req = await api({
                    method: expenseUid ? 'patch' : 'post',
                    data: values,
                    url,
                });
                const res = req.data;
                if (res) {
                    history.push(nextStep.path);
                }
            } catch (e) {
                console.log(e.response);
            }
        }
    };
    return (
        <Fragment>
            <h1>
                Please Enter Your{' '}
                <span className="color-blue">Monthly Payment</span> For Each
                Expense.
            </h1>
            <p>
                If you are unsure of your monthly payment amount(s) select{' '}
                <strong>I’m not sure</strong> and we can confirm this
                information later.
            </p>
            <Formik
                initialValues={initialValues}
                onSubmit={saveAmountExpenses}
                validationSchema={validation}
                enableReinitialize={true}
                validateOnMount={true}
            >
                {({ handleSubmit, isValid }) => (
                    <Fragment>
                        <section className="py-24">
                            {courtOrderPayment && (
                                <FormGroup legendText="">
                                    <AmountWrapper>
                                        <AmountInput
                                            id="court_ordered_payments_amount"
                                            name="court_ordered_payments_amount"
                                            labelText="Court Ordered Payments"
                                            placeholder=""
                                        />
                                        <span>$</span>
                                    </AmountWrapper>
                                </FormGroup>
                            )}
                            {childCarePayment && (
                                <FormGroup legendText="">
                                    <AmountWrapper>
                                        <AmountInput
                                            id="child_dependent_care_payments_amount"
                                            name="child_dependent_care_payments_amount"
                                            labelText="Child/Dependent Care Payments"
                                            placeholder=""
                                        />
                                        <span>$</span>
                                    </AmountWrapper>
                                </FormGroup>
                            )}
                            {insurancePremiumPayment && (
                                <FormGroup legendText="">
                                    <AmountWrapper>
                                        <AmountInput
                                            id="term_life_insurance_premiums_amount"
                                            name="term_life_insurance_premiums_amount"
                                            labelText="Term Life Insurance Premiums"
                                            placeholder=""
                                        />
                                        <span>$</span>
                                    </AmountWrapper>
                                </FormGroup>
                            )}
                            <FormGroup legendText="">
                                <Checkbox
                                    name={'skip_these'}
                                    id={'skip_these'}
                                    labelText={
                                        <ExpenseList header={'I’m not sure'} />
                                    }
                                />
                            </FormGroup>
                        </section>
                        <Navigation
                            disableNext={!isValid}
                            onPrevious={() => history.push(previousStep.path)}
                            onNext={handleSubmit}
                            className="mt-24"
                        />
                    </Fragment>
                )}
            </Formik>
        </Fragment>
    );
}

enableDotAmountCheck();
enableMinAmountCheck();
enableMaxAmountCheck();

const validation = Yup.object().shape({
    skip_these: Yup.boolean(),
    court_ordered_payments: Yup.boolean(),
    child_dependent_care_payments: Yup.boolean(),
    term_life_insurance_premiums: Yup.boolean(),
    court_ordered_payments_amount: Yup.string().when(
        ['skip_these', 'court_ordered_payments'],
        {
            is: (skip_these, court_ordered_payments) =>
                skip_these === false && court_ordered_payments === true,
            then: Yup.string()
                .required('Required field')
                .onlyDot()
                .minAmount(
                    MIN_AMOUNT,
                    `Court ordered payments must be equal or greater than ${MIN_AMOUNT_US}`
                )
                .maxAmount(
                    MAX_AMOUNT,
                    `Maximum amount can be ${MAX_AMOUNT_US}`
                ),
        }
    ),
    child_dependent_care_payments_amount: Yup.string().when(
        ['skip_these', 'child_dependent_care_payments'],
        {
            is: (skip_these, child_dependent_care_payments) =>
                skip_these === false && child_dependent_care_payments === true,
            then: Yup.string()
                .required('Required field')
                .onlyDot()
                .minAmount(
                    MIN_AMOUNT,
                    `Child/Dependent care payments must be equal or greater than ${MIN_AMOUNT_US}`
                )
                .maxAmount(
                    MAX_AMOUNT,
                    `Maximum amount can be ${MAX_AMOUNT_US}`
                ),
        }
    ),
    term_life_insurance_premiums_amount: Yup.string().when(
        ['skip_these', 'term_life_insurance_premiums'],
        {
            is: (skip_these, term_life_insurance_premiums) =>
                skip_these === false && term_life_insurance_premiums === true,
            then: Yup.string()
                .required('Required field')
                .onlyDot()
                .minAmount(
                    MIN_AMOUNT,
                    `Term life insurance premiums must be equal or greater than ${MIN_AMOUNT_US}`
                )
                .maxAmount(
                    MAX_AMOUNT,
                    `Maximum amount can be ${MAX_AMOUNT_US}`
                ),
        }
    ),
});

export default AmountExpenses;
