const SIGN_IN = '/'; // Sign in page path
const TERMS_OF_USE = '/terms-of-use/'; // Terms of use
const PRIVACY_POLICY = '/privacy-policy/'; // Terms of use
const PERSONAL_INFORMATION = '/personal-info/'; // Personal information add page path
const PERSONAL_INFO_VERIFY = `${PERSONAL_INFORMATION}verify/`;
const PERSONAL_FILING_SELECT = `${PERSONAL_INFORMATION}select-filing/`;
const PERSONAL_SPOUSE_INFO = `${PERSONAL_INFORMATION}spouse-info/`;
const PERSONAL_PROGRESS = `${PERSONAL_INFORMATION}progress/`;

const HOUSEHOLD_INFORMATION = '/household-info/'; // Household information add page path
const CLAIM_DEPENDENTS = `${HOUSEHOLD_INFORMATION}claim/`;
const ADD_DEPENDENTS = `${HOUSEHOLD_INFORMATION}dependents/`;
const DEPENDENTS_PROGRESS = `${HOUSEHOLD_INFORMATION}progress/`;

const CONNECT_BANKS = '/link-banks/'; // Link bank accounts page path
const CONNECT_BANKS_SPOUSE = `${CONNECT_BANKS}spouse/`;
const CONNECT_PERSONAL_BANKS = `${CONNECT_BANKS}personal/`;
const CONNECT_PERSONAL_MANUAL_BANKS = `${CONNECT_PERSONAL_BANKS}manual/`;
const CONNECT_SPOUSE_PERSONAL_BANKS = `${CONNECT_BANKS_SPOUSE}personal/`;
const CONNECT_SPOUSE_PERSONAL_MANUAL_BANKS = `${CONNECT_SPOUSE_PERSONAL_BANKS}manual/`;
const CONNECT_BUSINESS_BANKS = `${CONNECT_BANKS}business/`;
const CONNECT_BUSINESS_MANUAL_BANKS = `${CONNECT_BUSINESS_BANKS}manual/`;
const CONNECT_SPOUSE_BUSINESS_BANKS = `${CONNECT_BANKS_SPOUSE}business/`;
const CONNECT_SPOUSE_BUSINESS_MANUAL_BANKS = `${CONNECT_SPOUSE_BUSINESS_BANKS}manual/`;
const CONNECT_PERSONAL_JOINT_BANKS = `${CONNECT_BANKS}joint/`;
const CONNECT_JOINT_BANKS = `${CONNECT_BANKS}joint-spouse/`;
const BANK_DATA_ANALYZE = `${CONNECT_BANKS}bank-data-analyze/`;
const QUESTION_VERIFICATION = `${CONNECT_BANKS}question-verification/`;
const EMPLOYMENT = `${CONNECT_BANKS}employment/`;
const BANK_PROGRESS = `${CONNECT_BANKS}progress/`;
const SEPARATE_BUSINESS = `${CONNECT_BANKS}separate-business/`;
const SEPARATE_BUSINESS_SPOUSE = `${CONNECT_BANKS_SPOUSE}separate-business/`;
const SPOUSE_CONTRIBUTION = `${CONNECT_BANKS_SPOUSE}contribute/`;
const SPOUSE_EMPLOYMENT_TYPE = `${CONNECT_BANKS_SPOUSE}employment/`;
const SPOUSE_JOINT_BANK = `${CONNECT_BANKS_SPOUSE}joint-bank/`;
const SPOUSE_ONLINE_INFO = `${CONNECT_BANKS_SPOUSE}bank-info/`;

const ASSET_INVESTMENT_VERIFICATION = '/asset-investment/';
const ASSET_INVESTMENT_FINANCED_PROPERTIES = `${ASSET_INVESTMENT_VERIFICATION}property-financed/`;
const ASSET_INVESTMENT_PROPERTY_INFO = `${ASSET_INVESTMENT_VERIFICATION}property/`;
const ASSET_INVESTMENT_ADDITIONAL_PROPERTIES = `${ASSET_INVESTMENT_VERIFICATION}properties-additional/`;
const ASSET_INVESTMENT_FINANCED_VEHICLES = `${ASSET_INVESTMENT_VERIFICATION}vehicles-financed/`;
const ASSET_INVESTMENT_VEHICLE_INFO = `${ASSET_INVESTMENT_VERIFICATION}vehicle/`;
const ASSET_INVESTMENT_ADDITIONAL_VEHICLES = `${ASSET_INVESTMENT_VERIFICATION}vehicles-additional/`;
const ASSET_INVESTMENT = `${ASSET_INVESTMENT_VERIFICATION}investment/`;
const ASSET_INVESTMENT_ADD = `${ASSET_INVESTMENT}add/`;
const SPOUSE_ASSETS = `${ASSET_INVESTMENT_VERIFICATION}spouse/`;
const SPOUSE_ASSETS_INFO = `${SPOUSE_ASSETS}info/`;
const SPOUSE_FINANCED_PROPERTIES = `${SPOUSE_ASSETS}property-financed/`;
const SPOUSE_PROPERTY_INFO = `${SPOUSE_ASSETS}property/`;
const SPOUSE_ADDITIONAL_PROPERTIES = `${SPOUSE_ASSETS}properties-additional/`;
const SPOUSE_FINANCED_VEHICLES = `${SPOUSE_ASSETS}vehicles-financed/`;
const SPOUSE_VEHICLE_INFO = `${SPOUSE_ASSETS}vehicle/`;
const SPOUSE_ADDITIONAL_VEHICLES = `${SPOUSE_ASSETS}vehicles-additional/`;
const SPOUSE_ASSET_INVESTMENT = `${SPOUSE_ASSETS}investment/`;
const SPOUSE_ASSET_INVESTMENT_ADD = `${SPOUSE_ASSET_INVESTMENT}add/`;

const EMPLOYMENT_INCOME_VERIFICATION = '/employment/'; // Employment income verification step
const EMPLOYMENT_SPOUSE = `${EMPLOYMENT_INCOME_VERIFICATION}spouse/`;
const EMPLOYMENT_PROGRESS = `${EMPLOYMENT_INCOME_VERIFICATION}progress/`;

const EMPLOYMENT_FULLTIME = `${EMPLOYMENT_INCOME_VERIFICATION}work/`;
const EMPLOYMENT_FULLTIME_OWNERSHIP = `${EMPLOYMENT_FULLTIME}ownership/`;
const EMPLOYMENT_FULLTIME_ESTIMATE = `${EMPLOYMENT_FULLTIME}estimate-income/`;
const EMPLOYMENT_FULLTIME_DEPOSITS = `${EMPLOYMENT_FULLTIME}deposits/`;
const EMPLOYMENT_FULLTIME_REESTIMATE = `${EMPLOYMENT_FULLTIME}re-estimate/`;
const EMPLOYMENT_FULLTIME_MANUAL_ESTIMATE = `${EMPLOYMENT_FULLTIME}manual-estimate/`;

const SPOUSE_WORK = `${EMPLOYMENT_SPOUSE}work/`;
const SPOUSE_WORK_INFO = `${SPOUSE_WORK}info/`;
const SPOUSE_WORK_OWNERSHIP = `${SPOUSE_WORK}ownership/`;
const SPOUSE_WORK_ESTIMATE = `${SPOUSE_WORK}estimate-income/`;
const SPOUSE_WORK_DEPOSITS = `${SPOUSE_WORK}deposits/`;
const SPOUSE_WORK_REESTIMATE = `${SPOUSE_WORK}re-estimate/`;
const SPOUSE_WORK_MANUAL_ESTIMATE = `${SPOUSE_WORK}manual-estimate/`;

const SELF_EMPLOYMENT = `${EMPLOYMENT_INCOME_VERIFICATION}business/`;
const SELF_ESTIMATE_INCOME = `${SELF_EMPLOYMENT}estimate-income/`;
const SELF_MONTHLY_AVERAGE = `${SELF_EMPLOYMENT}monthly-average/`;
const SELF_DEPOSIT_SELECTION = `${SELF_EMPLOYMENT}deposits/`;
const SELF_RE_ESTIMATE_INCOME = `${SELF_EMPLOYMENT}re-estimate/`;
const SELF_MANUAL_ESTIMATE_INCOME = `${SELF_EMPLOYMENT}manual-estimate/`;
const SELF_BUSINESS_STRUCTURE = `${SELF_EMPLOYMENT}structure/`;
const SELF_BUSINESS_IDENTITY = `${SELF_EMPLOYMENT}identity/`;
const SELF_BUSINESS_EMPLOYEES = `${SELF_EMPLOYMENT}employees/`;
const SELF_EMPLOYEES_PAYMENT = `${SELF_EMPLOYMENT}employees-payment/`;
const SELF_EMPLOYEES_PAYROLL = `${SELF_EMPLOYMENT}employees-payroll/`;
const SELF_OTHER_BUSINESS = `${SELF_EMPLOYMENT}other-ownership/`;

const SPOUSE_BUSINESS = `${EMPLOYMENT_SPOUSE}business/`;
const SPOUSE_BUSINESS_INFO = `${SPOUSE_BUSINESS}info/`;
const SPOUSE_BUSINESS_ESTIMATE_INCOME = `${SPOUSE_BUSINESS}estimate-income/`;
const SPOUSE_BUSINESS_MONTHLY_AVERAGE = `${SPOUSE_BUSINESS}monthly-average/`;
const SPOUSE_BUSINESS_DEPOSIT_SELECTION = `${SPOUSE_BUSINESS}deposits/`;
const SPOUSE_BUSINESS_RE_ESTIMATE_INCOME = `${SPOUSE_BUSINESS}re-estimate/`;
const SPOUSE_BUSINESS_MANUAL_ESTIMATE_INCOME = `${SPOUSE_BUSINESS}manual-estimate/`;
const SPOUSE_BUSINESS_STRUCTURE = `${SPOUSE_BUSINESS}structure/`;
const SPOUSE_BUSINESS_IDENTITY = `${SPOUSE_BUSINESS}identity/`;
const SPOUSE_BUSINESS_EMPLOYEES = `${SPOUSE_BUSINESS}employees/`;
const SPOUSE_EMPLOYEES_PAYMENT = `${SPOUSE_BUSINESS}employees-payment/`;
const SPOUSE_EMPLOYEES_PAYROLL = `${SPOUSE_BUSINESS}employees-payroll/`;
const SPOUSE_OTHER_BUSINESS = `${SPOUSE_BUSINESS}other-ownership/`;

const ADDITIONAL_INCOME = '/additional-income/'; // additional income verification step
const ADDITIONAL_INCOME_VERIFICATION = `${ADDITIONAL_INCOME}verification/`;
const MANUAL_ADDITIONAL_INCOME = `${ADDITIONAL_INCOME}manual/`;
const MANUAL_ADDITIONAL_INCOME_ADD = `${MANUAL_ADDITIONAL_INCOME}add/`;
const CHECK_ADDITIONAL_INCOME = `${EMPLOYMENT_INCOME_VERIFICATION}check-additional/`;

const ESTIMATE_ADDITIONAL_INCOME = `${ADDITIONAL_INCOME}estimation/`;
const MANUAL_ENTRY_ADDITIONAL_INCOME = `${ADDITIONAL_INCOME}manual-entry/`;
const INCOME_SUMMARY = '/income-summary/'; // income-summary step

const EXPENSES = `${EMPLOYMENT_INCOME_VERIFICATION}expenses/`;
const EXPENSES_AMOUNTS = `${EXPENSES}amounts/`;
const EXPENSES_SPOUSE = `${EMPLOYMENT_SPOUSE}expenses/`;
const EXPENSES_AMOUNTS_SPOUSE = `${EXPENSES_SPOUSE}amounts/`;

const SPOUSE_ADDITIONAL_INCOME = `${ADDITIONAL_INCOME}spouse/`; // additional income verification step
const SPOUSE_ADDITIONAL_INCOME_VERIFICATION = `${SPOUSE_ADDITIONAL_INCOME}verification/`;
const SPOUSE_ESTIMATE_ADDITIONAL_INCOME = `${SPOUSE_ADDITIONAL_INCOME}estimation/`;
const SPOUSE_MANUAL_ENTRY_ADDITIONAL_INCOME = `${SPOUSE_ADDITIONAL_INCOME}manual-entry/`;
const SPOUSE_MANUAL_ADDITIONAL_INCOME = `${SPOUSE_ADDITIONAL_INCOME}manual/`;
const SPOUSE_MANUAL_ADDITIONAL_INCOME_ADD = `${SPOUSE_MANUAL_ADDITIONAL_INCOME}add/`;

const EXTERNAL_LOGIN = '/externalhandover/:short_token';

export {
    SIGN_IN,
    TERMS_OF_USE,
    PRIVACY_POLICY,
    PERSONAL_INFORMATION,
    PERSONAL_INFO_VERIFY,
    PERSONAL_FILING_SELECT,
    PERSONAL_SPOUSE_INFO,
    PERSONAL_PROGRESS,
    HOUSEHOLD_INFORMATION,
    CLAIM_DEPENDENTS,
    ADD_DEPENDENTS,
    CONNECT_BANKS,
    CONNECT_PERSONAL_BANKS,
    CONNECT_BUSINESS_BANKS,
    CONNECT_PERSONAL_JOINT_BANKS,
    CONNECT_JOINT_BANKS,
    BANK_DATA_ANALYZE,
    QUESTION_VERIFICATION,
    SEPARATE_BUSINESS,
    EMPLOYMENT,
    ASSET_INVESTMENT_VERIFICATION,
    ASSET_INVESTMENT_VEHICLE_INFO,
    ASSET_INVESTMENT_PROPERTY_INFO,
    ASSET_INVESTMENT_FINANCED_PROPERTIES,
    ASSET_INVESTMENT_ADDITIONAL_PROPERTIES,
    ASSET_INVESTMENT_FINANCED_VEHICLES,
    ASSET_INVESTMENT_ADDITIONAL_VEHICLES,
    EMPLOYMENT_INCOME_VERIFICATION,
    EMPLOYMENT_FULLTIME,
    EMPLOYMENT_FULLTIME_OWNERSHIP,
    EMPLOYMENT_FULLTIME_ESTIMATE,
    EMPLOYMENT_FULLTIME_DEPOSITS,
    EMPLOYMENT_FULLTIME_REESTIMATE,
    EMPLOYMENT_FULLTIME_MANUAL_ESTIMATE,
    SPOUSE_WORK,
    SPOUSE_WORK_OWNERSHIP,
    SPOUSE_WORK_ESTIMATE,
    SPOUSE_WORK_DEPOSITS,
    SPOUSE_WORK_REESTIMATE,
    SPOUSE_WORK_MANUAL_ESTIMATE,
    SELF_EMPLOYMENT,
    ADDITIONAL_INCOME,
    ADDITIONAL_INCOME_VERIFICATION,
    ESTIMATE_ADDITIONAL_INCOME,
    MANUAL_ENTRY_ADDITIONAL_INCOME,
    INCOME_SUMMARY,
    CHECK_ADDITIONAL_INCOME,
    SELF_ESTIMATE_INCOME,
    SELF_MONTHLY_AVERAGE,
    SELF_DEPOSIT_SELECTION,
    SELF_RE_ESTIMATE_INCOME,
    SELF_MANUAL_ESTIMATE_INCOME,
    EXPENSES,
    EXPENSES_AMOUNTS,
    DEPENDENTS_PROGRESS,
    BANK_PROGRESS,
    EMPLOYMENT_PROGRESS,
    SELF_BUSINESS_STRUCTURE,
    SELF_BUSINESS_IDENTITY,
    SELF_BUSINESS_EMPLOYEES,
    SELF_EMPLOYEES_PAYMENT,
    SELF_EMPLOYEES_PAYROLL,
    SELF_OTHER_BUSINESS,
    SPOUSE_BUSINESS,
    SPOUSE_BUSINESS_ESTIMATE_INCOME,
    SPOUSE_BUSINESS_MONTHLY_AVERAGE,
    SPOUSE_BUSINESS_DEPOSIT_SELECTION,
    SPOUSE_BUSINESS_RE_ESTIMATE_INCOME,
    SPOUSE_BUSINESS_MANUAL_ESTIMATE_INCOME,
    SPOUSE_BUSINESS_STRUCTURE,
    SPOUSE_BUSINESS_IDENTITY,
    SPOUSE_BUSINESS_EMPLOYEES,
    SPOUSE_EMPLOYEES_PAYMENT,
    SPOUSE_EMPLOYEES_PAYROLL,
    SPOUSE_OTHER_BUSINESS,
    SPOUSE_CONTRIBUTION,
    SPOUSE_EMPLOYMENT_TYPE,
    SPOUSE_JOINT_BANK,
    SPOUSE_ONLINE_INFO,
    CONNECT_SPOUSE_PERSONAL_BANKS,
    CONNECT_SPOUSE_BUSINESS_BANKS,
    SEPARATE_BUSINESS_SPOUSE,
    EMPLOYMENT_SPOUSE,
    EXPENSES_SPOUSE,
    EXPENSES_AMOUNTS_SPOUSE,
    SPOUSE_ADDITIONAL_INCOME,
    SPOUSE_ADDITIONAL_INCOME_VERIFICATION,
    SPOUSE_ESTIMATE_ADDITIONAL_INCOME,
    SPOUSE_MANUAL_ENTRY_ADDITIONAL_INCOME,
    SPOUSE_WORK_INFO,
    SPOUSE_BUSINESS_INFO,
    SPOUSE_FINANCED_PROPERTIES,
    SPOUSE_PROPERTY_INFO,
    SPOUSE_ADDITIONAL_PROPERTIES,
    SPOUSE_FINANCED_VEHICLES,
    SPOUSE_VEHICLE_INFO,
    SPOUSE_ADDITIONAL_VEHICLES,
    SPOUSE_ASSETS,
    SPOUSE_ASSETS_INFO,
    CONNECT_PERSONAL_MANUAL_BANKS,
    CONNECT_SPOUSE_PERSONAL_MANUAL_BANKS,
    CONNECT_BUSINESS_MANUAL_BANKS,
    CONNECT_SPOUSE_BUSINESS_MANUAL_BANKS,
    MANUAL_ADDITIONAL_INCOME,
    MANUAL_ADDITIONAL_INCOME_ADD,
    SPOUSE_MANUAL_ADDITIONAL_INCOME,
    SPOUSE_MANUAL_ADDITIONAL_INCOME_ADD,
    EXTERNAL_LOGIN,
    ASSET_INVESTMENT,
    ASSET_INVESTMENT_ADD,
    SPOUSE_ASSET_INVESTMENT,
    SPOUSE_ASSET_INVESTMENT_ADD,
};
