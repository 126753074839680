import { useContext, useEffect } from 'react';
import { InvestmentContext, PersonalInfoContext } from 'contexts';
import OwnedProperties from './Properties/OwnedProperties';
import OwnedVehicles from './Vehicles/OwnedVehicles';
import AdditionalVehicles from './Vehicles/AdditionalVehicles';
import Vehicle from './Vehicles/Vehicle';
import {
    SPOUSE_ASSETS,
    SPOUSE_ASSETS_INFO,
    SPOUSE_FINANCED_PROPERTIES,
    SPOUSE_PROPERTY_INFO,
    SPOUSE_ADDITIONAL_PROPERTIES,
    SPOUSE_FINANCED_VEHICLES,
    SPOUSE_VEHICLE_INFO,
    SPOUSE_ADDITIONAL_VEHICLES,
    ASSET_INVESTMENT_ADDITIONAL_PROPERTIES,
    ASSET_INVESTMENT_ADDITIONAL_VEHICLES,
    ASSET_INVESTMENT_FINANCED_PROPERTIES,
    ASSET_INVESTMENT_FINANCED_VEHICLES,
    ASSET_INVESTMENT_PROPERTY_INFO,
    ASSET_INVESTMENT_VEHICLE_INFO,
    ASSET_INVESTMENT_VERIFICATION,
    ASSET_INVESTMENT,
    ASSET_INVESTMENT_ADD,
    SPOUSE_ASSET_INVESTMENT,
    SPOUSE_ASSET_INVESTMENT_ADD,
} from '../../routes/paths';
import AdditionalProperties from './Properties/AdditionalProperties';
import { RetirementWelcome } from 'screens/Welcome';
import api, { endpoints } from 'api';
import Property from './Properties/Property';
import { InnerRoutes } from 'routes';
import SpouseHasAssetInvest from './SpouseHasAssetInvest';
import ClientKnowsSpouseAssetInvest from './ClientKnowsSpouseAssetInvest';
import { STATUS_MARRIED, STATUS_MARRIED_SEPARATELY } from 'const';
import axios from 'axios';
import CheckInvestments from './Investments/CheckInvestments';
import AddInvestments from './Investments/AddInvestments';

const assetInvestmentRoutes = [
    {
        path: `${SPOUSE_FINANCED_PROPERTIES}:property_id/`,
        component: <Property spouse />,
        options: { exact: true },
    },
    {
        path: ASSET_INVESTMENT_ADDITIONAL_VEHICLES,
        component: <OwnedVehicles />,
        options: { exact: true },
    },
    {
        path: `${ASSET_INVESTMENT_FINANCED_VEHICLES}:vehicle/`,
        component: <Vehicle />,
        options: { exact: true },
    },
    {
        path: ASSET_INVESTMENT_VEHICLE_INFO,
        component: <AdditionalVehicles />,
        options: { exact: true },
    },
    {
        path: ASSET_INVESTMENT_PROPERTY_INFO,
        component: <OwnedProperties />,
        options: { exact: true },
    },
    {
        path: ASSET_INVESTMENT_ADDITIONAL_PROPERTIES,
        component: <AdditionalProperties />,
        options: { exact: true },
    },
    {
        path: `${ASSET_INVESTMENT_FINANCED_PROPERTIES}:property_id/`,
        component: <Property />,
        options: { exact: true },
    },
    {
        path: ASSET_INVESTMENT_VERIFICATION,
        component: <RetirementWelcome />,
        options: { exact: true },
    },
    {
        path: SPOUSE_ADDITIONAL_VEHICLES,
        component: <OwnedVehicles spouse />,
        options: { exact: true },
    },
    {
        path: `${SPOUSE_FINANCED_VEHICLES}:vehicle/`,
        component: <Vehicle spouse />,
        options: { exact: true },
    },
    {
        path: SPOUSE_ASSETS,
        component: <SpouseHasAssetInvest />,
        options: { exact: true },
    },
    {
        path: SPOUSE_ASSETS_INFO,
        component: <ClientKnowsSpouseAssetInvest />,
        options: { exact: true },
    },
    {
        path: SPOUSE_VEHICLE_INFO,
        component: <AdditionalVehicles spouse />,
        options: { exact: true },
    },
    {
        path: SPOUSE_PROPERTY_INFO,
        component: <OwnedProperties spouse />,
        options: { exact: true },
    },
    {
        path: SPOUSE_ADDITIONAL_PROPERTIES,
        component: <AdditionalProperties spouse />,
        options: { exact: true },
    },
    {
        path: ASSET_INVESTMENT,
        component: <CheckInvestments />,
        options: { exact: true },
    },
    {
        path: ASSET_INVESTMENT_ADD,
        component: <AddInvestments />,
        options: { exact: true },
    },
    {
        path: SPOUSE_ASSET_INVESTMENT,
        component: <CheckInvestments spouse />,
        options: { exact: true },
    },
    {
        path: SPOUSE_ASSET_INVESTMENT_ADD,
        component: <AddInvestments spouse />,
        options: { exact: true },
    },
];

function AssetInvestmentVerification() {
    const { personalUid, filingStatus, spouseUid } = useContext(
        PersonalInfoContext
    );
    const { reFetchInvestments } = useContext(InvestmentContext);

    // Credit Post Process call then fetch clients properties and vehicles
    useEffect(() => {
        const creditDataProcess = async () => {
            const requests = [
                api({
                    method: 'post',
                    url: endpoints.CREDIT_REPORT_PROCESS,
                    data: {
                        profile: personalUid,
                    },
                }),
            ];

            // Spouse Post Process call for investment
            if (
                (filingStatus === STATUS_MARRIED ||
                    filingStatus === STATUS_MARRIED_SEPARATELY) &&
                spouseUid
            ) {
                requests.push(
                    api({
                        method: 'post',
                        url: endpoints.CREDIT_REPORT_PROCESS,
                        data: {
                            profile: spouseUid,
                        },
                    })
                );
            }

            try {
                const req = await axios.all(requests);
                const res = await req;
                if (Array.isArray(res) && res.length) {
                    reFetchInvestments();
                }
            } catch (e) {
                console.log(e);
            }
        };

        if (personalUid) creditDataProcess();
    }, [personalUid, reFetchInvestments, filingStatus, spouseUid]);

    return <InnerRoutes routes={assetInvestmentRoutes} />;
}

export default AssetInvestmentVerification;
