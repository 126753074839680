import { Fragment, useState, useEffect, useContext } from 'react';
import { Loading } from 'carbon-components-react';
import { PersonalInfoContext, contextActionTypes } from 'contexts';
import Switch from 'components/Switch';
import Navigation from 'components/Navigation';
import { useStepController } from '../../hooks';
import { useCallback } from 'react';
import api, { endpoints } from 'api';
import { history } from 'routes';
import { toUsCurrency } from 'lib';

const {
    ADDITIONAL_INCOME_CORRECT,
    ADDITIONAL_INCOME_CORRECT_SPOUSE,
} = contextActionTypes;

function CalculateIncome({ spouse }) {
    const {
        personalUid,
        spouseUid,
        dispatchEmployment,
    } = useContext(PersonalInfoContext);
    const [choice, setChoice] = useState();
    const [additionalIncome, setAdditionalIncome] = useState({});
    const [loading, setLoading] = useState(true);
    const { nextStep, previousStep } = useStepController();

    const { uid, amount, verified } = additionalIncome;

    useEffect(() => {
        const estimatedIncome = async () => {
            try {
                const req = await api({
                    url: endpoints.ESTIMATE_ADDITIONAL_INCOME,
                    params: {
                        profile: spouse ? spouseUid : personalUid,
                    },
                });
                const res = await req.data;

                if (res) {
                    setAdditionalIncome(res);
                    setLoading(false);
                }
            } catch (e) {
                console.log(e.response);
            }
        };
        if ((spouse && spouseUid) || (!spouse && personalUid)) {
            estimatedIncome();
        }
    }, [personalUid, spouseUid, spouse]);

    const handleChange = useCallback(
        ({ index }) => {
            dispatchEmployment({
                type: spouse
                    ? ADDITIONAL_INCOME_CORRECT_SPOUSE
                    : ADDITIONAL_INCOME_CORRECT,
                payload: index === 0,
            });

            setChoice(index);
        },
        [spouse, dispatchEmployment]
    );

    useEffect(() => {
        if (typeof verified === 'boolean') {
            handleChange({
                index: verified ? 0 : 1,
            });
        }
    }, [verified, handleChange]);

    const saveChoice = async () => {
        try {
            const req = await api({
                method: 'put',
                url: `${endpoints.ESTIMATE_ADDITIONAL_INCOME}${uid}/`,
                data: {
                    verified: choice === 0 ? true : false,
                },
            });
            const res = await req.data;

            if (res) {
                history.push(nextStep.path);
            }
        } catch (e) {
            console.log(e.response);
        }
    };

    return (
        <Fragment>
            <h1>
                Confirm Estimated Additional Income{' '}
                {spouse ? (
                    <Fragment>
                        For Your <span className="color-blue">Spouse</span>
                    </Fragment>
                ) : (
                    ''
                )}
            </h1>
            {loading && (
                <Fragment>
                    <h2>Estimated Additional Net Income</h2>
                    <p>
                        Verifilink is calculating your{' '}
                        {spouse ? (
                            <Fragment>
                                <span className="color-blue">Spouse's</span>
                            </Fragment>
                        ) : (
                            ''
                        )}
                        Additional Income.Please wait...
                        <span className="icon">
                            <Loading small withOverlay={false} />
                        </span>
                    </p>
                </Fragment>
            )}
            {!loading && (
                <Fragment>
                    <h2>
                        Estimated Additional Net Income:{' '}
                        <strong style={{ color: '#008ac8' }}>
                            {toUsCurrency(amount)}
                        </strong>
                    </h2>
                    <p className="mb-13">
                        Please verify that the below Additional average Income
                        is accurate before continuing.
                    </p>
                    <Switch
                        className="my-30"
                        onChange={handleChange}
                        choice={choice}
                    />
                </Fragment>
            )}
            <Navigation
                onPrevious={() => history.push(previousStep.path)}
                onNext={saveChoice}
                disableNext={typeof choice !== 'number'}
            />
        </Fragment>
    );
}
export default CalculateIncome;
