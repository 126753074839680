import { useContext, useEffect, useState } from 'react';
import SignInPhase from './SignInPhase';
import RequestOtpPhase from './RequestOtpPhase';
import { MetaContext } from '../../contexts';
import { Fragment } from 'react';
import styled from 'styled-components';

function SignIn() {
    const [canSetOtp, showOtp] = useState(false);
    const [clientInfo, setClientInfo] = useState({});
    const {
        updateMeta: { updatePageTitle },
    } = useContext(MetaContext);

    useEffect(() => {
        updatePageTitle();
    }, [updatePageTitle]);

    const handleOtpPhase = (clientInfo) => {
        if (window["hj"] && typeof window.hj === "function" && clientInfo["CaseID"]) {
            window.hj('identify', clientInfo.CaseID, {});
        }
        setClientInfo(clientInfo);
        showOtp(true);
    };

    return (
        <Fragment>
            {canSetOtp ? (
                <RequestOtpPhase client={clientInfo} />
            ) : (
                <SignInPhase onSubmit={handleOtpPhase} />
            )}

            <Version />
        </Fragment>
    );
}

const Version = () => <VersionStyled>Verifilink V 1.0</VersionStyled>;

const VersionStyled = styled.p`
    color: #6a7581;
    font-size: 11px;
    font-weight: 600;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;

    @media (min-width: 751px) {
        bottom: 50px;
    }
`;

export default SignIn;
